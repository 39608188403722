"use strict";

!function(a){a.fn.datepicker.dates.ja={days:["SUN","MON","TUE","WED","THU","FRI","SAT"],daysShort:["SUN","MON","TUE","WED","THU","FRI","SAT"],daysMin:["SUN","MON","TUE","WED","THU","FRI","SAT"],months:["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月"],monthsShort:["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月"],today:"今日",format:"yyyy/mm/dd",titleFormat:"yyyy年mm月",clear:"クリア"}}(jQuery);

$.ajaxSetup({
  headers: {
      "Authorization": "Bearer " + $.cookie('ssoToken'),
      'ngrok-skip-browser-warning': 'true',
  }
});


var ALL_USERS = ALL_USERS || [];
var SHUKUJITSU = SHUKUJITSU || '';

TF = TF || {};
TF.INIT = TF.INIT || {};
TF.staff = ALL_USERS;
TF.working_days = 6; //営業日範囲 -1する
TF.intervaltime = 50; //応答待ちインターバル
TF.apointment_check_interval = 60; //見込み通知チェックインターバル
TF.apointment_check_time = 300; //見込み通知チェックインターバル
TF.duration_threshold = 30; //通話時間閾値
TF.limit = 100; //表示件数

TF.job_data_switch_date = '2021-06-04 11:46';

if(location.href.indexOf('call-in.jp') > -1) {
  TF.job_data_switch_date = '2021-06-08 00:00';
}


TF.ajax_obj = {};
TF.api.key = '/';
TF.mp3dir = '';

//内閣府提供祝日一覧CSV
TF.api.shukujitsu = 'https://www8.cao.go.jp/chosei/shukujitsu/syukujitsu_kyujitsu.csv';
//通知用アドレス
//見込み通知用（アポインターグループ）
TF.talknote_group = 'g-1000002590-390399@mail.talknote.com';
//見込み通知用（管理者グループ）
TF.talknote_group_admin = 'g-1000002590-390400@mail.talknote.com';
//見込み予約時刻リマインド通知用
TF.talknote_group_remind = 'g-1000002590-390401@mail.talknote.com';
//エラー通知用
TF.talknote_group_error = 'g-1000002590-420198@mail.talknote.com';

TF.history = {};
TF.calender = {};
TF.calender.startDate = "2018/03/01";
TF.short_month = {
	'1月':'Jan',
	'2月':'Feb',
	'3月':'Mar',
	'4月':'Apr',
	'5月':'May',
	'6月':'June',
	'7月':'July',
	'8月':'Aug',
	'9月':'Sep',
	'10月':'Oct',
	'11月':'Nov',
	'12月':'Dec'
};

TF.status_sort = {
  kadenzumi: {name: '架電済み', list:[]},
  mikomi: {name: '見込み', list:[]},
  daihyoukotowari: {name: '代表断り', list:[]},
  yakuinkotowari: {name: '役員断り', list:[]},
  sonota: {name: 'その他', list:[]},
  kinshi: {name: '禁止', list:[]},
};

//エリアのプルダウン用仮データ
TF.area_names = TF.area_names || [
  {"id":1,"name":"東京都", "prefecture_code":"13", "identification":"03"},
  {"id":2, "prefecture_code":"13", "name":"東京都", "identification":"042"},
  {"id":3, "prefecture_code":"26", "name":"大阪府", "identification":"06"}
];

//文字置換
TF.call_counterpart_label = '商材';

//業種旧データ（開発用）
TF.jobs = [{id:"9",name:"ヘアサロン"},{id:"10",name:"ネイル・まつエク"},{id:"11",name:"エステ"},{id:"12",name:"治療院"},{id:"13",name:"リフォーム"},{id:"14",name:"ハウクリ・不用品回収"},{id:"15",name:"士業・コンサル"},{id:"16",name:"冠婚葬祭"},{id:"17",name:"ペット"},{id:"18",name:"歯科"},{id:"19",name:"不動産"},{id:"20",name:"介護"},{id:"21",name:"車・整備士"},{id:"22",name:"写真館"},{id:"23",name:"スクール・塾"},{id:"24",name:"塾"},{id:"25",name:"ファッション"},{id:"26",name:"食品"},{id:"27",name:"アウトドア系"},{id:"28",name:"健康器具"},{id:"29",name:"コスメ・美容品"},{id:"30",name:"インテリア・家具"},{id:"31",name:"ガーデニング"},{id:"32",name:"日用品"},{id:"33",name:"DIY・工具"},{id:"34",name:"ペット用品"},{id:"35",name:"ベビー・マタニティ"},{id:"36",name:"車系"},{id:"37",name:"美容系"},{id:"38",name:"飲食系"},{id:"39",name:"営業・サービス系"},{id:"40",name:"配送・ドライバー系"},{id:"41",name:"警備系"},{id:"42",name:"建築・土木系"},{id:"43",name:"開発設計系"},{id:"44",name:"士業系"},{id:"45",name:"事務系"},{id:"46",name:"製造・技術系"},{id:"47",name:"歯科系"},{id:"48",name:"医療系"},{id:"49",name:"介護福祉系"},{id:"50",name:"車系"},{id:"51",name:"教育系"},{id:"52",name:"農業・林業系"}];

var DEV_COUNTERPARTS = [];

if(location.href.match(/ban_list|\/?-?list/)) {
  document.body.innerHTML = document.body.innerHTML.split('エリア').join('都道府県');
}

(function(window) {
  // $.ajax({
  //   url: TF.api.url + "account/callin-staffs",
  //   type: 'GET'
  // }).done(function (data) {
  //   console.log(data)
  // }).fail(function (jqXHR, textStatus, errorThrown) {
  //   console.error(jqXHR, textStatus, errorThrown);
  // });


  //console.log()の改造
  /*
  const log = console.log.bind(console);
  console.log = function(...args){

    log(...args);
    if($('.p-call-entry')[0] && typeof args[0] == 'string' && args[0].match(/error/i)) {
      let subject = '';

      if(args[0].match(/error/i)) {
        subject = `[${CURRENT_USER.telforce_id}] ${CURRENT_USER.name}でエラーが発生しています`;
      }

      if(args[0].indexOf('履歴登録失敗') > -1) {
        subject = `[${CURRENT_USER.telforce_id}] ${CURRENT_USER.name}が履歴登録失敗しています`;
      }

      let opt = {
        url:  '/api/push',
        type: 'POST',
        data: {
          to: TF.talknote_group_error,
          message: `\n\n${JSON.stringify(...args)}`,
          subject: subject
        }
      }
      getApiDataCommon(opt, () => {}, () => {});
    }
  }*/

  //JSurlのパス取得
  TF.js_url_path = (
    document.currentScript
        ? document.currentScript.src
        : document.getElementsByTagName('script')[document.getElementsByTagName('script').length-1].src
    )
    .replace(new RegExp('^'+location.origin), '')
    .replace(/[^\/]+$/, '');

  //ユーザー情報をセット
  if(location.href.indexOf('.html') == -1) getUserInfoForUtility();

  const $body = $('body');
  
  // 日本の祝日一覧取得
  reGetData('shukujitsu');

  // 業種切り替え日
  TF.job_data_switch_date = moment(TF.job_data_switch_date, 'YYYY-MM-DD HH:mm').unix();

  //SP用ヘッダ周り構築
  const user_info = $('.-user.js-link').clone(true);
  const user_info_box = $('.p-user-info-box').clone(true);
  $('.l-header-navi-area').append($('<div class="p-user-info-sp" />').append(user_info)).append(user_info_box);

  //SP用ドロワーメニュー構築
  const target = $('<div class="p-menu-sp-navi-header" />').append($('.l-main-header-menu-item:eq(1) .l-main-header-menu-item-link').clone(true)).append('&nbsp;&nbsp;').append($('.l-main-header-menu-item:eq(2) .l-main-header-menu-item-link').clone(true));
  const navi = $('.l-header-navi').eq(0).clone(true);

  const adminnavi = $('.l-header-navi-area .-admin').clone(true);
  const menu = $('<div class="p-menu-sp-navi" />').append(target).append(navi).append(adminnavi);
  const menu_area = $('<div class="p-menu-sp-navi-area" />').append(menu);

  $body.append(menu_area);

  $('.js-menu-toggle').on('click', () => {
    if(!$body.data('status') || $body.attr('data-status') == 'is-menu-off'){
      $body.attr('data-status', 'is-menu-active');
    } else {
      $body.attr('data-status', 'is-menu-off');
    }
  });

  //ユーザー情報表示
  $('.-user.js-link').on('click', function () {
    var sp = matchMedia('only screen and (max-width: 1400px)').matches ? '.l-header-navi-area ' : '.l-main-header ';
    $.ajax({
      url: TF.api.url + "account/me",
      type: 'GET'
    }).done(function (data) {
      $(sp + '.js-user-info-box #account-email').text(data.data.mail);
      $(sp + '.js-user-info-box #account-tel').text(data.data.tel);
      if(data.data.team_id !== null){
        $(sp + '.js-user-info-box #account-team-id').text(data.data.team_id);
      }
      $(sp + '.js-user-info-box').fadeIn(200);
      $('.p-user-info-box-close').one('click', function () {
        $('.js-user-info-box').fadeOut(200);
      });
    }).fail(function (jqXHR, textStatus, errorThrown) {
      if (data.statusText == 'abort') {
        console.log('cancel');
      }
    });
  });

  //限定アカウント
  TF.dev_mode = false;
  let account_id = $('.js-user-info-box .p-user-info-box-dl:eq(0) .p-user-info-box-dd:eq(0)').text() - 0;
  if(account_id == 9998) {
    TF.dev_mode = true;
    $('th.p-data-table-th.-convert, td.p-data-table-td.-convert').show();
  }


  //モーダル初期化
  if($('.-search.js-modal-area')[0]) modalInit('.-search');
  
  //モーダル閉じる
  $(document).on('click', (e) => {
    const target = $(e.target);
    
    if(target.hasClass('js-modal-close') || target.hasClass('js-modal-area')) { 
      $('.-active.js-modal-area').fadeOut(100, function() {
        TF.modal_click_obj = null;
        $(this).removeClass('-active');
        $('.js-btn-reset:not(.call_entry)').mousedown();
      });
    }

    if(target.hasClass('p-menu-sp-navi-area')) {
      $('.js-menu-toggle').click();
    }
  });

  //音源初期設定  
  let cnt = 0;
  let sit = setInterval(() => {

    if(typeof audiojs !== 'undefined') {
      clearInterval(sit);
      const a = audiojs.createAll({});
      playerCss();

      // Load in the first track
      const audio = a[0];
      const first = TF.js_url_path + "first.mp3";
      audio.load(first);
    }
    cnt++;

    if(cnt > 200) {
      clearInterval(sit);
    }
  }, TF.intervaltime);

  // enterキーでform送信無効化
  $("input").on("keydown", (e) => {
    if ((e.which && e.which === 13) || (e.keyCode && e.keyCode === 13)) {
        return false;
    } else {
        return true;
    }
  });

  //架電予約チェック
  if(!$('body').hasClass('-user_report')) apointCheck();
})(window);
