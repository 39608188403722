//プルダウンセレクトに各データセット
function setSelectList(list, dataid) { 

  let list_tmp = list || ['staff', 'call_counterpart', 'call_status', 'office', 'dept_unit', /*'jobs',*/ 'area'];
  
  if(typeof TF.select2_list == 'object' && TF.select2_list.length > 0) {
    list_tmp = TF.select2_list;  
    TF.select2_list = [];  
  } 
  TF.select2_ids = [];
  TF.select2opt = {};
  let values, parent, table;


  list_tmp.filter((v) => {
    let elm = '';
    let id = v.id? v.id: v;


    elm = id + '_select';
    table = dataid ? dataid.replace('_edit', ''): v.replace('_edit', '');
    values = {}
    values.ids = [];
    values.names = [];
    values.relation_ids = [];

    if(typeof v.parent == 'object' && typeof TF.list_contents.select2values != 'undefined') {
      parent = v.parent;
      
      if(parent.id && typeof TF.list_contents.select2values[parent.id] == 'object') {  

        TF.list_contents.select2values[parent.id].filter((vv) => {
          values.ids.push(vv.id);
          values.names.push(vv.name);

          if(vv.relation_id) {
            values.relation_ids.push(vv.relation_id);
          }
        });
      }
    }


    if($('#' + elm)[0]) {
      TF.select2_ids.push('#' + elm);

      let options = [];
      let data = TF[table];
      if(data) {

        for(let i in data) {
          
          let name = table == 'office'? data[i].office_name: data[i].name;
          name = table == 'dept_unit'? data[i].unit_name: name;

          //旧業種に（旧）をつける
          name = table == 'jobs'? '(旧)' + data[i].name: name;
          
          //data属性を増やす
          let add_data = '';
          //業種カテゴリの場合にdata属性にデータをつっこむ
          if(table == 'jobs_category_list') {
            name = data[i].counterpart + ' - ' + data[i].name;
            let jobs = JSON.stringify(data[i].jobs);
            add_data = ` data-counterpart_id="${data[i].counterpart_id}" data-jobs='${jobs}'`;
          }

          let id = data[i].telforce_id || data[i].id;
          let id_text = data[i].telforce_id? ' [' + id + ']': '';
          options.push(`<option value="${id}" data-table="${table}"${add_data}>${name}${id_text}</option>`);


        };
        options = options.join('');

        //幅を調整
        let select = $('#' + elm);
        const text = select.find('optgroup').attr('label');
        if(select.parents().hasClass('p-modal')) {
          select.css('min-width','100%');
        } else {
          select.css('min-width',(text.length) + 'em');
        }
        
        select.find('optgroup').empty().append(options);

        TF.select2opt[elm] = {
          language: "ja", 
          placeholder: text,
          //debug: true,
          dropdownAutoWidth: true,
          width: 'style'
        };

        if(elm.indexOf('staff') > -1) {
          TF.select2opt[elm].maximumSelectionLength = 4;//最大4件まで
          TF.select2opt[elm].language = {
            maximumSelected: function (args) {
              var message = args.maximum + ' 件のみ選択可';
              return message;
            },
          }
        }

        if(
          elm.indexOf('jobs_edit') > -1 ||
          elm.indexOf('call_counterpart_edit') > -1
        ) {
          TF.select2opt[elm].disabled = true;//変更不可
        }


        /******　セレクト時　*******/
        TF.set_select2_list[elm] = select.select2(TF.select2opt[elm])
        .on('select2:select', (e) => {
          //data[]
          let id = $(e.currentTarget).val();
          if(data[id]) {
            TF.select_data = data[id];

            if(typeof TF.getSelectData == 'function') {
              e.preventDefault = function(){};
              TF.getSelectData(e);
            }

            if(typeof TF.selectAction == 'function') {
              e.preventDefault = function(){};
              TF.selectAction(e);
            }
          }

          //商材に紐づいている業種のみ選択可に
          let table_name = $(e.currentTarget).attr('id');
          let jobs_categories = [];
          let count = 0;

          //商材選択時
          if(table_name.indexOf('call_counterpart') > -1 && id.length > 0) {
            let jobs_category_list = $('#jobs_category_list_select');

            for(let i in TF.jobs_category_list[i]) {
              if(jobs_categories.indexOf(TF.jobs_category_list[i].id) == -1) {
                jobs_categories.push(TF.jobs_category_list[i].id);
              }
            }

            //すでに業種カテゴリが選択されていたら
            if(jobs_category_list.val()) {
              let ids = jobs_category_list.val();
              let tmp = [];
              for(let i in ids) {
                if(jobs_categories.indexOf((ids[i]|0)) > -1) {
                  tmp.push(ids[i]);
                }
              }

              jobs_category_list.val(tmp).trigger('change');
            }

            //optionを無効化
            let opts = jobs_category_list.find('option'); 
            opts.attr('disabled', true);
            if(!jobs_category_list.val()) {
              jobs_category_list.parent().find('.select2-search__field').attr('placeholder','選択された商材の業種カテゴリを選択');
            }

            for(let ii in opts) {
              let call_counterpart_id = opts.eq(ii).data('counterpart_id');
             
              for(let iii in id) {
                if((id[iii]|0) == (call_counterpart_id|0)) opts.eq(ii).removeAttr('disabled');
              }
            }
          }

          //業種カテゴリ選択時
          if(table_name.indexOf('jobs_category_list') > -1 && id.length > 0) {
            let jobs_select = $('#jobs_select');

            //一旦select2解除して追加したoption削除して再初期化
            jobs_select.select2("destroy");
            jobs_select.find('.add_job').remove();
            jobs_select.select2(TF.select2opt.jobs_select);

            const text = jobs_select.find('optgroup').attr('label');
            if(jobs_select.parents().hasClass('p-modal')) {
              jobs_select.css('min-width','100%');
            } else {
              jobs_select.css('min-width',(text.length) + 'em');
            }

            let jobs_ary = [];
            let jobs_options = [];

            for(let i in id) {
              let data_jobs = $('#jobs_category_list_select option[value="' + id[i] + '"]').data('jobs');
              if(data_jobs.length > 0) {
                for(let ii in data_jobs) {
                  if(jobs_ary.indexOf(data_jobs[ii]) == -1) jobs_ary.push(data_jobs[ii]);
                }
              } else {
                console.log('業種が設定されていません');
              }
            }

            //すでに業種が選択されていたら
            if(jobs_select.val()) {
              let ids = jobs_select.val();
              let tmp = [];
              for(let i in ids) {
                if(jobs.indexOf((ids[i]|0)) > -1) {
                  tmp.push(ids[i]);
                }
              }

              jobs_category_list.val(tmp).trigger('change');
            }

            //optionを無効化
            let opts = jobs_select.find('option');
            opts.attr('disabled', true);
          
            //プレースホルダの文言変更
            if(!jobs_select.val()) {
              jobs_select.parent().find('.select2-search__field').attr('placeholder','選択された業種カテゴリの業種を選択');
            }

            //業種があれば追加
            if(jobs_ary.length > 0) {
              for(let i in jobs_ary) {
                jobs_options.push(`<option class="add_job" value="${jobs_ary[i].id}">${jobs_ary[i].name}</option>`);
              }
              
              jobs_options = jobs_options.join('');
              jobs_select.find('optgroup').prepend(jobs_options);
              jobs_select.trigger('change');
            } else {
              message('この業種カテゴリに業種が設定されていません', 1);
            }

            

          }


        })

                
        /******　セレクト解除時　*******/
        .on('select2:unselect', (e) => {
          let _this = $(e.currentTarget);

          if(_this.parents().hasClass('p-modal')) {
            _this.parent().find('.select2-search__field').width('100%');
          } else {
            _this.parent().find('.select2-search__field').width((text.length + 1)+ 'em');
          }

          var table_name = _this.attr('id');
          if(table_name.match(/jobs_[0-9]+_select/)) {
            parent.id = table_name.match(/jobs_([0-9]+)_select/)[1];
          }


          if(typeof TF.unSelectData == 'function') {
            TF.unSelectData();
          }

          //商材に紐づいている業種のみ選択可に
          let count = 0;
          var valid = _this.val() || [];
          let jobs = [];
          let jobs_category_list = $('#jobs_category_list_select');

          if(table_name.indexOf('call_counterpart') > -1 && !valid) {
            jobs_category_list.find('option').removeAttr('disabled'); //一旦選択不可解除
          } 

          if(!jobs_category_list.val()) {
            if($('#call_counterpart_select').val()) {
              jobs_category_list.parent().find('.select2-search__field').attr('placeholder','選択された商材の業種カテゴリを選択');
            } else  {
              jobs_category_list.parent().find('.select2-search__field').attr('placeholder','業種を選択');
            }
          }
          
          
          //業種カテゴリ解除時
          if(table_name.indexOf('jobs_category_list') > -1) {

            let jobs_ary = [];
            let jobs_options_dom = [];
            let jobs_select = $('#jobs_select');
            let jobs_opts = jobs_select.find('option');

            //一旦select2解除して追加したoption削除して再初期化
            jobs_select.select2("destroy");
            jobs_select.find('.add_job').remove();
            jobs_select.select2(TF.select2opt.jobs_select);

            //残った選択カテゴリ項目から業種取得
            if(valid.length > 0) {

              for(let i in valid) {
                let data_jobs = $('#jobs_category_list_select option[value="' + valid[i] + '"]').data('jobs');
                if(data_jobs.length > 0) {
                  for(let ii in data_jobs) {
                    if(jobs_ary.indexOf(data_jobs[ii]) == -1) jobs_ary.push(data_jobs[ii]);
                  }
                } else {
                  console.log('業種が設定されていません');
                }
              }

              //業種があれば
              if(jobs_ary.length > 0) {
                //optionを無効化
                
                

                //プルダウンに追加
                for(let i in jobs_ary) {
                  jobs_options_dom.push(`<option class="add_job" value="${jobs_ary[i].id}">${jobs_ary[i].name}</option>`);
                }
                
                jobs_options_dom = jobs_options_dom.join('');
                jobs_select.find('optgroup').prepend(jobs_options_dom);
                jobs_select.trigger('change');
              } else {
                message('この業種カテゴリに業種が設定されていません', 1);
                jobs_opts.removeAttr('disabled');
              }
            } else {
              jobs_opts.removeAttr('disabled');
            }
          }
        });

        //削除ボタンにdata振り
        if(values.ids) {
          select.val(values.ids).trigger("change");
          values.names.filter((v, i) => {
            if(values.relation_ids[i]) {
              $(select[0]).parent().find('.select2-selection__choice[title="' + v + '"] .select2-selection__choice__remove').attr('data-id', values.relation_ids[i]);
            }
          });

          select.values = values;
        }
        if(select.parents().hasClass('p-modal')) {
          select.parent().find('.select2-search__field').width('100%');
        } else {
          select.parent().find('.select2-search__field').width((text.length + 1)+ 'em');
        }
      }
    }
  });

}
