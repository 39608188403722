/*****
メッセージをSlack投稿するクラス

// Slack投稿用
TF.slack_channel = {}
// slack投稿URL
TF.slack_channel.post_URL = 'https://slack.com/api/chat.postMessage';
TF.slack_channel.token = 'xoxb-5478590747857-6654389254358-bYqMkGTZBSaTPgtrFGJiwRyN';
//見込み通知用（アポインターグループ）
TF.slack_channel.mikomi = '';
//見込み通知用（管理者グループ）
TF.slack_channel.admin = '';
//見込み予約時刻リマインド通知用
TF.slack_channel.remind = '';
//エラー通知用
TF.slack_channel.error = 'C06K0B406ET';

******/

const MessagePostClass = class {
  constructor(param) {
    this.slack = TF.slack_channel
    this.param = param
    this.textHeader = ''

    switch(this.param.type) {
      case 'error': {
        this.channelID = this.slack.error 
        this.textHeader = this.errorHeader()
      }
      default: {
        this.channelID = this.param.channelID
        this.textHeader = this.defaultHeader() 
      }
    }
  }

  defaultHeader() {
    return `(1) プログラム名: Call-in
    (2) 環境：${this.param.isProd? 'production': 'dev'}
    (3) 担当：<@U05KVHTBBSN>
    (4) プラグラムURL: ${location.href}
    (5) メッセージ：`
  }

  errorHeader () {
    return  `(1) プログラム名: Call-in
    (2) 環境：${this.param.isProd? 'production': 'dev'}
    (3) 担当：<@U05KVHTBBSN>
    (4) プラグラムURL: ${location.href}
    (5) エラーメッセージ：`
  }
  
  post() {
    if(!this.channelID) {
      console.error('投稿先のchannel IDがありません')
      return false
    }  

    try {
      fetch(this.slack.post_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `token=${encodeURIComponent(this.slack.token)}&channel=${encodeURIComponent(this.channelID)}&text=${encodeURIComponent(this.textHeader + this.param.text)}`
      })
      .then(function (response) {
        return response.json()
      }).then(function (response) {

        if(response.message == "not current version") {
          alert(`バージョンが違うようです。\n更新します。`);
          location.reload()
          postResult = false
          return false
        } else {
          
          console.log('投稿完了', response)

          return response
        }

      })
      .catch((e) => {
        console.error(e.stack, e.message)
      })
    } catch(e) {
      console.error(e)
    }
  }
}