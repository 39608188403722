function apointCheck(entry_apoint_date) {

  let db = localStorage;
  let apoint_dates = db.getItem('sgtelforce_apoint_dates');
  if(apoint_dates) {
    apoint_dates = JSON.parse(apoint_dates);
  } else {
    return false;
  }

  let current = moment().unix();
  let today_start = moment().startOf('days').unix();
  let today_end = moment().endOf('days').unix();
  let today = moment().format('YYYY-MM-DD');
  let todays_apoint = {};

  //今日より古いのは削除
  let tmp = {};
  for(let i in apoint_dates) {
    if(today_start <= (i|0)) tmp[i] = apoint_dates[i];

    //今日に該当するデータを走査
    //配列かどうか
    if(Array.isArray(apoint_dates[i])) { 
      //データがあれば格納
      for(let ii in apoint_dates[i]) {
        if(today_start <= i && today_end >= i && apoint_dates[i][ii].staff_id == CURRENT_USER.telforce_id && !apoint_dates[i][ii].cancel_flag && !apoint_dates[i][ii].closing_flag) {
          if(!todays_apoint[i]) todays_apoint[i] = [];
          todays_apoint[i].push(apoint_dates[i][ii]);
        }
      }
    } else {
      //データがあれば格納
      if(today_start <= i && today_end >= i && apoint_dates[i].staff_id == CURRENT_USER.telforce_id && !apoint_dates[i].cancel_flag && !apoint_dates[i].closing_flag) {    
        if(!todays_apoint[i]) todays_apoint[i] = [];
        todays_apoint[i].push(apoint_dates[i]);
      }
    }
  }

  apoint_dates = tmp;
  tmp = JSON.stringify(tmp);
  db.setItem('sgtelforce_apoint_dates', tmp);

  let check_modal = `
<div class="p-modal-area -apoint-check js-modal-area">
<div class="p-modal">
  <div class="l-pain">
    <div class="l-pain-child">
      <div class="p-data">
        <h3 class="u-ttl">本日 %%TODAY%% は以下の架電予約があります</h3>
        <a href="javascript:void(0)" class="p-modal-close js-modal-close"></a>
        <div class="p-widget-content">
          %%DOM%%
        </div>
      </div>
    </div>
  </div>
</div>
</div>`;

  let check_widget = `<div class="p-widget -appoint_info u-shadow">
<div class="p-widget-inner">
  <h3 class="u-ttl"><span class="is-pc-tablet">本日 %%TODAY%% の架電予約</span><span class="is-sp">本日の予約</span></h3>
  <div class="p-widget-content">
    <a href="javascript:void(0)" class="p-modal-close p-widget-close js-widget-close"></a>
    <a href="javascript:void(0)" class="p-widget-list-toggle js-widget-list-toggle close">本日の予約一覧</a>
    %%DOM%%
  </div>
</div>
</div>`;

  const todaysCheck = () => {

    //未チェックであれば
    if(today_start <= current) {
      //今日に該当するデータを走査
      todays_apoint = {};
      for(let i in apoint_dates) {
        //今日に該当するデータを走査
        //配列かどうか
        if(Array.isArray(apoint_dates[i])) { 
          //データがあれば格納
          for(let ii in apoint_dates[i]) {
            if(today_start <= i && today_end >= i && apoint_dates[i][ii].staff_id == CURRENT_USER.telforce_id && !apoint_dates[i][ii].cancel_flag && !apoint_dates[i][ii].closing_flag) {    
              if(!todays_apoint[i]) todays_apoint[i] = [];
              todays_apoint[i].push(apoint_dates[i][ii]);
            }
          }
        } else {
          //データがあれば格納
          if(today_start <= i && today_end >= i && apoint_dates[i].staff_id == CURRENT_USER.telforce_id && !apoint_dates[i].cancel_flag && !apoint_dates[i].closing_flag) {    
            if(!todays_apoint[i]) todays_apoint[i] = [];
            todays_apoint[i].push(apoint_dates[i]);
          }
        }
      }

      //データがあれば
      let tmp_ary = ['<ul class="p-widget-list close">'];

      if(Object.keys(todays_apoint).length > 0) {
        for(let i in todays_apoint) {
          console.log(todays_apoint[i])
          if(!i.match(/[0-9]{10}/)) break; 
          for(let ii in todays_apoint[i]) {

            let apoint_time = todays_apoint[i][ii].apoint_date.split(' ')[1];
            let cls = entry_apoint_date && entry_apoint_date == todays_apoint[i][ii].apoint_date? ' entry':'';
            let tmp = [`<li class="p-widget-list-item${cls}" data-date="${todays_apoint[i][ii].apoint_date}">`];
            tmp.push(`<div class="p-widget-list-item-row -justify">`);
            tmp.push(`<div class="p-widget-list-item-txt -apoint_date" data-time="${apoint_time}">${apoint_time}</div>`);
            tmp.push(`<div class="p-widget-list-item-txt -tel_no">${todays_apoint[i][ii].tel_no}</div>`);
            tmp.push(`<div class="p-widget-list-item-btn -recall-complete"><div class="p-data-btnbox">
            <a href="javascript:void(0)" class="p-data-btn js-btn-recall" data-id="${todays_apoint[i][ii].id}" data-apoint_date="${todays_apoint[i][ii].apoint_date}">
              <span>完了</span>
            </a>
          </div></div>`);
            tmp.push(`</div>`);
            tmp.push(`<div class="p-widget-list-item-row">メモ：<span class="u-text">${todays_apoint[i][ii].memo}</span></div>`);
            tmp.push(`</li>`);
            tmp = tmp.join('');
            tmp_ary.push(tmp);
          }
        }
        tmp_ary.push('</ul>');
        tmp_ary = tmp_ary.join('');
        
        $('.p-modal-area.-apoint-check').remove();
        check_modal = check_modal.replace('%%TODAY%%', today.split('-').join('/'));
        check_modal = $(check_modal.replace('%%DOM%%', tmp_ary));

        $('.p-widget.-appoint_info').remove();
        check_widget = check_widget.replace('%%TODAY%%', today.split('-').join('/'));
        check_widget = $(check_widget.replace('%%DOM%%', tmp_ary));

        //予約通知タブ
        $('body').append(check_widget);
        $('.js-btn-recall').off('mousedown').on('mousedown', completeRecall);
        nextApointDate();

        //予約通知タブ操作
        $('.p-widget.-appoint_info h3.u-ttl, .js-widget-close').on('click', (e) => {
          nextApointDate();
          let elm = $('.js-widget-close');
          let h = $('.p-widget.-appoint_info .p-widget-content').outerHeight();
          if(elm.hasClass('close')) {
            elm.removeClass('close');
            $('.p-widget.-appoint_info').css('bottom', 0);
          } else {
            elm.addClass('close');
            $('.p-widget-list-item.entry').removeClass('entry');
            $('.p-widget.-appoint_info').css('bottom', h * -1);
          }
        });

        //今日チェックしたかどうか
        let apoint_popup_flag = db.getItem('sgtelforce_apoint_popup_flag');
        if(apoint_popup_flag) {
          apoint_popup_flag = JSON.parse(apoint_popup_flag);
        } else {
          apoint_popup_flag = {};
        }

        if(apoint_popup_flag[today] === false) {
          apoint_popup_flag[today] = true;
          apoint_popup_flag = JSON.stringify(apoint_popup_flag);
          //web storageに格納
          db.setItem('sgtelforce_apoint_popup_flag', apoint_popup_flag);
          //予約通知モーダル
          $('body').append(check_modal);
          check_modal.fadeIn(100, function() {
            $(this).addClass('-active');
          });

        } else {
          $('.p-widget.-appoint_info h3.u-ttl').click();
        }
          
        //定時チェック
        let adj = moment().format('mm:ss').split(':');
        let second = ((adj[0]|0) * 60)  + (adj[1]|0);
        second = second % TF.apointment_check_interval;
        //時刻合わせ
        second = TF.apointment_check_interval - second;

        setTimeout(() => {
          setInterval(() => { 
            apointBeforeTimeCheck();
          }, TF.apointment_check_interval * 1000);
        }, second * 1000);

        apointBeforeTimeCheck();

      } else {
        setTimeout(todaysCheck, 1000);
      }
    }
  }

  //次の見込み
  const nextApointDate = (bool) => {

    let next_apoint;
    for(let i in todays_apoint) {
    
      let obj = todays_apoint[i];

      if((i|0) < current ) continue;

      next_apoint = obj;

      if((i|0) > current) break;
    }
    

    let tmp = [];
    let content = ['<div class="p-widget-content-desc">'];
    content.push(`<h4 class="p-widget-content-desc-ttl">次の見込み</h4>`);
   
    for(let i in next_apoint) {

      if(next_apoint[i].cancel_flag == 1 || next_apoint[i].closing_flag == 1) continue;

      let apoint_time = next_apoint[i].apoint_date.split(' ')[1];
      tmp.push(`<div class="p-widget-content-desc-row -next_apoint_row" data-id="${next_apoint[i].id}">`);
      tmp.push(`<div class="p-widget-content-desc-txt -next_apoint" data-date="${next_apoint[i].apoint_date}">${apoint_time}</div>`);
      tmp.push(`<div class="p-widget-content-desc-txt -next_apoint_tel_no">${next_apoint[i].tel_no}</div>`);
      tmp.push(`</div>`);
      tmp.push(`<div class="p-widget-content-desc-row">メモ：<span class="u-text">${next_apoint[i].memo}</span></div>`);

      content.push(tmp.join(''));
      tmp = [];
    }

    content.push(`</div>`);
    content = content.join('');

    $('.p-widget.-appoint_info .p-widget-content-desc').remove();
    $('.p-widget.-appoint_info .p-widget-content').prepend(content);


    $('.p-widget.-appoint_info .js-widget-list-toggle').off('click').on('click', (e) => {
      $(e.currentTarget).toggleClass('close');
      $('.p-widget.-appoint_info .p-widget-list').toggleClass('close');
    });

    if(bool) {
      $('.p-widget.-appoint_info .js-widget-list-toggle').click();
    }
  }
  
  //架電予約チェック
  let apointBeforeTimeCheck = () => {
    let elm = $('.-appoint_info');
    let current = moment().unix();
    elm.find('.-flash').removeClass('-flash');
    elm.find('.-tel_no').removeClass('-flash');
    elm.find('.-alert').removeClass('-alert');
    elm.find('.-alert-text').remove();


    let n = 0;
    for(let i in todays_apoint) {
      let obj = todays_apoint[i];
      let cond = (i|0) - current < TF.apointment_check_time;
      if(!cond && current < (i|0) && current + TF.apointment_check_time >= (i|0)) {
        let hit = elm.find(`[data-date="${obj[0].apoint_date}"]`);

        hit.find('.-apoint_date').addClass('-flash');
        hit.find('.-tel_no').addClass('-flash');
        hit.find('.-apoint_date').parent().prepend('<div class="-alert -alert-text">次の架電予約時刻が近づいています</div>');

        if($('.js-widget-close').hasClass('close')) {
          $('.js-widget-close').click();
          setTimeout(function() {
            elm.find('.-next_apoint').removeClass('-flash').addClass('-flash');
            elm.find('.-next_apoint_tel_no').removeClass('-flash').addClass('-flash');
          }, TF.intervaltime);
          elm.find('.-next_apoint').parent().prepend('<div class="-alert -alert-text">次の架電予約時刻が近づいています</div>');
        }

        //talknoteへsgtelforceを介して通知
        let mikomi_api = '/api/push';

        let title = `【架電予約時刻5分前です】対象者：${obj[0].staff}[${obj[0].staff_id}]`;

        let mes = [];
        mes.push(`\n▼架電予約登録内容\n\n`);


        for(let i in obj) {
          let status = obj[i].status + '';
          if(status.match(/[0-9]+/) && TF.call_status) {
            status = TF.call_status.filter((v) => v.id === status);
            status = status? status[0].name: obj[i].status;
          } 

          mes.push(`[架電対象電話番号]　　${obj[i].tel_no}\n[ステータス]　　　　　${obj[i].status}\n[ステータス登録日時]　${obj[i].call_time}\n[登録者]　　　　　　　${obj[i].staff} [${obj[i].staff_id}]\n[対象者所属グループ]　${obj[i].unit_name}\n[予約日時]　　　　　　${obj[i].apoint_date}\n[メモ]\n${obj[i].memo}\n`);
        }

        let mikomi_opt = {
          url:  mikomi_api,
          type: 'POST',
          data: {
            to: TF.talknote_group_remind,
            message: mes.join('--\n'),
            subject: title
          }
        };

        let mikomi_doFunc = (data) => {
          if(data) {
            console.log(data);
          }

          message('予約時刻が近づいていることをtalknoteに通知しました');
        }

        let mikomi_failFunc = (data) => {
          console.log(data);
          message('sgtelforceへの接続失敗');
        }
        //talknoteへ送信
        getApiDataCommon(mikomi_opt, mikomi_doFunc, mikomi_failFunc);

      }
      n++;
    }
  };

  //再コール完了ボタン
  let completeRecall = (e) => {
    if(CURRENT_USER.auth_level > 3) {
      alert('不正操作');
      return false;
    }
    let _this = $(e.currentTarget);
    let history_id = _this.data('id');
    let apoint_date = _this.data('apoint_date');

    let current = moment(new Date()).format('YYYY/MM/DD HH:mm:ss');
    let query = `call_history/id/${history_id}`;
    //let data = `{"cancel_flag":null,"closing_flag":"1","recall_time":"${current}"}`;
    let data = `{"cancel_flag":null,"closing_flag":"1"}`;

    //予約時刻完了フラグ用
    let key = moment(apoint_date, 'YYYY/MM/DD HH:mm:ss').unix();

    let db = localStorage;
    let apoint_dates = db.getItem('sgtelforce_apoint_dates');
    if(!apoint_dates) apoint_dates = '{}';

    if(apoint_dates) {
      apoint_dates = JSON.parse(apoint_dates);
        
      if(apoint_dates[key] && !Array.isArray(apoint_dates[key])) {
        apoint_dates[key] = [apoint_dates[key]];
      }

      //いったん展開
      let spread = [];
      let tmp = {};
      for(let i in apoint_dates) {
        for(let ii in apoint_dates[i]) {
          if(apoint_dates[i][ii].id == history_id) {
            
            //予約時刻キャンセルフラグ
            apoint_dates[i][ii].cancel_flag = null;
            apoint_dates[i][ii].closing_flag = 1;
          }

          spread.push(apoint_dates[i][ii]);
          let key = moment(apoint_dates[i][ii].apoint_date, 'YYYY/MM/DD HH:mm').unix();
          tmp[key] = [];
        }
      }

      for(let i in spread) {
        let key = moment(spread[i].apoint_date, 'YYYY/MM/DD HH:mm').unix();
        tmp[key].push(spread[i]);
      }

      apoint_dates = JSON.stringify(apoint_dates);
      //web storageに格納
      db.setItem('sgtelforce_apoint_dates', apoint_dates);

      //フラグ削除
      let apoint_popup_flag = db.getItem('sgtelforce_apoint_popup_flag');
      if(apoint_popup_flag) {
        apoint_popup_flag = JSON.parse(apoint_popup_flag);
      } else {
        apoint_popup_flag = {};
      }

      let key2 = moment(apoint_date, 'YYYY/MM/DD HH:mm').format('YYYY-MM-DD');
      delete apoint_popup_flag[key2];

      apoint_popup_flag = JSON.stringify(apoint_popup_flag);
      //web storageに格納
      db.setItem('sgtelforce_apoint_popup_flag', apoint_popup_flag);
    }

    const opt = {
      url: TF.api.url + query + TF.api.key,
      type: 'PUT',
      data: data,
      dataType: 'text'
    };

    const doFunc = function(data) {

      for(let i in todays_apoint) {
        for(let ii in todays_apoint[i]) {
          if(todays_apoint[i][ii].id == history_id) todays_apoint[i][ii].closing_flag = 1;
        }
      }


      message('再コール完了');
      
      if(location.href.indexOf('recall_list') > -1) { 
        setTimeout(() => {location.reload();}, 1000);
      } else {
        _this.parents('.p-widget-list-item').fadeOut(200, () => {
          _this.parents('.p-widget-list-item').remove();
  
          if($(`.-appoint_info .p-widget-list li`).length == 0) {
            $(`.-appoint_info`).remove();
          }
        });
        if($(`.-next_apoint_row[data-id="${history_id}"]`)[0]) {
          $(`.-next_apoint_row[data-id="${history_id}"]`).fadeOut(150, (e) => {
            $('.p-widget.-appoint_info .p-widget-content-desc').remove();
            
          });
        }
      }
    };

    const failFunc = function(err) {
      console.log(err);
      message('再コール完了設定に失敗しました');
    };
    
    getApiDataCommon(opt, doFunc, failFunc);

  }

  
  todaysCheck();
}