/****************** 
各レポート画面データ項目
*******************/
TF.report = {};
TF.report.data_items = {};
TF.report.data_items.table = {};
TF.report.data_items.weekday = ['日','月','火','水','木','金','土'];
TF.report.graph = {};
TF.report.graph.inits = [];
TF.report.graph.bar = {};
TF.report.graph.bar.colors = ["#fb9b83","#fc8cb2","#f03a6d","#fd1c46","#d81b5f","#c3000d","#f84548","#7e0b2c","#d92d07","#283929","#0b512c","#74ad46","#458a49","#97b241","#72ae68","#817722","#ea681a","#fd980c","#edcf0d","#faef96","#ffdb91","#f1cd83","#dacba0","#d58760","#d88745","#9b6213","#ef9b39","#d88027","#d1622b","#9d5c36","#765242","#141b2e","#488cc9","#55c0ee","#1c6e7a","#2a5578","#4f6067","#506169","#788b8f","#333a42","#3d3c4e","#b1afa2","#63334d","#5f3565","#78477f","#6c54aa","#aa7aa4","#b1aeb5","#ed8495"];

TF.report.graph.bar.call_status_colors = ['#fb9b83','#fc8cb2','#f03a6d','#fd1c46', '#488cc9','#55c0ee','#1c6e7a','#2a5578',"#d81b5f","#c3000d","#f84548","#7e0b2c","#d92d07"];

TF.report.graph.circle = {};
TF.report.graph.circle.colors = TF.report.graph.bar.colors;
