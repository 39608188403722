 /****************** 
 * 会話テキスト表示
*******************/

function recordToTextInit(setting) {
  new RecordToText(setting);
}

const RecordToText = class {
  constructor(setting) {
    try {
      this.history_id = location.href.match(/id=([0-9]+)?/)[1];
      this.getHistoryData();
    } catch(err) {
      message('履歴のIDがありません', 1);
    }
  }

  getHistoryData() {
    this.query = `call_history/id/${this.history_id}`;

    $.ajax({
      url: TF.api.url + this.query + TF.api.key,
      type: 'GET'
    })
    .done((data) => {
      data = JSON.parse(data);
      if(data.length > 0 && data[0].record_to_text) {
        this.history_data = data[0];
        this.staff_id = data[0].staff_id;
        $('title, h2.u-ttl').text(`[${this.history_data.tel_no}]`+ $('title').text());

        this.getUserData();
        this.viewTalk();
      } else {
        message('表示するテキストがありません', 1);
      }
    })
    .fail((data) => {
        message('履歴データの取得に失敗しました', 1);
    });
  }

  getUserData() {
    this.query = `staff/${this.staff_id}`;

    $.ajax({
      url: TF.api.url + this.query + TF.api.key,
      type: 'GET'
    })
    .done((data) => {
      data = JSON.parse(data);
      this.name = '未登録';

      if(data.length > 0) {
        this.name = data[0].name;
        $('.p-talk h3.u-ttl').append(` ${this.name} [${this.staff_id}]`);
      } else {
        message('該当するユーザーは未登録です', 1);
        $('.p-talk h3.u-ttl').append(` ${this.name}`);
      }
    })
    .fail((data) => {
        message('ユーザー情報の取得に失敗しました', 1);
    });
  }

  viewTalk() {
    //音声再生準備
    if(typeof playerInit != 'undefined') {
      let src = this.history_data.record_file;
      $("#player").attr('data-src', src).empty().html('<audio preload></audio>');
      playerInit(src);
    }
    
    let record_to_text = JSON.parse(this.history_data.record_to_text);
    let talk = [];
    let n = 0;
    for(let i in record_to_text) {
      let type = n%2 == 0? 'A': 'B'; 
      let li = `<li id="message${n}" class="p-talk-item -speaker${record_to_text[i].speaker}">
        <div class="p-talk-speaker">発言${type}</div>
        <div class="p-talk-message">${record_to_text[i].message}</div>
      </li>`;
      talk.push(li);
      n++;
    }
    TF.csv_contents = record_to_text;

    let ul = $('<ul>', {class: 'p-talk-list'}).append(talk.join(''));
    $('.js-talk').append(ul);

    $('.p-data-btn.-export').off('mousedown').on('mousedown', (e) => {
      const _CSV = new CSV('record_to_text', '');
      _CSV.output(TF.csv_contents, ',', `${this.history_data.tel_no}-${this.name}[${this.staff_id}]`);
    });
  }
}
