/****************** 
レポート画面生成
*******************/

//棒グラフ/
/* 
function report_stick(type) {
  switch(type){
    case 'hour': {
      let temp = TF.REPORT_TEMPLATES.stick;
      temp = temp.replace('%TITLE%', '時間帯別グラフ');
      $('.-all .p-report-content-data.-bar').append(temp);

      TF.report.graph.inits.push(function() {
        $(function() {
          var data = {
            labels: ['～9', '9～10', '10～11', '11～12', '12～13', '13～14', '14～15', '15～16', '16～17', '17～18', '18～19', '19～20', '20～21', '21～'],
            datasets: [{
              type:            'bar',
              label:           'コール数',
              data:            [29, 771, 500, 1500, 25, 670, 1980, 1379, 0, 0, 0, 0, 0, 0, ],
              borderWidth:     1,
              backgroundColor: TF.report.graph.bar.colors[0],
              yAxisID: 'y-axis-3',
              order: 3,
              categoryPercentage: 1,
              stack: 'OB'
            },
            {
              type:            'bar',
              label:           'コール数2',
              data:            [29, 771, 500, 1500, 25, 670, 1980, 1379, 0, 0, 0, 0, 0, 0, ],
              borderWidth:     1,
              backgroundColor: TF.report.graph.bar.colors[4],
              yAxisID: 'y-axis-3',
              order: 3,
              categoryPercentage: 1,
              stack: 'OB'
            },
             {
              type:            'bar',
              label:           '通話数',
              data:            [25, 670, 1980, 1379, 25, 670, 1980, 1379, 0, 0, 0, 0, 0, ],
              borderWidth:     1,
              backgroundColor: TF.report.graph.bar.colors[1],
              yAxisID: 'y-axis-1',
              stack: 'test',
              categoryPercentage: 1,
              order: 2
            }, {
              type:            'line',
              label:           'つながり率',
              data:            [86.21, 86.9, 90.29, 91.93, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, ],
              borderWidth:     1,
              borderColor: TF.report.graph.bar.colors[2],
              backgroundColor: TF.report.graph.bar.colors[2],
              fill: false,
              lineTension: 0,
              yAxisID: 'y-axis-2',
              order: 1
            }],
          };
          new Chart(document.getElementById('chart-bars').getContext("2d"), {
            type: 'bar',
            data: data,
            options: TF.report.graph.complexChartOption
          });
        });
      });

      TF.report.graph.complexChartOption = {
        responsive: true,
        scales: {
          yAxes: [{
            id: 'y-axis-1',
            position: "right",
            gridLines: {
              drawOnChartArea: false,
            },
          }, {
            position: "left",
            id: 'y-axis-2',
          }, {
            position: "left",
            id: 'y-axis-3',
            scaleLabel: {
              display: false
            },
            gridLines: {
              drawOnChartArea: false,
            },
          }],

          xAxes: [{
            id: "x-axis-1",
            offset: true,
          }]
        },
        legend:{
          display: true,
          labels: {
            usePointStyle: true,
            padding: 15,
            boxWidth: 8
          }
        },
        tooltips:{
          mode:'label' //マウスオーバー時に表示されるtooltip
        }
      };
      TF.report.graph.inits[0]();
      break;
    }
  }

}

//円グラフ
function report_circle(type) {
  switch(type){
    case 'hour': {
      let temp = REPORT_TEMPLATES.circle;
      temp = temp.replace('%TITLE%', '時間帯別グラフ');
      $('.-all .p-report-content-data.-circle').append(temp);

      TF.report.graph.inits.push(function() {
        $(function() {
          var data = {
            labels: ['リロCMS', 'リロEC', 'ウェルCMS', 'ウェルEC', 'イツザイ'],
            datasets: [{
              data: ['131','83','505','80','923',],
              borderWidth: 0,
              backgroundColor: TF.report.graph.circle.colors,
              hoverBackgroundColor: TF.report.graph.circle.colors,
            }],
          };

          new Chart(document.getElementById('chart-pie').getContext("2d"), {
            type: 'pie',
            data: data,
            options: {
              legend:{
                display: true,
                labels: {
                  usePointStyle: true,
                  padding: 15,
                  boxWidth: 8
                }
              }
            }
          });
        });
      });
      TF.report.graph.inits[1]();
      break;
    }
  }
}
*/
/******************
 * Report Class
******************/
const Report = class {

  constructor(setting) {

    this.setting = setting;

    if(this.setting.type == 'hour') {
      if(!this.setting.table_setting) {
        this.setting.table_setting = {};
        this.setting.table_setting.rows = [];
      }
      this.setting.table_setting.rows = [
        {start: '00:00:00', end: '09:00:00'},
        {start: '09:00:00', end: '10:00:00'},
        {start: '10:00:00', end: '11:00:00'},
        {start: '11:00:00', end: '12:00:00'},
        {start: '12:00:00', end: '13:00:00'},
        {start: '13:00:00', end: '14:00:00'},
        {start: '14:00:00', end: '15:00:00'},
        {start: '15:00:00', end: '16:00:00'},
        {start: '16:00:00', end: '17:00:00'},
        {start: '17:00:00', end: '18:00:00'},
        {start: '18:00:00', end: '19:00:00'},
        {start: '19:00:00', end: '20:00:00'},
        {start: '20:00:00', end: '21:00:00'},
        {start: '21:00:00', end: '22:00:00'},
        {start: '22:00:00', end: '23:00:00'},
        {start: '23:00:00', end: '24:00:00'}
      ];
    }

    $('body').addClass('-' + setting.type);
    this.duration_threshold = TF.duration_threshold;


    this.type = setting.type;
    this.report_data = {};
    this.select_list = ['staff', 'call_counterpart', 'office', 'dept_unit', 'call_status', 'jobs', 'area'];
    this.datatype = ['call_counterpart','call_status','jobs_category','jobs','area'];

    let tabs = [];

    //レポートの追加表示があれば
    if(this.type != 'user' && this.type != 'group' && this.type != 'jobs' && this.type != 'area') {
      this.addReport('call_counterpart', TF.job_relations, 'jobs');
    }

    //各レポートのコラム名設定
    if(this.type == 'user') {
      this.setting.table_setting.columns.push({key: 'no_register', name: '未登録番号', calc: 'sum'});
      this.setting.table_setting.columns.push({key: 'no_register_rate', name: '未登録発生率', calc: 'rate[no_register/tsuuwa]'});
    }
    if(this.type == 'user' || this.type == 'group') {
      TF.call_status.filter((v) => {
        this.setting.table_setting.columns.push({key: v.id + '-call_status', name: v.name, calc: 'sum'});
      });

      TF.call_counterpart.filter((v) => {
        this.setting.table_setting.columns.push({key: v.id + '-call_counterpart', name: v.name, calc: 'sum'});
      });
    }

    this.setting_orig = $.extend(true, {}, setting);
 
/*
    if(this.type == 'group') {
      TF.call_status.filter((v) => {
        this.setting.table_setting.columns.push({key: v.id, name: v.name, calc: 'sum'});
      });
    }
*/
    //各レポートの表示内容のセット
    this.pageset = {

      hour : () => {
        //業種カテゴリ初期化
        this.jobsCategoryInit();
        this.getData('jobs_category', TF.jobs_category);
        tabs = ['all', 'call_counterpart', 'call_status', 'jobs_category', 'jobs', 'area'];        
        
        //データ処理
        this.proccessing();

        tabs.filter((v) => {
          if(v == 'jobs_category') {

            //各商材のタブを出力
            if(COUNTERPARTS) {
              //商材ループ
              for(let i in COUNTERPARTS) {
                let obj = COUNTERPARTS[i];

                //テーブル用カラム
                let column = [];
                column.push({key: 'working', name: '稼働時間', calc: 'total'});

                for(let ii in TF.jobs_category) {
                  //業種を商材ごとに格納
                  if((TF.jobs_category[ii].counterpart_id|0) == (obj.id|0)) {
                    column.push({key: TF.jobs_category[ii].id, name: TF.jobs_category[ii].name, calc: 'sum'});
                  }
                }

                let report_temp = this.jobsCategorySettingData(obj, column);
                
                //ユーザー絞り込み用の設定実行
                this.selectedUserReport('jobs_category');
                try {
                  //テーブル出力
                  let tmp = new this.table(this.type, this.setting, this.report_data_jobs_category.jobs_category[obj.id], v, this.select_month);
                  tmp.init(report_temp[0], v);
                  //グラフ出力
                  tmp = new this.graph(this.type, this.setting, this.report_data_jobs_category.jobs_category[obj.id], v, this.select_month);
                  tmp.init(report_temp[1], v);
                } catch(err) {
                  let obj = {settime: 10000};
                  message("業種データが存在しない商材があります\nそのためグラフが正常に表示されません\n"  + err, 1, obj);
                  //console.log(err);
                }
                
              }
            }

            //ユーザーが選択されていたら
            if(TF.selected_staff && TF.selected_staff.length > 0) {
              //各ユーザーのレポート出力
              for(let id in TF.selected_staff) {
                let staff_id = TF.selected_staff[id];
                
                //各商材タブ
                for(let tab_id in this.setting.tabs.jobs_category) {
                  let data = this.setting.tabs.jobs_category[tab_id].data;
                  //商材IDだけ取得
                  let counterpart_id = tab_id.match(/[0-9]+/)[0];

                  //ユーザー用レポート出力
                  for(let ii = 2, n = data.length; ii < n; ii++) {
                    try {
                      if(data[ii].type == 'table' && data[ii].staff_id && data[ii].staff_id == staff_id) {
                        //カラム設定
                        let column = [];
                        column.push({key: 'working', name: '稼働時間', calc: 'total'});

                        for(let iii in TF.jobs_category) {
                          if((TF.jobs_category[iii].counterpart_id|0) == (counterpart_id|0)) {
                            column.push({key: TF.jobs_category[iii].id, name: TF.jobs_category[iii].name, calc: 'sum'});
                          }
                        }

                        //レポート設定にカラム設定を格納
                        data[ii].column = column;
                        //テーブル出力
                        let tmp = new this.table(this.type, this.setting, TF.staff_report_data[staff_id].jobs_category[counterpart_id], v, this.select_month);
                        tmp.init(data[ii], v);
                      }

                      //グラフ出力
                      if(data[ii].type == 'graph'  && data[ii].staff_id && data[ii].staff_id == staff_id) {
                        let tmp = new this.graph(this.type, this.setting, TF.staff_report_data[staff_id].jobs_category[counterpart_id], v, this.select_month);
                        tmp.init(data[ii], v);
                      }
                    } catch(err) {
                      let obj = {settime: 10000};
                      message("業種データが存在しない商材があります\nそのためグラフが正常に表示されません\n"  + err, 1, obj);
                      //console.log(err);
                    }

                  }
                }
              }
            }
         
          } else {
            //ユーザー絞り込み
            this.selectedUserReport(v);
            this.setting.tabs[v].data.filter((vv) => {
              const tmp = new this[vv.type](this.type, this.setting, this.report_data, v, this.select_month);
              tmp.init(vv, v);
            });
          }

        });
      },

      week : () => {
        //業種カテゴリ初期化
        this.jobsCategoryInit();

        tabs = ['all', 'call_counterpart', 'call_status', 'jobs_category', 'jobs', 'area'];
        if(this.monthDays('week')) {

          this.proccessing();

          tabs.filter((v) => {
            if(v == 'jobs_category') {
              if(COUNTERPARTS) {
                for(let i in COUNTERPARTS) {
                  let obj = COUNTERPARTS[i];
                  let column = [];
                  column.push({key: 'working', name: '稼働日', calc: 'total'});
  
                  for(let ii in TF.jobs_category) {
                    if((TF.jobs_category[ii].counterpart_id|0) == (obj.id|0)) {
                      column.push({key: TF.jobs_category[ii].id, name: TF.jobs_category[ii].name, calc: 'sum'});
                    }
                  }
  
                  let report_temp = this.jobsCategorySettingData(obj, column);
                  //ユーザー絞り込み
                  this.selectedUserReport(v);
                  
                  try {
                    let tmp = new this.table(this.type, this.setting, this.report_data_jobs_category.jobs_category[obj.id], v, this.select_month);
                    tmp.init(report_temp[0], v);

                    tmp = new this.graph(this.type, this.setting, this.report_data_jobs_category.jobs_category[obj.id], v, this.select_month);
                    tmp.init(report_temp[1], v);

                    tmp = new this.table(this.type, this.setting, this.report_data_jobs_category.jobs_category[obj.id], v, this.select_month);
                    tmp.init(report_temp[2], v);

                    tmp = new this.graph(this.type, this.setting, this.report_data_jobs_category.jobs_category[obj.id], v, this.select_month);
                    tmp.init(report_temp[3], v);
                  } catch(err) {
                    let obj = {settime: 10000};
                    message("業種データが存在しない商材があります\nそのためグラフが正常に表示されません\n"  + err, 1, obj);
                    //console.log(err);
                  }
                }
              }

              //ユーザーが選択されていたら
              if(TF.selected_staff && TF.selected_staff.length > 0) {
                //各ユーザーのレポート出力
                for(let id in TF.selected_staff) {
                  let staff_id = TF.selected_staff[id];
                  
                  //各商材タブ
                  for(let tab_id in this.setting.tabs.jobs_category) {
                    let data = this.setting.tabs.jobs_category[tab_id].data;
                    //商材IDだけ取得
                    let counterpart_id = tab_id.match(/[0-9]+/)[0];

                    //ユーザー用レポート出力
                    for(let ii = 4, n = data.length; ii < n; ii++) {
                      try {
                        if(data[ii].type == 'table' && data[ii].staff_id && data[ii].staff_id == staff_id) {
                          //カラム設定
                          let column = [];
                          column.push({key: 'working', name: '稼働日', calc: 'total'});

                          for(let iii in TF.jobs_category) {
                            if((TF.jobs_category[iii].counterpart_id|0) == (counterpart_id|0)) {
                              column.push({key: TF.jobs_category[iii].id, name: TF.jobs_category[iii].name, calc: 'sum'});
                            }
                          }

                          //レポート設定にカラム設定を格納
                          data[ii].column = column;
                          //テーブル出力
                          let tmp = new this.table(this.type, this.setting, TF.staff_report_data[staff_id].jobs_category[counterpart_id], v, this.select_month);
                          tmp.init(data[ii], v);
                        }

                        //グラフ出力
                        if(data[ii].type == 'graph'  && data[ii].staff_id && data[ii].staff_id == staff_id) {
                          let tmp = new this.graph(this.type, this.setting, TF.staff_report_data[staff_id].jobs_category[counterpart_id], v, this.select_month);
                          tmp.init(data[ii], v);
                        }
                      } catch(err) {
                        let obj = {settime: 10000};
                        message("業種データが存在しない商材があります\nそのためグラフが正常に表示されません\n"  + err, 1, obj);
                        //console.log(err);
                      }
                    }
                  }
                }
              }

            } else {
              //ユーザー絞り込み
              this.selectedUserReport(v);
              this.setting.tabs[v].data.filter((vv) => {
                const tmp = new this[vv.type](this.type, this.setting, this.report_data, v, this.select_month);
                tmp.init(vv, v);
              });

            }
          });
        }
      },

      month : () => {
        //業種カテゴリ初期化
        this.jobsCategoryInit();

        this.monthDays('month');

        tabs = ['all', 'call_counterpart', 'call_status', 'jobs_category', 'jobs', 'area'];
        

        this.proccessing();
        tabs.filter((v) => {
          if(v == 'jobs_category') {
            if(COUNTERPARTS) {
              for(let i in COUNTERPARTS) {
                let obj = COUNTERPARTS[i];
                let column = [];
                column.push({key: 'working', name: '稼働日', calc: 'total'});

                for(let ii in TF.jobs_category) {
                  if((TF.jobs_category[ii].counterpart_id|0) == (obj.id|0)) {
                    column.push({key: TF.jobs_category[ii].id, name: TF.jobs_category[ii].name, calc: 'sum'});
                  }
                }

                let report_temp = this.jobsCategorySettingData(obj, column);
                //ユーザー絞り込み
                this.selectedUserReport(v);
                try {
                  let tmp = new this.table(this.type, this.setting, this.report_data_jobs_category.jobs_category[obj.id], v, this.select_month);
                  tmp.init(report_temp[0], v);

                  tmp = new this.graph(this.type, this.setting, this.report_data_jobs_category.jobs_category[obj.id], v, this.select_month);
                  tmp.init(report_temp[1], v);
                } catch(err) {
                  let obj = {settime: 10000};
                  message("業種データが存在しない商材があります\nそのためグラフが正常に表示されません\n"  + err, 1, obj);
                  //console.log(err);
                }
              }
            }

            //ユーザーが選択されていたら
            if(TF.selected_staff && TF.selected_staff.length > 0) {
              //各ユーザーのレポート出力
              for(let id in TF.selected_staff) {
                let staff_id = TF.selected_staff[id];
                
                //各商材タブ
                for(let tab_id in this.setting.tabs.jobs_category) {
                  let data = this.setting.tabs.jobs_category[tab_id].data;
                  //商材IDだけ取得
                  let counterpart_id = tab_id.match(/[0-9]+/)[0];

                  //ユーザー用レポート出力
                  for(let ii = 2, n = data.length; ii < n; ii++) {
                    try {
                      if(data[ii].type == 'table' && data[ii].staff_id && data[ii].staff_id == staff_id) {
                        //カラム設定
                        let column = [];
                        column.push({key: 'working', name: '稼働日', calc: 'total'});

                        for(let iii in TF.jobs_category) {
                          if((TF.jobs_category[iii].counterpart_id|0) == (counterpart_id|0)) {
                            column.push({key: TF.jobs_category[iii].id, name: TF.jobs_category[iii].name, calc: 'sum'});
                          }
                        }

                        //レポート設定にカラム設定を格納
                        data[ii].column = column;
                        //テーブル出力
                        let tmp = new this.table(this.type, this.setting, TF.staff_report_data[staff_id].jobs_category[counterpart_id], v, this.select_month);
                        tmp.init(data[ii], v);
                      }

                      //グラフ出力
                      if(data[ii].type == 'graph'  && data[ii].staff_id && data[ii].staff_id == staff_id) {
                        let tmp = new this.graph(this.type, this.setting, TF.staff_report_data[staff_id].jobs_category[counterpart_id], v, this.select_month);
                        tmp.init(data[ii], v);
                      }
                    } catch(err) {
                      let obj = {settime: 10000};
                      message("業種データが存在しない商材があります\nそのためグラフが正常に表示されません\n"  + err, 1, obj);
                      //console.log(err);
                    }

                  }
                }
              }
            }
          } else {
            //ユーザー絞り込み
            this.selectedUserReport(v);
            this.setting.tabs[v].data.filter((vv) => {
              const tmp = new this[vv.type](this.type, this.setting, this.report_data, v, this.select_month);
              tmp.init(vv, v);
            });
          }

        });
      },

      user : () => {
        this.proccessing('all');   

        this.setting.tabs.all.data.filter((vv) => {
          const tmp = new this[vv.type](this.type, this.setting, this.report_data);
          tmp.init(vv, 'all');
        });
      },

      group : () => {
        this.proccessing('all');
        this.setting.tabs.all.data.filter((vv) => {
          const tmp = new this[vv.type](this.type, this.setting, this.report_data);
          tmp.init(vv, 'all');
        });
      },

      jobs : () => {
        tabs = ['all-jobs_category', 'call_status-jobs_category', 'area-jobs_category' , 'all', 'call_counterpart', 'call_status', 'area'];
        let columns = {
          all: [
            {key: 'working', name: '業種', calc: 'total'},
            {key: 'call', name: 'コール数', calc: 'sum'},
            {key: 'tsuuwa', name: '通話数', calc: 'sum'},
            {key: 'tsuuwa_rate', name: 'つながり率', calc: 'rate[tsuuwa/call]'},
            {key: 'tsuuwa_time', name: '合計通話時間', calc: 'sumTime'}
          ],
          call_status: [{key: 'working', name: '業種', calc: 'total'}],
          area: [{key: 'working', name: '業種', calc: 'total'}]
        }

        this.proccessing();
        tabs.filter((v) => {
          if(v.indexOf('-jobs_category') > -1) {
            //業種カテゴリ初期化
            this.jobsCategoryInit(v);

             //カラム設定
             let column = [];
             let datakey;
             if(v.indexOf('all') == 0) {
               column = columns.all;
               datakey = 'all';
             }

             if(v.indexOf('call_status') == 0) {
               column = columns.call_status;
               for(let ii in TF.call_status) {
                 column.push({key: TF.call_status[ii].id, name: TF.call_status[ii].name, calc: 'sum'});
               }
               datakey = 'call_status';
             }

             if(v.indexOf('area') == 0) {
               column = columns.area;
               for(let ii in TF.area) {
                 column.push({key: TF.area[ii].id, name: TF.area[ii].name, calc: 'sum'});
               }
               datakey = 'area';
             }

            if(COUNTERPARTS) {
              for(let i in COUNTERPARTS) {
                let obj = COUNTERPARTS[i];

                let report_temp = this.jobsCategorySettingData(obj, column, v);
                //ユーザー絞り込み
                this.selectedUserReport(v);

                try {
                  let tmp = new this.table(this.type, this.setting, this.report_data_jobs_category[datakey][obj.id], v, this.select_month);
                  tmp.init(report_temp[0], v);

                  tmp = new this.graph(this.type, this.setting, this.report_data_jobs_category[datakey][obj.id], v, this.select_month);
                  tmp.init(report_temp[1], v);
                } catch(err) {
                  let obj = {settime: 10000};
                  message("業種データが存在しない商材があります\nそのためグラフが正常に表示されません\n"  + err, 1, obj);
                  //console.log(err);
                }

              }

              //ユーザーが選択されていたら
              if(TF.selected_staff && TF.selected_staff.length > 0) {

                //各ユーザーのレポート出力
                for(let id in TF.selected_staff) {
                  let staff_id = TF.selected_staff[id];

                  //各商材タブ
                  for(let tab_id in this.setting.tabs[v]) {
                    let data, counterpart_id;
                    if(tab_id == 'data') {
                      data = this.setting.tabs[v].data;
                      counterpart_id = this.counterpart_id;
                    } else {
                      data = this.setting.tabs[v][tab_id].data;
                      //商材IDだけ取得
                      counterpart_id = tab_id.match(/[0-9]+/)[0];
                    }
                   

                    /*for(let iii in TF.jobs_category) {
                      if((TF.jobs_category[iii].counterpart_id|0) == (counterpart_id|0)) {
                        column.push({key: TF.jobs_category[iii].id, name: TF.jobs_category[iii].name, calc: 'sum'});
                      }
                    }*/

                    //ユーザー用レポート出力
                    for(let ii = 2, n = data.length; ii < n; ii++) {
                      try {
                        if(data[ii].type == 'table' && data[ii].staff_id && data[ii].staff_id == staff_id) {
                          

                          //レポート設定にカラム設定を格納
                          data[ii].column = column;
                          //テーブル出力
                          let tmp = new this.table(this.type, this.setting, TF.staff_report_data_jobs_category[staff_id][datakey][counterpart_id], v, this.select_month);
                          tmp.init(data[ii], v);
                        }

                        //グラフ出力
                        if(data[ii].type == 'graph'  && data[ii].staff_id && data[ii].staff_id == staff_id) {

                          let tmp = new this.graph(this.type, this.setting, TF.staff_report_data_jobs_category[staff_id][datakey][counterpart_id], v, this.select_month);
                          tmp.init(data[ii], v);
                        }

                      } catch(err) {
                        let obj = {settime: 10000};
                        message("業種データが存在しない商材があります\nそのためグラフが正常に表示されません\n"  + err, 1, obj);
                        //console.log(err);
                      }

                    }
                  }
                }
              }
            }
 
          } else {
            //ユーザー絞り込み
            this.selectedUserReport(v);
            this.setting.tabs[v].data.filter((vv) => {
              const tmp = new this[vv.type](this.type, this.setting, this.report_data, v, this.select_month);
              tmp.init(vv, v);
            });
          }
        });
      },

      area : () => {
        //業種カテゴリ初期化
        this.jobsCategoryInit();
        tabs = ['all', 'call_counterpart', 'call_status', 'jobs_category', 'jobs'];

        this.proccessing();
        tabs.filter((v) => {
          if(v == 'jobs_category') {
            if(COUNTERPARTS) {
              for(let i in COUNTERPARTS) {
                let obj = COUNTERPARTS[i];
                let column = [];
                column.push({key: 'working', name: 'エリア', calc: 'total'});

                for(let ii in TF.jobs_category) {
                  if((TF.jobs_category[ii].counterpart_id|0) == (obj.id|0)) {
                    column.push({key: TF.jobs_category[ii].id, name: TF.jobs_category[ii].name, calc: 'sum'});
                  }
                }

                try {
                  let report_temp = this.jobsCategorySettingData(obj, column);
                  //ユーザー絞り込み
                  this.selectedUserReport(v);
                  let tmp = new this.table(this.type, this.setting, this.report_data_jobs_category.jobs_category[obj.id], v, this.select_month);
                  tmp.init(report_temp[0], v);

                  tmp = new this.graph(this.type, this.setting, this.report_data_jobs_category.jobs_category[obj.id], v, this.select_month);
                  tmp.init(report_temp[1], v);
                } catch(err) {
                  let obj = {settime: 10000};
                  message("業種データが存在しない商材があります\nそのためグラフが正常に表示されません\n"  + err, 1, obj);
                  //console.log(err);
                }
              }
            }

            //ユーザーが選択されていたら
            if(TF.selected_staff && TF.selected_staff.length > 0) {
              //各ユーザーのレポート出力
              for(let id in TF.selected_staff) {
                let staff_id = TF.selected_staff[id];
                
                //各商材タブ
                for(let tab_id in this.setting.tabs.jobs_category) {
                  let data = this.setting.tabs.jobs_category[tab_id].data;
                  //商材IDだけ取得
                  let counterpart_id = tab_id.match(/[0-9]+/)[0];

                  //ユーザー用レポート出力
                  for(let ii = 4, n = data.length; ii < n; ii++) {
                    try {
                      if(data[ii].type == 'table' && data[ii].staff_id && data[ii].staff_id == staff_id) {
                        //カラム設定
                        let column = [];
                        column.push({key: 'working', name: 'エリア', calc: 'total'});

                        for(let iii in TF.jobs_category) {
                          if((TF.jobs_category[iii].counterpart_id|0) == (counterpart_id|0)) {
                            column.push({key: TF.jobs_category[iii].id, name: TF.jobs_category[iii].name, calc: 'sum'});
                          }
                        }

                        //レポート設定にカラム設定を格納
                        data[ii].column = column;
                        //テーブル出力
                        let tmp = new this.table(this.type, this.setting, TF.staff_report_data[staff_id].jobs_category[counterpart_id], v, this.select_month);
                        tmp.init(data[ii], v);
                      }

                      //グラフ出力
                      if(data[ii].type == 'graph'  && data[ii].staff_id && data[ii].staff_id == staff_id) {
                        let tmp = new this.graph(this.type, this.setting, TF.staff_report_data[staff_id].jobs_category[counterpart_id], v, this.select_month);
                        tmp.init(data[ii], v);
                      }

                    } catch(err) {
                      let obj = {settime: 10000};
                      message("業種データが存在しない商材があります\nそのためグラフが正常に表示されません\n"  + err, 1, obj);
                      //console.log(err);
                    }
                  }
                }
              }
            }
          } else {
            //ユーザー絞り込み
            this.selectedUserReport(v);
            this.setting.tabs[v].data.filter((vv) => {
              const tmp = new this[vv.type](this.type, this.setting, this.report_data, v, this.select_month);
              tmp.init(vv, v);
            });
          }
        });
      },

      user_report : () => {
       

        TF.select_staff_id = location.href.match(/id=([0-9,]+)/)[1].split(',');
        TF.orig_tab = location.href.match(/tab=([a-z_0-9\-]+)/)[1];
        this.selectedUserReport(['user_report']);

        let orig_tab = TF.orig_tab.indexOf('_jobs_of') == 0? 'jobs_category': TF.orig_tab;
        
        let orig_tab_parent = orig_tab;
        let setting_data = [];
        
        if(orig_tab.indexOf('-jobs_category') > -1) {
          orig_tab_parent = orig_tab.match(/[^\-]+-jobs_category/)[0];
          setting_data = this.setting.tabs[orig_tab_parent][orig_tab].data;
        } else {
          setting_data = this.setting.tabs[orig_tab_parent].data;
        }
   
        this.counterpart_id = '';
        let staff_report_data = TF.staff_report_data[TF.select_staff_id[0]];

        if(TF.orig_tab.match(/_jobs_of_[0-9]+/)) {
          this.counterpart_id = TF.orig_tab.match(/_jobs_of_([0-9]+)/)[1] - 0;

          //業種別から開いた場合
          if(TF.orig_tab.match(/^[^\-]+-jobs_category/)) {
            let cat = TF.orig_tab.match(/^([^\-]+)-jobs_category/)[1];
            staff_report_data = TF.staff_report_data_jobs_category[TF.select_staff_id[0]][cat];
          }
        }

        if(orig_tab == 'jobs_category') {
          setting_data = this.setting.tabs.jobs_category[TF.orig_tab].data;
          staff_report_data = TF.staff_report_data[TF.select_staff_id[0]].jobs_category[this.counterpart_id];
        }

        let column = setting_data[0].column;

        if(TF.select_staff_id.length == 1) {
          this.graphClass(orig_tab);
          this.tableClass(orig_tab);

          for(let i in setting_data) {
            let data = setting_data[i];
            data.column = column;
            data.counterpart_id = this.counterpart_id;
            
            data.dist = orig_tab.indexOf('jobs_category') > -1? data.dist.replace('p-report-child-content', 'p-report-content'): data.dist;
            console.log(data.dist);
            if((data.staff_id|0) == TF.select_staff_id[0]|0) {
              new this[data.type](TF.report_type, this.setting, staff_report_data, orig_tab, TF.select_month ).init(data, orig_tab);
            }
          }
        } 
        
        if(TF.select_staff_id.length > 1) {

          this.tableClass(orig_tab);
          
          let title = [];
          for(let i in TF.select_staff_id) {
            let staff = TF.staff[TF.select_staff_id[i]|0];
            title.unshift(`${staff.name}[${staff.telforce_id}] `);
          }

          title = title.join('、');

          for(let i in TF.select_staff_id) {
            let data = setting_data[i];

            if(!data.is_ext && !data.staff_id) {

              data.title = `${title}の${data.title}`;
              data.dist = data.dist.replace('p-report-content-data', 'p-report-content-userdata-selectusers');
              data.dist = data.dist.replace('-pie', '-circle');

              if(data.dist.indexOf('-bar') > -1 && data.graph_type == 'pie') {
                data.dist = data.dist.replace('-bar', '-circle');
              }

              if(data.canvas_id) data.canvas_id = data.canvas_id.replace('_graph','_graph_selectusers');

              if(typeof setting_data.data == 'undefined') setting_data.data = {}; 
              setting_data.data[i] = data;

              try {
                if(data.type == 'table') {               
                  new this[data.type](TF.report_type, this.setting, TF.staff_report_data[TF.select_staff_id[0]], orig_tab, TF.select_month ).init(data, orig_tab);
                }
    
                if(data.type == 'graph') {
                  let canvas_id = TF.chart_integration_setting[orig_tab].canvas_id;

                  if(data.dist.indexOf('-weekday') > -1) {
                    canvas_id = canvas_id.replace('week', 'weekday');
                  }

                  let temp = TF.REPORT_TEMPLATES.bar;
                  temp = temp.replace('%TITLE%', data.title);
                  temp = temp.replace('%CANVASID%', canvas_id);

                  if(TF.report_type == 'area') {
                    temp = temp.replace(/height="[^"]+"/,'height="480"');
                  }

                  let dist = data.dist.replace(/[^ ]+/,'');
                  $(dist).empty().append(temp);

                  let elm = document.getElementById(canvas_id);

     /*
                options: {
                  responsive: true,
                  scales: {
                    yAxes: [{
                      id: 'y-axis-21',
                      position: "right",
                      gridLines: {
                        drawOnChartArea: false,
                      },
                    }, {
                      position: "left",
                      id: 'y-axis-20',
                    }, {
                      position: "left",
                      id: 'y-axis-12',
                      scaleLabel: {
                        display: false
                      },
                      gridLines: {
                        drawOnChartArea: false,
                      },
                    }],
          
                    xAxes: [{
                      id: "x-axis-1",
                      offset: true,
                    }]
                  },
                  legend:{
                    display: true,
                    labels: {
                      usePointStyle: true,
                      padding: 15,
                      boxWidth: 8
                    }
                  },
                  tooltips:{
                    mode:'label' //マウスオーバー時に表示されるtooltip
              
                  },
                },
                type: "bar"
              }*/

                  let obj = TF.chart_integration_setting[orig_tab].obj;

                  if(canvas_id.indexOf('weekday') > -1) {
                    obj = TF.chart_integration_setting[orig_tab].weekday.obj;
                  }
                  if(elm) {
                    //new Chart(elm.getContext("2d"), obj);
                    new Chart(elm.getContext("2d"), obj);
                  } else {
                    console.log(canvas_id, 'グラフの表示先が存在しません。');
                  }

                }
              } catch(err) {
                let obj = {settime: 10000};
                message("業種データが存在しない商材があります\nそのためグラフが正常に表示されません\n"  + err, 1, obj);
                //console.log(err);
              }
            }
          }
        }
      }
    }
  }

  init() {
    
    this.search();
    this.graphClass();
    this.tableClass();
    this.pageset[this.type]();

    //もろもろ保存
    this.setData('call_counterpart', TF.call_counterpart);
    this.setData('call_status', TF.call_status);
    this.setData('jobs', TF.jobs);
    this.setData('jobs_category', TF.jobs_category);
    this.setData('CURRENT_USER', CURRENT_USER);
    this.setData('COUNTERPARTS', COUNTERPARTS);
    this.setData('area', TF.area);
    this.setData('staff', TF.staff);
    
    //CSVインポートエクスポート
    const _CSV = new CSV('call_history');
    $('.p-data-btn.-export').off('mousedown').on('mousedown', (e) => {
      _CSV.output(TF.report_data, ',', _CSV.filename());
    });


    if($('.p-report-content-userdata-wrap')[0]) {
      let position = $('.p-report-content-userdata-wrap:eq(0)').offset().top;
      $("html, body").animate({scrollTop:position}, 300, "swing");
    }
    
    return 'init';
  }

  //各タブ
  tab(tab_name) {
    this.proccessing(tab_name);
    this.setting.tabs[tab_name].data.filter((vv) => {
      const tmp = new this[vv.type](this.type, this.setting, this.report_data, tab_name, this.select_month);
      tmp.init(vv, tab_name);
    });
  }

  //追加表示
  addReport(tab_name, data, datatype) {
    if(!data || Object.keys(data).length == 0) {
      return false;
    }

    let report_temp = [
      {
        type: 'table',
        title: '[商材]%title%に登録されている業種',
        row_type: this.type,
        dist: `.-${tab_name} .p-report-content-data-ext.-table`,
        is_ext: 1,
      },
      {
        type: 'graph',
        title: '[商材]%title%に登録されている業種割合',
        graph_type: 'pie',
        dist: `.-${tab_name} .p-report-content-data-ext.-circle`,
        canvas_id: '%canvas_id%',
        is_ext: 1,
        setting: {
          labels: [],
          options: {
            responsive: true,
            maintainAspectRatio: false,
            legend: {
              display: true,
              labels: {
                usePointStyle: true,
                padding: 15,
                boxWidth: 8
              }
            }
          }
        }
      }
    ];

    let dist = {};
    let titles = {};

    for(let i in data) {
      
      for(let ii in report_temp) {
        let tmp = $.extend(true, {}, report_temp[ii]);
        let obj = TF[tab_name].filter(v => v.id === i);
        obj = obj.length > 0? obj[0]: null;

        if(obj) { 
          //出力先クラス名
          let ext_cls = 'ext_report' + (i + ii);
          tmp.dist = tmp.dist + '.-' + ext_cls;

          dist[tmp.type + '_' + obj.id] = tmp.dist; 

          //出力先タイトル
          tmp.title = tmp.title.replace('%title%', obj.name);
          titles[tmp.type + '_' + obj.id] = tmp.title;

          //レポートタイプ
          let type = tmp.type == 'graph'? tmp.graph_type == 'pie'? 'circle -wide': tmp.graph_type + ' -wide' : tmp.type;

          if(tmp.type == 'graph') {
            data[i].filter((v) => {
              tmp.setting.labels.push(v.name);
            });
            tmp.setting.labels = tmp.setting.labels.filter(function(elem, index, self) {
              return self.indexOf(elem) === index;
            });
          }

          //canvas id
          if(tmp.canvas_id) {
            tmp.canvas_id = ext_cls;
          }

          //出力先append
          $(`.p-report-content.-${tab_name}`).append($('<div>').addClass(`p-report-content-data-ext -${type} -${ext_cls}`));
          this.setting.tabs[tab_name].data.push(tmp);
        }
      }
    }

    TF.add_report = {tab: tab_name, data: data, datatype: datatype, dist: dist, titles: titles};
  }

  //業種カテゴリ設定
  jobsCategoryInit(tab = '') {
    

    if(COUNTERPARTS) {
      let n = 0;
      for(let i in COUNTERPARTS) {
        let obj = COUNTERPARTS[i];

        //タブを出力
        let target = tab + '_jobs_of_' + obj.id;
        
        if($(`.${target}`)[0]) continue;
        
        let disttab = tab? `.-${tab} `: '';

        let is_current = (n == 0)? ' is-current':'';
        
        let li = $('<li>', {class: 'p-report-child-tab-item'}).append($('<a>', {href: '#' + target, class: 'p-report-child-tab-item-link js-tab' + is_current, text: obj.name}));
        $(disttab + '.p-report-child-tab.-grandchild').append(li);

        //表示先エリアを生成
        let dist = $(`<div class="p-report-child-content ${target}">
          <div class="p-report-child-content-data -table"></div>
          <div class="p-report-child-content-data -bar -wide"></div>
        </div>`);
        
        if(location.href.indexOf('week') > -1 ) {
          dist = $(`<div class="p-report-child-content ${target}">
          <div class="p-report-child-content-data -table -week"></div>
          <div class="p-report-child-content-data -bar -week -wide"></div>
          <div class="p-report-child-content-data -table -weekday"></div>
          <div class="p-report-child-content-data -bar -weekday -wide"></div>
        </div>`);
        }

        /*if(location.href.indexOf('jobs') > -1 && tab.indexOf('all') == 0) {
          dist = $(`<div id="${target}" class="p-report-child-content">
          <div class="p-report-child-content-data -table"></div>
          <div class="p-report-child-content-data -circle -wide"></div>
        </div>`);
        }*/

        
        $(disttab + '.p-report-child-contents').append(dist);
        if(n == 0) dist.show();

        n++;
      }
    }
  }

  //業種カテゴリ用セッティング
  jobsCategorySettingData(obj, column, tab = '') {
    let target = tab + '_jobs_of_' + obj.id;
    let label_type = this.type;
    let report_setting_add = '';

    if(!this.setting.tabs.jobs_category) {
      this.setting.tabs.jobs_category = {};
    }

    if(!this.setting.tabs.jobs_category[target]) {
      this.setting.tabs.jobs_category[target] = {};
    }

    if(!this.setting.tabs.jobs_category[target].data) {
      this.setting.tabs.jobs_category[target].data = {};
    }

    let report_setting = [
      {
        counterpart_id: obj.id,
        type: 'table',
        title: `[商材]${obj.name}に登録されている業種`,
        row_type: this.type,
        dist: `.${target} .p-report-child-content-data.-table`,
        column: column
      },
      {
        counterpart_id: obj.id,
        type: 'graph',
        title: `[商材]${obj.name}の時間帯別グラフ`,
        graph_type: 'bar',
        label_type: label_type,
        dist: `.${target} .p-report-child-content-data.-bar`,
        canvas_id: target + '_graph',
        stack: target + '_stack',
        setting: {

          options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              yAxes: [{
                id: "y-axis-jobs" + obj.id,
                type: "linear",
                position: "left",
                scaleLabel: "%",

                gridLines: {
                    drawOnChartArea: false,
                },
              }],
            },

            legend: {
              display: true,
              labels: {
                usePointStyle: true,
                padding: 15,
                boxWidth: 8
              }
            },
            tooltips:{
              mode:'label' //マウスオーバー時に表示されるtooltip
            }
          }
        }
      }
    ];

    //時間別の場合
    if(this.type == 'hour') {
      this.setting.tabs.jobs_category[target].data = report_setting;
    }
    
    //週別の場合
    if(this.type == 'week') {
      report_setting[0].title = `[商材]${obj.name}に登録されている週別業種コール数`;
      report_setting[1].title = `[商材]${obj.name}の週別グラフ`;
      report_setting[0].dist = `.${target} .p-report-child-content-data.-week.-table`;
      report_setting[1].dist = `.${target} .p-report-child-content-data.-week.-bar`;
      
      report_setting_add = [
        {
          counterpart_id: obj.id,
          type: 'table',
          title: `[商材]${obj.name}に登録されている曜日別業種コール数`,
          row_type: 'weekday',
          dist: `.${target} .p-report-child-content-data.-weekday.-table`,
          column: column
        },
        {
          counterpart_id: obj.id,
          type: 'graph',
          title: `[商材]${obj.name}の曜日別グラフ`,
          graph_type: 'bar',
          label_type: 'weekday',
          dist: `.${target} .p-report-child-content-data.-weekday.-bar`,
          canvas_id: target + '_weekday_graph',
          stack: target + '_weekday_stack',
          setting: {
  
            options: {
              responsive: true,
              maintainAspectRatio: false,
              scales: {
                yAxes: [{
                  id: "y-axis-jobs" + obj.id,
                  type: "linear",
                  position: "left",
                  scaleLabel: "%",
  
                  gridLines: {
                      drawOnChartArea: false,
                  },
                }],
              },
  
              legend: {
                display: true,
                labels: {
                  usePointStyle: true,
                  padding: 15,
                  boxWidth: 8
                }
              },
              tooltips:{
                mode:'label' //マウスオーバー時に表示されるtooltip
              }
            }
          }
        }
      ];

      report_setting = report_setting.concat(report_setting_add);

      this.setting.tabs.jobs_category[target].data = report_setting;
    } 

    //月別の場合
    if(this.type == 'month') {
      report_setting[0].title = `[商材]${obj.name}に登録されている業種`;
      report_setting[1].title = `[商材]${obj.name}の月別グラフ`;

      this.setting.tabs.jobs_category[target].data = report_setting;
    }

    //業種別の場合
    if(this.type == 'jobs' ) { 

      if(tab.indexOf('all') == 0) {
        report_setting[0].title = `[商材]${obj.name}に登録されている業種コール数`;
        report_setting[1].title = `[商材]${obj.name}の業種コール数グラフ`;

        report_setting[1].setting.indexies = [
          {key: 'call', name: 'コール数', graph_type: 'bar', yAxisID: 'y-axis-2', order: 2},
          {key: 'tsuuwa', name: '通話数', graph_type: 'bar', yAxisID: 'y-axis-2', order: 2},
          {key: 'tsuuwa_rate', name: 'つながり率', graph_type: 'line', yAxisID: 'y-axis-1', borderColor:'', fill: false, lineTension: 0, order: 1}
        ];

        report_setting[1].setting.options = {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [{
              id: "y-axis-1",
              type: "linear",
              position: "right",
              scaleLabel: "件"
            }, {
              id: "y-axis-2",
              type: "linear",
              position: "left",
              scaleLabel: "%",

              gridLines: {
                drawOnChartArea: false,
              },
            }],
          },

          legend: {
            display: true,
            labels: {
              usePointStyle: true,
              padding: 15,
              boxWidth: 8
            }
          },
          tooltips:{
            mode:'label' //マウスオーバー時に表示されるtooltip
          }
        };
      }
    

      if(tab.indexOf('call_status') == 0) {
        report_setting[0].title = `[商材]${obj.name}に登録されている業種ステータス`;
        report_setting[1].title = `[商材]${obj.name}の業種ステータスグラフ`;
        
      }

      if(tab.indexOf('area') == 0) {
        report_setting[0].title = `[商材]${obj.name}に登録されている業種エリア`;
        report_setting[1].title = `[商材]${obj.name}の業種エリアグラフ`;
      }

      if(!this.setting.tabs[tab]) {
        this.setting.tabs[tab] = {};
      }

      if(!this.setting.tabs[tab][target]) {
        this.setting.tabs[tab][target] = {};
      }

      if(!this.setting.tabs[tab][target].data) {
        this.setting.tabs[tab][target].data = {};
      }

      this.setting.tabs[tab][target].data = report_setting;
    }

    //エリア別の場合
    if(this.type == 'area') {
      report_setting[0].title = `[商材]${obj.name}に登録されている業種エリア数`;
      report_setting[1].title = `[商材]${obj.name}の業種エリアグラフ`;

      this.setting.tabs.jobs_category[target].data = report_setting;
    }

    return report_setting;
  }


  //ユーザー絞り込み表示
  selectedUserReport(tab) {

    //もともとの設定をユーザーごとに複製して修正してDOMと変数に追加
    //もとの設定をコピー
    let setting = $.extend(true, {}, this.setting);
 
    //ユーザー絞り込み
    if(tab == 'user_report') {

      if(TF.select_staff_id.length == 0) {
        alert('スタッフが指定されていません');
        return false;
      }

      this.setting = this.getData('html_setting');

      TF.staff_report_data = this.getData('staff_report_data');
      TF.staff_report_data_jobs_category = this.getData('staff_report_data_jobs_category');
      TF.chart_setting = this.getData('chart_setting');
      TF.call_counterpart = this.getData('call_counterpart');
      TF.call_status = this.getData('call_status');
      TF.jobs = this.getData('jobs');
      TF.jobs_category = this.getData('jobs_category');
      CURRENT_USER = this.getData('CURRENT_USER');
      COUNTERPARTS = this.getData('COUNTERPARTS');
      
      TF.area = this.getData('area');
      TF.staff = this.getData('staff');
      TF.report_type = this.getData('report_type');
      TF.select_month = this.getData('select_month');


      let setting_data = {};

      //ユーザーが一人だけ選択されているとき
      if(TF.select_staff_id.length == 1) {
        if(TF.orig_tab.match(/_jobs_of_[0-9]+/)) {
          if(TF.orig_tab.match(/^[^\-]+-/)) {
            let parent_tab = TF.orig_tab.replace(/_jobs_of_[0-9]+/, '');
            setting_data = this.setting.tabs[parent_tab][TF.orig_tab].data;
          } else {
            setting_data = this.setting.tabs.jobs_category[TF.orig_tab].data;
          }
        } else {
          setting_data = this.setting.tabs[TF.orig_tab].data;
        }

        for(let i in setting_data) {

          let data = setting_data[i];
          if(data.staff_id == TF.select_staff_id[0]|0) {
            let dist_wrap_cls = `.p-report-content-userdata${data.staff_id}-wrap`;
            if($(dist_wrap_cls).length == 0) {
              let wrap = $('<div>').attr('id', `userdata${data.staff_id}`).addClass(`p-report-content-userdata-wrap p-report-content-userdata${data.staff_id}-wrap`)
              $(`.p-report-contents`).append(wrap);
            }

            let type = data.type == 'graph'? data.graph_type == 'pie'? 'circle -wide': data.graph_type + ' -wide' : data.type;

            let label_type = data.label_type;
            if(typeof data.row_type != 'undefined') label_type = data.row_type;
            if(typeof label_type == 'undefined') label_type = setting.type;

            let box = $('<div>').addClass(`p-report-content-userdata p-report-content-userdata${data.staff_id} -${type} -${label_type}`);

            $(`.p-report-content-userdata${data.staff_id}-wrap`).append(box);
          }
        }
      }

      //二人以上選択されているとき
      if(TF.select_staff_id.length > 1) {
        let setting_data = (TF.orig_tab.match(/_jobs_of_[0-9]+/))? this.setting.tabs.jobs_category[TF.orig_tab].data: this.setting.tabs[TF.orig_tab].data;

        //ユーザーごとのレポートデータを合計する
        TF.staff_report_integration_data = {};
        for(let i in TF.select_staff_id) {
          let staff_id = TF.select_staff_id[i];
          let data = TF.staff_report_data[staff_id];

          //各タブのデータをループ
          for(let key in data) {
            if(!TF.staff_report_integration_data[key]) TF.staff_report_integration_data[key] = {};

            //各段をループ
            for(let row in data[key]) {
              if(!TF.staff_report_integration_data[key][row]) TF.staff_report_integration_data[key][row] = {};

              if(row == 'weekday') {
        
                for(let weekday in TF.report.data_items.weekday) {
                  //各段をループ
                  if(!TF.staff_report_integration_data[key].weekday[weekday]) TF.staff_report_integration_data[key].weekday[weekday] = {};

                  //各段のデータをループ
                  for(let key2 in data[key].weekday[weekday]) {
                    if(!TF.staff_report_integration_data[key].weekday[weekday][key2]) TF.staff_report_integration_data[key].weekday[weekday][key2] = 0;

                    //データを加算していく
                    TF.staff_report_integration_data[key].weekday[weekday][key2] = TF.staff_report_integration_data[key].weekday[weekday][key2] + data[key].weekday[weekday][key2];
                  }
                }
              } else {
                //各段のデータをループ
                for(let key2 in data[key][row]) {
                  if(!TF.staff_report_integration_data[key][row][key2]) TF.staff_report_integration_data[key][row][key2] = 0;

                  //データを加算していく
                  TF.staff_report_integration_data[key][row][key2] = TF.staff_report_integration_data[key][row][key2] + data[key][row][key2];
                }
              }
            }
          }
        }

        //通話率計算
        for(let i in TF.staff_report_integration_data.all) {
          let rate = TF.staff_report_integration_data.all[i].call > 0? ((TF.staff_report_integration_data.all[i].tsuuwa/TF.staff_report_integration_data.all[i].call).toFixed(3)): 0;
          TF.staff_report_integration_data.all[i].tsuuwa_rate = (Math.round((rate * 100) * 10) / 10) + '%';
        }

        if(TF.staff_report_integration_data.all.weekday) {
          for(let i = 0, n = TF.report.data_items.weekday.length; i < n; i++) {
            let rate = TF.staff_report_integration_data.all.weekday[i].call > 0? ((TF.staff_report_integration_data.all.weekday[i].tsuuwa/TF.staff_report_integration_data.all.weekday[i].call).toFixed(3)): 0;
            TF.staff_report_integration_data.all.weekday[i].tsuuwa_rate = (Math.round((rate * 100) * 10) / 10) + '%';
          }
        }


        //ユーザーごとのグラフデータをマージする
        TF.chart_integration_setting = {};
        let n = 0, nn = 0;
        let yAxisID = [];
        let title = [];
        for(let i in TF.select_staff_id) {
          let staff_id = TF.select_staff_id[i];
          let data = TF.chart_setting[staff_id][TF.orig_tab];
          let staff = TF.staff[staff_id];

          title.push(`${staff.name}[${staff.telforce_id}]`);

          //各タブのデータをループ
          if(!TF.chart_integration_setting[TF.orig_tab]) TF.chart_integration_setting[TF.orig_tab] = {};

          //canvas_idをセット
          TF.chart_integration_setting[TF.orig_tab].canvas_id = `${TF.report_type}_${TF.orig_tab}_graph_selectusers`;

          //もろもろのデータをセット
          if(!TF.chart_integration_setting[TF.orig_tab].obj) {
            TF.chart_integration_setting[TF.orig_tab].obj = {}
            TF.chart_integration_setting[TF.orig_tab].obj.data = {}
            TF.chart_integration_setting[TF.orig_tab].obj.data.datasets = [];
            TF.chart_integration_setting[TF.orig_tab].obj.data.labels = data.obj.data.labels;
            TF.chart_integration_setting[TF.orig_tab].obj.type = data.obj.type;
          }

          for(let i in data.obj.data.datasets) {
            let set = data.obj.data.datasets[i];
            TF.chart_integration_setting[TF.orig_tab].obj.data.datasets[n] = {};

            if(data.obj.type == 'pie') {
              TF.chart_integration_setting[TF.orig_tab].obj.data.datasets[n].backgroundColor = set.backgroundColor;
            } else {
              TF.chart_integration_setting[TF.orig_tab].obj.data.datasets[n].backgroundColor = TF.report.graph.bar.colors[n];
            }

            TF.chart_integration_setting[TF.orig_tab].obj.data.datasets[n].borderWidth = set.borderWidth;
            TF.chart_integration_setting[TF.orig_tab].obj.data.datasets[n].categoryPercentage = set.categoryPercentage;
            TF.chart_integration_setting[TF.orig_tab].obj.data.datasets[n].data = set.data;
            TF.chart_integration_setting[TF.orig_tab].obj.data.datasets[n].label = `${staff.name}[${staff.telforce_id}]の` + set.label;
            TF.chart_integration_setting[TF.orig_tab].obj.data.datasets[n].order = set.order;
            TF.chart_integration_setting[TF.orig_tab].obj.data.datasets[n].type = set.type;
            TF.chart_integration_setting[TF.orig_tab].obj.data.datasets[n].yAxisID = set.yAxisID;

            if(yAxisID.indexOf((set.yAxisID)) == -1) {
              yAxisID.push(set.yAxisID);
            }

            if(set.type == 'bar') {
              TF.chart_integration_setting[TF.orig_tab].obj.data.datasets[n].stack = `stack-${i}`;
            }

            if(set.type == 'line') {
              TF.chart_integration_setting[TF.orig_tab].obj.data.datasets[n].type = set.type;
              TF.chart_integration_setting[TF.orig_tab].obj.data.datasets[n].borderColor = TF.report.graph.bar.colors[n];
              TF.chart_integration_setting[TF.orig_tab].obj.data.datasets[n].borderWidth = set.borderWidth;
              TF.chart_integration_setting[TF.orig_tab].obj.data.datasets[n].fill = set.fill;
              TF.chart_integration_setting[TF.orig_tab].obj.data.datasets[n].lineTension = set.lineTension;
            }

            n++;
          }
          
          //weekdayがあれば
          if(TF.chart_setting[staff_id][TF.orig_tab].weekday) {
            let data = TF.chart_setting[staff_id][TF.orig_tab].weekday;
            let staff = TF.staff[staff_id];
            let yAxisID_weekday = [];

            //各タブのデータをループ
            if(!TF.chart_integration_setting[TF.orig_tab].weekday) TF.chart_integration_setting[TF.orig_tab].weekday = {};

            //canvas_idをセット
            TF.chart_integration_setting[TF.orig_tab].weekday.canvas_id = `weekday_${TF.orig_tab}_graph_selectusers`;

            //もろもろのデータをセット
            if(!TF.chart_integration_setting[TF.orig_tab].weekday.obj) {
              TF.chart_integration_setting[TF.orig_tab].weekday.obj = {}
              TF.chart_integration_setting[TF.orig_tab].weekday.obj.data = {}
              TF.chart_integration_setting[TF.orig_tab].weekday.obj.data.datasets = [];
              TF.chart_integration_setting[TF.orig_tab].weekday.obj.data.labels = data.obj.data.labels;
              TF.chart_integration_setting[TF.orig_tab].weekday.obj.type = data.obj.type;
            }

            for(let i in data.obj.data.datasets) {
              let set = data.obj.data.datasets[i];
              TF.chart_integration_setting[TF.orig_tab].weekday.obj.data.datasets[nn] = {};
              
              TF.chart_integration_setting[TF.orig_tab].weekday.obj.data.datasets[nn].borderWidth = set.borderWidth;
              TF.chart_integration_setting[TF.orig_tab].weekday.obj.data.datasets[nn].categoryPercentage = set.categoryPercentage;
              TF.chart_integration_setting[TF.orig_tab].weekday.obj.data.datasets[nn].data = set.data;
              TF.chart_integration_setting[TF.orig_tab].weekday.obj.data.datasets[nn].label = `${staff.name}[${staff.telforce_id}]の` + set.label;
              TF.chart_integration_setting[TF.orig_tab].weekday.obj.data.datasets[nn].order = set.order;
              TF.chart_integration_setting[TF.orig_tab].weekday.obj.data.datasets[nn].type = set.type;
              TF.chart_integration_setting[TF.orig_tab].weekday.obj.data.datasets[nn].yAxisID = set.yAxisID;

              if(yAxisID_weekday.indexOf((set.yAxisID)) == -1) {
                yAxisID_weekday.push(set.yAxisID);
              }

              
              if(set.type == 'bar') {
                TF.chart_integration_setting[TF.orig_tab].weekday.obj.data.datasets[nn].stack = `stack-${i}`;
              }

              if(set.type == 'line') {
                TF.chart_integration_setting[TF.orig_tab].weekday.obj.data.datasets[nn].type = set.type;
                TF.chart_integration_setting[TF.orig_tab].weekday.obj.data.datasets[nn].borderColor = TF.report.graph.bar.colors[nn];
                TF.chart_integration_setting[TF.orig_tab].weekday.obj.data.datasets[nn].borderWidth = set.borderWidth;
                TF.chart_integration_setting[TF.orig_tab].weekday.obj.data.datasets[nn].fill = set.fill;
                TF.chart_integration_setting[TF.orig_tab].weekday.obj.data.datasets[nn].lineTension = set.lineTension;
              }
              nn++;
            }
          }
        }

        if(TF.chart_integration_setting[TF.orig_tab].obj.type == 'bar') {
          TF.chart_integration_setting[TF.orig_tab].obj.options = {};
          TF.chart_integration_setting[TF.orig_tab].obj.options.responsive = true;
          TF.chart_integration_setting[TF.orig_tab].obj.options.scales = {};
          TF.chart_integration_setting[TF.orig_tab].obj.options.scales.xAxes = [{
            id: "x-axis-1",
            offset: true,
          }];
          TF.chart_integration_setting[TF.orig_tab].obj.options.scales.yAxes = [];
          TF.chart_integration_setting[TF.orig_tab].obj.options.legend = {
            display: true,
            labels: {
              usePointStyle: true,
              padding: 15,
              boxWidth: 8
            }
          };
          TF.chart_integration_setting[TF.orig_tab].obj.options.tooltips = { mode:'label'};
  
          for(let i in yAxisID) {
            let obj = {
              id: yAxisID[i],
              position: "left",
              gridLines: {
                drawOnChartArea: false,
              }
            }
            
            obj.position = i == 0? "right": "left";
            obj.gridLines = i > 0? {drawOnChartArea: false} : {drawOnChartArea: true};
  
            TF.chart_integration_setting[TF.orig_tab].obj.options.scales.yAxes.push(obj);
          }
        }

        if(TF.chart_integration_setting[TF.orig_tab].obj.type == 'pie') {
          TF.chart_integration_setting[TF.orig_tab].obj.options = {
            responsive: true,
            title: {
              fontSize: 16,
              display: true,
              position: 'top',
              text: '外側から' + title.join('、')
            },
            legend : {
              display: true,
              labels: {
                usePointStyle: true,
                padding: 15,
                boxWidth: 8
              }
            }
          }
        }


        //weekdayがあれば
        if(TF.chart_integration_setting[TF.orig_tab].weekday) {
          TF.chart_integration_setting[TF.orig_tab].weekday.obj.options = {};
          TF.chart_integration_setting[TF.orig_tab].weekday.obj.options.responsive = true;
          TF.chart_integration_setting[TF.orig_tab].weekday.obj.options.scales = {};
          TF.chart_integration_setting[TF.orig_tab].weekday.obj.options.scales.xAxes = [{
            id: "x-axis-1",
            offset: true,
          }];
          TF.chart_integration_setting[TF.orig_tab].weekday.obj.options.scales.yAxes = [];
          TF.chart_integration_setting[TF.orig_tab].weekday.obj.options.legend = {
            display: true,
            labels: {
              usePointStyle: true,
              padding: 15,
              boxWidth: 8
            }
          };
          TF.chart_integration_setting[TF.orig_tab].weekday.obj.options.tooltips = { mode:'label'};

          for(let i in yAxisID) {
            let obj = {
              id: yAxisID[i],
              position: "left",
              gridLines: {
                drawOnChartArea: false,
              }
            }
            
            obj.position = i == 0? "right": "left";
            obj.gridLines = i > 0? {drawOnChartArea: false} : {drawOnChartArea: true};

            TF.chart_integration_setting[TF.orig_tab].weekday.obj.options.scales.yAxes.push(obj);
          }
        }

        //出力先append
        for(let i in setting_data) {
          let data = this.setting.tabs[TF.orig_tab].data[i];
          
          if(!data.staff_id && !data.is_ext) {
            let dist_wrap_cls = `.p-report-content-userdata-selectusers-wrap`;
            if($(dist_wrap_cls).length == 0) {
              let wrap = $('<div>').attr('id', `userdata-selectusers`).addClass(`p-report-content-userdata-wrap p-report-content-userdata-selectusers-wrap`);
              $(`.p-report-contents`).append(wrap);
            }

            let type = data.type == 'graph'? data.graph_type == 'pie'? 'circle -wide': data.graph_type + ' -wide' : data.type;

            let label_type = data.label_type;
            if(typeof data.row_type != 'undefined') label_type = data.row_type;
            if(typeof label_type == 'undefined') label_type = setting.type;

            let box = $('<div>').addClass(`p-report-content-userdata p-report-content-userdata-selectusers -${type} -${label_type}`);

            $(`.p-report-content-userdata-selectusers-wrap`).append(box);

          }
        }
      }

    } else {
  
      //通常
      //ユーザーが選択されていなければ
      if(
        (!TF.selected_staff || TF.selected_staff.length == 0) ||
        (TF.selected_staff && TF.selected_staff.length > 4)
      ) {
        this.setting = $.extend(true, {}, this.setting_orig);
        
        if(this.type == 'week' || this.type == 'month') {
          this.monthDays(this.type);
        }
        $('.p-widget.-anchor-box').remove();
        return false;
      } 

      //4名選択されていたら
      let shrink = TF.selected_staff.length == 4 ? ' -shrink': '';
      
      //localstrageに保存されているデータを削除
      //this.clearData(['select_month', 'html_setting', 'chart_setting', 'staff_report_data', 'report_type']);
    
      $(`.-${tab} .p-widget.-anchor-box`).remove();

      //for(let i in tabs) {
        //選択したユーザーのアンカーリンクのボックス
        let anchor_widget = $(`<div id="${tab}_anchor-box" class="p-widget -anchor-box u-shadow">
          <div class="p-widget-inner">
            <div class="p-widget-content">
            </div>
          </div>
        </div>`);

        if(tab.indexOf('all') == 0) {
          let btn = $('<a>').attr({
            href: `javascript:void(0)`,
            class: '-open_select_user_report'
          }).text('選択したユーザーレポートをまとめて表示');

          btn.on('click', (e) => {
            let ids = [];
            $('.-user_report_select:checked').each(function(i) {
              ids.push($(this).val());
            });

            if(ids.length == 0) {
              alert('ユーザーが選択されていません');
              return false;
            }

            window.open(`${TF.js_url_path}user_report.html?id=${ids.join(',')}&tab=all&report_type=${this.type}`,'_blank',"height=500, width=1000, menubar=no, toolbar=no, location=no, status=no, ,resizable=yes, scrollbars=yes, directories=no");
          });

          anchor_widget.find('.p-widget-content').append(btn);
        }

        $('.-' + tab).append(anchor_widget);
      //}
      for(let i in TF.selected_staff) {
        let staff = TF.staff[TF.selected_staff[i]];
        //for(let ii in tabs) {
          //let tab = tabs[ii];
         
          //業種カテゴリのユーザーレポート設定
          if(tab.indexOf('jobs_category') > -1) {
           
            //業種カテゴリ用レポート設定
            for(let child_tab in setting.tabs[tab]) {
              //各商材用
              let child_tab_data = setting.tabs[tab][child_tab];
              
              let set = child_tab_data.data;
               
              for(let ii in set) { 
                
                //グラフのラベルの形式を取得
                let label_type = set[ii].label_type;
                if(typeof set[ii].row_type != 'undefined') label_type = set[ii].row_type;
                if(typeof label_type == 'undefined') label_type = setting.type; 
              
                //拡張でないかつユーザー用でない設定であれば
                if(!set[ii].is_ext && !set[ii].staff_id) {
                  //設定を複製
                  let tmp;
                  tmp = $.extend(true, {}, set[ii]);
  
                  //スタッフIDを追加
                  tmp.staff_id = staff.telforce_id;
                  //各商材のIDを追加
                  tmp.child_tab_id = child_tab;
                  //ユーザー名を追加したタイトルを設定
                  tmp.title = `${staff.name}[${staff.telforce_id}] の${tmp.title}`;
                  //出力先をユーザー用に修正
                  tmp.dist = tmp.dist.replace('p-report-child-content-data', 'p-report-child-content-userdata' + staff.telforce_id);

                  //グラフ出力先を修正
                  tmp.dist = tmp.dist.replace('-pie', '-circle');
                  //グラフ出力先を修正
                  if(tmp.dist.indexOf('-bar') > -1 && tmp.graph_type == 'pie') {
                    tmp.dist = tmp.dist.replace('-bar', '-circle');
                  }
                  //canvas idを修正
                  if(tmp.canvas_id) tmp.canvas_id = tmp.canvas_id.replace('_graph','_graph_user' + staff.telforce_id);
                  //canvas idを修正
                  let res = setting.tabs[tab][child_tab].data.find(v => v.dist == tmp.dist);
                  if(!res) {
                    //レポート設定に追加
                    setting.tabs[tab][child_tab].data.push(tmp);
                  }
                }
                
              }
            }
          } else {

           //業種カテゴリ以外のユーザーレポート設定
            for(let iii in setting.tabs[tab].data) {
              let set = setting.tabs[tab].data[iii];

              let label_type = set.label_type;
              if(typeof set.row_type != 'undefined') label_type = set.row_type;
              if(typeof label_type == 'undefined') label_type = setting.type; 

              if(!set.is_ext && !set.staff_id) {

                let tmp;
                tmp = $.extend(true, {}, set);

                //スタッフIDを追加
                tmp.staff_id = staff.telforce_id;
                tmp.title = `${staff.name}[${staff.telforce_id}] の${tmp.title}`;
                tmp.dist = tmp.dist.replace('p-report-content-data', 'p-report-content-userdata' + staff.telforce_id);

                tmp.dist = tmp.dist.replace('-pie', '-circle');

                if(tmp.dist.indexOf('-bar') > -1 && tmp.graph_type == 'pie') {
                  tmp.dist = tmp.dist.replace('-bar', '-circle');
                }

                if(tmp.canvas_id) tmp.canvas_id = tmp.canvas_id.replace('_graph','_graph_user' + staff.telforce_id);

                setting.tabs[tab].data.push(tmp);
              }
            }
          }
        //}

        //設定を保存
        this.setting = $.extend(true, {}, setting);
        this.setData('html_setting', this.setting);
        this.setData('select_month', this.select_month);
        this.setData('report_type', this.type);

      }

      //出力先append
      //for(let i in setting.tabs) {

    
      //業種カテゴリの出力先
      if(tab.indexOf('jobs_category') > -1) {
        let tabs = setting.tabs[tab];
        
        let anchor_id = ''; 
        for(let tb in tabs) {
          if(!anchor_id) anchor_id = tb;

          for(let i in tabs[tb]) {
            let set = tabs[tb][i];

            for(let ii in set) {
              let data = set[ii]; 
              if(data.staff_id && data.child_tab_id) {

                let label_type = data.label_type;
                if(typeof data.row_type != 'undefined') label_type = data.row_type;
                if(typeof label_type == 'undefined') label_type = setting.type;

                let dist_wrap_cls = `.${data.child_tab_id} .p-report-child-content-userdata${data.staff_id}-wrap`;
                
                let area_id, selectuser_name;
                area_id = `userdata${data.staff_id}_${data.child_tab_id}`;
                selectuser_name = ALL_USERS[data.staff_id].name;

                if($(dist_wrap_cls).length == 0) {
                  if($('#' + area_id).length > 0) continue;

                  //wrapper
                  let wrap = $('<div>').attr('id', area_id).addClass(`p-report-child-content-userdata-wrap p-report-child-content-userdata${data.staff_id}-wrap${shrink}`);
    
                  wrap.append($('<a>').attr({
                    href: `javascript:window.open("${TF.js_url_path}user_report.html?id=${data.staff_id}&tab=${data.child_tab_id}&label_type=${label_type}&report_type=${this.type}",'_blank',"height=500, width=1000, menubar=no, toolbar=no, location=no, status=no, resizable=yes, scrollbars=yes, directories=no")`,
                    class: '-open_user_report',
                    'data-reportid': data.staff_id
                  }));
                  $(`.p-report-child-content.${data.child_tab_id}`).append(wrap);
                }
          
                //アンカー
                if(!$(`#${area_id}_anchor`)[0]) {
                  let anchor = $('<a>').attr({
                    href: `#${area_id}`,
                    id: `${area_id}_anchor`,
                    class: '-select-user-anchor -jobs_category-anchor'
                  }).text('▶ ' + selectuser_name);
 
                  if(data.child_tab_id.indexOf('all') == 0) {
                    let checkbox = `<div class="p-data-checkbox"><input type="checkbox" id="selectuser${data.staff_id}" class="-user_report_select" value="${data.staff_id}"><label for="selectuser${data.staff_id}"></label></div>`;
                    anchor.append(checkbox);
                  }
    
                  $(`#${tab}_anchor-box .p-widget-content`).append(anchor);
                }

                let type = data.type == 'graph'? data.graph_type == 'pie'? 'circle.-wide': data.graph_type + '.-wide' : data.type;
                let cls = `p-report-child-content-userdata.p-report-child-content-userdata${data.staff_id}.-${type}.-${label_type}`;

                if($(`.${data.child_tab_id} .${cls}`).length > 0) continue;

                let box = $('<div>').addClass(cls.split('.').join(' '));
                $(`.${data.child_tab_id} .p-report-child-content-userdata${data.staff_id}-wrap`).append(box);
              }
            }

            
          }
        }
        //アンカー表示
        $('.-jobs_category-anchor').hide();
        $('.-jobs_category-anchor[href*="' + anchor_id + '"]').show();

      } else {
        //業種カテゴリ以外の出力先

        let set = setting.tabs[tab].data;

       
        for(let ii in set) {
          let data = set[ii];
        
          if(data.staff_id) {
            let area_id = `userdata${data.staff_id}_${tab}`;
            let selectuser_name = ALL_USERS[data.staff_id].name;
            let label_type = data.label_type;
            if(typeof data.row_type != 'undefined') label_type = data.row_type;
            if(typeof label_type == 'undefined') label_type = setting.type;

            //let child = (data.dist.indexOf('jobs_of') > -1)? '-child': '';
            let child = '';

            let dist_wrap_cls = `.-${tab} .p-report-content-userdata${data.staff_id}-wrap`;

            if($(dist_wrap_cls).length == 0) {

              //wrapper
              let wrap = $('<div>').attr('id', area_id).addClass(`p-report-content-userdata-wrap p-report-content-userdata${data.staff_id}-wrap${shrink}`);

              wrap.append($('<a>').attr({
                href: `javascript:window.open("${TF.js_url_path}user_report.html?id=${data.staff_id}&tab=${tab}&label_type=${label_type}&report_type=${this.type}",'_blank',"height=500, width=1000, menubar=no, toolbar=no, location=no, status=no, resizable=yes, scrollbars=yes, directories=no")`,
                class: '-open_user_report',
                'data-reportid': data.staff_id
              }));
              $(`.p-report-content.-${tab}:not(.-jobs_category)`).append(wrap);

              //アンカー
              let anchor = $('<a>').attr({
                href: `#${area_id}`,
                class: '-select-user-anchor'
              }).text('▶ ' + selectuser_name);
              
              if(tab.indexOf('all') == 0) {
                let checkbox = `<div class="p-data-checkbox"><input type="checkbox" id="selectuser${data.staff_id}" class="-user_report_select" value="${data.staff_id}"><label for="selectuser${data.staff_id}"></label></div>`;
                anchor.append(checkbox);
              }

              $(`#${tab}_anchor-box .p-widget-content`).prepend(anchor);
            }
      
            let type = data.type == 'graph'? data.graph_type == 'pie'? 'circle -wide': data.graph_type + ' -wide' : data.type;
            let cls = `p-report-content-userdata.p-report-content-userdata${data.staff_id}.-${type}.-${label_type}`;

            if(!$(`.-${tab}.` + cls)[0]) {
              let box = $('<div>').addClass(cls.split('.').join(' '));
              $(`.-${tab}:not(.-jobs_category) .p-report-content-userdata${data.staff_id}-wrap`).append(box);
            }
          }
        }
      }
    }   
  }



  /*************************************************************************
  ▼データ処理
  ページごとに似たような処理があるのはデータ処理に時間がかかるためページごとに分割しています
  *************************************************************************/
  proccessing(datatype) {

    //大項目で判別
    switch(this.type) {
      case 'hour': {
        //各タブで判別
        this.report_data = {};
        this.report_data.all = {};
        TF.staff_report_data = {};

        //時間ごとのデータ構造をつくる
        //ユーザーが選択されていれば
        if(TF.selected_staff && TF.selected_staff.length > 0) {        
          for(let i in TF.selected_staff) {
            let key = TF.selected_staff[i]|0;
            TF.staff_report_data[key] = {};
            TF.staff_report_data[key].all = {};
          }
        }

        for(let i = 0, n = this.setting.table_setting.rows.length; i < n; i++) {
          let start = this.setting.table_setting.rows[i].start.split(':');
          start = ((start[0]|0) * 3600) + ((start[1]|0) * 60) + (start[2]|0);

          let end = this.setting.table_setting.rows[i].end.split(':');
          end = ((end[0]|0) * 3600) + ((end[1]|0) * 60) + (end[2]|0);

          let key = start + '-' + end;

          this.report_data.all[key] = {};
          this.report_data.all[key].call = 0;
          this.report_data.all[key].tsuuwa = 0;
          this.report_data.all[key].tsuuwa_rate = 0;
          this.report_data.all[key].tsuuwa_time = 0;

          //ユーザーが選択されていれば
          if(TF.selected_staff && TF.selected_staff.length > 0) {
            for(let ii in TF.selected_staff) {
              let key2 = TF.selected_staff[ii]|0;
              TF.staff_report_data[key2].all[key] = {};
              TF.staff_report_data[key2].all[key].call = 0;
              TF.staff_report_data[key2].all[key].tsuuwa = 0;
              TF.staff_report_data[key2].all[key].tsuuwa_rate = 0;
              TF.staff_report_data[key2].all[key].tsuuwa_time = 0;
            }
          }

          //各タブ
          for(let ii = 0, nn = this.datatype.length; ii < nn; ii++) {
            let dt = this.datatype[ii];
            
            if(typeof this.report_data[dt] == 'undefined') this.report_data[dt] = {};
            this.report_data[dt][key] = {};

            for(let iii = 0, nnn = TF[dt].length; iii < nnn; iii++) {
              this.report_data[dt][key][TF[dt][iii].id] = 0;
            }

            //追加レポートがありかつ、追加該当タブ名が一致したら
            if(TF.add_report && TF.add_report.tab == dt) {
              //追加用のobjectを定義
              if(typeof this.report_data[dt].add == 'undefined') this.report_data[dt].add = {};

              //追加用のデータ構造を定義
              for(let iiii in TF.add_report.data) {
                //各rowを定義
                if(typeof this.report_data[dt].add[iiii] == 'undefined') this.report_data[dt].add[iiii] = {};

                //rowを定義
                if(typeof this.report_data[dt].add[iiii][key] == 'undefined') this.report_data[dt].add[iiii][key] = {};

                //各rowのセルを定義
                for(let iiiii in TF.add_report.data[iiii]) {
                  this.report_data[dt].add[iiii][key][TF.add_report.data[iiii][iiiii].id] = 0;
                }
              }
            }

            //ユーザーが選択されていれば
            if(TF.selected_staff && TF.selected_staff.length > 0) {
              for(let ii in TF.selected_staff) {
                let key2 = TF.selected_staff[ii]|0;
                if(typeof TF.staff_report_data[key2][dt] == 'undefined') TF.staff_report_data[key2][dt] = {};
                TF.staff_report_data[key2][dt][key] = {};

                for(let iii = 0, nnn = TF[dt].length; iii < nnn; iii++) {
                  TF.staff_report_data[key2][dt][key][TF[dt][iii].id] = 0;
                }

              }
            }
          }
        }

        
        
        let career = {};
        let apli_before = {};
        let apli_after = {};

        //データ抽出
        for(let i = 0, n = TF.report_data.length; i < n; i++) {
          let data = TF.report_data[i];

          //商材かステータスが絞り込まれていたら
          let continue_flag = 0;
          if(data.call_counterpart != null && this.select_call_counterpart && this.select_call_counterpart.length > 0) {
            
            if(this.select_call_counterpart.indexOf(data.call_counterpart) == -1 ) {
              continue_flag++;
            }
          }

          if(data.call_status != null && this.select_call_status && this.select_call_status.length > 0) {
            if(this.select_call_status.indexOf(data.call_status) == -1 ) {
              continue_flag++;
            }
          }
          //商材もしくはステータスでどちらかもしくは両方ヒットしていなかったら
          if( continue_flag > 0 ) {
            continue;
          }
          
          if(
            (this.select_call_counterpart && this.select_call_counterpart.length > 0) ||
            (this.select_call_status && this.select_call_status.length > 0)
          ) {
            //durationと登録履歴を結びつける
            if(data && data.duration) {
              //キャリア登録の場合
              if(data.customer_id == '0') {
                if(!career[data.tel_no]) {
                  career[data.tel_no] = data.duration;
                } else {
                  let time = career[data.tel_no].split(':');
                  time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
                  
                  let time2 = data.duration.split(':');
                  time2 = ((time2[0]|0) * 3600) + ((time2[1]|0) * 60) + (time2[2]|0);

                  time = time + time2;
                  let h = time / 3600|0;
                  let m = time % 3600 / 60|0;
                  let s = time % 60;

                  time = (h < 10?'0'+ h:h)+':'+(m < 10?'0'+ m:m)+':'+(s < 10?'0'+ s:s);
                  career[data.tel_no] = time;
                }
                data.duration = null;
                
              }

              if(data.customer_id != '0') {
          
                if(!apli_before[data.tel_no]) {
                  apli_before[data.tel_no] = data.duration;
                } else {
                  let time = apli_before[data.tel_no].split(':');
                  time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
                  
                  let time2 = data.duration.split(':');
                  time2 = ((time2[0]|0) * 3600) + ((time2[1]|0) * 60) + (time2[2]|0);
        
                  time = time + time2;
                  let h = time / 3600|0;
                  let m = time % 3600 / 60|0;
                  let s = time % 60;
        
                  time = (h < 10?'0'+ h:h)+':'+(m < 10?'0'+ m:m)+':'+(s < 10?'0'+ s:s);
                  apli_before[data.tel_no] = time;
                }
        
                data.duration = null;
              }

              if(data.customer_id != '0' && apli_after[data.tel_no]) { //アプリ登録の場合
                data.call_counterpart = apli_after[data.tel_no].call_counterpart;
                data.call_status = apli_after[data.tel_no].call_status;
                data.integration_id_1 = apli_after[data.tel_no].integration_id_1;
                data.integration_id_2 = apli_after[data.tel_no].integration_id_2;

              //apli_after = {};
              }

            }
          }

          if(data && !data.duration) {

            //durationないけど諸データがセットされているとき
            if(
              data.call_counterpart ||
              data.call_status ||
              data.integration_id_1 ||
              data.integration_id_2
            ) {

              if(data.customer_id != '0' && apli_before[data.tel_no]) {
                data.duration = apli_before[data.tel_no];
              }

              if(data.customer_id != '0' && !apli_after[data.tel_no]) {
                apli_after[data.tel_no] = {
                  call_counterpart: data.call_counterpart,
                  call_status: data.call_status,
                  integration_id_1: data.integration_id_1,
                  integration_id_2: data.integration_id_2
                };
              }

              if(career[data.tel_no]) {
                data.duration = career[data.tel_no];
              }
            }
          }

          //商材もしくはステータスが絞り込まれているのにdataにどちらかがなかったら
          if(
            (!data.call_counterpart && this.select_call_counterpart && this.select_call_counterpart.length > 0) ||
            (!data.call_status && this.select_call_status && this.select_call_status.length > 0)
          ) {
            continue;
          }


          if(data && typeof data.call_time != 'undefined') {

            //時間ごと
            let call_time = data.call_time.split(' ');
                call_time = call_time[1].split(':');
                call_time = ((call_time[0]|0) * 3600) + ((call_time[1]|0) * 60) + (call_time[2]|0);

            for(let condition in this.report_data.all) {
              let key = condition.split('-');

              if((key[0]|0) <= call_time && (key[1]|0) > call_time) {

                //音源があるかないかでカウント
                if(data.duration) {

                  this.report_data.all[condition].call = this.report_data.all[condition].call + 1; 
                  //音源があれば
                  let time = data.duration.split(':');
                  time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
                  
                  //5秒以上録音されていれば
                  if((time|0) > this.duration_threshold) {
                    this.report_data.all[condition].tsuuwa = this.report_data.all[condition].tsuuwa + 1;
                  
                    this.report_data.all[condition].tsuuwa_time = this.report_data.all[condition].tsuuwa_time + time;
                  }
                }

                //他のタブのデータ
                for(let iii = 0, nnn = this.datatype.length; iii < nnn; iii++) {
                  let dt = this.datatype[iii];
                  let column = dt == 'area'? 'integration_id_1': dt;
                  column = dt == 'jobs'? 'integration_id_2': column;
                  column = dt == 'jobs_category'? 'integration_id_2': column;

                  if((data[column]|0) > 0) {
                    //データをカウント
                    this.report_data[dt][condition][data[column]] = this.report_data[dt][condition][data[column]] + 1;

                    //追加レポートがあれば
                    if(TF.add_report) {
                      for(let iiii in TF.add_report.data) {
                        if(TF.add_report.tab == dt && iiii == data[column]) {
                          let column2 = TF.add_report.datatype == 'area'? 'integration_id_1': TF.add_report.datatype;
                          column2 = TF.add_report.datatype == 'jobs'? 'integration_id_2': column2;
                          
                          if(typeof this.report_data[dt].add[iiii][condition][data[column2]] == 'number') {
                            this.report_data[dt].add[iiii][condition][data[column2]] = this.report_data[dt].add[iiii][condition][data[column2]] + 1;
                          }
                        }
                      }
                    }
                  }
                }

                //ユーザー絞り込み
                if(TF.selected_staff && TF.selected_staff.length > 0) {
                  for(let ii in TF.selected_staff) {
                    let key2 = TF.selected_staff[ii];
                    
                    if(data.staff_id == key2) {
                      
                      //音源があるかないかでカウント
                      if(data.duration) {

                        TF.staff_report_data[key2].all[condition].call = TF.staff_report_data[key2].all[condition].call + 1;
                        //音源があれば
                        let time = data.duration.split(':');
                        time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);

                        //5秒以上録音されていれば
                        if((time|0) > this.duration_threshold) {
                          TF.staff_report_data[key2].all[condition].tsuuwa = TF.staff_report_data[key2].all[condition].tsuuwa + 1;
                          TF.staff_report_data[key2].all[condition].tsuuwa_time += time;
                        }
                      }

                      //他のタブのデータ
                      for(let iii = 0, nnn = this.datatype.length; iii < nnn; iii++) {

                        let dt = this.datatype[iii];
                        let column = dt == 'area'? 'integration_id_1': dt;
                        column = dt == 'jobs'? 'integration_id_2': column;
                        column = dt == 'jobs_category'? 'integration_id_2': column;

                        if((data[column]|0) > 0) {
                          //データをカウント
                          TF.staff_report_data[key2][dt][condition][data[column]] = TF.staff_report_data[key2][dt][condition][data[column]] + 1;
                        }
                      }
                    }
                  }
                }
              } 
            }
          }
        }

        //通話率計算
        for(let i in this.report_data.all) {
          let rate = this.report_data.all[i].call > 0? ((this.report_data.all[i].tsuuwa/this.report_data.all[i].call).toFixed(3)): 0;

          this.report_data.all[i].tsuuwa_rate = (Math.round((rate * 100) * 10) / 10) + '%';
        }

        //ユーザー絞り込み通話率計算
        if(TF.selected_staff && TF.selected_staff.length > 0) {
          
          for(let i in TF.staff_report_data) {
            let tmp = {};
      
            for(let ii in TF.staff_report_data[i].all) {        
              let rate = TF.staff_report_data[i].all[ii].call > 0? ((TF.staff_report_data[i].all[ii].tsuuwa/TF.staff_report_data[i].all[ii].call).toFixed(3)): 0;
              TF.staff_report_data[i].all[ii].tsuuwa_rate = (Math.round((rate * 100) * 10) / 10) + '%';
            }

            //各ユーザーの業種カテゴリ用のデータを商材ごとに分割
            for(let key in TF.staff_report_data[i].jobs_category) {
              //key は時間帯　各業種を取り出してループ
              for(let job_id in TF.staff_report_data[i].jobs_category[key]) {
                let job_val = TF.staff_report_data[i].jobs_category[key][job_id]|0;
                //その業種を登録されている商材ごとに分類
                for(let iii in TF.jobs_category) {
                  let jobs = TF.jobs_category[iii];
                  //業種IDが同じだったら
                  if((job_id|0) == (jobs.id|0)) {
                    //商材のオブジェクトが定義されていなかったら仮の一時変数に定義
                    if(!tmp[jobs.counterpart_id]) {
                      tmp[jobs.counterpart_id] = {};
                    }

                    //商材だけで分ける場合　keyの時間帯ごとにオブジェクト定義
                    if(!tmp[jobs.counterpart_id][key]) {
                      tmp[jobs.counterpart_id][key] = {};
                    }

                    //業種件数を格納
                    tmp[jobs.counterpart_id][key][jobs.id] = job_val;
                  }
                }
              }
            }
           

            //一時変数でもとのオブジェクトを上書き
            TF.staff_report_data[i].jobs_category = tmp;
          }

          //絞り込み用に格納
          this.setData('staff_report_data', TF.staff_report_data);

        }

        //業種カテゴリ用のデータを商材ごとに分割
        this.report_data_jobs_category = {};
        this.report_data_jobs_category.jobs_category = {};
        for(let key in this.report_data.jobs_category) {
          for(let job_id in this.report_data.jobs_category[key]) {
            let job_val = this.report_data.jobs_category[key][job_id]|0;
            
            for(let i in TF.jobs_category) {
              let jobs = TF.jobs_category[i];
              if((job_id|0) == (jobs.id|0)) {
                if(!this.report_data_jobs_category.jobs_category[jobs.counterpart_id]) {
                  this.report_data_jobs_category.jobs_category[jobs.counterpart_id] = {};
                }

                /* カテゴリで分ける場合
                if(!this.report_data_jobs_category[jobs.counterpart_id][jobs.category_id]) {
                  this.report_data_jobs_category[jobs.counterpart_id][jobs.category_id] = {};
                }

                if(!this.report_data_jobs_category[jobs.counterpart_id][jobs.category_id][key]) {
                  this.report_data_jobs_category[jobs.counterpart_id][jobs.category_id][key] = {};
                }

                this.report_data_jobs_category[jobs.counterpart_id][jobs.category_id][key][jobs.id] = job_val;*/

                /*商材だけで分ける場合*/
                if(!this.report_data_jobs_category.jobs_category[jobs.counterpart_id][key]) {
                  this.report_data_jobs_category.jobs_category[jobs.counterpart_id][key] = {};
                }

                this.report_data_jobs_category.jobs_category[jobs.counterpart_id][key][jobs.id] = job_val;
              }
            }
          }
        }

        break;
      }

      case 'week': {

        //各タブで判別
        this.report_data = {};
        this.report_data.all = {};
        TF.staff_report_data = {};
        
        //週ごとのデータ構造をつくる
        //ユーザーが選択されていれば
        if(TF.selected_staff && TF.selected_staff.length > 0) {        
          for(let i in TF.selected_staff) {
            let key = TF.selected_staff[i]|0;
            TF.staff_report_data[key] = {};
            TF.staff_report_data[key].all = {};
          }
        }

        for(let i = 0, n = this.setting.table_setting.rows.length; i < n; i++) {
          
          let start = this.setting.table_setting.rows[i].start;
          let end = this.setting.table_setting.rows[i].end;
          
          /*let date = this.select_month + '/' + ((start < 10)? '0' + start: start);
 
          start = moment(date, 'YYYY/MM/DD').unix();
          date = this.select_month + '/' + ((end < 10)? '0' + end: end);
          end = moment(date, 'YYYY/MM/DD').unix();*/

          let key = start + '-' + end;
          this.report_data.all[key] = {};
          this.report_data.all[key].call = 0;
          this.report_data.all[key].tsuuwa = 0;
          this.report_data.all[key].tsuuwa_rate = 0;
          this.report_data.all[key].tsuuwa_time = 0;

          //ユーザーが選択されていれば
          if(TF.selected_staff && TF.selected_staff.length > 0) {
            for(let ii in TF.selected_staff) {
              let key2 = TF.selected_staff[ii]|0;
              TF.staff_report_data[key2].all[key] = {};
              TF.staff_report_data[key2].all[key].call = 0;
              TF.staff_report_data[key2].all[key].tsuuwa = 0;
              TF.staff_report_data[key2].all[key].tsuuwa_rate = 0;
              TF.staff_report_data[key2].all[key].tsuuwa_time = 0;
            }
          }

          //各タブ
          for(let ii = 0, nn = this.datatype.length; ii < nn; ii++) {
            let dt = this.datatype[ii];
            if(typeof this.report_data[dt] == 'undefined') this.report_data[dt] = {};
            this.report_data[dt][key] = {};
            //0で埋める
            for(let iii = 0, nnn = TF[dt].length; iii < nnn; iii++) {
              this.report_data[dt][key][TF[dt][iii].id] = 0;
            }

            //追加レポートがありかつ、追加該当タブ名が一致したら
            if(TF.add_report && TF.add_report.tab == dt) {
              //追加用のobjectを定義
              if(typeof this.report_data[dt].add == 'undefined') this.report_data[dt].add = {};        

              //追加用のデータ構造を定義
              for(let iiii in TF.add_report.data) {
                //各rowを定義
                if(typeof this.report_data[dt].add[iiii] == 'undefined') this.report_data[dt].add[iiii] = {};

                //rowを定義
                if(typeof this.report_data[dt].add[iiii][key] == 'undefined') this.report_data[dt].add[iiii][key] = {};

                //各rowのセルを定義
                for(let iiiii in TF.add_report.data[iiii]) {
                  this.report_data[dt].add[iiii][key][TF.add_report.data[iiii][iiiii].id] = 0;
                }
              }
            }

            //ユーザーが選択されていれば
            if(TF.selected_staff && TF.selected_staff.length > 0) {
              for(let ii in TF.selected_staff) {
                let key2 = TF.selected_staff[ii]|0;
                if(typeof TF.staff_report_data[key2][dt] == 'undefined') TF.staff_report_data[key2][dt] = {};
                TF.staff_report_data[key2][dt][key] = {};

                for(let iii = 0, nnn = TF[dt].length; iii < nnn; iii++) {
                  TF.staff_report_data[key2][dt][key][TF[dt][iii].id] = 0;
                }
              }
            }
          }
        }

        //曜日ごとデータ構造を作る
        this.report_data.all.weekday = {};
 
        //ユーザーが選択されていれば
        if(TF.selected_staff && TF.selected_staff.length > 0) {        
          for(let i in TF.selected_staff) {
            let key = TF.selected_staff[i]|0;
            TF.staff_report_data[key].all.weekday = {};
          }
        }

        for(let i = 0, n = TF.report.data_items.weekday.length; i < n; i++) {
          this.report_data.all.weekday[i] = {};
          this.report_data.all.weekday[i].call = 0;
          this.report_data.all.weekday[i].tsuuwa = 0;
          this.report_data.all.weekday[i].tsuuwa_rate = 0;
          this.report_data.all.weekday[i].tsuuwa_time = 0;

          //ユーザーが選択されていれば
          if(TF.selected_staff && TF.selected_staff.length > 0) {
            for(let ii in TF.selected_staff) {
              let key = TF.selected_staff[ii]|0;
              TF.staff_report_data[key].all.weekday[i] = {};
              TF.staff_report_data[key].all.weekday[i].call = 0;
              TF.staff_report_data[key].all.weekday[i].tsuuwa = 0;
              TF.staff_report_data[key].all.weekday[i].tsuuwa_rate = 0;
              TF.staff_report_data[key].all.weekday[i].tsuuwa_time = 0;
            }
          }

          //各タブ
          for(let ii = 0, nn = this.datatype.length; ii < nn; ii++) {
            let dt = this.datatype[ii];

            if(typeof this.report_data[dt] == 'undefined') this.report_data[dt] = {};
            if(typeof this.report_data[dt].weekday == 'undefined') this.report_data[dt].weekday = {};
            this.report_data[dt].weekday[i] = {};
            for(let iii = 0, nnn = TF[dt].length; iii < nnn; iii++) {
              this.report_data[dt].weekday[i][TF[dt][iii].id] = 0;
            }

            //ユーザーが選択されていれば
            if(TF.selected_staff && TF.selected_staff.length > 0) {
              for(let iii in TF.selected_staff) {
                let key2 = TF.selected_staff[iii]|0;
                if(typeof TF.staff_report_data[key2][dt].weekday == 'undefined') TF.staff_report_data[key2][dt].weekday = {};
                TF.staff_report_data[key2][dt].weekday[i] = {};

                for(let iiii = 0, nnn = TF[dt].length; iiii < nnn; iiii++) {
                  TF.staff_report_data[key2][dt].weekday[i][TF[dt][iiii].id] = 0;
                }

              }
            }
/*
            //追加レポートがありかつ、追加該当タブ名が一致したら
            if(TF.add_report && TF.add_report.tab == dt) {
              if(typeof this.report_data[dt].add.weekday == 'undefined') this.report_data[dt].add.weekday = {}          

              //追加用のデータ構造を定義
              for(let iiii in TF.add_report.data) {

                //各rowを定義
                if(typeof this.report_data[dt].add.weekday[iiii] == 'undefined') this.report_data[dt].add.weekday[iiii] = {};

                //rowを定義
                if(typeof this.report_data[dt].add.weekday[iiii][i] == 'undefined') this.report_data[dt].add.weekday[iiii][i] = {};

                //各rowのセルを定義
                for(let iiiii in TF.add_report.data[iiii]) {
                  this.report_data[dt].add.weekday[iiii][i][TF.add_report.data[iiii][iiiii].id] = 0;
                }
              }
            }*/
          }
        }

        //データ抽出
        let career = {};
        let apli_before = {};
        let apli_after = {};
        for(let i = 0, n = TF.report_data.length; i < n; i++) {
          let data = TF.report_data[i];

          //商材かステータスが絞り込まれていたら
          let continue_flag = 0;
          if(data.call_counterpart != null && this.select_call_counterpart && this.select_call_counterpart.length > 0) {
            
            if(this.select_call_counterpart.indexOf(data.call_counterpart) == -1 ) {
              continue_flag++;
            }
          }

          if(data.call_status != null && this.select_call_status && this.select_call_status.length > 0) {
            if(this.select_call_status.indexOf(data.call_status) == -1 ) {
              continue_flag++;
            }
          }
          //商材もしくはステータスでどちらかもしくは両方ヒットしていなかったら
          if( continue_flag > 0 ) {
            continue;
          }

          if(
            (this.select_call_counterpart && this.select_call_counterpart.length > 0) ||
            (this.select_call_status && this.select_call_status.length > 0)
          ) {
            //durationと登録履歴を結びつける
            if(data && data.duration) {
              //キャリア登録の場合
              if(data.customer_id == '0') {
                if(!career[data.tel_no]) {
                  career[data.tel_no] = data.duration;
                } else {
                  let time = career[data.tel_no].split(':');
                  time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
                  
                  let time2 = data.duration.split(':');
                  time2 = ((time2[0]|0) * 3600) + ((time2[1]|0) * 60) + (time2[2]|0);

                  time = time + time2;
                  let h = time / 3600|0;
                  let m = time % 3600 / 60|0;
                  let s = time % 60;

                  time = (h < 10?'0'+ h:h)+':'+(m < 10?'0'+ m:m)+':'+(s < 10?'0'+ s:s);
                  career[data.tel_no] = time;
                }
                data.duration = null;
                
              }

              if(data.customer_id != '0') {
                  
                if(!apli_before[data.tel_no]) {
                  apli_before[data.tel_no] = data.duration;
                } else {
                  let time = apli_before[data.tel_no].split(':');
                  time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
                  
                  let time2 = data.duration.split(':');
                  time2 = ((time2[0]|0) * 3600) + ((time2[1]|0) * 60) + (time2[2]|0);

                  time = time + time2;
                  let h = time / 3600|0;
                  let m = time % 3600 / 60|0;
                  let s = time % 60;

                  time = (h < 10?'0'+ h:h)+':'+(m < 10?'0'+ m:m)+':'+(s < 10?'0'+ s:s);
                  apli_before[data.tel_no] = time;
                }

                data.duration = null;
              }

              if(data.customer_id != '0' && apli_after[data.tel_no]) { //アプリ登録の場合
                data.call_counterpart = apli_after[data.tel_no].call_counterpart;
                data.call_status = apli_after[data.tel_no].call_status;
                data.integration_id_1 = apli_after[data.tel_no].integration_id_1;
                data.integration_id_2 = apli_after[data.tel_no].integration_id_2;

              //apli_after = {};
              }
            }
          

            if(data && !data.duration) {

              //durationないけど諸データがセットされているとき
              if(
                data.call_counterpart ||
                data.call_status ||
                data.integration_id_1 ||
                data.integration_id_2
              ) {

                if(data.customer_id != '0' && apli_before[data.tel_no]) {
                  data.duration = apli_before[data.tel_no];
                }

                if(data.customer_id != '0' && !apli_after[data.tel_no]) {
                  apli_after[data.tel_no] = {
                    call_counterpart: data.call_counterpart,
                    call_status: data.call_status,
                    integration_id_1: data.integration_id_1,
                    integration_id_2: data.integration_id_2
                  };
                }

                if(career[data.tel_no]) {
                  data.duration = career[data.tel_no];
                }
              }
            }
          }

          //商材もしくはステータスが絞り込まれているのにdataにどちらかがなかったら
          if(
            (!data.call_counterpart && this.select_call_counterpart && this.select_call_counterpart.length > 0) ||
            (!data.call_status && this.select_call_status && this.select_call_status.length > 0)
          ) {
            continue;
          }

          if(data && typeof data.call_time != 'undefined') {

            //週ごと
            //let call_time = moment(data.call_time, 'YYYY/MM/DD HH:mm:ss').unix();
            let call_time = moment(data.call_time, 'YYYY/MM/DD HH:mm:ss').format('D')|0;


            for(let condition in this.report_data.all) {
              let key = condition.split('-');

              if((key[0]|0) <= call_time && (key[1]|0) >= call_time) {

                //音源があるかないかでカウント
                if(data.duration) {
                  this.report_data.all[condition].call = this.report_data.all[condition].call + 1; 

                  //音源があれば
                  let time = data.duration.split(':');
                  time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
                  
                  //5秒以上録音されていれば
                  if((time|0) > this.duration_threshold) {
                    this.report_data.all[condition].tsuuwa = this.report_data.all[condition].tsuuwa + 1;
                    this.report_data.all[condition].tsuuwa_time += time;
                  }
                }

                for(let iii = 0, nnn = this.datatype.length; iii < nnn; iii++) {
                  let dt = this.datatype[iii];
                  let column = dt == 'area'? 'integration_id_1': dt;
                  column = dt == 'jobs'? 'integration_id_2': column;
                  column = dt == 'jobs_category'? 'integration_id_2': column;
                  
                  if((data[column]|0) > 0) {
                    //データをカウント
                    this.report_data[dt][condition][data[column]] = this.report_data[dt][condition][data[column]] + 1;

                    //追加レポートがあれば
                
                    if(TF.add_report) {
                      for(let iiii in TF.add_report.data) {
                        if(TF.add_report.tab == dt && iiii == data[column]) {
                          let column2 = TF.add_report.datatype == 'area'? 'integration_id_1': TF.add_report.datatype;
                          column2 = TF.add_report.datatype == 'jobs'? 'integration_id_2': column2;
                          
                          if(typeof this.report_data[dt].add[iiii][condition][data[column2]] != 'undefined') {
                            this.report_data[dt].add[iiii][condition][data[column2]] = this.report_data[dt].add[iiii][condition][data[column2]] + 1;
                          }
                        }
                      }
                    }
                  }
                }

                //ユーザー絞り込み
                if(TF.selected_staff && TF.selected_staff.length > 0) {
                  for(let ii in TF.selected_staff) {
                    let key2 = TF.selected_staff[ii];
                    if(data.staff_id == key2) {
                      
                      if(data.duration) {
                        TF.staff_report_data[key2].all[condition].call = TF.staff_report_data[key2].all[condition].call + 1;

                        //音源があれば
                        let time = data.duration.split(':');
                        time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);

                        //5秒以上録音されていれば
                        if((time|0) > this.duration_threshold) {
                          TF.staff_report_data[key2].all[condition].tsuuwa = TF.staff_report_data[key2].all[condition].tsuuwa + 1;
                          TF.staff_report_data[key2].all[condition].tsuuwa_time += time;
                        }
                      }

                      //他のタブのデータ
                      for(let iii = 0, nnn = this.datatype.length; iii < nnn; iii++) {

                        let dt = this.datatype[iii];
                        let column = dt == 'area'? 'integration_id_1': dt;
                        column = dt == 'jobs'? 'integration_id_2': column;
                        column = dt == 'jobs_category'? 'integration_id_2': column;

                        if((data[column]|0) > 0) {
                          //データをカウント
                          TF.staff_report_data[key2][dt][condition][data[column]] = TF.staff_report_data[key2][dt][condition][data[column]] + 1;
                        }
                      }
                    }
                  }
                }
              }
            }

            //曜日ごと
            let day = moment(data.call_time, 'YYYY/MM/DD HH:mm:ss').day();
            for(let ii = 0, nn = TF.report.data_items.weekday.length; ii < nn; ii++) {

              if(ii == day) {

                if(data.duration) {
                  this.report_data.all.weekday[ii].call = this.report_data.all.weekday[ii].call + 1;
                
                  //音源があれば
                  let time = data.duration.split(':');
                  time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);

                  //5秒以上録音されていれば
                  if((time|0) > this.duration_threshold) {
                    this.report_data.all.weekday[ii].tsuuwa = this.report_data.all.weekday[ii].tsuuwa + 1;
                    this.report_data.all.weekday[ii].tsuuwa_time += time;
                  }
                }


                for(let iii = 0, nnn = this.datatype.length; iii < nnn; iii++) {
                  let dt = this.datatype[iii];
                  let column = dt == 'area'? 'integration_id_1': dt;
                  column = dt == 'jobs'? 'integration_id_2': column;
                  column = dt == 'jobs_category'? 'integration_id_2': column;

                  if((data[column]|0) > 0) {
                    //データをカウント
                    this.report_data[dt].weekday[ii][data[column]] = this.report_data[dt].weekday[ii][data[column]] + 1;


                    //追加レポートがあれば
                    /*
                    if(TF.add_report) {
                      for(let iiii in TF.add_report.data) {
                        if(TF.add_report.tab == dt && iiii == data[column]) {
                          let column2 = TF.add_report.datatype == 'area'? 'integration_id_1': TF.add_report.datatype;
                          column2 = TF.add_report.datatype == 'jobs'? 'integration_id_2': column2;
                          
                          if(typeof this.report_data[dt].add.weekday[iiii][ii][data[column2]] == 'number') {
                            this.report_data[dt].add.weekday[iiii][ii][data[column2]] = this.report_data[dt].add.weekday[iiii][ii][data[column2]] + 1;
                          }
                        }
                      }
                    }*/
                  }
                }

                //ユーザー絞り込み
                if(TF.selected_staff && TF.selected_staff.length > 0) {
                  for(let iii in TF.selected_staff) {
                    let key = TF.selected_staff[iii];
                    if(data.staff_id == key) {
                      if(data.duration) {
                        TF.staff_report_data[key].all.weekday[ii].call = TF.staff_report_data[key].all.weekday[ii].call + 1;

                        //音源があれば
                        let time = data.duration.split(':');
                        time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);

                        //5秒以上録音されていれば
                        if((time|0) > this.duration_threshold) {
                          TF.staff_report_data[key].all.weekday[ii].tsuuwa = TF.staff_report_data[key].all.weekday[ii].tsuuwa + 1;
                          TF.staff_report_data[key].all.weekday[ii].tsuuwa_time += time;
                        }
                      }

                      //他のタブのデータ
                      for(let iiii = 0, nnn = this.datatype.length; iiii < nnn; iiii++) {

                        let dt = this.datatype[iiii];
                        let column = dt == 'area'? 'integration_id_1': dt;
                        column = dt == 'jobs'? 'integration_id_2': column;
                        column = dt == 'jobs_category'? 'integration_id_2': column;

                        if((data[column]|0) > 0) {
                          //データをカウント
                          TF.staff_report_data[key][dt].weekday[ii][data[column]] = TF.staff_report_data[key][dt].weekday[ii][data[column]] + 1;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        //通話率計算
        for(let i in this.report_data.all) {
          let rate = this.report_data.all[i].call > 0? ((this.report_data.all[i].tsuuwa/this.report_data.all[i].call).toFixed(3)): 0;
          this.report_data.all[i].tsuuwa_rate = (Math.round((rate * 100) * 10) / 10) + '%';
        }

        for(let i = 0, n = TF.report.data_items.weekday.length; i < n; i++) {
          let rate = this.report_data.all.weekday[i].call > 0? ((this.report_data.all.weekday[i].tsuuwa/this.report_data.all.weekday[i].call).toFixed(3)): 0;
          this.report_data.all.weekday[i].tsuuwa_rate = (Math.round((rate * 100) * 10) / 10) + '%';
        }
       

        //ユーザー絞り込み通話率計算
        if(TF.selected_staff && TF.selected_staff.length > 0) {

          for(let i in TF.staff_report_data) {
            let tmp = {};
            let tmp_weekday = {};

            for(let ii in TF.staff_report_data[i].all) {        
              let rate = TF.staff_report_data[i].all[ii].call > 0? ((TF.staff_report_data[i].all[ii].tsuuwa/TF.staff_report_data[i].all[ii].call).toFixed(3)): 0;
              TF.staff_report_data[i].all[ii].tsuuwa_rate = (Math.round((rate * 100) * 10) / 10) + '%';
            }

            for(let ii = 0, n = TF.report.data_items.weekday.length; ii < n; ii++) {      
              let rate = TF.staff_report_data[i].all.weekday[ii].call > 0? ((TF.staff_report_data[i].all.weekday[ii].tsuuwa/TF.staff_report_data[i].all.weekday[ii].call).toFixed(3)): 0;
              TF.staff_report_data[i].all.weekday[ii].tsuuwa_rate = (Math.round((rate * 100) * 10) / 10) + '%';
            }

            //各ユーザーの業種カテゴリ用のデータを商材ごとに分割 週ごと
            for(let key in TF.staff_report_data[i].jobs_category) {
              
              //key は週　各業種を取り出してループ
              for(let job_id in TF.staff_report_data[i].jobs_category[key]) {
                if(key == 'weekday') continue;

                let job_val = TF.staff_report_data[i].jobs_category[key][job_id]|0;
                //その業種を登録されている商材ごとに分類
                for(let iii in TF.jobs_category) {
                  let jobs = TF.jobs_category[iii];
                  //業種IDが同じだったら
                  if((job_id|0) == (jobs.id|0)) {
                    //商材のオブジェクトが定義されていなかったら仮の一時変数に定義
                    if(!tmp[jobs.counterpart_id]) {
                      tmp[jobs.counterpart_id] = {};
                    }

                    //商材だけで分ける場合　keyの時間帯ごとにオブジェクト定義
                    if(!tmp[jobs.counterpart_id][key]) {
                      tmp[jobs.counterpart_id][key] = {};
                    }

                    //業種件数を格納
                    tmp[jobs.counterpart_id][key][jobs.id] = job_val;
                  }
                }
              }
            }

            //各ユーザーの業種カテゴリ用のデータを商材ごとに分割 曜日ごと
            for(let day in TF.staff_report_data[i].jobs_category.weekday) {
              
              //key は週　各業種を取り出してループ
              for(let job_id in TF.staff_report_data[i].jobs_category.weekday[day]) {

                let job_val = TF.staff_report_data[i].jobs_category.weekday[day][job_id]|0;
                //その業種を登録されている商材ごとに分類
                for(let iii in TF.jobs_category) {
                  let jobs = TF.jobs_category[iii];
                  //業種IDが同じだったら
                  if((job_id|0) == (jobs.id|0)) {
                    //商材のオブジェクトが定義されていなかったら仮の一時変数に定義
                    if(!tmp_weekday[jobs.counterpart_id]) {
                      tmp_weekday[jobs.counterpart_id] = {};
                    }

                    //商材だけで分ける場合　keyの時間帯ごとにオブジェクト定義
                    if(!tmp_weekday[jobs.counterpart_id][day]) {
                      tmp_weekday[jobs.counterpart_id][day] = {};
                    }

                    //業種件数を格納
                    tmp_weekday[jobs.counterpart_id][day][jobs.id] = job_val;
                  }
                }
              }
            }

            for(let counterpart_id in tmp) {
              if(counterpart_id == 'weekday') continue;
              tmp[counterpart_id].weekday = tmp_weekday[counterpart_id];
            } 

            //一時変数でもとのオブジェクトを上書き
            TF.staff_report_data[i].jobs_category = tmp;
          }

          this.setData('staff_report_data', TF.staff_report_data);
        }

        //業種カテゴリ用のデータを商材ごとに分割
        this.report_data_jobs_category = {};
        this.report_data_jobs_category.jobs_category = {};
        for(let key in this.report_data.jobs_category) {
          if(key != 'weekday') {
            for(let job_id in this.report_data.jobs_category[key]) {
              let job_val = this.report_data.jobs_category[key][job_id]|0;
              
              for(let i in TF.jobs_category) {
                let jobs = TF.jobs_category[i];
                if((job_id|0) == (jobs.id|0)) {
                  if(!this.report_data_jobs_category.jobs_category[jobs.counterpart_id]) {
                    this.report_data_jobs_category.jobs_category[jobs.counterpart_id] = {};
                  }
  
  
                  /*商材だけで分ける場合*/
                  if(!this.report_data_jobs_category.jobs_category[jobs.counterpart_id][key]) {
                    this.report_data_jobs_category.jobs_category[jobs.counterpart_id][key] = {};
                  }
  
                  this.report_data_jobs_category.jobs_category[jobs.counterpart_id][key][jobs.id] = job_val;
                }
              }
            }
          } else if(key == 'weekday') {
            for(let weekday in this.report_data.jobs_category.weekday) {
              for(let job_id in this.report_data.jobs_category.weekday[weekday]) {
                let job_val = this.report_data.jobs_category.weekday[weekday][job_id]|0;
                
                for(let i in TF.jobs_category) {
                  let jobs = TF.jobs_category[i];
                  if((job_id|0) == (jobs.id|0)) {
                    
                    if(!this.report_data_jobs_category.jobs_category[jobs.counterpart_id].weekday) {
                      this.report_data_jobs_category.jobs_category[jobs.counterpart_id].weekday = {};
                    }

                    /*商材だけで分ける場合*/
                    if(!this.report_data_jobs_category.jobs_category[jobs.counterpart_id].weekday[weekday]) {
                      this.report_data_jobs_category.jobs_category[jobs.counterpart_id].weekday[weekday] = {};
                    }

                    this.report_data_jobs_category.jobs_category[jobs.counterpart_id].weekday[weekday][jobs.id] = job_val;
                  }
                }
              }
            }
          }

        }

        break;
      }

      case 'month': {

        //各タブで判別
        this.report_data = {};
        this.report_data.all = {};
        TF.staff_report_data = {};
        
        //ユーザーが選択されていれば
        if(TF.selected_staff && TF.selected_staff.length > 0) {        
          for(let i in TF.selected_staff) {
            let key = TF.selected_staff[i]|0;
            TF.staff_report_data[key] = {};
            TF.staff_report_data[key].all = {};
          }
        }

        //月ごとのデータ構造をつくる
        for(let i = 0, n = this.setting.table_setting.rows.length; i < n; i++) {
          let key = this.setting.table_setting.rows[i];
          this.report_data.all[key] = {};
          this.report_data.all[key].call = 0;
          this.report_data.all[key].tsuuwa = 0;
          this.report_data.all[key].tsuuwa_rate = 0;
          this.report_data.all[key].tsuuwa_time = 0;

          //ユーザーが選択されていれば
          if(TF.selected_staff && TF.selected_staff.length > 0) {
            for(let ii in TF.selected_staff) {
              let key2 = TF.selected_staff[ii]|0;
              TF.staff_report_data[key2].all[key] = {};
              TF.staff_report_data[key2].all[key].call = 0;
              TF.staff_report_data[key2].all[key].tsuuwa = 0;
              TF.staff_report_data[key2].all[key].tsuuwa_rate = 0;
              TF.staff_report_data[key2].all[key].tsuuwa_time = 0;
            }
          }

          //各タブ
          for(let ii = 0, nn = this.datatype.length; ii < nn; ii++) {
            let dt = this.datatype[ii];
            if(typeof this.report_data[dt] == 'undefined') this.report_data[dt] = {};
            this.report_data[dt][key] = {};
            for(let iii = 0, nnn = TF[dt].length; iii < nnn; iii++) {
              this.report_data[dt][key][TF[dt][iii].id] = 0;

              //追加レポートがありかつ、追加該当タブ名が一致したら
              if(TF.add_report && TF.add_report.tab == dt) {
                //追加用のobjectを定義
                if(typeof this.report_data[dt].add == 'undefined') this.report_data[dt].add = {};        

                //追加用のデータ構造を定義
                for(let iiii in TF.add_report.data) {
                  //各rowを定義
                  if(typeof this.report_data[dt].add[iiii] == 'undefined') this.report_data[dt].add[iiii] = {};

                  //rowを定義
                  if(typeof this.report_data[dt].add[iiii][key] == 'undefined') this.report_data[dt].add[iiii][key] = {};

                  //各rowのセルを定義
                  for(let iiiii in TF.add_report.data[iiii]) {
                    this.report_data[dt].add[iiii][key][TF.add_report.data[iiii][iiiii].id] = 0;
                  }
                }
              }

              //ユーザーが選択されていれば
              if(TF.selected_staff && TF.selected_staff.length > 0) {
                for(let iii in TF.selected_staff) {
                  let key2 = TF.selected_staff[iii]|0;
                  if(typeof TF.staff_report_data[key2][dt] == 'undefined') TF.staff_report_data[key2][dt] = {};
                  TF.staff_report_data[key2][dt][key] = {};

                  for(let iiii = 0, nnn = TF[dt].length; iiii < nnn; iiii++) {
                    TF.staff_report_data[key2][dt][key][TF[dt][iiii].id] = 0;
                  }

                }
              }

            }
          }
        }


        //データ抽出
        let career = {};
        let apli_before = {};
        let apli_after = {};
        for(let i = 0, n = TF.report_data.length; i < n; i++) {
          let data = TF.report_data[i];

          //商材かステータスが絞り込まれていたら
          let continue_flag = 0;
          if(data.call_counterpart != null && this.select_call_counterpart && this.select_call_counterpart.length > 0) {
            
            if(this.select_call_counterpart.indexOf(data.call_counterpart) == -1 ) {
              continue_flag++;
            }
          }

          if(data.call_status != null && this.select_call_status && this.select_call_status.length > 0) {
            if(this.select_call_status.indexOf(data.call_status) == -1 ) {
              continue_flag++;
            }
          }
          //商材もしくはステータスでどちらかもしくは両方ヒットしていなかったら
          if( continue_flag > 0 ) {
            continue;
          }

          if(
            (this.select_call_counterpart && this.select_call_counterpart.length > 0) ||
            (this.select_call_status && this.select_call_status.length > 0)
          ) {
            //durationと登録履歴を結びつける
            if(data && data.duration) {
              //キャリア登録の場合
              if(data.customer_id == '0') {
                if(!career[data.tel_no]) {
                  career[data.tel_no] = data.duration;
                } else {
                  let time = career[data.tel_no].split(':');
                  time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
                  
                  let time2 = data.duration.split(':');
                  time2 = ((time2[0]|0) * 3600) + ((time2[1]|0) * 60) + (time2[2]|0);

                  time = time + time2;
                  let h = time / 3600|0;
                  let m = time % 3600 / 60|0;
                  let s = time % 60;

                  time = (h < 10?'0'+ h:h)+':'+(m < 10?'0'+ m:m)+':'+(s < 10?'0'+ s:s);
                  career[data.tel_no] = time;
                }
                data.duration = null;
                
              }

              if(data.customer_id != '0') {
                  
                if(!apli_before[data.tel_no]) {
                  apli_before[data.tel_no] = data.duration;
                } else {
                  let time = apli_before[data.tel_no].split(':');
                  time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
                  
                  let time2 = data.duration.split(':');
                  time2 = ((time2[0]|0) * 3600) + ((time2[1]|0) * 60) + (time2[2]|0);

                  time = time + time2;
                  let h = time / 3600|0;
                  let m = time % 3600 / 60|0;
                  let s = time % 60;

                  time = (h < 10?'0'+ h:h)+':'+(m < 10?'0'+ m:m)+':'+(s < 10?'0'+ s:s);
                  apli_before[data.tel_no] = time;
                }

                data.duration = null;
              }

              if(data.customer_id != '0' && apli_after[data.tel_no]) { //アプリ登録の場合
                data.call_counterpart = apli_after[data.tel_no].call_counterpart;
                data.call_status = apli_after[data.tel_no].call_status;
                data.integration_id_1 = apli_after[data.tel_no].integration_id_1;
                data.integration_id_2 = apli_after[data.tel_no].integration_id_2;

              //apli_after = {};
              }
            }

            if(data && !data.duration) {

              //durationないけど諸データがセットされているとき
              if(
                data.call_counterpart ||
                data.call_status ||
                data.integration_id_1 ||
                data.integration_id_2
              ) {

                if(data.customer_id != '0' && apli_before[data.tel_no]) {
                  data.duration = apli_before[data.tel_no];
                }

                if(data.customer_id != '0' && !apli_after[data.tel_no]) {
                  apli_after[data.tel_no] = {
                    call_counterpart: data.call_counterpart,
                    call_status: data.call_status,
                    integration_id_1: data.integration_id_1,
                    integration_id_2: data.integration_id_2
                  };
                }

                if(career[data.tel_no]) {
                  data.duration = career[data.tel_no];
                }
              }
            }
          }

          //商材もしくはステータスが絞り込まれているのにdataにどちらかがなかったら
          if(
            (!data.call_counterpart && this.select_call_counterpart && this.select_call_counterpart.length > 0) ||
            (!data.call_status && this.select_call_status && this.select_call_status.length > 0)
          ) {
            continue;
          }

          if(data && typeof data.call_time != 'undefined') {

            //月ごと
            let date = moment(data.call_time, 'YYYY/MM/DD HH:mm:ss').get('date');

            for(let ii = 0, nn = this.setting.table_setting.rows.length; ii < nn; ii++) {
              let key = this.setting.table_setting.rows[ii];

              if(key == date) {
                
                if(data.duration) {
                  this.report_data.all[key].call = this.report_data.all[key].call + 1; 
                  
                  //音源があれば
                  let time = data.duration.split(':');
                  time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
                  
                  //5秒以上録音されていれば
                  if((time|0) > this.duration_threshold) {
                    this.report_data.all[key].tsuuwa = this.report_data.all[key].tsuuwa + 1;
                    
                    this.report_data.all[key].tsuuwa_time += time;
                  }
                }

                for(let iii = 0, nnn = this.datatype.length; iii < nnn; iii++) {
                  let dt = this.datatype[iii];
                  let column = dt == 'area'? 'integration_id_1': dt;
                  column = dt == 'jobs'? 'integration_id_2': column;
                  column = dt == 'jobs_category'? 'integration_id_2': column;

                  if((data[column]|0) > 0) {
                    //データをカウント
                    this.report_data[dt][key][data[column]] = this.report_data[dt][key][data[column]] + 1;

                    //追加レポートがあれば
                
                    if(TF.add_report) {
                      for(let iiii in TF.add_report.data) {
                        if(TF.add_report.tab == dt && iiii == data[column]) {
                          let column2 = TF.add_report.datatype == 'area'? 'integration_id_1': TF.add_report.datatype;
                          column2 = TF.add_report.datatype == 'jobs'? 'integration_id_2': column2;
                          
                          if(typeof this.report_data[dt].add[iiii][key][data[column2]] != 'undefined') {
                            this.report_data[dt].add[iiii][key][data[column2]] = this.report_data[dt].add[iiii][key][data[column2]] + 1;
                          }
                        }
                      }
                    }
                  }
                }

                //ユーザー絞り込み
                if(TF.selected_staff && TF.selected_staff.length > 0) {
                  for(let iiii in TF.selected_staff) {
                    let key2 = TF.selected_staff[iiii];

                    if(data.staff_id == key2) {
                      
                      if(data.duration) {
                        TF.staff_report_data[key2].all[key].call = TF.staff_report_data[key2].all[key].call + 1;

                        //音源があれば
                        let time = data.duration.split(':');
                        time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
                        
                        //5秒以上録音されていれば
                        if((time|0) > this.duration_threshold) {
                          TF.staff_report_data[key2].all[key].tsuuwa = TF.staff_report_data[key2].all[key].tsuuwa + 1;
                          TF.staff_report_data[key2].all[key].tsuuwa_time += time;
                        }
                      }

                      //他のタブのデータ
                      for(let iiiii = 0, nnn = this.datatype.length; iiiii < nnn; iiiii++) {

                        let dt = this.datatype[iiiii];
                        let column = dt == 'area'? 'integration_id_1': dt;
                        column = dt == 'jobs'? 'integration_id_2': column;
                        column = dt == 'jobs_category'? 'integration_id_2': column;

                        if((data[column]|0) > 0) {
                          //データをカウント
                          TF.staff_report_data[key2][dt][key][data[column]] = TF.staff_report_data[key2][dt][key][data[column]] + 1;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        for(let i in this.report_data.all) {
          let rate = this.report_data.all[i].call > 0? ((this.report_data.all[i].tsuuwa/this.report_data.all[i].call).toFixed(3)): 0;
          this.report_data.all[i].tsuuwa_rate = (Math.round((rate * 100) * 10) / 10) + '%';
        }

        //ユーザー絞り込み通話率計算
        if(TF.selected_staff && TF.selected_staff.length > 0) {
          for(let i in TF.staff_report_data) {
            
            let tmp = {};
      
            for(let ii in TF.staff_report_data[i].all) {        
              let rate = TF.staff_report_data[i].all[ii].call > 0? ((TF.staff_report_data[i].all[ii].tsuuwa/TF.staff_report_data[i].all[ii].call).toFixed(3)): 0;
              TF.staff_report_data[i].all[ii].tsuuwa_rate = (Math.round((rate * 100) * 10) / 10) + '%';
            }

            //各ユーザーの業種カテゴリ用のデータを商材ごとに分割
            for(let key in TF.staff_report_data[i].jobs_category) {
              //key は時間帯　各業種を取り出してループ
              for(let job_id in TF.staff_report_data[i].jobs_category[key]) {
                let job_val = TF.staff_report_data[i].jobs_category[key][job_id]|0;
                //その業種を登録されている商材ごとに分類
                for(let iii in TF.jobs_category) {
                  let jobs = TF.jobs_category[iii];
                  //業種IDが同じだったら
                  if((job_id|0) == (jobs.id|0)) {
                    //商材のオブジェクトが定義されていなかったら仮の一時変数に定義
                    if(!tmp[jobs.counterpart_id]) {
                      tmp[jobs.counterpart_id] = {};
                    }

                    //商材だけで分ける場合　keyの時間帯ごとにオブジェクト定義
                    if(!tmp[jobs.counterpart_id][key]) {
                      tmp[jobs.counterpart_id][key] = {};
                    }

                    //業種件数を格納
                    tmp[jobs.counterpart_id][key][jobs.id] = job_val;
                  }
                }
              }
            }
           

            //一時変数でもとのオブジェクトを上書き
            TF.staff_report_data[i].jobs_category = tmp;
          }
    
          this.setData('staff_report_data', TF.staff_report_data);
        }

        //業種カテゴリ用のデータを商材ごとに分割
        this.report_data_jobs_category = {};
        this.report_data_jobs_category.jobs_category = {};
        for(let key in this.report_data.jobs_category) {
          for(let job_id in this.report_data.jobs_category[key]) {
            let job_val = this.report_data.jobs_category[key][job_id]|0;
            
            for(let i in TF.jobs_category) {
              let jobs = TF.jobs_category[i];
              if((job_id|0) == (jobs.id|0)) {
                if(!this.report_data_jobs_category.jobs_category[jobs.counterpart_id]) {
                  this.report_data_jobs_category.jobs_category[jobs.counterpart_id] = {};
                }

                /* カテゴリで分ける場合
                if(!this.report_data_jobs_category[jobs.counterpart_id][jobs.category_id]) {
                  this.report_data_jobs_category[jobs.counterpart_id][jobs.category_id] = {};
                }

                if(!this.report_data_jobs_category[jobs.counterpart_id][jobs.category_id][key]) {
                  this.report_data_jobs_category[jobs.counterpart_id][jobs.category_id][key] = {};
                }

                this.report_data_jobs_category[jobs.counterpart_id][jobs.category_id][key][jobs.id] = job_val;*/

                /*商材だけで分ける場合*/
                if(!this.report_data_jobs_category.jobs_category[jobs.counterpart_id][key]) {
                  this.report_data_jobs_category.jobs_category[jobs.counterpart_id][key] = {};
                }

                this.report_data_jobs_category.jobs_category[jobs.counterpart_id][key][jobs.id] = job_val;
              }
            }
          }
        }


        break;
      }

      case 'user': {
        this.report_data.all = {};
        this.jobs_staff_data = {};

        let staffs = TF.staff
        if(TF.selected_staff && TF.selected_staff.length > 0) {
          staffs = TF.selected_staff;
        }

        for(let i in staffs) {
          let row = staffs[i];
          //let staff_id = row.telforce_id || row|0;
          let staff_id = row.login_id || row;

          this.report_data.all[staff_id] = {};
          
          for(let ii = 0, nn = this.setting.table_setting.columns.length; ii < nn; ii++) {
            let column = this.setting.table_setting.columns[ii];
            this.report_data.all[staff_id][column.key] = 0;
            if(column.key == 'no_register') this.report_data.all[staff_id][column.key] = [];
          }

          this.jobs_staff_data[staff_id] = {};
          for(let ii in TF.jobs_category) {
            this.jobs_staff_data[staff_id][TF.jobs_category[ii].id] = 0;
          }
        }


        let start = moment($('#duration-start').val(), 'YYYY/MM/DD').unix();
        let end = moment($('#duration-end').val(), 'YYYY/MM/DD').add(1, 'd').unix();

        //ダミー
//        start = moment('2020/10/26', 'YYYY/MM/DD').unix();
//        end = moment('2020/10/27', 'YYYY/MM/DD').unix();

        //データ抽出
        let career = {};
        let apli_before = {};
        let apli_after = {};
        let relation_check = {};
        
        for(let i = 0, n = TF.report_data.length; i < n; i++) {
          let data = TF.report_data[i];
         
          /***************************************
           * 商材かステータスが絞り込まれていたら
           * **************************************/
          let continue_flag = 0;
          if(data.call_counterpart != null && this.select_call_counterpart && this.select_call_counterpart.length > 0) {
            
            if(this.select_call_counterpart.indexOf(data.call_counterpart) == -1 ) {
              continue_flag++;
            }
          }

          if(data.call_status != null && this.select_call_status && this.select_call_status.length > 0) {
            if(this.select_call_status.indexOf(data.call_status) == -1 ) {
              continue_flag++;
            }
          }
          //商材もしくはステータスでどちらかもしくは両方ヒットしていなかったら
          if( continue_flag > 0 ) {
            continue;
          }
          
          if((this.select_call_counterpart && this.select_call_counterpart.length > 0 ) || (this.select_call_status && this.select_call_status.length > 0)) {

            //durationと登録履歴を結びつける
            if(data && data.duration) {

              //キャリア登録の場合
              if(data.customer_id == '0') {
                if(!career[data.tel_no]) {
                  career[data.tel_no] = data.duration;
                } else {
                  let time = career[data.tel_no].split(':');
                  time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
                  
                  let time2 = data.duration.split(':');
                  time2 = ((time2[0]|0) * 3600) + ((time2[1]|0) * 60) + (time2[2]|0);

                  time = time + time2;
                  let h = time / 3600|0;
                  let m = time % 3600 / 60|0;
                  let s = time % 60;

                  time = (h < 10?'0'+ h:h)+':'+(m < 10?'0'+ m:m)+':'+(s < 10?'0'+ s:s);
                  career[data.tel_no] = time;
                }
                data.duration = null;
              }

              if(data.customer_id != '0') {
                
                if(!apli_before[data.tel_no]) {
                  apli_before[data.tel_no] = data.duration;
                } else {
                  let time = apli_before[data.tel_no].split(':');
                  time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
                  
                  let time2 = data.duration.split(':');
                  time2 = ((time2[0]|0) * 3600) + ((time2[1]|0) * 60) + (time2[2]|0);

                  time = time + time2;
                  let h = time / 3600|0;
                  let m = time % 3600 / 60|0;
                  let s = time % 60;

                  time = (h < 10?'0'+ h:h)+':'+(m < 10?'0'+ m:m)+':'+(s < 10?'0'+ s:s);
                  apli_before[data.tel_no] = time;
                }

                data.duration = null;
              }

              if(data.customer_id != '0' && apli_after[data.tel_no]) { //アプリ登録の場合
                data.call_counterpart = apli_after[data.tel_no].call_counterpart;
                data.call_status = apli_after[data.tel_no].call_status;
                data.integration_id_1 = apli_after[data.tel_no].integration_id_1;
                data.integration_id_2 = apli_after[data.tel_no].integration_id_2;
              //apli_after = {};
              }

            }

            if(data && !data.duration) {

              //durationないけど諸データがセットされているとき
              if(
                data.call_counterpart ||
                data.call_status ||
                data.integration_id_1 ||
                data.integration_id_2
              ) {

                if(data.customer_id != '0' && apli_before[data.tel_no]) {
                  data.duration = apli_before[data.tel_no];
                  
                }

                if(data.customer_id != '0' && !apli_after[data.tel_no]) {
                  apli_after[data.tel_no] = {
                    call_counterpart: data.call_counterpart,
                    call_status: data.call_status,
                    integration_id_1: data.integration_id_1,
                    integration_id_2: data.integration_id_2
                  };
                }

                if(career[data.tel_no]) {
                  data.duration = career[data.tel_no];
                }
              }
            }
          }

          //商材もしくはステータスが絞り込まれているのにdataにどちらかがなかったら
          if(
            (!data.call_counterpart && this.select_call_counterpart && this.select_call_counterpart.length > 0) ||
            (!data.call_status && this.select_call_status && this.select_call_status.length > 0)
          ) {
            continue;
          }

          /*** 商材かステータスが絞り込まれていたら ここまで ***/


          if(data.call_time) {

            let call_time = moment(data.call_time, 'YYYY-MM-DD HH:mm:ss').unix();

            if(start <= call_time && end > call_time) {
              if(typeof this.report_data.all[data.staff_id] != 'undefined') {

                if(data.duration) {
                  //紐づけチェックを定義（前）
                  if(!relation_check[data.tel_no]) {
                    relation_check[data.tel_no] = [data.duration, data.staff_id];
                  }

                  this.report_data.all[data.staff_id].call = this.report_data.all[data.staff_id].call + 1; 
              
                  //音源があれば
                  let time = data.duration.split(':');
                  time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
                  
                  //5秒以上録音されていれば
                  if((time|0) > this.duration_threshold) {
                    this.report_data.all[data.staff_id].tsuuwa = this.report_data.all[data.staff_id].tsuuwa + 1;
                    this.report_data.all[data.staff_id].tsuuwa_time += time;
                  } else {
                    //諸データが登録されていてもいなくても5秒以内は削除（前）
                    delete relation_check[data.tel_no];
                  }

                  //紐づけチェック（後）
                  if(relation_check[data.tel_no] && relation_check[data.tel_no][0] == 1 && relation_check[data.tel_no][1] == data.staff_id) {
                    delete relation_check[data.tel_no];
                  } else if((time|0) > this.duration_threshold) {
                    relation_check[data.tel_no] = [data.duration, data.staff_id];
                  }

                } else {

                  //紐づけチェックを定義（後）
                  if(
                    !relation_check[data.tel_no] &&
                    (
                      data.integration_id_1 ||
                      data.integration_id_2 ||
                      (data.call_status && data.call_status != '0') ||
                      (data.call_counterpart && data.call_counterpart != '0')
                    ) 
                  ) {
                    relation_check[data.tel_no] = [1, data.staff_id];
                  }
                }

                //紐づけチェック（前）
                if(
                  relation_check[data.tel_no] &&
                  relation_check[data.tel_no][0] != 1 &&
                  relation_check[data.tel_no][1] == data.staff_id && 
                  (
                    data.integration_id_1 ||
                    data.integration_id_2 ||
                    (data.call_status && data.call_status != '0') ||
                    (data.call_counterpart && data.call_counterpart != '0')
                  ) 
                ) {
                  //諸データが登録されていれば削除
                  delete relation_check[data.tel_no];
                }

                for(let iii in data) {

                  if(data[iii]) {
                    this.report_data.all[data.staff_id][data[iii] + '-' + iii] = this.report_data.all[data.staff_id][data[iii] + '-' + iii] + 1;

                    if(iii == 'integration_id_2') {
                      this.jobs_staff_data[data.staff_id][data.integration_id_2] = this.jobs_staff_data[data.staff_id][data.integration_id_2] + 1;
                    }
                  }
                }  
              }
            }
          }
        }

        for(let i in this.report_data.all) {
         
          let rate = this.report_data.all[i].call > 0? ((this.report_data.all[i].tsuuwa/this.report_data.all[i].call).toFixed(3)): 0;
          this.report_data.all[i].tsuuwa_rate = (Math.round((rate * 100) * 10) / 10) + '%';

          for(let ii in relation_check) {
            if(relation_check[ii][0] != 1 && i == relation_check[ii][1]) {
              this.report_data.all[i].no_register.push(ii);
            } 
          }

          let no_rate = this.report_data.all[i].no_register.length > 0? ((this.report_data.all[i].no_register.length/this.report_data.all[i].tsuuwa).toFixed(3)): 0;
          this.report_data.all[i].no_register_rate = (Math.round((no_rate * 100) * 10) / 10) + '%';
        }

        //業種ごとに振り分け
        let tmp = {};
        for(let staff_id in this.jobs_staff_data) {
          tmp[staff_id] = {};

          for(let i in TF.jobs_category) {
            if(!tmp[staff_id][TF.jobs_category[i].counterpart_id]) {
              tmp[staff_id][TF.jobs_category[i].counterpart_id] = {};
            }
            tmp[staff_id][TF.jobs_category[i].counterpart_id][TF.jobs_category[i].id] = this.jobs_staff_data[staff_id][TF.jobs_category[i].id];
          }
        }

        this.jobs_staff_data = tmp;

        break;
      }

      case 'group': {
        this.report_data.all = {};
        let office = TF.office;
        if(TF.selected_office && TF.selected_office.length > 0) {
          office = [];
          for(let i in TF.selected_office) {
            office.push({telforce_id: TF.selected_office[i]});
          }
        }

        //データ構造をつくる
        let office_data = {};
        for(let i in office) {
          office_data[office[i].telforce_id] = {};
          office_data[office[i].telforce_id].call = 0;
          office_data[office[i].telforce_id].tsuuwa = 0;
          office_data[office[i].telforce_id].tsuuwa_time = 0;

          /*for(let ii in TF.call_status) {
            office_data[TF.office[i].telforce_id][TF.call_status[ii].id] = 0; 
          }*/

          for(let ii = 0, nn = this.setting.table_setting.columns.length; ii < nn; ii++) {
            let column = this.setting.table_setting.columns[ii];
            office_data[office[i].telforce_id][column.key] = 0;
          }
        }

        let dept_unit = TF.dept_unit;
        if(TF.selected_dept_unit && TF.selected_dept_unit.length > 0) {
          dept_unit = [];
          for(let i in TF.selected_dept_unit) {
            dept_unit.push({telforce_id: TF.selected_dept_unit[i]});
          }
        }

        let group_data = {};
        for(let i in dept_unit) {
          group_data[dept_unit[i].telforce_id] = {};
          group_data[dept_unit[i].telforce_id].call = 0;
          group_data[dept_unit[i].telforce_id].tsuuwa = 0;
          group_data[dept_unit[i].telforce_id].tsuuwa_time = 0;

          /*for(let ii in TF.call_status) {
            group_data[TF.dept_unit[i].telforce_id][TF.call_status[ii].id] = 0; 
          }*/

          for(let ii = 0, nn = this.setting.table_setting.columns.length; ii < nn; ii++) {
            let column = this.setting.table_setting.columns[ii];
            group_data[dept_unit[i].telforce_id][column.key] = 0;
          }
        }

        //データ構造を代入
        this.report_data.all.office = office_data;
        this.report_data.all.group = group_data;

        //期間設定
        let start = moment($('#duration-start').val(), 'YYYY/MM/DD').unix();
        let end = moment($('#duration-end').val(), 'YYYY/MM/DD').add(1, 'd').unix();

        //ダミー
        //start = moment('2020/10/26', 'YYYY/MM/DD').unix();
        //end = moment('2020/10/27', 'YYYY/MM/DD').unix();

        //データ抽出
        let career = {};
        let apli_before = {};
        let apli_after = {};
        for(let i = 0, n = TF.report_data.length; i < n; i++) {
          let data = TF.report_data[i];

          //商材かステータスが絞り込まれていたら
          let continue_flag = 0;
          if(data.call_counterpart != null && this.select_call_counterpart && this.select_call_counterpart.length > 0) {
            
            if(this.select_call_counterpart.indexOf(data.call_counterpart) == -1 ) {
              continue_flag++;
            }
          }

          if(data.call_status != null && this.select_call_status && this.select_call_status.length > 0) {
            if(this.select_call_status.indexOf(data.call_status) == -1 ) {
              continue_flag++;
            }
          }
          //商材もしくはステータスでどちらかもしくは両方ヒットしていなかったら
          if( continue_flag > 0 ) {
            continue;
          }

          if(
            (this.select_call_counterpart && this.select_call_counterpart.length > 0) ||
            (this.select_call_status && this.select_call_status.length > 0)
          ) {
            //durationと登録履歴を結びつける
            if(data && data.duration) {
              //キャリア登録の場合
              if(data.customer_id == '0') {
                if(!career[data.tel_no]) {
                  career[data.tel_no] = data.duration;
                } else {
                  let time = career[data.tel_no].split(':');
                  time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
                  
                  let time2 = data.duration.split(':');
                  time2 = ((time2[0]|0) * 3600) + ((time2[1]|0) * 60) + (time2[2]|0);

                  time = time + time2;
                  let h = time / 3600|0;
                  let m = time % 3600 / 60|0;
                  let s = time % 60;

                  time = (h < 10?'0'+ h:h)+':'+(m < 10?'0'+ m:m)+':'+(s < 10?'0'+ s:s);
                  career[data.tel_no] = time;
                }
                data.duration = null;
                
              }

              if(data.customer_id != '0') {
          
                if(!apli_before[data.tel_no]) {
                  apli_before[data.tel_no] = data.duration;
                } else {
                  let time = apli_before[data.tel_no].split(':');
                  time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
                  
                  let time2 = data.duration.split(':');
                  time2 = ((time2[0]|0) * 3600) + ((time2[1]|0) * 60) + (time2[2]|0);
        
                  time = time + time2;
                  let h = time / 3600|0;
                  let m = time % 3600 / 60|0;
                  let s = time % 60;
        
                  time = (h < 10?'0'+ h:h)+':'+(m < 10?'0'+ m:m)+':'+(s < 10?'0'+ s:s);
                  apli_before[data.tel_no] = time;
                }
        
                data.duration = null;
              }

              if(data.customer_id != '0' && apli_after[data.tel_no]) { //アプリ登録の場合
                data.call_counterpart = apli_after[data.tel_no].call_counterpart;
                data.call_status = apli_after[data.tel_no].call_status;
                data.integration_id_1 = apli_after[data.tel_no].integration_id_1;
                data.integration_id_2 = apli_after[data.tel_no].integration_id_2;

              //apli_after = {};
              }

            }

            if(data && !data.duration) {

              //durationないけど諸データがセットされているとき
              if(
                data.call_counterpart ||
                data.call_status ||
                data.integration_id_1 ||
                data.integration_id_2
              ) {

                if(data.customer_id != '0' && apli_before[data.tel_no]) {
                  data.duration = apli_before[data.tel_no];
                }

                if(data.customer_id != '0' && !apli_after[data.tel_no]) {
                  apli_after[data.tel_no] = {
                    call_counterpart: data.call_counterpart,
                    call_status: data.call_status,
                    integration_id_1: data.integration_id_1,
                    integration_id_2: data.integration_id_2
                  };
                }

                if(career[data.tel_no]) {
                  data.duration = career[data.tel_no];
                }
              }
            }
          }

          //商材もしくはステータスが絞り込まれているのにdataにどちらかがなかったら
          if(
            (!data.call_counterpart && this.select_call_counterpart && this.select_call_counterpart.length > 0) ||
            (!data.call_status && this.select_call_status && this.select_call_status.length > 0)
          ) {
            continue;
          }


          //通話日時があれば
          if(data.call_time) {
            let call_time = moment(data.call_time, 'YYYY-MM-DD HH:mm:ss').unix();

            if(start <= call_time && end > call_time) {
              
              //ユーザー情報を取得
              let staff = TF.staff[data.staff_id] || false;

              //オフィスごとの履歴取得
              if(staff && typeof this.report_data.all.office[staff.office_id] != 'undefined') {

                if(data.duration) {
                  this.report_data.all.office[staff.office_id].call = this.report_data.all.office[staff.office_id].call + 1;
               
                  //音源があれば
                  let time = data.duration.split(':');
                  time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
                  
                  //5秒以上録音されていれば
                  if((time|0) > this.duration_threshold) {
                    this.report_data.all.office[staff.office_id].tsuuwa = this.report_data.all.office[staff.office_id].tsuuwa + 1;
                    this.report_data.all.office[staff.office_id].tsuuwa_time += time;

                    //this.report_data.all.office[staff.office_id][data.call_status] = this.report_data.all.office[staff.office_id][data.call_status] + 1;               
                  }
                }

                //ほかのステータス取得
                for(let ii in data) {
                  if(data[ii]) {
                    this.report_data.all.office[staff.office_id][data[ii] + '-' + ii] = this.report_data.all.office[staff.office_id][data[ii] + '-' + ii] + 1;
                  }
                }   
              }

              //グループごとの履歴取得
              if(staff && typeof this.report_data.all.group[staff.dept_unit_id] != 'undefined') {
                
                if(data.duration) {
                  this.report_data.all.group[staff.dept_unit_id].call = this.report_data.all.group[staff.dept_unit_id].call + 1; 

                  //音源があれば
                  let time = data.duration.split(':');
                  time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);

                  //5秒以上録音されていれば
                  if((time|0) > this.duration_threshold) {
                    this.report_data.all.group[staff.dept_unit_id].tsuuwa = this.report_data.all.group[staff.dept_unit_id].tsuuwa + 1;
                    this.report_data.all.group[staff.dept_unit_id].tsuuwa_time += time;
                  }
                }

                //ほかのステータス取得
                for(let ii in data) {
                  if(data[ii]) {
                    this.report_data.all.group[staff.dept_unit_id][data[ii] + '-' + ii] = this.report_data.all.group[staff.dept_unit_id][data[ii] + '-' + ii] + 1;
                  }
                }   
              }
            }
          }
        }

        //つながり率を算出
        for(let i in this.report_data.all) {
          for(let ii in this.report_data.all[i]) {
            let rate = this.report_data.all[i][ii].call > 0? ((this.report_data.all[i][ii].tsuuwa/this.report_data.all[i][ii].call).toFixed(3)): 0;
            this.report_data.all[i][ii].tsuuwa_rate = (Math.round((rate * 100) * 10) / 10) + '%';
          }
        }

        break;
      }

      case 'jobs': {
        $('title, .l-view h2.u-ttl').text('業種別レポート');

        //各タブで判別
        this.report_data_scope = TF.report_data;
        this.selected_staff_scope = TF.selected_staff;
        this.jobs_scope = TF.jobs;
        this.jobs_category_scope = TF.jobs_category; 

        this.report_data = {};
        this.report_data.all = {};
        this.report_data_jobs_category = {};
        this.report_data_jobs_category.all = {};
        this.staff_report_data = {};
        this.staff_report_data_jobs_category = {};
        this.staff_report_data_jobs_category = {};

        //ユーザーが選択されていれば
        if(this.selected_staff_scope && this.selected_staff_scope.length > 0) {        
          for(let i in this.selected_staff_scope) {
            let key = this.selected_staff_scope[i]|0;
            this.staff_report_data[key] = {};
            this.staff_report_data[key].all = {};

            this.staff_report_data_jobs_category[key] = {};
            this.staff_report_data_jobs_category[key].all = {};
          }
        }

        //データ構造をつくる(旧)
        for(let i = 0, n = this.jobs_scope.length; i < n; i++) {
          let key = this.jobs_scope[i].id;

          this.report_data.all[key] = {};
          this.report_data.all[key].call = 0;
          this.report_data.all[key].tsuuwa = 0;
          this.report_data.all[key].tsuuwa_rate = 0;
          this.report_data.all[key].tsuuwa_time = 0;

           //ユーザーが選択されていれば
           if(this.selected_staff_scope && this.selected_staff_scope.length > 0) {
            for(let ii in this.selected_staff_scope) {
              let key2 = this.selected_staff_scope[ii]|0;
              this.staff_report_data[key2].all[key] = {};
              this.staff_report_data[key2].all[key].call = 0;
              this.staff_report_data[key2].all[key].tsuuwa = 0;
              this.staff_report_data[key2].all[key].tsuuwa_rate = 0;
              this.staff_report_data[key2].all[key].tsuuwa_time = 0;
            }
          }

          //各タブ
          for(let ii = 0, nn = this.datatype.length; ii < nn; ii++) {
            let dt = this.datatype[ii];
            this.tmp_scope = TF[dt];

            if(typeof this.report_data[dt] == 'undefined') this.report_data[dt] = {};
            this.report_data[dt][key] = {};
        
            for(let iii = 0, nnn = this.tmp_scope.length; iii < nnn; iii++) {
              this.report_data[dt][key][this.tmp_scope[iii].id] = 0;
            }

            //ユーザーが選択されていれば
            if(this.selected_staff_scope && this.selected_staff_scope.length > 0) {
              for(let iii in this.selected_staff_scope) {
                let key2 = this.selected_staff_scope[iii]|0;
                if(typeof this.staff_report_data[key2][dt] == 'undefined') this.staff_report_data[key2][dt] = {};
                this.staff_report_data[key2][dt][key] = {};

                for(let iiii = 0, nnn = this.tmp_scope.length; iiii < nnn; iiii++) {
                  this.staff_report_data[key2][dt][key][this.tmp_scope[iiii].id] = 0;
                }

              }
            }
          }
        }

        //データ構造をつくる
        for(let i = 0, n = this.jobs_category_scope.length; i < n; i++) {
          let key = this.jobs_category_scope[i].id;

          this.report_data_jobs_category.all[key] = {};
          this.report_data_jobs_category.all[key].call = 0;
          this.report_data_jobs_category.all[key].tsuuwa = 0;
          this.report_data_jobs_category.all[key].tsuuwa_rate = 0;
          this.report_data_jobs_category.all[key].tsuuwa_time = 0;

          //ユーザーが選択されていれば
          if(this.selected_staff_scope && this.selected_staff_scope.length > 0) {
            for(let ii in this.selected_staff_scope) {
              let key2 = this.selected_staff_scope[ii]|0;
              this.staff_report_data_jobs_category[key2].all[key] = {};
              this.staff_report_data_jobs_category[key2].all[key].call = 0;
              this.staff_report_data_jobs_category[key2].all[key].tsuuwa = 0;
              this.staff_report_data_jobs_category[key2].all[key].tsuuwa_rate = 0;
              this.staff_report_data_jobs_category[key2].all[key].tsuuwa_time = 0;
            }
          }

          //各タブ
          for(let ii = 0, nn = this.datatype.length; ii < nn; ii++) {
            let dt = this.datatype[ii];
            this.tmp_scope = TF[dt];

            if(typeof this.report_data_jobs_category[dt] == 'undefined') this.report_data_jobs_category[dt] = {};
            this.report_data_jobs_category[dt][key] = {};
        
            for(let iii = 0, nnn = this.tmp_scope.length; iii < nnn; iii++) {
              this.report_data_jobs_category[dt][key][this.tmp_scope[iii].id] = 0;
            }

            //ユーザーが選択されていれば
            if(this.selected_staff_scope && this.selected_staff_scope.length > 0) {
              for(let iii in this.selected_staff_scope) {
                let key2 = this.selected_staff_scope[iii]|0;
                if(typeof this.staff_report_data_jobs_category[key2][dt] == 'undefined') this.staff_report_data_jobs_category[key2][dt] = {};
                this.staff_report_data_jobs_category[key2][dt][key] = {};

                for(let iiii = 0, nnn = this.tmp_scope.length; iiii < nnn; iiii++) {
                  this.staff_report_data_jobs_category[key2][dt][key][this.tmp_scope[iiii].id] = 0;
                }

              }
            }
          }
        }

        console.time('select_duration');

        //データ抽出
        //取得したデータをループ

        let career = {};
        let apli_before = {};
        let apli_after = {};
        for(let i = 0, n = TF.report_data.length; i < n; i++) {
          let data = TF.report_data[i];

          //商材かステータスが絞り込まれていたら
          let continue_flag = 0;
          if(data.call_counterpart != null && this.select_call_counterpart && this.select_call_counterpart.length > 0) {
            
            if(this.select_call_counterpart.indexOf(data.call_counterpart) == -1 ) {
              continue_flag++;
            }
          }

          if(data.call_status != null && this.select_call_status && this.select_call_status.length > 0) {
            if(this.select_call_status.indexOf(data.call_status) == -1 ) {
              continue_flag++;
            }
          }
          //商材もしくはステータスでどちらかもしくは両方ヒットしていなかったら
          if( continue_flag > 0 ) {
            continue;
          }
          
          //durationと登録履歴を結びつける
          if(data && data.duration) {
            //キャリア登録の場合
            if(data.customer_id == '0') {
              if(!career[data.tel_no]) {
                career[data.tel_no] = data.duration;
              } else {
                let time = career[data.tel_no].split(':');
                time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
                
                let time2 = data.duration.split(':');
                time2 = ((time2[0]|0) * 3600) + ((time2[1]|0) * 60) + (time2[2]|0);

                time = time + time2;
                let h = time / 3600|0;
                let m = time % 3600 / 60|0;
                let s = time % 60;

                time = (h < 10?'0'+ h:h)+':'+(m < 10?'0'+ m:m)+':'+(s < 10?'0'+ s:s);
                career[data.tel_no] = time;
              }
              data.duration = null;
              
            }

            if(data.customer_id != '0') {
        
              if(!apli_before[data.tel_no]) {
                apli_before[data.tel_no] = data.duration;
              } else {
                let time = apli_before[data.tel_no].split(':');
                time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
                
                let time2 = data.duration.split(':');
                time2 = ((time2[0]|0) * 3600) + ((time2[1]|0) * 60) + (time2[2]|0);
      
                time = time + time2;
                let h = time / 3600|0;
                let m = time % 3600 / 60|0;
                let s = time % 60;
      
                time = (h < 10?'0'+ h:h)+':'+(m < 10?'0'+ m:m)+':'+(s < 10?'0'+ s:s);
                apli_before[data.tel_no] = time;
              }
      
              data.duration = null;
            }

            if(data.customer_id != '0' && apli_after[data.tel_no]) { //アプリ登録の場合
              data.call_counterpart = apli_after[data.tel_no].call_counterpart;
              data.call_status = apli_after[data.tel_no].call_status;
              data.integration_id_1 = apli_after[data.tel_no].integration_id_1;
              data.integration_id_2 = apli_after[data.tel_no].integration_id_2;

            //apli_after = {};
            }

          }

          if(data && !data.duration) {

            //durationないけど諸データがセットされているとき
            if(
              data.call_counterpart ||
              data.call_status ||
              data.integration_id_1 ||
              data.integration_id_2
            ) {

              if(data.customer_id != '0' && apli_before[data.tel_no]) {
                data.duration = apli_before[data.tel_no];
              }

              if(data.customer_id != '0' && !apli_after[data.tel_no]) {
                apli_after[data.tel_no] = {
                  call_counterpart: data.call_counterpart,
                  call_status: data.call_status,
                  integration_id_1: data.integration_id_1,
                  integration_id_2: data.integration_id_2
                };
              }

              if(career[data.tel_no]) {
                data.duration = career[data.tel_no];
              }
            }
          }

          //商材もしくはステータスが絞り込まれているのにdataにどちらかがなかったら
          if(
            (!data.call_counterpart && this.select_call_counterpart && this.select_call_counterpart.length > 0) ||
            (!data.call_status && this.select_call_status && this.select_call_status.length > 0)
          ) {
            continue;
          }


          //業種データがあれば
          if(data && typeof data.call_time != 'undefined') {
            //データ構造をループ(旧)
            for(let key in this.report_data.all) {
              //業種のIDと一致すれば
              if(key == data.integration_id_2) {
                //allタブのデータ構造の各業種にデータ加算
                if(data.duration) {
                  this.report_data.all[key].call = this.report_data.all[key].call + 1; 
                
                  //音源があれば
                  let time = data.duration.split(':');
                  time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
                  
                  //5秒以上録音されていれば
                  if((time|0) > this.duration_threshold) {
                    //通話に加算
                    this.report_data.all[key].tsuuwa = this.report_data.all[key].tsuuwa + 1;
                    this.report_data.all[key].tsuuwa_time += time;
                  }
                }

                //各タブをループ
                for(let iii = 0, nnn = this.datatype.length; iii < nnn; iii++) {
                  //各タブの名前取得
                  let dt = this.datatype[iii];
                  //データのキーを変換
                  let column = dt == 'area'? 'integration_id_1': dt;
                  column = dt == 'jobs'? 'integration_id_2': column;
                  column = dt == 'jobs_category'? 'integration_id_2': column;

                  //データがセットされていれば
                  if((data[column]|0) > 0) {
                    //各タブの各業種にデータをカウント
                    this.report_data[dt][key][data[column]] = this.report_data[dt][key][data[column]] + 1;
                  }
                }

                //ユーザー絞り込み
                if(this.selected_staff_scope && this.selected_staff_scope.length > 0) { 
                  //staffをループ
                  for(let ii in this.selected_staff_scope) {
                    //staffIDをゲット
                    let staff_id = this.selected_staff_scope[ii];
                    //staffIDとレポートデータのstaffが一致すれば
                    if(data.staff_id == staff_id) {
                      //staffごとにデータを加算
                      if(data.duration) {
                        this.staff_report_data[staff_id].all[key].call = this.staff_report_data[staff_id].all[key].call + 1;

                        //音源があれば
                        let time = data.duration.split(':');
                        //5秒以上録音されていれば
                        if((time[2]|0) > 5) {
                          //通話に加算
                          this.staff_report_data[staff_id].all[key].tsuuwa = this.staff_report_data[staff_id].all[key].tsuuwa + 1;
                          //通話時間に加算
                          time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
                          this.staff_report_data[staff_id].all[key].tsuuwa_time += time;
                        }
                      }

                      //他のタブのデータ
                      for(let iii = 0, nn = this.datatype.length; iii < nn; iii++) {
                        //各タブの名前取得
                        let dt = this.datatype[iii];
                        //データのキーを変換
                        let column = dt == 'area'? 'integration_id_1': dt;
                        column = dt == 'jobs'? 'integration_id_2': column;
                        column = dt == 'jobs_category'? 'integration_id_2': column;

                        if((data[column]|0) > 0) {
                          //データをカウント
                          this.staff_report_data[staff_id][dt][key][data[column]] = this.staff_report_data[staff_id][dt][key][data[column]] + 1;
                        }
                      }
                    }
                  }
                }
              }
            }
              
            //データ構造をループ(業種カテゴリあり)
            for(let key in this.report_data_jobs_category.all) {
              //業種のIDと一致すれば
              if(key == data.integration_id_2) {
                //allタブのデータ構造の各業種にデータ加算
                if(data.duration) {
                  this.report_data_jobs_category.all[key].call = this.report_data_jobs_category.all[key].call + 1; 
                
                  //音源があれば
                  let time = data.duration.split(':');
                  time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
                  
                  //5秒以上録音されていれば
                  if((time|0) > this.duration_threshold) {
                    //通話に加算
                    this.report_data_jobs_category.all[key].tsuuwa = this.report_data_jobs_category.all[key].tsuuwa + 1;
                    this.report_data_jobs_category.all[key].tsuuwa_time += time;
                  }
                }

                //各タブをループ
                for(let iii = 0, nnn = this.datatype.length; iii < nnn; iii++) {
                  //各タブの名前取得
                  let dt = this.datatype[iii];
                  //データのキーを変換
                  let column = dt == 'area'? 'integration_id_1': dt;
                  column = dt == 'jobs'? 'integration_id_2': column;
                  column = dt == 'jobs_category'? 'integration_id_2': column;

                  //データがセットされていれば
                  if((data[column]|0) > 0) {
                    //各タブの各業種にデータをカウント
                    this.report_data_jobs_category[dt][key][data[column]] = this.report_data_jobs_category[dt][key][data[column]] + 1;
                  }
                }

                //ユーザー絞り込み
                if(this.selected_staff_scope && this.selected_staff_scope.length > 0) {
                  //staffをループ
                  for(let ii in this.selected_staff_scope) {
                    //staffIDをゲット
                    let staff_id = this.selected_staff_scope[ii];
                    //staffIDとレポートデータのstaffが一致すれば
                    if(data.staff_id == staff_id) {
                      //staffごとにデータを加算
                      if(data.duration) {
                        this.staff_report_data_jobs_category[staff_id].all[key].call = this.staff_report_data_jobs_category[staff_id].all[key].call + 1;

                        //音源があれば
                        let time = data.duration.split(':');
                        time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
                        
                        //5秒以上録音されていれば
                        if((time|0) > this.duration_threshold) {
                          //通話に加算
                          this.staff_report_data_jobs_category[staff_id].all[key].tsuuwa = this.staff_report_data_jobs_category[staff_id].all[key].tsuuwa + 1;
                          this.staff_report_data_jobs_category[staff_id].all[key].tsuuwa_time += time;
                        }
                      }

                      //他のタブのデータ
                      for(let iii = 0, nn = this.datatype.length; iii < nn; iii++) {
                        //各タブの名前取得
                        let dt = this.datatype[iii];
                        //データのキーを変換
                        let column = dt == 'area'? 'integration_id_1': dt;
                        column = dt == 'jobs'? 'integration_id_2': column;
                        column = dt == 'jobs_category'? 'integration_id_2': column;

                        if((data[column]|0) > 0) {
                          //データをカウント
                          this.staff_report_data_jobs_category[staff_id][dt][key][data[column]] = this.staff_report_data_jobs_category[staff_id][dt][key][data[column]] + 1;
                        }
                        
                      }
                    }
                  }
                }
              } 
            }
          }
        }

        for(let i in this.report_data.all) {
          let rate = this.report_data.all[i].call > 0? ((this.report_data.all[i].tsuuwa/this.report_data.all[i].call).toFixed(3)): 0;
          this.report_data.all[i].tsuuwa_rate = (Math.round((rate * 100) * 10) / 10) + '%';
        }

        for(let i in this.report_data_jobs_category.all) {
          let rate = this.report_data_jobs_category.all[i].call > 0? ((this.report_data_jobs_category.all[i].tsuuwa/this.report_data_jobs_category.all[i].call).toFixed(3)): 0;
          this.report_data_jobs_category.all[i].tsuuwa_rate = (Math.round((rate * 100) * 10) / 10) + '%';
        }

        //ユーザー絞り込み通話率計算
        if(this.selected_staff_scope && this.selected_staff_scope.length > 0) {
          

          for(let i in this.staff_report_data) {

            for(let ii in this.staff_report_data[i].all) {        
              let rate = this.staff_report_data[i].all[ii].call > 0? ((this.staff_report_data[i].all[ii].tsuuwa/this.staff_report_data[i].all[ii].call).toFixed(3)): 0;
              this.staff_report_data[i].all[ii].tsuuwa_rate = (Math.round((rate * 100) * 10) / 10) + '%';
            }

            for(let ii in this.staff_report_data_jobs_category[i].all) {   
                   
              let rate = this.staff_report_data_jobs_category[i].all[ii].call > 0? ((this.staff_report_data_jobs_category[i].all[ii].tsuuwa/this.staff_report_data_jobs_category[i].all[ii].call).toFixed(3)): 0;
              this.staff_report_data_jobs_category[i].all[ii].tsuuwa_rate = (Math.round((rate * 100) * 10) / 10) + '%';
            }
 
            let tmp = {};

            //各ユーザーの業種カテゴリ用のデータを商材ごとに分割
            for(let tab in this.staff_report_data_jobs_category[i]) {
              //
              if(!tmp[tab]) {
                tmp[tab] = {};
              } 
              //tab は各タブ　各業種を取り出してループ
              for(let job_id in this.staff_report_data_jobs_category[i][tab]) {
                
                let job_val = this.staff_report_data_jobs_category[i][tab][job_id];

                //その業種を登録されている商材ごとに分類
                for(let iii in TF.jobs_category) {
                  let jobs = TF.jobs_category[iii];
                  //業種IDが同じだったら
                  if((job_id|0) == (jobs.id|0)) {
                    //商材のオブジェクトが定義されていなかったら仮の一時変数に定義
                    if(!tmp[tab][jobs.counterpart_id]) {
                      tmp[tab][jobs.counterpart_id] = {};
                    }

                    //商材だけで分ける場合　tabの時間帯ごとにオブジェクト定義
                    if(!tmp[tab][jobs.counterpart_id][job_id]) {
                      tmp[tab][jobs.counterpart_id][job_id] = {};
                    }

                    //業種件数を格納
                    tmp[tab][jobs.counterpart_id][jobs.id] = job_val;
                  }
                }
              }
            }

            //一時変数でもとのオブジェクトを上書き
            this.staff_report_data_jobs_category[i] = tmp;
          }

          this.setData('staff_report_data', this.staff_report_data);
          this.setData('staff_report_data_jobs_category', this.staff_report_data_jobs_category);
          TF.staff_report_data = this.staff_report_data;
          TF.staff_report_data_jobs_category = this.staff_report_data_jobs_category;
        }



        //業種カテゴリ用のデータを商材ごとに分割
        let tmp = {};
        for(let key in this.report_data_jobs_category) {
          if(!tmp[key]) {
            tmp[key] = {};
          }
          for(let job_id in this.report_data_jobs_category[key]) {
            let job_val = this.report_data_jobs_category[key][job_id];
            for(let i in TF.jobs_category) {
              let jobs = TF.jobs_category[i];
              if((job_id|0) == (jobs.id|0)) {
                if(!tmp[key][jobs.counterpart_id]) {
                  tmp[key][jobs.counterpart_id] = {};
                }

                /*商材だけで分ける場合*/
                tmp[key][jobs.counterpart_id][job_id] = job_val;
              }
            }
          }
        }

        this.report_data_jobs_category = tmp;

        console.timeEnd('select_duration');
        break;
      }

      case 'area': {
        $('title, .l-view h2.u-ttl').text('エリア別レポート');

        //各タブで判別
        this.report_data_scope = TF.report_data;
        this.selected_staff_scope = TF.selected_staff;
        this.area_scope = TF.area; 

        this.report_data = {};
        this.report_data.all = {};
        this.staff_report_data = {};
        
        //ユーザーが選択されていれば
        if(this.selected_staff_scope && this.selected_staff_scope.length > 0) {        
          for(let i in this.selected_staff_scope) {
            let key = this.selected_staff_scope[i]|0;
            this.staff_report_data[key] = {};
            this.staff_report_data[key].all = {};
          }
        }

        //データ構造をつくる
        for(let i = 0, n = this.area_scope.length; i < n; i++) {
          let key = this.area_scope[i].id;

          this.report_data.all[key] = {};
          this.report_data.all[key].call = 0;
          this.report_data.all[key].tsuuwa = 0;
          this.report_data.all[key].tsuuwa_rate = 0;
          this.report_data.all[key].tsuuwa_time = 0;

          //ユーザーが選択されていれば
          if(this.selected_staff_scope && this.selected_staff_scope.length > 0) {
            for(let ii in TF.selected_staff) {
              let key2 = TF.selected_staff[ii]|0;
              this.staff_report_data[key2].all[key] = {};
              this.staff_report_data[key2].all[key].call = 0;
              this.staff_report_data[key2].all[key].tsuuwa = 0;
              this.staff_report_data[key2].all[key].tsuuwa_rate = 0;
              this.staff_report_data[key2].all[key].tsuuwa_time = 0;
            }
          }
          
          //各タブ
          for(let ii = 0, nn = this.datatype.length; ii < nn; ii++) {
            let dt = this.datatype[ii];
            let tmp_scope = TF[dt];
            if(typeof this.report_data[dt] == 'undefined') this.report_data[dt] = {};
            this.report_data[dt][key] = {};
            for(let iii = 0, nnn = tmp_scope.length; iii < nnn; iii++) {
              this.report_data[dt][key][tmp_scope[iii].id] = 0;
            }

            //ユーザーが選択されていれば
            if(this.selected_staff_scope && this.selected_staff_scope.length > 0) {
              for(let iii in this.selected_staff_scope) {
                let key2 = this.selected_staff_scope[iii]|0;
                if(typeof this.staff_report_data[key2][dt] == 'undefined') this.staff_report_data[key2][dt] = {};
                this.staff_report_data[key2][dt][key] = {};

                for(let iiii = 0, nnn = tmp_scope.length; iiii < nnn; iiii++) {
                  this.staff_report_data[key2][dt][key][tmp_scope[iiii].id] = 0;
                }

              }
            }
          }
        }

        console.time('data_select');
        
       //データ抽出
       let career = {};
       let apli_before = {};
       let apli_after = {};
       for(let i = 0, n = TF.report_data.length; i < n; i++) {
         let data = TF.report_data[i];

         //商材かステータスが絞り込まれていたら
         let continue_flag = 0;
         if(data.call_counterpart != null && this.select_call_counterpart && this.select_call_counterpart.length > 0) {
           
           if(this.select_call_counterpart.indexOf(data.call_counterpart) == -1 ) {
             continue_flag++;
           }
         }

         if(data.call_status != null && this.select_call_status && this.select_call_status.length > 0) {
           if(this.select_call_status.indexOf(data.call_status) == -1 ) {
             continue_flag++;
           }
         }
         //商材もしくはステータスでどちらかもしくは両方ヒットしていなかったら
         if( continue_flag > 0 ) {
           continue;
         }
         
         //durationと登録履歴を結びつける
         if(data && data.duration) {
           //キャリア登録の場合
           if(data.customer_id == '0') {
             if(!career[data.tel_no]) {
               career[data.tel_no] = data.duration;
             } else {
               let time = career[data.tel_no].split(':');
               time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
               
               let time2 = data.duration.split(':');
               time2 = ((time2[0]|0) * 3600) + ((time2[1]|0) * 60) + (time2[2]|0);

               time = time + time2;
               let h = time / 3600|0;
               let m = time % 3600 / 60|0;
               let s = time % 60;

               time = (h < 10?'0'+ h:h)+':'+(m < 10?'0'+ m:m)+':'+(s < 10?'0'+ s:s);
               career[data.tel_no] = time;
             }
             data.duration = null;
             
           }

           if(data.customer_id != '0') {
        
            if(!apli_before[data.tel_no]) {
              apli_before[data.tel_no] = data.duration;
            } else {
              let time = apli_before[data.tel_no].split(':');
              time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
              
              let time2 = data.duration.split(':');
              time2 = ((time2[0]|0) * 3600) + ((time2[1]|0) * 60) + (time2[2]|0);
    
              time = time + time2;
              let h = time / 3600|0;
              let m = time % 3600 / 60|0;
              let s = time % 60;
    
              time = (h < 10?'0'+ h:h)+':'+(m < 10?'0'+ m:m)+':'+(s < 10?'0'+ s:s);
              apli_before[data.tel_no] = time;
            }
    
            data.duration = null;
          }

          if(data.customer_id != '0' && apli_after[data.tel_no]) { //アプリ登録の場合
             data.call_counterpart = apli_after[data.tel_no].call_counterpart;
             data.call_status = apli_after[data.tel_no].call_status;
             data.integration_id_1 = apli_after[data.tel_no].integration_id_1;
             data.integration_id_2 = apli_after[data.tel_no].integration_id_2;

           //apli_after = {};
           }

         }

         if(data && !data.duration) {

           //durationないけど諸データがセットされているとき
           if(
             data.call_counterpart ||
             data.call_status ||
             data.integration_id_1 ||
             data.integration_id_2
           ) {

             if(data.customer_id != '0' && apli_before[data.tel_no]) {
               data.duration = apli_before[data.tel_no];
             }

             if(data.customer_id != '0' && !apli_after[data.tel_no]) {
               apli_after[data.tel_no] = {
                 call_counterpart: data.call_counterpart,
                 call_status: data.call_status,
                 integration_id_1: data.integration_id_1,
                 integration_id_2: data.integration_id_2
               };
             }

             if(career[data.tel_no]) {
               data.duration = career[data.tel_no];
             }
           }
         }

         //商材もしくはステータスが絞り込まれているのにdataにどちらかがなかったら
         if(
           (!data.call_counterpart && this.select_call_counterpart && this.select_call_counterpart.length > 0) ||
           (!data.call_status && this.select_call_status && this.select_call_status.length > 0)
         ) {
           continue;
         }

          if(data && typeof data.integration_id_1 != 'undefined') {

            for(let key in this.report_data.all) {

              if(key == data.integration_id_1) {
                
                if(data.duration) {
                  this.report_data.all[key].call = this.report_data.all[key].call + 1; 
                
                  //音源があれば
                  let time = data.duration.split(':');
                  time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
                  
                  //5秒以上録音されていれば
                  if((time|0) > this.duration_threshold) {
                    //通話に加算
                    this.report_data.all[key].tsuuwa = this.report_data.all[key].tsuuwa + 1;
                    this.report_data.all[key].tsuuwa_time += time;
                  }
                }

                for(let iii = 0, nnn = this.datatype.length; iii < nnn; iii++) {
                  let dt = this.datatype[iii];
                  let column = dt == 'area'? 'integration_id_1': dt;
                  column = dt == 'jobs'? 'integration_id_2': column;
                  column = dt == 'jobs_category'? 'integration_id_2': column;

                  if((data[column]|0) > 0) {
                    //データをカウント
                    this.report_data[dt][key][data[column]] = this.report_data[dt][key][data[column]] + 1;
                  }
                }

                //ユーザー絞り込み
                if(this.selected_staff_scope && this.selected_staff_scope.length > 0) { 
                  //staffをループ
                  for(let ii in this.selected_staff_scope) {
                    //staffIDをゲット
                    let staff_id = this.selected_staff_scope[ii];
                    //staffIDとレポートデータのstaffが一致すれば
                    if(data.staff_id == staff_id) {
                      //staffごとにデータを加算
                      if(data.duration) {
                        this.staff_report_data[staff_id].all[key].call = this.staff_report_data[staff_id].all[key].call + 1;

                        //音源があれば
                        let time = data.duration.split(':');
                        //5秒以上録音されていれば
                        if((time[2]|0) > 5) {
                          //通話に加算
                          this.staff_report_data[staff_id].all[key].tsuuwa = this.staff_report_data[staff_id].all[key].tsuuwa + 1;
                          //通話時間に加算
                          time = ((time[0]|0) * 3600) + ((time[1]|0) * 60) + (time[2]|0);
                          this.staff_report_data[staff_id].all[key].tsuuwa_time += time;
                        }
                      }

                      //他のタブのデータ
                      for(let iii = 0, nn = this.datatype.length; iii < nn; iii++) {
                        //各タブの名前取得
                        let dt = this.datatype[iii];
                        //データのキーを変換
                        let column = dt == 'area'? 'integration_id_1': dt;
                        column = dt == 'jobs'? 'integration_id_2': column;
                        column = dt == 'jobs_category'? 'integration_id_2': column;

                        if((data[column]|0) > 0) {
                          //データをカウント
                          this.staff_report_data[staff_id][dt][key][data[column]] = this.staff_report_data[staff_id][dt][key][data[column]] + 1;
                        }
                      }
                    }
                  }
                }
              }                 
            }
          }
        }

        for(let i in this.report_data.all) {
          let rate = this.report_data.all[i].call > 0? ((this.report_data.all[i].tsuuwa/this.report_data.all[i].call).toFixed(3)): 0;
          this.report_data.all[i].tsuuwa_rate = (Math.round((rate * 100) * 10) / 10) + '%';
        }

        //ユーザー絞り込み通話率計算
        if(this.selected_staff_scope && this.selected_staff_scope.length > 0) { 
          for(let i in this.staff_report_data) {
            let tmp = {};
            
            for(let ii in this.staff_report_data[i].all) {        
              let rate = this.staff_report_data[i].all[ii].call > 0? ((this.staff_report_data[i].all[ii].tsuuwa/this.staff_report_data[i].all[ii].call).toFixed(3)): 0;
              this.staff_report_data[i].all[ii].tsuuwa_rate = (Math.round((rate * 100) * 10) / 10) + '%';
            }

            //各ユーザーの業種カテゴリ用のデータを商材ごとに分割
            for(let key in this.staff_report_data[i].jobs_category) {
              //key は時間帯　各業種を取り出してループ
              for(let job_id in this.staff_report_data[i].jobs_category[key]) {
                let job_val = this.staff_report_data[i].jobs_category[key][job_id]|0;
                //その業種を登録されている商材ごとに分類
                for(let iii in TF.jobs_category) {
                  let jobs = TF.jobs_category[iii];
                  //業種IDが同じだったら
                  if((job_id|0) == (jobs.id|0)) {
                    //商材のオブジェクトが定義されていなかったら仮の一時変数に定義
                    if(!tmp[jobs.counterpart_id]) {
                      tmp[jobs.counterpart_id] = {};
                    }

                    //商材だけで分ける場合　keyの時間帯ごとにオブジェクト定義
                    if(!tmp[jobs.counterpart_id][key]) {
                      tmp[jobs.counterpart_id][key] = {};
                    }

                    //業種件数を格納
                    tmp[jobs.counterpart_id][key][jobs.id] = job_val;
                  }
                }
              }
            }
            

            //一時変数でもとのオブジェクトを上書き
            this.staff_report_data[i].jobs_category = tmp;
          }
          this.setData('staff_report_data', this.staff_report_data);
          TF.staff_report_data = this.staff_report_data;
        }

        //業種カテゴリ用のデータを商材ごとに分割
        this.report_data_jobs_category = {};
        this.report_data_jobs_category.jobs_category = {};
        for(let key in this.report_data.jobs_category) {
          for(let job_id in this.report_data.jobs_category[key]) {
            let job_val = this.report_data.jobs_category[key][job_id]|0;
            
            for(let i in TF.jobs_category) {
              let jobs = TF.jobs_category[i];
              if((job_id|0) == (jobs.id|0)) {
                if(!this.report_data_jobs_category.jobs_category[jobs.counterpart_id]) {
                  this.report_data_jobs_category.jobs_category[jobs.counterpart_id] = {};
                }

                /* カテゴリで分ける場合
                if(!this.report_data_jobs_category[jobs.counterpart_id][jobs.category_id]) {
                  this.report_data_jobs_category[jobs.counterpart_id][jobs.category_id] = {};
                }

                if(!this.report_data_jobs_category[jobs.counterpart_id][jobs.category_id][key]) {
                  this.report_data_jobs_category[jobs.counterpart_id][jobs.category_id][key] = {};
                }

                this.report_data_jobs_category[jobs.counterpart_id][jobs.category_id][key][jobs.id] = job_val;*/

                /*商材だけで分ける場合*/
                if(!this.report_data_jobs_category.jobs_category[jobs.counterpart_id][key]) {
                  this.report_data_jobs_category.jobs_category[jobs.counterpart_id][key] = {};
                }

                this.report_data_jobs_category.jobs_category[jobs.counterpart_id][key][jobs.id] = job_val;
              }
            }
          }
        }
        console.timeEnd('data_select');

        break;
      }
    } 
  }


  setData(key, data) {
    var db = localStorage; 
    data = JSON.stringify(data);
    data = LZString.compress(data);

    /*/// 必ず Uint8Array に変換すること
    data = this.strToUint8Arr(data);
    /// そしたらJSONをGzipに圧縮
    data = new Zlib.Gzip(data);
    data = data.compress();
    //web storageに格納
    console.log(Object.prototype.toString.call(data))*/
    db.setItem(key, data);
  }

  getData(key) {
    var db = localStorage;
    var data = db.getItem(key);

    if (data != null) {
      data = LZString.decompress(data);
      /*/// そして圧縮データを展開
      data = this.strToUint8Arr(data);
      console.log(Object.prototype.toString.call(data))
      var unzip = new Zlib.Gunzip(data);
      data = unzip.decompress();

      /// Uint8Array なので文字列に戻す必要あり！
      data = this.uint8ArrToStr(data);*/
      return JSON.parse(data);
    } else {
      return false;
    }


    
  }

  clearData(keys) {
    let db = localStorage;
    keys.filter((v) => {
      db.removeItem(v);
    });
  }

  /// 文字列をUint8Arrayに変換
  strToUint8Arr(str) {
    /*var str = btoa(unescape(encodeURIComponent(str))),
        charList = str.split(''), uintArray = [];
    for (var i = 0; i < charList.length; i++) {
      uintArray.push(charList[i].charCodeAt(0));
    }*/
    return new TextEncoder().encode(str);
    return new Uint8Array(uintArray);
  }

  /// Uint8Arrayを文字列に変換
  uint8ArrToStr(uint8Arr) {
    /*var encodedStr = String.fromCharCode.apply(null, uint8Arr),
        decodedStr = decodeURIComponent(escape(atob(encodedStr)));*/

    return new TextDecoder().decode(uint8Arr);
    return decodedStr;
  }

  graphClass() {
    let _ = this;

    //グラフ
    this.graph = class {
      constructor(type, setting, report_data, tab_name, select_month) {
        //レポート形式
        this.type = type;

        //レポート設定
        this.report_setting = setting;

        //グラフで表示するデータ
        this.report_data = report_data;
        
        //表示するタブ名
        this.tab_name = tab_name || '';

        //グラフで表示する処理されたデータ用
        this.report_data_tmp = [];
        
        //タイトル
        this.title = '';

        //出力先
        this.dist = '';

        //出力カテゴリ
        this.category = '';

        this.select_month = select_month;

        //グラフタイプ
        this.graph_type = '';
        

        //グラフ用計算
        this.calcs = {
          tmp: [],

          //合計値算出
          sum: (values, set) => {
            let value = 0;
            values.filter((v) => {
              value += v;
            });
            this.calcs.tmp[set + '_total'] = value;
            return value;
          },

          //合計時間算出
          sumTime: (values, set) => {
            let value = 0;
            values.filter((v) => {
              value += v;
            });
            this.calcs.tmp[set + '_total'] = value;
            return value;
          },

          //比率算出
          rate: (values1, values2) => {
            return values1 / values2;
          },
        }
      }
      
      
      //グラフ初期化
      init(graph_data, category) {

        this.title = graph_data.title;
        this.dist = graph_data.dist;
        this.is_ext = graph_data.is_ext;
        this.category = category;
        this.stack = graph_data.stack;
        this.graph_type = graph_data.graph_type;
        this.canvas_id = graph_data.canvas_id;
        this.label_type = graph_data.label_type;
        this.setting = graph_data.setting;

        this.staff_id = graph_data.staff_id;
        if(this.staff_id && TF.staff_report_data[this.staff_id]) {
          //グラフで表示するデータを入れ替える
          this.report_data = TF.staff_report_data[this.staff_id];

          if(this.type == 'jobs' && category.indexOf('jobs_category') > -1) this.report_data = TF.staff_report_data_jobs_category[this.staff_id];
        }
        
        if(graph_data.counterpart_id) {
          this.counterpart_id = graph_data.counterpart_id;
        } 

        this.view();
      }

      //棒グラフx軸ラベル
      labels(category) {
        let columns = [];


        if(category == 'all') {

          if(this.type == 'week' && this.label_type == 'week') {
            this.setting.labels = [];
            this.report_setting.table_setting.rows.filter((v) => {
              this.setting.labels.push(v.start + '~' + v.end);
            });
          }

          if(this.type == 'week' && this.label_type == 'weekday') {
            this.setting.labels = [];
            this.setting.labels = TF.report.data_items.weekday;
          }

          if(this.type == 'month') {
            this.setting.labels = [];
            this.report_setting.table_setting.rows.filter((v) => {
              this.setting.labels.push(v);
            });
          }

          if(this.type == 'user' && this.graph_type == 'bar') {
            this.setting.labels = [];
            let staffs = TF.staff;
            if(TF.selected_staff && TF.selected_staff.length > 0) {
              staffs = TF.selected_staff;
            }

            for( let i in staffs) {
              let row = staffs[i];
              let staff_id = row.telforce_id || row|0;
              this.setting.labels.push('[' + staff_id + ']' + TF.staff[staff_id].name);
            }
          }

          if(this.type == 'user' && this.graph_type == 'pie') {
            this.setting.labels = [];
            TF.call_status.filter((v) => {
              this.setting.labels.push(v.name);
            });  
          }

          if(this.type == 'group' && this.label_type == 'office') {
            this.setting.labels = [];
            let office = TF.office;
            if(TF.selected_office && TF.selected_office.length > 0) {
              office = {};
              let tmp = TF.selected_office;
              tmp.filter((v) => {
                let obj = TF.office.filter(vv => vv.telforce_id == v|0)[0];
                office[v] = {office_name: obj.office_name, telforce_id: v};
              }); 
            }

            for(let i in office) {
              this.setting.labels.push(office[i].office_name);
            }  
          }

          if(this.type == 'group' && this.label_type == 'group') {
            this.setting.labels = [];
            let dept_unit = TF.dept_unit;
            if(TF.selected_dept_unit && TF.selected_dept_unit.length > 0) {
              dept_unit = {};
              let tmp = TF.selected_dept_unit;
              tmp.filter((v) => {
                let obj = TF.dept_unit.filter(vv => vv.telforce_id == v|0)[0];
                dept_unit[v] = {unit_name: obj.unit_name, telforce_id: v};
              }); 
            }

            for(let i in dept_unit) {
              this.setting.labels.push(dept_unit[i].unit_name);
            };
 
          }
          
          if(/*(this.type == 'jobs' || this.type == 'area') && */(this.label_type == 'jobs' || this.label_type == 'area')) {
            this.setting.labels = [];
            TF[this.label_type].filter((v) => {
              this.setting.labels.push(v.name);
            });
          }

          return this.setting.labels;
        }

        if(this.tab_name.indexOf('jobs_category') > -1) {
          
          if(category.indexOf('all') == 0 || category.indexOf('call_status') == 0 || category.indexOf('jobs_category') == 0 || category.indexOf('area') == 0) {
            this.setting.labels = [];
            TF.jobs_category.filter((v) => {
              if((v.counterpart_id|0) == (this.counterpart_id)) {
                this.setting.labels.push(`【${v.category}】―` + v.name);
              }
            });
          }
          
          if(this.type == 'area') {
            this.setting.labels = [];
            TF.area.filter((v) => {
              this.setting.labels.push(v.name);
            });
          }
          return this.setting.labels;
        }  

        if(category == 'call_counterpart' || category == 'call_status' || category == 'jobs' || category == 'jobs_category' || category == 'area') {
          
          if(this.type == 'week' && this.graph_type == 'bar') {
            //columns = TF.report.data_items.weekday;

            if(this.label_type == 'week') {
              this.setting.labels = [];
              this.report_setting.table_setting.rows.filter((v) => {
                this.setting.labels.push(v.start + '~' + v.end);
              });
            }

            if(category == 'jobs_category') {
              TF[category].filter((v) => {
                if((v.counterpart_id|0) == (this.counterpart_id)) {
                  columns.push(`【${v.category}】―` + v.name);
                }
              });
            } else {
              TF[category].filter((v) => {
                columns.push(v.name);
              });
            } 
          } else {

            if(this.label_type == 'jobs' || this.label_type == 'area') {
              TF[this.label_type].filter((v) => {
                columns.push(v.name);
              });
            } else if(category == 'jobs_category') {
              TF[category].filter((v) => {
                if((v.counterpart_id|0) == (this.counterpart_id)) {
                  columns.push(`【${v.category}】―` + v.name);
                }
              });
            } else {
              TF[category].filter((v) => {
                columns.push(v.name);
              });
            }
          }

          return columns;
        }


        /*if(category == 'jobs' || category == 'area') {
          this.setting.labels = [];
          TF[category].filter((v) => {
            this.setting.labels.push(v.name);
          });
          return this.setting.labels;
        }*/

      }

      //データ項目構築
      indexies() {
        this.report_data_tmp = this.report_data[this.tab_name];
   
        if(this.type == 'hour' || this.type == 'week' || this.type == 'month' || this.type == 'user' || this.label_type == 'office' || this.label_type == 'group' || ( this.tab_name.indexOf('all') == 0 && this.tab_name.indexOf('jobs_category') > -1 )) {
          this.report_data_tmp = {};
          this.report_data_tmp.call = [];
          this.report_data_tmp.tsuuwa = [];
          this.report_data_tmp.tsuuwa_rate = [];

          let all = this.report_data.all;

          if((this.type == 'jobs' && this.tab_name.indexOf('jobs_category') > -1 )) {
            all = this.report_data;

            if(this.counterpart_id && this.staff_id) {
              all = this.report_data.all[this.counterpart_id];
            }
          }
         
          if((this.type == 'hour' && this.tab_name == 'all') || this.type == 'week' || this.type == 'month' || this.type == 'user' || (this.tab_name.indexOf('all') == 0 && this.tab_name.indexOf('jobs_category') > -1 )) {
            for(let i in all) {   
              this.report_data_tmp.call.push(all[i].call);
              this.report_data_tmp.tsuuwa.push(all[i].tsuuwa);
              this.report_data_tmp.tsuuwa_rate.push(all[i].tsuuwa_rate.replace('%','')|0);
            }
          }
     
          if(this.label_type == 'weekday' && this.tab_name == 'all') {
            this.report_data_tmp = {};
            this.report_data_tmp.call = [];
            this.report_data_tmp.tsuuwa = [];
            this.report_data_tmp.tsuuwa_rate = [];

            for(let i in this.report_data.all.weekday) {
              this.report_data_tmp.call.push(this.report_data.all.weekday[i].call);
              this.report_data_tmp.tsuuwa.push(this.report_data.all.weekday[i].tsuuwa);
              if(this.report_data.all.weekday[i].tsuuwa_rate) {
                this.report_data_tmp.tsuuwa_rate.push(this.report_data.all.weekday[i].tsuuwa_rate.replace('%','')|0);
              } else {
                this.report_data_tmp.tsuuwa_rate.push(this.report_data.all.weekday[i].tsuuwa_rate);
              }
            }
          }

          if(this.label_type == 'office' || this.label_type == 'group') {
            for(let i in this.report_data.all[this.label_type]) {   
              this.report_data_tmp.call.push(this.report_data.all[this.label_type][i].call);
              this.report_data_tmp.tsuuwa.push(this.report_data.all[this.label_type][i].tsuuwa);
              //this.report_data_tmp.tsuuwa_rate.push(this.report_data.all[this.label_type][i].tsuuwa_rate);
              if(this.report_data.all[this.label_type][i].tsuuwa_rate) {
                this.report_data_tmp.tsuuwa_rate.push(this.report_data.all[this.label_type][i].tsuuwa_rate.replace('%','')|0);
              } else {
                this.report_data_tmp.tsuuwa_rate.push(this.report_data.all[this.label_type][i].tsuuwa_rate);
              }
            }
          }
        }


       
        if((this.type == 'jobs' && this.tab_name.indexOf('jobs_category') == -1 ) || this.type == 'area' || this.category == 'jobs_category' || this.category == 'jobs' || this.category == 'area') {
          this.setting.indexies = undefined;
        }
   
        if(typeof this.setting.indexies == 'undefined') {
          let tmp = [];
          let obj = {};
          this.report_data_tmp = {};
          /*
          this.report_data[this.type][this.category].filter((v) => {
            obj = {key: v.id, name: v.name, graph_type: 'bar', yAxisID: 'y-axis-' + this.category, order: 2};
            if(this.stack) {
              obj.stack = 'stack-'+ this.category;
              obj.categoryPercentage = 1; 
            }

            this.report_data_tmp[v.id] = v.data;
            tmp.push(obj);
          });*/

          let cat = this.category;
          let tab_name = this.tab_name;
          /*
          if((this.type == 'jobs' || this.type == 'area') && (cat == 'call_counterpart' || cat == 'call_status' || cat == 'area' || cat == 'jobs')) {
            //cat = this.type;
          }*/

          let _data = this.report_data[tab_name];
  
          _data = (this.label_type == 'weekday' && cat != 'jobs_category')? this.report_data[tab_name].weekday: _data;
          
          _data = cat == 'jobs_category' && this.label_type != 'weekday'? this.report_data: _data;
          _data = cat == 'jobs_category' && this.label_type == 'weekday'? this.report_data.weekday: _data;

          _data = this.type == 'jobs'? TF[tab_name]: _data;
          _data = this.type == 'area'? TF.area: _data;
          _data = this.type == 'area' && this.tab_name == 'call_counterpart'? TF.call_counterpart: _data;
          _data = this.type == 'area' && this.tab_name == 'call_status'? TF.call_status: _data;
          _data = this.type == 'area' && this.tab_name == 'jobs'? TF.jobs: _data;

          _data = (
            (this.label_type == 'hour' || this.label_type == 'week' || this.label_type == 'month' || this.label_type == 'area') &&
             tab_name == 'jobs_category' && this.staff_id
            )? this.report_data[tab_name][this.counterpart_id]: _data;

          _data = (
            this.label_type == 'week' &&
              tab_name == 'jobs_category' && this.staff_id
            )? this.report_data[tab_name][this.counterpart_id]: _data;

          _data = (this.label_type == 'weekday' && tab_name == 'jobs_category' && this.staff_id)? this.report_data[tab_name][this.counterpart_id].weekday: _data;

          if(this.type == 'jobs' && this.tab_name.indexOf('jobs_category') > -1) {
            if(this.tab_name.indexOf('call_status') == 0) {
              _data = TF.call_status;
            }

            if(this.tab_name.indexOf('area') == 0) {
              _data = TF.area;
            }
          }

          if(this.type == 'area' && this.tab_name.indexOf('jobs_category') > -1) {
            let tmp = [];
            let keys = this.report_data[1];
            for(let i in keys) {
              for(let ii in TF.jobs_category) {
                if((i|0) == (TF.jobs_category[ii].id|0)) {
                  tmp.push({id: TF.jobs_category[ii].id, name: `【${TF.jobs_category[ii].category}】- ` + TF.jobs_category[ii].name});
                }
              }  
            }

            _data = tmp;
          }

          for(let i in _data) {
            //let v = TF[cat][i];
            //let id = this.type == 'user'? i: v.id;
            if(this.label_type == 'week' && i == 'weekday') continue;

            if(this.type == 'user') {
              for(let i in this.report_data_tmp[id]) {
                _report_data_tmp.all.call.push(this.report_data_tmp[id][i].call);
                _report_data_tmp.all.tsuuwa.push(this.report_data_tmp[id][i].tsuuwa);
              }
            }

            let id = i;
            let name = i;

            if(i.match(/^[0-9]+\-[0-9]+$/)) {
              name = i.split('-');
             
              for(let ii in name) {
                if(this.type == 'hour') {
                  let h = name[ii] / 3600 | 0;
                  let m = name[ii] % 3600 / 60 | 0;
                  h = h < 10? '0' + h: h;
                  m = m < 10? '0' + m: m; 
                  let value = `${h}:${m}`;
                  name[ii] = value;
                }

                /*if(this.type == 'week') {
                  name[ii] = moment.unix(name[ii]|0).format('MM/DD');
                }*/
              }

              name = name.join('～');
            }

            if(this.type == 'jobs' || this.type == 'area') {
              id = _data[i].id;
              name = _data[i].name;
            }

            if(this.label_type == 'weekday') {
              name = TF.report.data_items.weekday[id];
            }

            let yaxis = (this.counterpart_id)? 'jobs' + this.counterpart_id: this.category;
            
            obj = {key: id, name: name, graph_type: 'bar', yAxisID: 'y-axis-' + yaxis, order: 2};
            if(this.stack) {
              obj.stack = 'stack-'+ this.category;
              obj.categoryPercentage = 1;
            }

            tmp.push(obj);
          }

          if(this.type == 'hour' || this.type == 'month' || (this.type == 'week' && this.label_type == 'week')) {
            if(tab_name == 'jobs_category' || tab_name == 'jobs' || tab_name == 'area') {
              this.report_data_tmp = {};

              for(let i in _data) {
                let obj = _data[i];

                if(typeof this.report_data_tmp[i] == 'undefined') this.report_data_tmp[i] = [];

                for(let ii in obj) {
                  let obj2 = obj[ii];
                  if(i.match(/^[0-9]+\-[0-9]+$/) || i.match(/^[0-9]+$/)) {
                    if(typeof this.report_data_tmp[i] == 'undefined') this.report_data_tmp[i] = [];
                    this.report_data_tmp[i].push(obj2|0);
                  }  
                }
              }
            }
          }
/*
          if((this.type == 'week' && this.label_type == 'week')) {
            this.report_data_tmp = {};
            if(tab_name == 'jobs' || tab_name == 'area') {
              for(let i = 0, n = TF[tab_name].length; i < n; i++) {
                let obj = TF[tab_name][i];
                if(obj.id) {
                  for(let ii in this.report_data[tab_name]) {
                    if(ii.match(/^[0-9]+\-[0-9]+$/)) {
                      
                      if(typeof this.report_data_tmp[obj.id] == 'undefined') this.report_data_tmp[obj.id] = [];
                      
                      this.report_data_tmp[obj.id].push(this.report_data[tab_name][ii][obj.id]);
                    }
                  }
                }
              }
            }
          }
*/
          if(this.type == 'week' && this.label_type == 'weekday') {
            if(tab_name == 'jobs_category' || tab_name == 'jobs' || tab_name == 'area') {
              this.report_data_tmp = {};
              for(let i in _data) {
                let obj = _data[i];

                for(let ii in obj) {
                  let obj2 = obj[ii];
                  if(typeof this.report_data_tmp[i] == 'undefined') this.report_data_tmp[i] = [];
                  this.report_data_tmp[i].push(obj2|0);
                }
              }
            }
          }

          if(this.type == 'jobs' || this.type == 'area') {
            if(tab_name == 'call_counterpart' || tab_name == 'call_status' || tab_name == 'jobs' || tab_name == 'area') {
              this.report_data_tmp = {};
              
              for(let i in TF[tab_name]) {
                if(typeof this.report_data_tmp[TF[tab_name][i].id] == 'undefined') this.report_data_tmp[TF[tab_name][i].id] = [];
                for(let ii in TF[this.type]) {

                  let values = this.report_data[tab_name][TF[this.type][ii].id];
                  
                  if(values) this.report_data_tmp[TF[tab_name][i].id].push(values[TF[tab_name][i].id]);
                }
              }

            }

            if(tab_name.indexOf('jobs_category') > -1) {
              this.report_data_tmp = {};
              
              //親タブがareaの場合
              if(this.type == 'area') {
                for(let i in this.report_data) {
                  for(let ii in this.report_data[i]) {
                    if(typeof this.report_data_tmp[ii] == 'undefined') this.report_data_tmp[ii] = [];
                    this.report_data_tmp[ii].push(this.report_data[i][ii]);
                  }
                }
              }
              
              //子タブのIDの先頭がcall_statusの場合
              if(tab_name.indexOf('call_status') == 0) {

                if(this.staff_id) {
                  this.report_data = this.report_data['call_status'][this.counterpart_id];
                }

                for(let i in TF.call_status) {
                  if(typeof this.report_data_tmp[TF.call_status[i].id] == 'undefined') this.report_data_tmp[TF.call_status[i].id] = [];

                  for(let ii in this.report_data) {
                    let values = this.report_data[ii];
                    
                    this.report_data_tmp[TF.call_status[i].id].push(values[TF.call_status[i].id]);
                  }
                  
                }
              }

              //子タブのIDの先頭がareaの場合
              if(tab_name.indexOf('area') == 0) {
                if(this.staff_id) {
                  this.report_data = this.report_data['area'][this.counterpart_id];
                }

                for(let i in TF.area) {
                  if(typeof this.report_data_tmp[TF.area[i].id] == 'undefined') this.report_data_tmp[TF.area[i].id] = [];

                  for(let ii in this.report_data) {
                    let values = this.report_data[ii];
                    this.report_data_tmp[TF.area[i].id].push(values[TF.area[i].id]);
                  }
                }
              }
              
            }
          }
          this.setting.indexies = tmp;


          if(this.type == 'user') {
            this.report_data_tmp = _report_data_tmp;
          }
        }

        return this.setting.indexies;
      }

      //データセット生成
      datasets() {
        let obj = [];
        let i = 0;
        let type = this.type;

        if(type == 'user' && this.graph_type == 'pie') {
          this.category = 'call_status';
        }

        if((type == 'jobs' || type == 'area') && this.graph_type == 'pie') {
          this.category = type;
        }

        //データ内容で切り替え
        switch(this.graph_type){
          case 'bar': {

            let _indexies;

            let colors = TF.report.graph.bar.colors;
 
            if(this.category == 'call_status') colors = TF.report.graph.bar.call_status_colors;

            _indexies = this.indexies();

            _indexies.filter((v) => {
              let data = this.report_data_tmp[v.key];
              let tmp = {
                type:            v.graph_type,
                label:           v.name,
                data:            data,
                borderWidth:     1,
                backgroundColor: colors[i],
                categoryPercentage: 1,
                yAxisID: v.yAxisID,
              };

              if('fill' in v) {
                tmp.fill = v.fill;
              }

              if('lineTension' in v) {
                tmp.lineTension = v.lineTension;
              }

              if('borderColor' in v) {
                tmp.borderColor = TF.report.graph.bar.colors[i];
              }

              if('order' in v) {
                tmp.order = v.order;
              }

              if('stack' in v) {
                tmp.stack = v.stack;
              }

              obj.push(tmp);

              i++;
            });

            break;
          }

          case 'pie': {
            let data = [];
            let _report_data = this.report_data;

            if(this.type == 'user') {
              _report_data = {};
              _report_data[this.category] = {};

              for(let i in this.report_data.all) {
                let tmp = this.report_data.all[i];
                
                for(let ii in tmp) {
                  if(ii.indexOf(this.category) > -1 && tmp[ii]) {
                    
                    let ccpid = ii.replace('-' + this.category, '');
                    if(typeof _report_data[this.category][ccpid] == 'undefined') {
                      _report_data[this.category][ccpid] = [];
                    }
                    _report_data[this.category][ccpid].push(1);
                    
                  }
                } 
              }
            }
            

            if(this.type == 'hour' || this.type == 'week' || this.type == 'month' || this.type == 'jobs' || this.type == 'area') {

              //各タブごとに格納

              if(TF.add_report && this.is_ext == 1) {
  
                if(!TF.add_report_objs) {
 
                  //親項目でループ
                  TF.add_report_objs = [];

                  for(let i in TF.call_counterpart){
                      let call_counterpart_key = TF.call_counterpart[i].id;
                      data = [];
          
                      let job_table_data = _report_data[this.category].add[call_counterpart_key];

                      if(job_table_data) {
                        let value = [];

                        //時間ごとにループ    
                        for(let condition in job_table_data) {
                          obj = [];

                          if(job_table_data[condition]) {

                            //子項目でループ
                            let iiii = 0;
                            for(let job_key in job_table_data[condition]) {
                              let job = TF[TF.add_report.datatype].filter(v => v.id === (job_key|0))[0];

                              //各子項目に加算
                              if(job && job_table_data[condition][job.id]) {
                                if(!value[iiii]) value[iiii] = 0; 
                                value[iiii] += job_table_data[condition][job.id];     
                              }

                              iiii++;
                            }
                          }
                        }

                        let colors = TF.report.graph.circle.colors;
                        if(this.category == 'call_status') colors = TF.report.graph.bar.call_status_colors;
                        
                        //objectに格納
                        let tmp = {
                          data: value,
                          borderWidth: 0,
                          backgroundColor: colors,
                          hoverBackgroundColor: colors,
                        };
          
                        obj.push(tmp);
                        TF.add_report_objs.push( obj );
                      }

                  }
                  let objtmp = TF.add_report_objs[0];

                  TF.add_report_objs.shift();
                  return {datasets: objtmp};
                  
                } else {

                  let objtmp = TF.add_report_objs[0];
                  TF.add_report_objs.shift();
                  if(TF.add_report_objs.length == 0) {
                    TF.add_report_objs = null;
                  }
                  return {datasets: objtmp};
                }

              } else {

                for(let i in TF[this.category]) {
                  let value = 0;
                  let obj = TF[this.category][i];
          
                    for(let ii in _report_data[this.category]) {
                      if(ii != 'weekday' && ii != 'add') value += _report_data[this.category][ii][obj.id];
                    }
                    data.push(value);
                }

                let colors = TF.report.graph.circle.colors;
                if(this.category == 'call_status') colors = TF.report.graph.bar.call_status_colors;

                let tmp = {
                  data: data,
                  borderWidth: 0,
                  backgroundColor: colors,
                  hoverBackgroundColor: colors,
                };
    
                obj.push(tmp);
              }

            } else {
              for(let i in TF[this.category]) {
                let value = 0;
                let obj = TF[this.category][i];

                for(let ii in _report_data[this.category][obj.id]) {
                  value += _report_data[this.category][obj.id][ii];
                }

                /*this.report_data[this.category][obj.id].filter((v) => {
                  value += v;
                });*/
                
                data.push(value);
              }

              let tmp = {
                data: data,
                borderWidth: 0,
                backgroundColor: TF.report.graph.bar.call_status_colors,
                hoverBackgroundColor: TF.report.graph.bar.call_status_colors,
              };
  
              obj.push(tmp);
            }

            /*
            TF[this.category].filter((v) => {
              let value = 0;
              this.report_data[this.category][v.id].filter((v) => {
                value += v;
              });
              data.push(value);
            });*/

            break;
          }
        }

        let labels = this.is_ext == 1? this.setting.labels: this.labels(this.category);
        return {labels: labels, datasets: obj};
      }

      //グラフ用オプション
      option() {
        this.setting.options.tooltips = {
          intersect: false,
          mode: "index",
          position: "custom",
          yAlign: "center"
        };

        /*if(this.tab_name.indexOf('jobs_category') > -1 ) {

          if(this.type == 'area') {
            this.setting.options.layout = {
              padding: {
                  left: 0,
                  right: 0,
                  top: 0,
                  bottom: 300
              }
            }
          }
        }*/
        return this.setting.options;
      }
      
      //グラフ出力
      view() {
        let temp = TF.REPORT_TEMPLATES.bar;
        temp = temp.replace('%TITLE%', this.title);
        temp = temp.replace('%CANVASID%', this.canvas_id);

        let chart_obj;
       
        if(TF.chart_setting) {
          if(this.label_type == 'weekday') {
            this.canvas_id = TF.chart_setting[TF.select_staff_id[0]|0][this.category].weekday.canvas_id;
            chart_obj = TF.chart_setting[TF.select_staff_id[0]|0][this.category].weekday.obj;
            temp = temp.replace('%TITLE%', this.title);
            temp = temp.replace('%CANVASID%', this.canvas_id);
  
          } else {
            if(this.category == 'jobs_category') {
              this.canvas_id = TF.chart_setting[TF.select_staff_id[0]|0][this.category][this.counterpart_id].canvas_id;
              chart_obj = TF.chart_setting[TF.select_staff_id[0]|0][this.category][this.counterpart_id].obj;
            } else {
              if(this.category.indexOf('-jobs_category') > -1) {
                let cat = this.category.replace(/_jobs_of_[0-9]+/, '');
                this.canvas_id = TF.chart_setting[TF.select_staff_id[0]|0][cat][this.counterpart_id].canvas_id;
                chart_obj = TF.chart_setting[TF.select_staff_id[0]|0][cat][this.counterpart_id].obj;
              } else {
                this.canvas_id = TF.chart_setting[TF.select_staff_id[0]|0][this.category].canvas_id;
                chart_obj = TF.chart_setting[TF.select_staff_id[0]|0][this.category].obj;
              }

            }
          }
          
          $(this.dist.replace(/[^ ]+/,'')).empty().append(temp);

          let cls = '.p-report-child-content-data.-bar.-wide .p-report-box-graph-area, .p-report-child-content-userdata.-bar.-wide .p-report-box-graph-area, .p-report-content-userdata.-bar.-wide .p-report-box-graph-area';
          let elm = $(cls);
          let num = chart_obj.data.labels.length;
          elm.attr({width: num * 30}).width(num * 30);

        } else {

          $(this.dist).empty().append(temp);
          let datasets_obj = this.datasets();

          let cls;
          if((this.type == 'user' || this.type == 'office' || this.type == 'group' || this.category == 'jobs_category' || this.category == 'jobs' ||   this.category == 'area') && this.graph_type == 'bar') {
            //temp = temp.replace(/height="[^"]+"/, 'height="30"');
            //temp = temp.replace(/width="[^"]+"/, 'width="auto"');
            let key = this.type == 'user'? 'staff': this.type;
            key = this.category == 'jobs' || this.category == 'area'? this.category: key;
   
            let num = datasets_obj.labels.length;
            if(this.type == 'user') {
              let staffs = TF.staff;
              num = Object.keys(staffs).length;
              if(TF.selected_staff && TF.selected_staff.length > 0) {
                staffs = TF.selected_staff;
                num = staffs.length;
              }
            }

            cls = '.-' + this.category + ' .p-report-content-data.-bar.-wide .p-report-box-graph-area';

            if(num > 15) {  
              let elm = $(cls); 
              elm.attr({width: num * 30, height: 500}).height(500).width(num * 30);
            }

          }

          if(this.is_ext == 1) {
            datasets_obj.labels = this.setting.labels;
          }
          
          let opt = this.option();
          //グラフ用セッティング
          chart_obj = {
            type: this.graph_type,
            data: datasets_obj,
            options: opt
          };
          
          if(this.tab_name.indexOf('jobs_category') > -1 ) {
            let cls = '.p-report-child-content-data.-bar.-wide .p-report-box-graph-area, .p-report-child-content-userdata.-bar.-wide .p-report-box-graph-area, .p-report-content-userdata.-bar.-wide .p-report-box-graph-area';
            let elm = $(cls);
            let num = datasets_obj.labels.length;
            
            if(num > 15) { 
              elm.attr({width: num * 30}).width(num * 30);
            }
            
            if(this.type == 'area') {
              elm.attr({height: 1200}).height(1200);
            }
          }

          //ユーザー絞り込み用に保存
          if(this.staff_id) {
            let setting = _.getData('chart_setting');
            if(!setting) setting = {}; 
            if(!setting[this.staff_id]) setting[this.staff_id] = {};

            if(this.label_type == 'weekday') {
              if(!setting[this.staff_id].weekday) setting[this.staff_id].weekday = {};
              setting[this.staff_id][this.tab_name].weekday = {
                canvas_id: this.canvas_id,
                obj: chart_obj
              };
            } else {

              //chart_obj.type = 'doughnut';
              if(this.tab_name.indexOf('jobs_category') > -1 ) {
                if(!setting[this.staff_id][this.tab_name]) setting[this.staff_id][this.tab_name] = {};
                let child_tab = this.canvas_id.match(/[0-9]+/,'')[0];
                setting[this.staff_id][this.tab_name][child_tab] = {
                  canvas_id: this.canvas_id,
                  obj: chart_obj
                };
              } else {
                setting[this.staff_id][this.tab_name] = {
                  canvas_id: this.canvas_id,
                  obj: chart_obj
                };
              }
            }

            _.setData('chart_setting', setting);
          }
        }


        let elm = document.getElementById(this.canvas_id);
        if(elm) {
          new Chart(elm.getContext("2d"), chart_obj);
          
          Chart.Tooltip.positioners.custom = function(elements, position) {
            if (!elements.length) {
              return false;
            }
            const ps = elements[0].tooltipPosition();
            const area = elements[0]._chart.chartArea;
            return {
              x: ps.x,
              y: (area.bottom) / 2
            };
          };
        } else {
          console.log(this.canvas_id, 'グラフの表示先が存在しません。');
        }


        setTimeout(() => {
            //$('#' + this.canvas_id).height(500);
        }, 200);     
      }

       
    }
  }

  //テーブル
  tableClass() {
    let _ = this;

    this.table = class {
      constructor(type, setting, report_data, tab_name, select_month) {

        //レポート形式
        this.type = type;

        //レポート設定
        this.setting = _.setting;
       
        //グラフで表示するデータ
        this.report_data = report_data;

        //表示するタブ名
        this.tab_name = tab_name;

        //タイトル
        this.title = '';

        //出力先,
        this.dist = '';

        //タブ名
        this.tab = '';

        //月が選択されていれば
        this.select_month = select_month; 

        //テーブル用計算
        this.calcs = {
          tmp: [],

          //合計値算出
          sum: (values, set) => {
            let value = 0;

            values.filter((v) => {
              value += v;
            });
            this.calcs.tmp[set + '_total'] = value;
            return value;
          },

          //合計時間算出
          sumTime: (values, set) => {
            let value = 0;
            values.filter((v) => {
              if(v) {
                
                let time;
                if(String(v).match(/[0-9]{2,}:[0-9]{2}:[0-9]{2}/)) {
                  v = v.split(':');
                  time = ((v[0]|0) * 3600) + ((v[1]|0) * 60) + (v[2]|0);
                  value += time;
                } else if(String(v).match(/[0-9]+/)) {
                  value += v;
                }
                
              }
            });
            
            let h = value / 3600 | 0;
            let m = value % 3600 / 60 | 0;
            let s = value % 60;
            
            h = h < 10? '0' + h: h;
            m = m < 10? '0' + m: m;
            s = s < 10? '0' + s: s; 
            value = `${h}:${m}:${s}`;

            this.calcs.tmp[set + '_total'] = value;
            return value;
          },

          //比率算出
          rate: (values1, values2) => {
            return values1 / values2;
          }
        }

      }
    
      //テーブル初期化
      init(table_data, tab) {

        this.tab = tab;
        this.title = table_data.title;
        this.dist = table_data.dist;
        this.row_type = table_data.row_type;
        this.is_ext = table_data.is_ext;

        this.staff_id = table_data.staff_id;

        if(table_data.counterpart_id) {
          this.counterpart_id = table_data.counterpart_id;
        }

        if(table_data.column) {
          this.custom_column = table_data.column;
        }

        if(this.staff_id && TF.staff_report_data && TF.staff_report_data[this.staff_id]) {
          //グラフで表示するデータを入れ替える
          if(tab.indexOf('jobs_category') == -1) this.report_data = TF.staff_report_data[this.staff_id];
          if(this.type == 'jobs' && tab.indexOf('jobs_category') > -1) this.report_data = TF.staff_report_data_jobs_category[this.staff_id];
        }


        //ユーザー絞り込みしたとき
        if(TF.staff_report_integration_data) {
          this.report_data = TF.staff_report_integration_data;
        }

        if(this.is_ext == 1) { 
          for(let i in TF.add_report.data) {
            this.title = TF.add_report.titles['table_' + i];
            this.dist = TF.add_report.dist['table_' + i];
            this.view(i);      
          };
        } else {

          this.view('week');

          if(this.row_type == 'week') {
            this.row_type = 'weekday';
            this.view('weekday');
          }

        }
      }

      //テーブルカラム設定
      column(add_id) {

        let columns = [];
          if(this.tab == 'all' || this.tab == 'jobs_area') {
            columns = this.setting.table_setting.columns;
          }

          if(this.tab.indexOf('jobs_category') > -1) {
            return this.custom_column;
          }

          if(this.tab == 'call_counterpart' || this.tab == 'call_status' || this.tab == 'jobs' || this.tab == 'area') {
            columns.push(this.setting.table_setting.columns[0]);

            TF[this.tab].filter((v) => {
              if(this.is_ext == 1) {
                let add_data = TF.add_report.data[add_id];
                if(v.id == add_id) {
                  let unique_ary = [];
                  for(let i in add_data) {
                    
                    if(unique_ary.indexOf(add_data[i].id) == -1) {
                      unique_ary.push(add_data[i].id);
                      columns.push({key: (add_data[i].id|0), name: add_data[i].name, calc: 'sum'});
                    }
                  }
                }
              } else {
                columns.push({key: (v.id|0), name: v.name, calc: 'sum'});
              }
            });

            return columns;
          }

          if(this.type == 'user') {}

          if(this.row_type == 'office') {
            /*TF.call_status.filter((v) => {
              this.setting.table_setting.columns.push({key: v.id, name: v.name, calc: 'sum'});
            });*/
          }

          if(this.row_type == 'group') {
            this.setting.table_setting.columns[0].name = 'グループ';
          }

          columns = this.setting.table_setting.columns;

        return columns;
      }

      //テーブルロウ設定
      row() {
        let rows = [];

        switch(this.type) {
          case 'hour':
          case 'month': {        
            rows = _.setting.table_setting.rows;
            break;
          }
          case 'week': 
          case 'weekday': {

            rows = this.row_type == 'week'? _.setting.table_setting.rows: TF.report.data_items.weekday;

            break;
          }
          case 'user': {

            let staffs = TF.staff;
            if(TF.selected_staff && TF.selected_staff.length > 0) {
              let tmp = [];
              for(let i in TF.selected_staff) {
                if(TF.staff[TF.selected_staff[i]]) tmp.push(TF.staff[TF.selected_staff[i]]);
              }
              staffs = tmp;
            }

            for( let i in staffs) {
              let row = staffs[i];
              console.log(row);
              let staff_id = row.login_id;

              rows.push([row.name, staff_id ]);
            }

            break;
          }
          case 'group': {
            //オフィス
            if(this.row_type == 'office') {
              let office = TF.office;
              if(TF.selected_office && TF.selected_office.length > 0) {
                office = {};
                let tmp = TF.selected_office;
                tmp.filter((v) => {
                  let obj = TF.office.filter(vv => vv.telforce_id == v|0)[0];
                  office[v] = {office_name: obj.office_name, telforce_id: v};
                }); 
              } 
              for(let i in office) {
                rows.push([office[i].office_name, office[i].telforce_id]);
              }
          
              if(
                TF.selected_dept_unit && TF.selected_dept_unit.length > 0 &&
                TF.selected_office && TF.selected_office.length == 0
                ) {
                //rows = null
              }
            }

            if(this.row_type == 'group') {
              //グループ
              let dept_unit = TF.dept_unit;
              if(TF.selected_dept_unit && TF.selected_dept_unit.length > 0) {
                dept_unit = {};
                
                let tmp = TF.selected_dept_unit;

                tmp.filter((v) => {
                  let obj = TF.dept_unit.filter(vv => vv.telforce_id == v|0)[0];
                  dept_unit[v] = {unit_name: obj.unit_name, telforce_id: v};
                }); 
              }

              for(let i in dept_unit) {
                rows.push([dept_unit[i].unit_name, dept_unit[i].telforce_id]);
              };

              if(
                TF.selected_dept_unit && TF.selected_dept_unit.length == 0 &&
                TF.selected_office && TF.selected_office.length > 0
                ) {
                //rows = null
              }
            }
            
            break;
          }
          case 'jobs':
          case 'area':  {
            if(this.type == 'jobs' && this.tab.indexOf('jobs_category') > -1) {
              TF.jobs_category.filter((v) => {
                rows.push([v.name, v.id]);
              });
            } else {
              TF[this.type].filter((v) => {
                rows.push([v.name, v.id]);
              });
            }

            break;
          }
        }

        return rows;
      }

      view(add_id) {
        let tr = [];
        let th = [];
        let th_groups = [];
        let td = [];
        let temp = TF.REPORT_TEMPLATES.table;
        let columns = this.column(add_id);
        let rows = this.row();
        let report_data = [];

        if(this.tab.indexOf('jobs_category') > -1) {
          report_data = this.report_data;

          if(this.type == 'jobs' && this.staff_id) {
            let child_tab = this.tab.replace(/-jobs_category.*/, '');
            report_data = report_data[child_tab];  
            if(this.counterpart_id) report_data = report_data[this.counterpart_id];
          }
        } else {
          report_data = this.report_data[this.tab];
        }
        
        this.dist = TF.select_staff_id || TF.staff_report_integration_data? this.dist.replace(/[^ ]+/, ''): this.dist;

        if(this.row_type == 'office' || this.row_type == 'group' || this.row_type == 'weekday' || this.is_ext == 1) {
          if(this.row_type != 'weekday' && this.is_ext == 1) {
            report_data = this.report_data[this.tab].add[add_id];
          } else {
            
            if(this.tab.indexOf('jobs_category') > -1) {
              report_data = this.report_data[this.row_type];
            } else {
              report_data = this.report_data[this.tab][this.row_type];
            }
          }
        }
        
        
        //th構築
        for(let i = 0, n = columns.length; i < n; i++) {
          let data_attr = '';

          //業種カテゴリ用にヘッダのセルのグループ分け
          if(this.tab.indexOf('jobs_category') > -1 ) {
            if(i == 0) {
              data_attr = (this.type == 'jobs')? ' colspan="2"' :' rowspan="2"';
            } else if(this.type != 'jobs') {
              for(let ii in TF.jobs_category) {
                if((TF.jobs_category[ii].id|0) == (columns[i].key|0)) {
                  data_attr = ` data-th_group="${TF.jobs_category[ii].category_id}"`;
                }
              }
            }
          }

          th.push(`<th class="p-data-table-th"${data_attr}><span class="u-text -vertical-text">${columns[i].name}</span></th>`);
        };

        tr.push('<thead><tr class="p-data-table-tr">' + th.join('') + '</tr></thead>');
        th = [];

        //td構築
        let count = 0;
        let _report_data;



        for(let ii = 0, nn = rows.length; ii < nn; ii++) {
          let v = rows[ii];
          let id;

          if(this.row_type == 'hour') {
            let start = (v.start.split(':')[0]|0) * 3600;
            let end = (v.end.split(':')[0]|0) * 3600;
            id = `${start}-${end}`;
            if(typeof report_data == 'undefined') continue;
            _report_data = report_data[id];
          }


          if(this.row_type == 'week') {
            //let date = this.select_month + '/' + ((v.start < 10)? '0' + v.start: v.start);
            let start = v.start;
            
            //date = this.select_month + '/' + ((v.end < 10)? '0' + v.end: v.end);
            let end = v.end;

            id = `${start}-${end}`;
         
            _report_data = report_data[id];
          }


          if(this.row_type == 'weekday') { 
            _report_data = report_data[ii];
          }

          if(this.row_type == 'month') {
            _report_data = report_data[v];
          }

          if(this.row_type == 'user') {
            /*let staffs = TF.staff;
            if(TF.selected_staff && TF.selected_staff.length > 0) {
              staffs = TF.selected_staff;
            }

            for( let i in staffs) {
              let row = staffs[i];
              let staff_id = row.telforce_id || row|0;
              rows.push([TF.staff[staff_id].name, i]);
            }*/

              id = v[1];
              v = v[0] + '<br>[' + id + ']';

            this.this_row_staff_id = id;
            _report_data = report_data[id];
          }

          if(this.row_type == 'office' || this.row_type == 'group') {
            id = v[1];
            v = v[0] + '<br>[' + id + ']';
            _report_data = report_data[id];
            
          }

          if(this.type == 'jobs' || this.type == 'area' || this.row_type == 'jobs' || this.row_type == 'area') {
            id = v[1];
            v = v[0];

            if(report_data[id]) {
              _report_data = report_data[id];
            } else {
              continue;
            }
          }
         
          for(let i = 0, n = columns.length; i < n; i++) {

            if(i == 0) {
              let row = '';

              switch(this.type) {
                case 'hour':
                case 'week': {
                  row = v.start + '&#65374;' + v.end;

                  if(this.row_type == 'weekday') {
                    row = v;
                  }
                  break;
                }
                case 'month':
                case 'user':
                case 'group':
                case 'jobs':
                case 'area': {
                  row = v;
                  break;
                }
              }

              //業種カテゴリ用にセルのグループ分け
              let data_attr = '';
              if(this.tab.indexOf('jobs_category') > -1 && this.type == 'jobs') {
                for(let ii in TF.jobs_category) {
                  if((TF.jobs_category[ii].id|0) == (id|0)) {
                    data_attr = ` data-td_group="${TF.jobs_category[ii].category_id}"`;
                  }
                }
              }

              td.push(`<td class="p-data-table-td"${data_attr}>` + row + '</td>');
            } else {

              if(typeof this.calcs.tmp[columns[i].key] == 'undefined') {
                this.calcs.tmp[columns[i].key] = [];
              }

              let cell_data;
              let num;

              //if(this.row_type == 'hour' || this.row_type == 'week' || this.row_type == 'weekday' || this.row_type == 'office' || this.row_type == 'group' || this.row_type == 'user') {

              cell_data = _report_data[columns[i].key];
              
              num = _report_data[columns[i].key];
              if(columns[i].key == 'tsuuwa_time') {
                let h = Math.floor(cell_data / (60 * 60));
                let m = Math.floor(cell_data % (24 * 60 * 60) % (60 * 60) / 60);
                let s = cell_data % (24 * 60 * 60) % (60 * 60) % 60;
                h = h < 10? '0' + h: h;
                m = m < 10? '0' + m: m;
                s = s < 10? '0' + s: s;

                cell_data = [h,m,s].join(':');
              }

              if(columns[i].key == 'no_register') {
                num = cell_data.length;
                if(num == 0) {
                  cell_data = 0;
                } else {
                  cell_data = `<b style="font-weight:bold" data-num="${cell_data.length}">合計 ${cell_data.length} 件</b><br>` + cell_data.join('<br>');
                }
 
              }
              /*} else {
                if(typeof _report_data[columns[i].key] != 'undefined' && _report_data[columns[i].key][count] != 'undefined') {
                  cell_data = _report_data[columns[i].key][count];               
                  if(typeof cell_data == 'undefined') {
                    cell_data = 0;
                  }
                } else {
                  cell_data = 0;
                }
              }*/

              //値を代入
              this.calcs.tmp[columns[i].key].push(num);

              td.push('<td class="p-data-table-td" data-key="' + columns[i].key + '" data-value="' + num + '">' + cell_data + '</td>');
            }
          }
          
          //ユーザー別の場合にtrにスタッフID付与
          let data_attr = '';
          if(this.this_row_staff_id) {
            data_attr = ' data-staff_id="' + this.this_row_staff_id + '"';
          }

          tr.push('<tr class="p-data-table-tr"' + data_attr + '>' + td.join('') + '</tr>');

          td = [];
          count++;
        };

        //合計計算
        columns.filter((v) => {
         
          let attr = '';
          let value = 0;
          if(v.key == 'working') {
            value = '合計';

            if(this.type == 'jobs' && this.tab.indexOf('jobs_category') > -1) {
              attr = ' colspan="2"';
            }
          }
    
          if(v.calc.match(/^sum/)) {
            value = this.calcs[v.calc](this.calcs.tmp[v.key], v.key);
          }

          if(v.calc.indexOf('rate') > -1) {
            let values = v.calc.match(/\[([^\]]+)\]?/);
            values = values[1].split('/');
            value = this.calcs.rate(this.calcs.tmp[values[0] + '_total'], this.calcs.tmp[values[1] + '_total']);

            value = value.toFixed(3);
            value = (Math.round((value * 100) * 10) / 10) + '%';
          }

          value = typeof value == 'string' && value.indexOf('NaN%') > -1 ? '0%': value;
          td.push(`<td class="p-data-table-td"${attr}>` + value + '</td>');
        });
        /*
        columns.filter((v) => {
          if(typeof this.calcs[this.tab][v.key] != 'undefined') {
            td.push('<td class="p-data-table-td">' + this.calcs[this.tab][v.key].calc() + '</td>');
          }
        });*/

  
        tr.push('<tr class="p-data-table-tr -bottom">' + td.join('') + '</tr>');
        this.calcs.tmp = [];
        console.log(this.title);
        temp = temp.replace('%TITLE%', this.title);
        temp = temp.replace('%ROWS%', tr.join(''));

        let cls = this.row_type && this.row_type.indexOf('week') > -1 && this.is_ext != 1? '.-' + this.row_type: '';
        
        //テーブル表示
  
        let dist = $(this.dist + cls);
        dist.empty().append(temp);

        $('.-user .p-report-box-table-area').addClass('p-data-area -table');

        //業種カテゴリのときにカテゴリ用のヘッダ追加
        if(this.tab.indexOf('jobs_category') > -1) {

          if(this.type == 'jobs') {
            let td_group = dist.find('td[data-td_group]');
            let count = {};
            let td = [];
            
            for(let i = 0, n = td_group.length; i < n; i++) {
              let elm = td_group.eq(i).data('td_group');
              count[elm] = (count[elm] || 0) + 1;
            }

            for(let i in count) {
              for(let ii in TF.jobs_category) {
                if((i|0) == (TF.jobs_category[ii].category_id|0)) {
                  let cell = `<td class="p-data-table-td" rowspan="${count[i]}"><span class="u-text">${TF.jobs_category[ii].category}</span></td>`;
                  if(td.indexOf(cell) == -1) td.push(cell);
                } 
              }
            }

            let start = 1;
            for(let i in td) {
              let elm = $(td[i]);
              let n = elm.attr('rowspan')|0;

              dist.find('.p-data-table tr').eq(start).addClass('-separate').prepend(elm);
              start = start + n;
              
            }

          } else {
            let th_1st = dist.find('th').eq(0);
            let th_group = dist.find('th[data-th_group]');
            let count = {};

            let th = [];
            for(let i = 0, n = th_group.length; i < n; i++) {
              let elm = th_group.eq(i).data('th_group');
              count[elm] = (count[elm] || 0) + 1;
            }
            for(let i in count) {
              for(let ii in TF.jobs_category) {
                if((i|0) == (TF.jobs_category[ii].category_id|0)) {
                  let cell = `<th class="p-data-table-th" colspan="${count[i]}"><span class="u-text">${TF.jobs_category[ii].category}</span></th>`;
                  if(th.indexOf(cell) == -1) th.push(cell);
                } 
              }
            }

            th = $('<tr class="p-data-table-tr">' + th.join('') + '</tr>').prepend(th_1st);
            dist.find('.p-data-table thead').prepend(th);
          }
        }

        //ユーザー別の時に各ユーザー業種のコールランキングを表示させる
        if(_.jobs_staff_data) {
          let number = ['①','②','③'];

          for(let staff_id in _.jobs_staff_data) {
            let staff_data = _.jobs_staff_data[staff_id];
            let row = $('.p-data-table-tr[data-staff_id="' + staff_id + '"]');
            
            for(let counterpart_id in staff_data) {
              let cell = row.find('td[data-key="' + counterpart_id + '-call_counterpart"]').addClass('-jobs-ranking');
              if((cell.text()|0) > 0) {
                let pairs = Object.entries(staff_data[counterpart_id]);
                pairs.sort(function(p1, p2){
                  let p1Val = (p1[1]|0), p2Val = (p2[1]|0);
                  return p2Val - p1Val;
                });
                
                //上位3位まで抽出
                pairs = pairs.slice(0,3);
  
                let ranking_box = $('<ul class="-ranking-list">');
                let rank = 0;
                for(let i in pairs) {  
                  let job_name = '';
                  for(let ii in TF.jobs_category) {
                    if((pairs[i][0]|0) == (TF.jobs_category[ii].id|0)) {
                      job_name = `<span class="u-text">【${TF.jobs_category[ii].category}】</span><br>${TF.jobs_category[ii].name}`;
                    }
                  }

                  if(pairs[i][1] > 0) {
                    let job = $('<li>', {
                      class: '-ranking-list-item',
                      html: `${number[rank]}&nbsp;${job_name}&nbsp;<span class="u-text">${pairs[i][1]}</span>&nbsp;件`
                    });
    
                    ranking_box.append(job);
                    rank++;
                  }
                }

                cell.append(ranking_box);
              } 
            }
          }
        }
      }
    }
  }

  monthDays(type) {
    let months = [
      $('#select-month').val()? moment($('#select-month').val(), 'YYYY/MM').unix():0,
      $('#duration-start').val()? moment($('#duration-start').val(), 'YYYY/MM').unix():0,
      $('#duration-end').val()? moment($('#duration-end').val(), 'YYYY/MM').unix():0
    ];

    let startmonth = $('#duration-start').val()? moment($('#duration-start').val(), 'YYYY/MM').endOf("month").format('DD'):0;

    let endmonth = $('#duration-end').val()? moment($('#duration-end').val(), 'YYYY/MM').endOf("month").format('DD'):0;

    this.select_month = moment.unix(Math.max(...months)).format('YYYY/MM');

    if($('#duration-end').val() && startmonth <= endmonth) {
      this.select_month = $('#duration-end').val();
    }

    if($('#duration-start').val() && startmonth > endmonth) {
      this.select_month = $('#duration-start').val();
    }
   
   

    //this.select_month = $('#select-month, #duration-start').val();
    //var DAMMY_MONTH = '2020/10';
    //this.select_month = DAMMY_MONTH; // ダミーデータ

    const month = new Date(this.select_month);

    // 今月の最終日を取得する
    month.setMonth(month.getMonth() + 1);
    month.setDate(0);
    const lastDate = month.getDate();
    const lastDay = month.getDay();

    // 今月１日の曜日を取得する
    month.setDate(1);
    const firstDate = 1;
    const firstDay = month.getDay();

    // 前月の日付で最初に表示される日を割り出すための `setDate()` の引数
    let prevMonthStart = firstDate - firstDay;

    // 翌月の日付で最後に表示される日を割り出すための 'setDate()' の引数
    let nextMonthEnd = lastDate + (6 - lastDay);

    // 月と年を取得
    const currentYear = month.getFullYear();
    const currentMonth = month.getMonth();

    // カレンダーの配列を作る
    const dateList = [];
    for (let i = prevMonthStart; i <= nextMonthEnd; i++) {
      const currentDate = new Date(currentYear, currentMonth, i);
      dateList.push({
        year: currentDate.getFullYear(),
        month: currentDate.getMonth() + 1,
        date: currentDate.getDate(),
        day: currentDate.getDay()
      });
    }

    // カレンダーに何週分必要か計算
    const weekCount = Math.ceil(dateList.length / 7);

    this.setting.table_setting.rows = [];
    this.setting_orig.table_setting.rows = [];
 
    let tmp = {};
    dateList.filter((v) => {
      if(v.day == 0) {
        tmp = {};
      }

      if(v.month == currentMonth + 1) {
        if(type == 'week') {
          if(v.day == 0 || v.date == 1) {
            tmp.start = v.date;
          }
  
          if(v.day == 6 || lastDate == v.date) {
            tmp.end = v.date;

            this.setting.table_setting.rows.push(tmp);
            this.setting_orig.table_setting.rows.push(tmp);
          }
        }
  
        if(type == 'month') {
          
          if(this.setting.table_setting.rows.indexOf(v.date) == -1) this.setting.table_setting.rows.push(v.date);
          if(this.setting_orig.table_setting.rows.indexOf(v.date) == -1) this.setting_orig.table_setting.rows.push(v.date);
        }
      }
    });

    
    
    return true;
  }

  // 検索
  search() {
    //ローディングインスタンス
    this.loading = new TF.loading($('.js-bulk-change'));

    onlyNumeric();

    //検索ボタンクリック
    $('.js-bulk-change').on('click', (e) => {
      e.preventDefault();
      //ローディングスタート
      this.loading.start();
      //検索用クエリの初期化
      TF.search_query = {};
      //ユーザー選択用
      TF.selected_staff = [];
      //オフィス選択用
      TF.selected_office = [];
      //グループ選択用
      TF.selected_dept_unit = [];

      //各プルダウンリスト選択項目取得
      TF.search_query.selects = [];
      this.select_list_enable = 0;
      this.select_list_num = 0;
      this.select_call_status = [];
      this.select_call_counterpart = [];

      let select_columns = ['sel.customer_id','sel.tel_no','sel.call_time','sel.integration_id_1','sel.integration_id_2','sel.staff_id','sel.call_status','sel.call_counterpart','sel.duration','sel.call_type','sel.reg_date'];
      let url_groups = [];
      let group = [];
      let nn = 0;
      for(let i in this.select_list) {
        let ary = $(`#${this.select_list[i]}_select`).val();
        let n = ary? ary.length: 0;

        if(n > 0) {
          this.select_list_enable++;
          this.select_list_num += n;

          TF.search_query.selects[nn] = [];

          ary.filter((vv) => { 
            if(vv) {

              if(this.select_list[i] == 'office') {
                TF.selected_office.push(vv);
                for(let ii in TF.staff) {
                  if(TF.staff[ii].office_id == vv) {
                    group.push(`staff/${ii}`);
                    if(this.type == 'user') TF.selected_staff.push(ii);
                  } 
                }
              } else if(this.select_list[i] == 'dept_unit') {
                TF.selected_dept_unit.push(vv);
                let ary = [];
                for(let ii in TF.staff) {
                  if(TF.staff[ii].dept_unit_id == vv) {
                    ary.push(`staff/${ii}`);
                    if(this.type == 'user') TF.selected_staff.push(ii);
                  }
                }
                //グループに所属しているstaffがいなかったら
                if(ary.length == 0) ary.push(`staff/0`);
                group = group.concat(ary);
              } else if(this.select_list[i] == 'staff') {
                //staffIDを格納
                TF.selected_staff.push(vv);
                group.push(`staff/${vv}`);
                
              } else {

                switch(this.select_list[i]) {
                  case 'call_counterpart': {
                    this.select_call_counterpart.push(vv);
                    break;
                  }
                  case 'call_status': {
                    this.select_call_status.push(vv);
                    break;
                  }
                  default: {
                    group.push(`${this.select_list[i]}/${vv}`);
                    TF.search_query.selects[nn].push(`${this.select_list[i]}/${vv}`);
                    break;
                  }
                }
              }

              TF.search_query.selects[nn].push(`${this.select_list[i]}/${vv}`);
            }
          });
          nn++;     
        } 
      }

      if(group.length > 0) url_groups.push('(/' + group.join('/') + '/)');

      if(url_groups.length > 0) url_groups = url_groups.join('/'); 
      this.select_list_num *= this.select_list_enable;

      //検索クエリを構築
      let url = [TF.api.url + 'call_history'];
      let set_date = [];

      //日付指定
      if($('#duration-start, #duration-end')[0]) {

        let format = this.type == 'month'? 'YYYY/MM': 'YYYY/MM/DD';
        let moment_format = this.type == 'month'? 'YYYY-MM': 'YYYY-MM-DD';
        let add_format = this.type == 'month'? 'M': 'd';
        
        let date_start = $('#duration-start').val()? moment($('#duration-start').val(), format).format(moment_format): '';
        let date_end = $('#duration-end').val()? moment($('#duration-end').val(), format).add(1, add_format).format(moment_format): '';
  
        TF.search_query.date = {
          start: date_start,
          end: date_end
        };

        //架電日時開始日付
        if(TF.search_query.date.start) {
          set_date.push('call_time>=/' + TF.search_query.date.start);
        }
      
        //架電日時終了日付
        if(TF.search_query.date.end) {
          set_date.push('call_time</' + TF.search_query.date.end);
        }

        //架電日時一日指定
        if(TF.search_query.date.start && TF.search_query.date.end && TF.search_query.date.start == TF.search_query.date.end) {
          let end_date = moment(TF.search_query.date.end, format).add(1, add_format).format(moment_format);

          set_date = [];
          set_date.push('call_time>=/' + TF.search_query.date.start + '/call_time</' +  end_date);
        }
      }

      //月指定
      if($('#select-month')[0] && $('#select-month').val()) {
        let start_month = $('#select-month').val();
        let select_month = start_month? moment(start_month, 'YYYY/MM').format('YYYY-MM'): '';
        let next_month = moment(select_month, 'YYYY/MM').add(1, 'M').format('YYYY-MM');
        set_date.push('call_time>=/' + select_month + '/call_time</' +  next_month);
      }

      url.push(select_columns.join('/'));

      if(set_date.length > 0) url.push(set_date.join('/'));

      if(url_groups) url.push(url_groups);

      url = url.join('/');
      //カラム置換
      this.select_list.filter((v) => {
        if(url.indexOf('/staff/') > -1) url = url.split('/staff/').join('/staff_id=/');
        if(url.indexOf('/call_status/') > -1) url = url.split('/call_status/').join('/call_status=/');
        if(url.indexOf('/jobs/') > -1) url = url.split('/jobs/').join('/integration_id_2=/');
        if(url.indexOf('/area/') > -1) url = url.split('/area/').join('/integration_id_1=/');
        if(url.indexOf('/call_counterpart/') > -1) url = url.split('/call_counterpart/').join('/call_counterpart=/');
      });

      let urls_tmp = [url];

      //検索結果取得開始
      urls_tmp.filter((v) => {

        const opt = {
          url: v + TF.api.key,
          type: 'GET'
        }

        //実行結果
        const doFunc = (d) => {

          d = JSON.parse(d);

          //表示
          TF.report_data = d;

          this.graphClass();
          this.tableClass();
          TF.staff_report_data = {};
          
          //レポート設定のユーザー用設定と業種カテゴリ用の設定を除外
          for(let tab in this.setting.tabs) {
            let tmp = [];

            for(let i in this.setting.tabs[tab].data) {
              //ユーザー用設定除外
              if(!this.setting.tabs[tab].data[i].staff_id) {
                tmp.push(this.setting.tabs[tab].data[i]);
              }
            }
            //業種カテゴリ用設定除外
            if(tab != 'jobs_category') this.setting.tabs[tab].data = tmp;
          }
          //ユーザーレポートを削除
          $('.p-report-content-userdata-wrap, .p-report-child-content-userdata-wrap').remove();

          //レポートを再初期化
          this.pageset[this.type]();
          this.loading.end();
        }

        const failFunc = (d) => {
          console.log(d);
          message('データを取得できませんでした。',1);
          this.loading.end();
        }

        getApiDataCommon(opt, doFunc, failFunc);
      });
    });

  }
}