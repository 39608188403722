/**音源再生用**/
//初期化
function playerInit(src) {
  const a = audiojs.createAll({});
  const audio = a[0];
  const filename = src || $('#player').data('src');
  const fileurl	= TF.mp3dir + filename + '.mp3';
  audio.load(fileurl);
}

function playerCss() {

  const audio_css = '\
  <style>\
    #player { width: 100%; }\
    .p-data-btn-area.-right #player { width: 200px; display: inline-block; }\
    /*　プレイヤー（再生バー）の高さ、幅、背景色の指定　*/\
    .audiojs { height: 22px; background: #adadad; width: 100%; box-shadow:none; font-size: 10px;}\
    .p-data-btn-area.-right #player .audiojs { height: 25px; }\
    /*　再生・停止ボタンの高さ、幅、パディングの指定　*/\
    .audiojs .play-pause { width: 25px; height: 25px; padding: 0px 8px 0px 0px; background: #626262; border-right: 0;}\
    .p-data-btn-area.-right #player .audiojs .play-pause { height: 25px; }\
    /*　再生・停止ボタンなどの p 要素　*/\
    .audiojs p { width: 25px; height: 20px; margin: -3px 0px 0px -1px; }\
    .p-data-btn-area.-right #player .audiojs p { margin: -1px 0px 0px -1px; }\
    /*　進行状態・ロードの状態を表示する部分　*/\
    .audiojs .scrubber { background: #ffffff; width: calc(100% - 135px); height: 6px; margin: 8px 5px; border-top: 0; }\
    .p-data-btn-area.-right #player .audiojs .scrubber { margin: 9px 5px;}\
    /*　進行状態を表示するバーの部分　*/\
    .audiojs .progress { height: 10px; width: 0px; background: #0184ba; }\
    /*　ロード状態を表示するバーの部分　*/\
    .audiojs .loaded { height: 10px; background: #cfe7f1;}\
    /*　再生時間の部分　*/\
    .audiojs .time { float: left; height: 25px; line-height: 25px; margin: 0px 0px 0px 6px; padding: 0px 6px 0px 0px; border-left: 0; text-shadow: none; color: #ffffff;}\
    .audiojs .error-message { height: 24px;line-height: 24px; }\
      /*　再生している曲の情報　*/\
    .track-details { clear: both; height: 20px; width: 280px; padding: 1px 6px; background: #eee; color: #222;  font-size: 11px; line-height: 20px;}\
    .track-details:before { content: "♬  Now Playing: "; }\
  </style>';

  $('head').append($(audio_css));
  
}