/****************** 
レポート画面初期設定
*******************/
function reportInit(setting) {

  //HTML修正前調整
  if(location.href.match(/(report$|hour|week|month|area)/) && !$('a[href="#jobs_category"].p-report-child-tab-item-link.js-tab')[0]) {
    let elm = '<li class="p-report-child-tab-item"><a href="#jobs_category" class="p-report-child-tab-item-link js-tab">業種</a></li>';
    $('a[href="#jobs"].p-report-child-tab-item-link.js-tab').text('業種（旧）').parent().before(elm);
  }

  if($('.p-data-select-toolbox .p-data-btnbox')[0]) {
    $('.p-data-select-toolbox .p-data-btnbox:eq(0)').after(`<div class="p-data-btnbox">
      <a href="javascript:void(0)" onclick="location.reload();return false;" class="p-data-btn -reset">
        <span>リセット</span>
      </a>
</div>`);
  }

  if(location.href.match(/\/[^#]+jobs/)) {
    $('.p-report-header-inner h3').text('業種別レポート');

    if(!$('.-call_status-old')[0] && !$('.-area-old')[0]) {
      $('.p-report-content.-call_counterpart-old').after(`<div class="p-report-content -call_status -old -call_status-old">
  <div class="p-report-content-data -table"></div>
  <div class="p-report-content-data -bar -wide"></div>
</div>
<div class="p-report-content -area -old -area-old">
  <div class="p-report-content-data -table"></div>
  <div class="p-report-content-data -bar -wide"></div>
</div>`);
    }


    if(setting.tabs.call_status.data[0].dist != '.-call_status.-old .p-report-content-data.-table') setting.tabs.call_status.data[0].dist = '.-call_status.-old .p-report-content-data.-table';
    if(setting.tabs.call_status.data[1].dist != '.-call_status.-old .p-report-content-data.-bar')setting.tabs.call_status.data[1].dist = '.-call_status.-old .p-report-content-data.-bar';
    if(setting.tabs.area.data[0].dist != '.-area.-old .p-report-content-data.-table')setting.tabs.area.data[0].dist = '.-area.-old .p-report-content-data.-table';
    if(setting.tabs.area.data[1].dist != '.-area.-old .p-report-content-data.-bar')setting.tabs.area.data[0].dist = '.-area.-old .p-report-content-data.-bar';
  }

  new Calender(setting.calender_type);


  const getsit = setInterval(() => {
    if(typeof GetData == 'function') {
      clearInterval(getsit);
      TF.GetData = new GetData();
      TF.GetData.getOfficeList();
      TF.GetData.getGroupList();
      TF.GetData.getJobsList();
      //ローディングインスタンス
      TF.loadingObj = new TF.loading($('body'));
      TF.loadingObj.start();
    }
  }, TF.intervaltime);

  const sit = setInterval(() => {
    if(TF.call_counterpart && TF.call_status && TF.staff && TF.office && TF.dept_unit ) {
      clearInterval(sit);
      TF.call_counterpart = JSON.parse(TF.call_counterpart);
      TF.call_status = JSON.parse(TF.call_status);

      TF.call_counterpart = TF.call_counterpart.filter(v => (v.client_id|0) == 2);


      let tmp = [];
      for(let i in TF.call_status) {
        if(TF.call_status[i].client_id == 2) tmp.push(TF.call_status[i]);
      }
      TF.call_status = tmp;
      
      //TF.GetData.getJobsList();
      TF.GetData.getAreaList();

      TF.set_select2_list = {};
      setSelectList();
    }
  }, TF.intervaltime);

  const tab = function(e) {
    //console.log(e.preventDefault);
    //if(e) e.preventDefault();

    //e.preventDefault();
    //タブ切り替え
    if(location.hash.indexOf('userdata') > -1 && !e) return false;
    if(location.hash || e) {
      let elm = $(e?e.currentTarget:'.js-tab[href="' + location.hash + '"]');

      if(elm.parents('.-grandchild')[0]) {
        
        let tab = '';
        if(location.hash.indexOf('jobs_of') > -1) {
          
          $('.p-report-child-tab:eq(0) .js-tab').removeClass('is-current');
          $('.p-report-content').css('display', 'none');

          if(location.hash.match(/^#(userdata[0-9]+?_)?_/)) {
            tab = 'jobs_category';
            $('.p-report-content.-' + tab).css('display', 'flex');
          }

          if(!location.hash.match(/^#(userdata[0-9]+?_)?_/)) {
            tab = location.hash.replace('#', '').replace(/_jobs_of_[0-9]+/, '');
          }

          $('.p-report-child-tab:eq(0) .js-tab[href="#' + tab + '"]').addClass('is-current');
          $('.p-report-content.-' + tab).css('display', 'flex');
        }

        let hash = elm.attr('href').replace('#', '');

        elm.parents('.p-report-content').find('.p-report-child-content').css('display', 'none');
        $('.p-report-child-content.' + hash).css('display', 'block');
        elm.parents('.p-report-child-tab').find('.js-tab').removeClass('is-current');
        elm.addClass('is-current');

        $('.-jobs_category-anchor').hide();
        $('.-jobs_category-anchor[href*="' + hash + '"]').show();

      } else {
        $('.p-report-content').css('display', 'none');
        $('.p-report-child-tab:eq(0) .js-tab').removeClass('is-current');

        const attr = elm.addClass('is-current').attr('href').replace('#', '');
        let display = 'flex';
        
        //display: block 
        if(attr == 'jobs' || attr == 'area') display = 'block';
        if(location.href.match(/\/?\-?jobs|\/?\-?area/) && (attr == 'call_counterpart' || attr == 'call_status')) display = 'block';
  
        $('.p-report-content.-' + attr).css('display', display);
      }


      //$('.p-report-content.-' + attr).find('canvas').height(500);
    }
    
    return false;
  };



  //データ切り替え
  if(CURRENT_USER.auth_level >= 3) {
  //if(true) {
    TF.selected_staff = [String(CURRENT_USER.telforce_id)];
    TF.report_mode = 1;
    let btn = `<div class="p-data-toggle">
    <input type="checkbox" id="switch_data" class="p-data-toggle-checkbox js-data-toggle" checked>
    <label for="switch_data">
      <span class="u-text -cond1">全体</span>
      <span class="u-text -cond2">個人</span>
    </label>
  </div>`;
    $('.p-data-select-toolbox').prepend(btn);



    $('#switch_data').on('change', (e) => {
      if(TF.report.obj) {
        let loading = new TF.loading($('#switch_data'));
        loading.start();
        setTimeout(() => {
          TF.selected_staff = (!$(e.currentTarget).prop('checked'))? []: [String(CURRENT_USER.telforce_id)];
          TF.report.obj.graphClass();
          TF.report.obj.tableClass();
          TF.staff_report_data = {};
          $('.p-report-content-userdata-wrap').remove();
          TF.report.obj.pageset[TF.report.obj.type]();

          if($('.p-report-content-userdata-wrap')[0]) {
            let position = $('.p-report-content-userdata-wrap:eq(0)').offset().top;
            $("html, body").animate({scrollTop:position}, 300, "swing");
          }
          loading.end();
        }, TF.intervaltime * 20);
      }
    });
  }

  const sit2 = setInterval(() => {
    if(TF.call_counterpart && TF.call_status && TF.staff && TF.office && TF.dept_unit && TF.report_data) {
      clearInterval(sit2);
      TF.loadingObj.end();
      TF.report_data = JSON.parse(TF.report_data);
      TF.report_data_copy = TF.report_data;
      TF.report.obj = new Report(setting);
      TF.report.obj.init();

      tab();
      $('.js-tab').on('mousedown tap', tab);
    }
  }, TF.intervaltime);

}


//初期データ取得
function getReportData(query) {
  const get_data = new GetData();
  get_data.getApiData('report_data', query);
}