
/****************** 
 * データ取得
*******************/
//エラー回避用
function reGetData(type) {
  const sit = setInterval(() => {
    if(typeof GetData == 'function') {
      clearInterval(sit);
      new GetData(type);
    }
  }, TF.intervaltime);
}

const GetData = class {
  constructor(type) {
    this.type = type;
    this.proc();
  }

  proc() {
    switch(this.type) {
      case 'staff': {
        this.getUserList();
        break;
      }
      case 'call_status': {
        this.getStatusList();
        break;
      }
      case 'call_counterpart': {
        this.getCounterpartList();
        break;
      }
      case 'call_log': {
        this.getCallLogList();
        break;
      }
      case 'shukujitsu': {
        this.getShukujitsu();
        break;
      }
    }
  }
  
  //ユーザー情報
  getStaffList() {
    TF.staff = TF.staff;

    $.ajax( {
      url: TF.api.url,
      type: 'GET',
      data: {
        method: 'GET',
        query: [
          'staff',
        ],
      },
    })
    .done((data) => {
      TF.staff = data;
    });
  }
    

  //ユーザー情報取得
  getStaffInfo(id) {
    for(let i in TF.staff) {
      if((TF.staff[i].login_id|0) == (id|0)) return TF.staff[i];
    }
  }

  //商材
  getCounterpartList() {
    //TF.call_counterpart = [];
    TF.call_counterpart = null;

    $.ajax( {
      url: TF.api.url + 'call_counterpart/(/client_id=/0/client_id=/2/)' + TF.api.key,
      type: 'GET',
    })
    .done((data) => {
      TF.call_counterpart = JSON.parse(data);
    });
  }

  //架電ステータス取得
  getStatusList() {  
    TF.call_status = [];

    $.ajax( {
      url: TF.api.url + 'call_status' + TF.api.key,
      type: 'GET',
    })
    .done((data) => {
      TF.call_status = JSON.parse(data);
    });
  }


  //架電ステータス取得
  getStatusListAll() {  
    TF.call_status_all = [];

    $.ajax( {
      url: TF.api.url + 'call_status' + TF.api.key,
      type: 'GET',
    })
    .done((data) => {
      TF.call_status_all = JSON.parse(data);
    });
  }

  //オフィス取得
  getOfficeList() {
    //TF.office = OFFICE || [];
    TF.office = OFFICE;
    return;
  }

  //グループ取得
  getGroupList() {
    //TF.dept_unit = DEPT_UNIT || [];
    TF.dept_unit = DEPT_UNIT;
    return;
  }

  //業種取得

  getJobsList() {
    TF.jobs = TF.jobs || JOBS;
    TF.jobs_category = [];
    TF.jobs_category_list = [];
    if(typeof COUNTERPARTS == 'undefined') {
      window.COUNTERPARTS = [];
    } 

    if(typeof DEV_COUNTERPARTS == 'object') {
      //COUNTERPARTS = COUNTERPARTS.concat(DEV_COUNTERPARTS);
      //COUNTERPARTS = DEV_COUNTERPARTS;
    }

    if(COUNTERPARTS.length > 0) {

      for(let i in COUNTERPARTS) {
        let counterpart_id = COUNTERPARTS[i].id;
        let counterpart = COUNTERPARTS[i].name;

        if(COUNTERPARTS[i].categories.length > 0) {
          for(let ii in COUNTERPARTS[i].categories) {
            if(COUNTERPARTS[i].categories[ii].jobs && COUNTERPARTS[i].categories[ii].jobs.length > 0) {
              let jobs = COUNTERPARTS[i].categories[ii].jobs;
              let category_id = COUNTERPARTS[i].categories[ii].id;
              let cat = COUNTERPARTS[i].categories[ii].name;

              TF.jobs_category_list.push({id: category_id, name: cat, counterpart_id: counterpart_id, counterpart: counterpart, jobs: jobs});

              for(let iii in jobs) {
                TF.jobs_category.push({id:jobs[iii].id, name: jobs[iii].name, category_id: category_id, category: cat, counterpart_id: counterpart_id, counterpart: counterpart}); 
              }
            }
          }
        }
      }
 
    }
/*
    TF.job_relations = {};
    for(let i in TF.jobs) {
      let job = TF.jobs[i];

      if(job.job_relations && job.job_relations.length > 0) {
        for(let ii in job.job_relations) {
          let relations = job.job_relations[ii];
          let call_counterpart_id = relations.call_counterpart;

          if(typeof TF.job_relations[call_counterpart_id] == 'undefined')  TF.job_relations[call_counterpart_id] = [];

          let job_name = job.id == relations.job_id? job.name: '';
          
          if(TF.job_relations[call_counterpart_id].length > 0) {
            let obj = TF.job_relations[call_counterpart_id].filter(v => v.id == relations.job_id);
            if(obj.length > 0) {
              continue;
            } 
          }

          TF.job_relations[call_counterpart_id].push({id: relations.job_id, name: job_name});

        }
      }
    }*/

    
    return TF.jobs;
  }

  //各業種取得
  getJobsInfo(id) {
    return TF.jobs.find((v) => v.id === id);
  }

  //エリア取得
  getAreaList() {
    TF.area = TF.area || [];
    return TF.area;
  }

  //各エリア取得
  getAreaInfo(id) {
    return TF.area.find((v) => v.id === id);
  }

  //コールログ
  getCallLogList() {
    var query = TF.api.url;
    var last = 2018;
    var current_year = moment().format('YYYY')|0;
    var current = moment().format('YYYY-MM-DD');
    var half = [[['-01-01'],['-06-30']],[['-07-01'],['-12-31']]];
    var ajax_objs = [];
    var history_data = [];
    var count = 0;
    var max = (current_year - last) * 2;

    for(var i = 0; i <= current_year - last; i++ ) { 
      
      var year = current_year - i;

      for(var ii in half) {
        var tmp = [];

        if((CURRENT_USER.auth_level|0) <= 2) {
          tmp.push('call_history/sel.id');
        }
      
        if((CURRENT_USER.auth_level|0) == 3) {
          tmp.push('call_history/sel.id/staff_id=/' + CURRENT_USER.telforce_id);
        }

        tmp.push('reg_date>=/' + year + half[ii][0] + '/reg_date<=/' + year + half[ii][1]);

        var url = tmp.join('/');
        
        ajax_objs.push({ 
          opt: {
            url: query + url + TF.api.key,
            type: 'GET',
          },
    
          doFunc: function(data) {
            data = JSON.parse(data);
            count++;
            history_data.concat(data);
            console.log(history_data)
          },
    
          failFunc: function(err) {
            console.log(err);
          }
        });
      }
    }
    

    if(CURRENT_USER.auth_level <= 3) {
      for(var i in ajax_objs) {
        getApiDataCommon(ajax_objs[i].opt, ajax_objs[i].doFunc, ajax_objs[i].failFunc);
      }
  
      var sit = setInterval(() => {
        console.log(count , max);
        if(count == max) {
          clearInterval(sit);
          history_data.sort(
            function(a,b){
              return ((a.id|0) < (b.id|0) ? 1 : -1);
            }
          );
          
          TF.history_id = history_data;

        }
      }, TF.intervaltime);

   
    } else {
      alert('残念ながら閲覧権限がありません');
    }
  }

  //再コールリスト
  getRecallList(start, end) {
    start = start?start:0;
    end = end?end:TF.limit;

    //再コールフラグのあるものだけ取得するURL
    let get_api = TF.api.url + 'call_history/sel.id/upd_date>=/2017-01-01/recall_flag=/1/' + TF.api.talken;

    //再コールフラグのあるものだけ取得
    $.ajax({
      url: get_api,
      type: 'POST',
      dataType: 'json',
    })
    .done((data) => {
      
      TF.list_contents = data;
      //再コール日時で降順でソート
      TF.list_contents.sort(function(a,b){
        return new Date(b.recall_time).getTime() - new Date(a.recall_time).getTime();
      });

      const sit = setInterval(() => {
        if(TF.staff && TF.call_status && TF.call_counterpart) {

          //取得したデータを再コール日時降順でソート        
          TF.list_contents.sort(function(a,b){
            return new Date(b.recall_time).getTime() - new Date(a.recall_time).getTime();
          });
          
          clearInterval(sit);
          TF.List.printList();
        }
      }, TF.intervaltime);
    });
  }

  //汎用
  getApiData(key, path) {
    $.ajax({
      url: path,
      type: 'POST',
      dataType: 'json',
      //jsonpCallback: 'callback'
    })
    .done((data) => {
      TF[key] = data;
    });
  }

  getAndPutData(data, name) {
    
    if(!data || !name) return;

    if(CURRENT_USER && (CURRENT_USER.auth_level|0) <= 2) {
      $.ajax({
        url: TF.js_url_path + 'data/index.php',
        type: 'POST',
        data: {
          name: name,
          data: data
        }
      })
      .done((data) => {
        console.log(data);
      })
      .fail((err) => {
        console.log(err);
      })
    }
  } 

  //祝日取得用
  getShukujitsu(type) {

    // 祝日取得
    let scrape = (data) => {
      TF.calender.shukujitsu = typeof data == 'string' ? this.convertCSVToArray(data): data;
      let date = new Date();
      let num = date.getFullYear() - 0 - 2;
      let tmp = [];
      TF.calender.shukujitsu.filter((v) => {
        let y = v[0].split('/')[0];
        if(y - 0 >= num) {
          tmp[v[0]] = v;
        }
        TF.calender.shukujitsu = tmp;
      });
    };

    if(typeof SHUKUJITSU == 'object') {
      scrape(SHUKUJITSU);
    } else {
      TF.calender.shukujitsu = {'9999/99/99': ["9999/99/99", ""]};
    }
  }

  // 読み込んだCSVデータを二次元配列に変換する関数convertCSVtoArray()の定義
  convertCSVToArray(str) { // 読み込んだCSVデータが文字列として渡される
    var result = []; // 最終的な二次元配列を入れるための配列
    var tmp = str.split("\n"); // 改行を区切り文字として行を要素とした配列を生成

    // 各行ごとにカンマで区切った文字列を要素とした二次元配列を生成
    for(var i=0;i<tmp.length;++i){
        result[i] = tmp[i].split(',');
    }

    return result;
  }
}

