
/***********
  utility
***********/
function onlyNumeric() {
  $('input.-onlynumeric').on('input', function(e) {
    //半角数字以外除去して返す
    let num = $(this).val()
    num = hankaku2Zenkaku(num); // 全角数字を半角に
    if(location.href.indexOf('call_log') > -1) {
      num = num.replace(/[^0-9 ]/g, '');
    } else {
      num = num.replace(/[^0-9]/g, '');
    }
    $(this).val(num);
  });
}

//全角数字を半角に変換
function hankaku2Zenkaku(str) {
  return str.replace(/[０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });
}

//タイムスタンプ変換
function dateConverter(timestamp, format) {
  const d = new Date( timestamp - 0);
  const year  = d.getFullYear();
  const month = d.getMonth() + 1;
  const day  = d.getDate();
  const hour = ( d.getHours()   < 10 ) ? '0' + d.getHours()   : d.getHours();
  const min  = ( d.getMinutes() < 10 ) ? '0' + d.getMinutes() : d.getMinutes();
  const sec   = ( d.getSeconds() < 10 ) ? '0' + d.getSeconds() : d.getSeconds();
  
  let date = '';

  // formatで表示切り替え
  switch(format) {
    case 'list': {
      date = year + '/' + month + '/' + day + ' ' + hour + ':' + min;
      break;
    }
    default: {
      date = year + '-' + month + '-' + day + ' ' + hour + ':' + min + ':' + sec;
    }
  }

  return date;
}

// メッセージ表示
function message(mes, err = false, obj) {
  TF.cto;
  let _obj = obj || {};
  let settime = _obj.settime || 3000;


  if(mes == 'del') {
    $('.p-message').remove();
    return false;
  }

  if(typeof mes == 'string') {
    mes = [mes];
  }

  if(_obj && typeof _obj == 'object') {
   
    if(_obj.class) { 
      let elm = $('<div class="p-message" />', {
        class: 'p-message' 
      }).append($('<span>', {
        class: 'u-text',
        html: mes[0]
      })).addClass(_obj.class);

      $('body').append(elm);
      elm.addClass('is-active');

      TF.cto = setTimeout(() => {
        elm.removeClass('is-active').on('transitionend', (e) => {
          elm.remove();
        });
      }, settime);
      return;
    }
    
  }


  for(let i in mes) {
    let elm;
    if(!$('.p-message')[0]) {
      elm = $('<div class="p-message -message' + i + '" />').append('<span class="u-text">');
    
      if(err) {
        elm.addClass('-error');
      }
      
      $('body').append(elm);
    
      elm.find('.u-text').html(mes[i]);
      elm.addClass('is-active');
    
      TF.cto = setTimeout(() => {
        elm.removeClass('is-active').on('transitionend', (e) => {
          elm.remove();
        });
      }, settime);

    } else {
      if(TF.cto) {
        clearTimeout(TF.cto);
      }

      elm = $('.p-message');
      elm.find('.u-text').append('<br>' + mes[i]);
      if(err) {
        elm.addClass('-error');
      }
      TF.cto = setTimeout(() => {
        elm.removeClass('is-active').on('transitionend', (e) => {
          elm.remove();
        });
      }, settime);
    }
  }
}
/*
// Ajaxフック
function addXMLRequestCallback(callback){
  var oldSend, i;

  if( XMLHttpRequest.callbacks ) {
      // we've already overridden send() so just add the callback
      XMLHttpRequest.callbacks.push( callback );
  } else {
      // create a callback queue
      XMLHttpRequest.callbacks = [callback];
      // store the native send()
      oldSend = XMLHttpRequest.prototype.send;
      
      // override the native send()
      XMLHttpRequest.prototype.send = function(){
          // process the callback queue
          // the xhr instance is passed into each callback but seems pretty useless
          // you can't tell what its destination is or call abort() without an error
          // so only really good for logging that a request has happened
          // I could be wrong, I hope so...
          // EDIT: I suppose you could override the onreadystatechange handler though
          for( i = 0; i < XMLHttpRequest.callbacks.length; i++ ) {
              XMLHttpRequest.callbacks[i]( this );
          }
          // call the native send()
          oldSend.apply(this, arguments);
      }
  }
}

// e.g.
addXMLRequestCallback( function( xhr ) {
  console.log('xhr', xhr);
  if(xhr.responseURL.indexOf('/browser-sync/') > -1) return;

  xhr.onloadstart = function(d) {
    if(d.srcElement.responseURL.indexOf('/browser-sync/') > -1) return;
    console.log('onloadstart', arguments);
    message('ロード開始 ' + d.target.responseURL);
  }

  xhr.onloadend = function(d) {
    if(d.currentTarget.responseURL.indexOf('/browser-sync/') > -1) return;
    console.log('onloadend', d);
    message('ロード終了 ' + d.currentTarget.responseURL);
  }
  //console.log( xhr.responseText ); // (an empty string)
});
*/

function l(value) {
  console.log(value);
}

function getUserInfoForUtility() {
  try {
    if(CURRENT_USER) {
      $.cookie('url_origin', TF.api.url.replace('/rest/', ''), {
        path: '/'
      });
      $.cookie('api_key', TF.api.key.replace('/?key=', ''), {
        path: '/'
      });
      $.cookie('login_id', CURRENT_USER.login_id, {
        path: '/'
      });
      $.cookie('auth_level', CURRENT_USER.auth_level, {
        path: '/'
      });
      $.cookie('data_url', TF.js_url_path, {
        path: '/'
      });
      $.cookie('interface', 'Call in', {
        path: '/'
      });

      
      $('body').addClass('setinfo');
    }
  } catch(err) {
    console.log(err);
  }
}

function unicodeEscape(str) {
  if (!String.prototype.repeat) {
      String.prototype.repeat = function(digit) {
          var result = '';
          for (var i = 0; i < Number(digit); i++) result += str;
          return result;
      };
  }

  var strs = str.split(''), hex, result = '';

  for (var i = 0, len = strs.length; i < len; i++) {
      hex = strs[i].charCodeAt(0).toString(16);
      result += '\\\\u' + ('0'.repeat(Math.abs(hex.length - 4))) + hex;
  }
  return result;
}