/****************** 
 * コール画面初期アクション
*******************/
function callInit() {
  let elm = $('.p-call-history-detail-list');

  //elm.html(elm.html().split('禁止チェック').join('禁止<br>チェック'));
  //elm.html(elm.html().split('架電予定日時').join('架電予定<br>日時'));
  //elm.html(elm.html().split('ステータス').join('ステー<br>タス'));
  //elm.html(elm.html().split('応対者').join('商材'));
  //$('#tel_no').attr('maxlength', 13);

  TF.CallEntry = new CallEntry();
}

const CallEntry = class {
  constructor() {

    //架電履歴登録ルーチン初期化
    const sit = setInterval(() => {
      if(TF.call_counterpart && TF.call_status && TF.staff) {
        clearInterval(sit);
        $('.p-call-history-detail-list-item-dd').text('-');
        $('#tel_no').attr({id:'',name:''});

        TF.call_counterpart = JSON.parse(TF.call_counterpart);
        TF.call_status = JSON.parse(TF.call_status);

        this.GetData = new GetData();
        this.GetData.getStatusListAll();
        this.GetData.getJobsList();
        this.printCounterpartList();
        this.printStatusList();
        this.printPrefectureList();

        $('.js-btn-reset').addClass('call_entry');
        $('.p-call-history-list').wrap('<div class="p-call-history-list-wrap">');

        this.wait_call_Interval;
        this.input_wait;

        $('#tel_no').on('keydown', (e) => {
          if(e.key == 'Enter') {
            this.telnoCheck(e);
          }
        });

        $('.js-pref').click((e) => {
          let id = $(e.currentTarget).data('id');
          let _name = $(e.currentTarget).text();
          $('#area_select').val(id);
          $("#search_pref").val(_name.trim());
        });

      }
    }, TF.intervaltime); 
  }

  //商材出力
  printCounterpartList() {
    if(typeof TF.call_counterpart == 'undefined') {
      console.log('商材リストが取得されていません');
    } else {
      const call_counterpart = TF.call_counterpart;

      let list = [];
      for(let i = 0, n = call_counterpart.length; i < n; i++ ) {
        if(i == 0) list[0] = '<ul class="p-input-radio-list">';
        if(call_counterpart[i].active_flag == '1') {
          list[0] += '<li class="p-input-radio-list-item"><label><input type="radio" name="call_counterpart" value="' + call_counterpart[i].id + '"><span class="u-text">' + call_counterpart[i].name + '</span></label></li>';
        }
        if(i + 1 == call_counterpart.length) list[0] += '</ul>';
      }
      
      list = list.join('\n');
      $('.p-input-box.-counterpart .p-input-radio-list').remove();
      $('.p-input-box.-counterpart').append($(list));
      this.getAllJobData();
    }
  }

  //架電ステータス出力
  printStatusList() {
    if(typeof TF.call_status == 'undefined') {
      console.log('ステータスリストが取得されていません');
      return
    } 
    
    if(typeof TF.status_sort == 'undefined') {
      console.log('ステータスの並び順が設定されていません');
      return
    } 

    if(TF.call_status && TF.status_sort) {
      const call_status = TF.call_status;
      let status_sort = TF.status_sort;
      let names = [];
      let sonota_list = [];

      //名前だけ抽出
      for(let i in status_sort) names.push(status_sort[i].name);

      //それぞれのリストに格納    
      for(let ii = 0, nn = call_status.length; ii < nn; ii++ ) {
        for(let i in status_sort) {

          if(
            sonota_list.indexOf(call_status[ii].name) == -1 &&
            names.indexOf(call_status[ii].fixed_phrase) == -1 &&
            i != "sonota"
          ) {
            sonota_list.push(call_status[ii].name);
            status_sort.sonota.list.push(call_status[ii]);
            continue;
          }

          if(status_sort[i].name == call_status[ii].fixed_phrase) {
            status_sort[i].list.push(call_status[ii]);
          }
        }
      }
  
      let list = '';

      let table = `<table class="p-input-table">`;

      for(let i in status_sort) {
        table += `<tr class="p-input-table-tr ${i}"><td class="p-input-table-td">${status_sort[i].name}</td><td class="p-input-table-td">`;

        for(let ii = 0, nn = status_sort[i].list.length; ii < nn; ii++ ) {
          const item = status_sort[i].list[ii];

          if(ii == 0) {
            const ul_class = i == 'kinshi'? ' -ban': '';
            list = `<ul class="p-input-radio-list${ul_class}">`;
          }
          
          if(item.active_flag == '1') {
            const label_class = i == 'mikomi'? ' class="js-modal -mikomi"':'';

            list += `<li class="p-input-radio-list-item"><label${label_class} data-name="${item.name}"><input type="radio" name="status" data-statustype="${i}" value="${item.id}"><span class="u-text">${item.name}</span></label></li>`;
          }
          if(ii + 1 == nn) list += '</ul>';
        }
        table += `${list}</td></tr>`;
      }

      table += '</tr></table>';

      //list = list.join('\n');
      $('.p-input-box.-status .p-input-radio-list').remove();
      $('.p-input-box.-status').append(table);
      
      //モーダル設定（見込み詳細）
      modalInit('.-mikomi');
      //モーダル設定（登録内容チェック）
      modalInit('.-history-check');

      //架電履歴登録ルーチン初期化
      const sit = setInterval(() => {
        if(TF.call_counterpart) {
          clearInterval(sit);
          this.historyDataEntry();
        }
      }, TF.intervaltime);
      
    }
  }

  /**コール画面用**/

  /****************** 
   * 電話番号チェック
  *******************/

  telnoCheck(_this) {
    
    //リクエスト発生前にキャンセル
    clearTimeout(this.wait_call_Interval); 

    let telno = $(_this.currentTarget).val();

    //局番が区切られていれば局番だけ取得
    let area_code = '';
    if(telno.match(/[\-ー 　]/g)) {
      area_code = telno.replace(/[\-ー 　]/g, '-');
      area_code = hankaku2Zenkaku(area_code);// 全角数字を半角に
      area_code = area_code.split('-')[0];
    }

    if(telno.match(/[^0-9０-９]+/g)) {
      //半角数字以外除去する
      telno = hankaku2Zenkaku(telno);// 全角数字を半角に
      telno = telno.replace(/[^0-9]+/g, '');
      $(_this.currentTarget).val(telno);
      //return false;
    }

    if(telno.length > 13) {
      telno = telno.substr(0, 13);
      $(_this.currentTarget).val(telno);
      //return false;
    }
    
    //半角数字以外除去する
    telno = hankaku2Zenkaku(telno); // 全角数字を半角に
    telno = telno.replace(/[^0-9]+/g, '');


    // 履歴データにセット
    TF.history.tel_no = telno;

      let idens = [];
      let iden = '';
      //let area_select = $('#area_group_select');
      for(let i = 0, n = TF.area_codes.length; i < n; i++) {
        iden = TF.area_codes[i].identification + '';
        iden = (iden.indexOf('0') == 0)? iden : '0' + iden;

        let reg = new RegExp(`^${iden}`, '');
        
        if(telno.match(reg)) {
          idens.push([iden, TF.area_codes[i].prefecture_code|0]);
        }
      }

      idens.sort(
        function(a,b){
          return (a[0] < b[0] ? 1 : -1);
        }
      );
      if(
        (idens.length > 0 && telno.length > idens[0][0].length) ||
        area_code
        ) {
        message('del');
      }
      
      if(idens.length > 0) {

        let check = [];
        let check_area_code = [];
        idens.filter((v) => {
          //if(v[0].length == telno.length) {
            check.push(v[1]);
          //}
          
          //局番の文字数が一緒だったら
          if(area_code && v[0].length == area_code.length) {
            check_area_code.push(v[1]);
          }

          /*if(v[0].length < telno.length) {
            let num = telno[v[0].length];
          }*/
        });
        
        //ユニーク化
        check = Array.from(new Set(check));

        //局番がセットされていればユニーク化
        if(check_area_code.length > 0) {
          check_area_code = Array.from(new Set(check_area_code));
        }

        //複数エリアがマッチしていれば
        if(check.length > 1 && check_area_code.length > 1) {

          message('del');
          let name = 'この市外局番に該当するエリアが複数あります。';
          message(name);
          for(let i in check) {
            name = $('.js-pref[data-id="' + check[i] + '"]').text();
            message(name);
          }
          name = "いずれか選択してください。";
          message(name);
        } else {
          message('del');
        }

        let id = idens[0][1];
        //局番が一つのエリアのみマッチしていれば
        if(check_area_code.length == 1) {
          id = check_area_code[0];
        }

        let pref_name = TF.area_codes.filter((v) => {
          if((v.prefecture_code|0) == (id|0)) {
            return v;
          }
        })[0].prefecture_name;

        TF.history.area = id;
        $('#area_select').val(id);

        if(typeof MODELS == 'object') {
          MODELS.prefecture_name = pref_name;
          MODELS.prefecture_identity = id;
        }
      }
      /*
      if(idens.length > 0) {

      }*/

      idens = [];
    //}


    //入力値が5ケタ以下かつ確定していたら 
    if(telno.length <= 6 && TF.area_code_ok) {
      TF.area_code_ok = false;
      TF.area_code = null;
      return false;
    }

    //6ケタ以上の数字が入力されたら市外局番はヒットするしないにかかわらず確定して局番IDを返す
    if(telno.length > 6 ) {
        
      //市外局番IDがundefinedだったらno_idをセットする
      if(typeof TF.area_code == 'undefined') {
        TF.area_code = 'no_id';
      }

      //10ケタ以上13桁の数字が入力されたらAPIになげる
      if(telno.length >= 10 && telno.length <= 13) {
        $('.-input-tel-no .-alert').remove();
        
        //電話番号確定して返す
        //半角数字以外除去する
        telno = $(_this.currentTarget).val();
        telno = hankaku2Zenkaku(telno); // 全角数字を半角に
        telno = telno.replace(/[^0-9]+/g, '');
        $(_this.currentTarget).val(telno);

        //電話番号確定まで待つ
        this.wait_call_Interval = setTimeout(() => {
          if(TF.ajax_obj && TF.ajax_obj.call_history && TF.ajax_obj.customer) {
            TF.ajax_obj.call_history.abort();
            TF.ajax_obj.customer.abort();
          }

          TF.call_history = [];
          TF.call_history_flag = false;
          TF.customer = [];
          TF.ajax_obj = {};
        
          

          //該当履歴取得
          let get_api = `${TF.api.url}call_history/duration>/0/tel_no=/${telno}${TF.api.key}`;
          let opt = {
            url:  get_api,
            type: 'GET',
          };

          let doFunc = (data) => {
            //	0455728588
            TF.call_history_flag = true;
            //TF.call_history = JSON.parse(data);
            TF.call_history = TF.call_history.concat(JSON.parse(data));
            TF.call_history.sort(function(a, b) {
              let a_time = a.call_time.replace(/[^0-9]/g, '') - 0;
              let b_time = b.call_time.replace(/[^0-9]/g, '') - 0;
              if (a_time < b_time) {
                  return -1;
              } else {
                  return 1;
              }
            });
            //message('音源のある履歴取得完了');

            //TF.call_history = TF.call_history.filter(Boolean);
            this.historyList(TF.call_history);

          };

          let failFunc = (err) => {
            console.log(JSON.parse(err), 440);
          };
          //message('音源のある履歴取得開始');
          //TF.ajax_obj.call_history = $.ajax(opt).done(doFunc).fail(failFunc);
        

          //該当顧客データ取得
          let get_api2 = TF.api.url + 'customer/tel_1=/' + telno + TF.api.key;
          let opt2 = {
            url:  get_api2,
            type: 'GET',
          };

          let doFunc2 = (data) => {
            TF.customer = JSON.parse(data);

            if(TF.customer) {
              TF.customer_flag = true;
            }

            //両方のデータが戻ってきたら
            let count = 0;
            //let sit = setInterval(() => {
              if(TF.customer_flag) {
                //clearInterval(sit);
  
                let history_obj = [];

                if(TF.call_history && TF.call_history.length > 0) {
                  for(let ii in TF.call_history) {
                    let is_ban = '', call_status = '';
                    const h = TF.call_history[ii];

                    call_status = TF.call_status.filter((v) => v.id === h.call_status);

                    //禁止かどうか
                    is_ban = call_status[0] && call_status[0].fixed_phrase == '禁止' ? call_status[0].fixed_phrase: null;

                    TF.call_history[ii].prohibit_flag = is_ban || '';
                    TF.call_history[ii].unixtime = moment(h.call_time, 'YYYY-MM-DD HH:mm:ss').unix();
                  }
                }

                if(TF.customer.length > 0) {
                  for(let i in TF.customer) {
                    const c = TF.customer[i];

                    let tel_no = '', is_ban = '', call_status = '', call_status_id = '', call_counterpart = '', sound = '', memo = '', area = '', job = '', unixtime = '', call_time = '', recall_time = '', staff_id = '', recall_cancel = false;
                    if(TF.call_history.length > 0) {
                      for(let ii in TF.call_history) {
                        const h = TF.call_history[ii];
                        
                        if(h.customer_id == c.id) {

                          //電話番号
                          tel_no = h.tel_no;

                          //スタッフ
                          staff_id = h.staff_id;

                          //ステータス
                          call_status_id = h.call_status;

                          //商材
                          call_counterpart = h.call_counterpart; 

                          //音源
                          sound = h.record_file;

                          //メモ
                          memo = h.memo_1;
                          
                          //エリア
                          area = h.integration_id_1;

                          //業種
                          job = h.integration_id_2;

                          //登録時刻
                          call_time = h.call_time;
                          
                          //予約時刻
                          //再コールがキャンセルされていたら
                          if(h.cancel_flag && !h.closing_flag) {
                            recall_cancel = true;
                            recall_time = '';
                          } else {
                            recall_time = h.recall_time;
                          }

                          //unixタイム
                          unixtime = h.unixtime;

                          //禁止か
                          is_ban = h.prohibit_flag ;

                          delete TF.call_history[ii];
                        }
                      }
                    } 
                  
                    //再コールがキャンセルされていたら
                    if(recall_cancel || c.cancellation_date) {
                      c.add_info99 = '';
                      recall_time = '';
                    } 

                    let obj = {
                      id: c.id,
                      tel_no: c.tel_1 || tel_no,
                      recall_time: c.add_info99 || recall_time,
                      call_time: c.last_call || call_time,
                      staff_id: c.staff_id || staff_id,
                      call_status: c.call_status || call_status_id,
                      call_counterpart: c.add_info98 || call_counterpart,
                      integration_id_1: c.prefecture || area,
                      integration_id_2: c.add_info100 || job,
                      prohibit_flag: c.prohibit_flag || is_ban,
                      record_file: sound || '',
                      memo_1: c.remarks || memo
                    };

                    history_obj.push(obj);
                  }
                }

                TF.call_history = TF.call_history.concat(history_obj);
              
                TF.call_history.sort(function(a, b) {
                  if (a.unixtime < b.unixtime) {
                      return -1;
                  } else {
                      return 1;
                  }
                });
                //message('履歴取得完了');

                TF.call_history = TF.call_history.filter(Boolean);
                this.historyList(TF.call_history);
              }

              count++;
              if(count >= 2400) {
                clearInterval(sit);
                message('履歴が取得できませんでした', 1);
              }

            //}, TF.intervaltime); //データ取得待ち
          };

          let failFunc2 = (err) => {
            console.log(err);
          };
          
          TF.ajax_obj.customer = $.ajax(opt2).done(doFunc2).fail(failFunc2);

        }, TF.intervaltime * 10); //電話番号確定待ち
        
      } else {
        if(!$('.-input-tel-no .-alert')[0]) {
          $('.-input-tel-no').append('<span class="-alert">10~13桁入力してください</span>');
        }
      }

      //APIに投げるため市外局番確定して返す
      if(!TF.area_code_ok) {
        TF.area_code_ok = true;
        return TF.area_code;
      }
    }

    return false;
  }

  //履歴リストを構築
  historyList(data) {
    
    //　09090909090
    let raw = '';
    let is_ban_flag = false;
    let is_ban_number = '';
    let sit = setInterval(() => {
      if(!TF.call_status_all) return;
      
      if(data.length > 0) {
        clearInterval(sit);

        for(let i = data.length-1, n = 0; i >= n; i--) {
          const _data = data[i];
          if(!_data) continue;
          const recall_time = (_data.recall_time && _data.recall_time != "0000-00-00 00:00:00" && !_data.cancel_flag )? dateConverter(Date.parse(_data.recall_time.replace(/\-/g, '/')).toString(), 'list'): '-';
          const call_time = (_data.call_time)? dateConverter(Date.parse(_data.call_time.replace(/\-/g, '/')).toString(), 'list'): '-';
          const staff = this.GetData.getStaffInfo(_data.staff_id);
          const name = (typeof staff == 'undefined')? '名前不詳': staff.name;
          const call_status = TF.call_status.filter((v) => v.id === _data.call_status);
          const call_counterpart = TF.call_counterpart.filter((v) => v.id === _data.call_counterpart);
          const call_counterpart_name = call_counterpart[0] ? call_counterpart[0].name: '-';

          let is_ban = '-';
          if(_data.prohibit_flag|0) {
            is_ban = '禁止';
            is_ban_flag = true;
            is_ban_number = _data.tel_no;
          }

          //数値のみ取得
          const time = _data.call_time.replace(/[^0-9]/g, '');
          const call_status_name = call_status[0] ? call_status[0].name: '-';
          const sound = _data.record_file ? '<a href="' + TF.mp3dir + _data.record_file + '.mp3" download class="-sound-icon" target="_blank"></a>':'-';
          const memo = _data.memo_1 ? '<span class="-memo-icon"></span>':'-';

          raw += '\
          <tr data-history-id="' + _data.id + '" data-time="' + time + '">\
            <td class="p-call-history-list-td">' + _data.tel_no + '</td>\
            <td class="p-call-history-list-td">' + recall_time + '</td>\
            <td class="p-call-history-list-td is-sp-hide">' + call_time + '</td>\
            <td class="p-call-history-list-td">' + call_counterpart_name + '</td>\
            <td class="p-call-history-list-td is-sp-hide">' + name + '</td>\
            <td class="p-call-history-list-td">' + call_status_name + '</td>\
            <td class="p-call-history-list-td -ban">' + is_ban + '</td>\
            <td class="p-call-history-list-td is-sp-hide">' + sound + '</td>\
            <td class="p-call-history-list-td">' + memo + '</td>\
          </tr>';
          
        }
      } else {
        clearInterval(sit);
        raw = '<tr><td class="p-call-history-list-td" colspan="8">該当リストが見つかりませんでした。</td></tr>';
      }

      $('.p-call-history-list tr').not('.js-history-list-header').remove();
      $('.js-history-list-header').after($(raw));
      $('tr[data-history-id]').on('click', (e) => { this.setHistoryDetail(e); });
 
      if(is_ban_flag) {
        message('この番号は禁止番号です', false, {class: '-is_ban', number: is_ban_number});
      }
    }, TF.intervaltime);
  }
    
  //履歴詳細セット
  setHistoryDetail(e) {
    const id = '' + $(e.currentTarget).data('history-id');
    const call_history_detail = TF.call_history.filter((v) => { if(v.id === id) return v })[0];
    const history_detail = $('.-history-detail');
  
    $('tr[data-history-id]').removeClass('is-active');
    $(e.currentTarget).addClass('is-active');
    
    //電話番号
    history_detail.find('.js-history-tel').text(call_history_detail.tel_no);
    
    //エリア
    //let area = $('#area_select option[value="' + call_history_detail.integration_id_1 + '"]').text();
    
    let area = '';
    if(call_history_detail.integration_id_1) {
      area = TF.area_codes.filter((v) => {
        if((v.prefecture_code|0) == (call_history_detail.integration_id_1|0)) {
          return v;
        }
      })[0].prefecture_name;
    } else {
      //const area = undefined;
      area = '未設定';
    }
    history_detail.find('.js-history-area').text(area);
    
    //商材
    const counterpart = TF.call_counterpart.filter((v) => v.id === call_history_detail.call_counterpart)[0];
    const counterpart_type = (typeof counterpart == 'undefined')? '未設定': counterpart.name;
    history_detail.find('.js-history-counterpart').text(counterpart_type);
    
    //業種
    if(call_history_detail.call_counterpart) {
      console.log({call_history_detail});
      this.getJobData(call_history_detail.call_counterpart, (data) => {
        var job;
        for(var i = 0, n = data.categories.length; i < n; i++) {
          var cats = data.categories[i];
          for(var j = 0, m = cats.jobs.length; j < m; j++) {
            if((cats.jobs[j].id|0) == (call_history_detail.integration_id_2|0)) {
              job = cats.jobs[j];
              break;
            }
          }
          if(job) break;
        }
        
        job = job ? job.name : '未設定';
        history_detail.find('.js-history-jobtype').text(job);
      });
    } else {
      history_detail.find('.js-history-jobtype').text('未設定');
    }

    //予約日時
    const recall_time = (call_history_detail.recall_time && call_history_detail.recall_time != "0000-00-00 00:00:00")? dateConverter(Date.parse(call_history_detail.recall_time.replace(/\-/g, '/')).toString(), 'list'): '-';
    history_detail.find('.js-history-apoint-date').text(recall_time);
    
    //架電日時
    const call_time = (call_history_detail.call_time)? dateConverter(Date.parse(call_history_detail.call_time.replace(/\-/g, '/')).toString(), 'list'): '-';
    history_detail.find('.js-history-entry-date').text(call_time);

    //担当者
    /*for(let i in TF.staff) {
      if(TF.staff[i].id == call_history_detail.staff_id) {
        const staff = TF.staff[i]; 
        return;
      }
    } */

    const staff = TF.staff[call_history_detail.staff_id];
    
    const name = (typeof staff == 'undefined')? '名前不詳': staff.name;
    history_detail.find('.js-history-staff').text(name);
    
    //ステータス
    const call_status = TF.call_status.filter((v) => v.id === call_history_detail.call_status)[0];
    const call_status_name = (typeof call_status == 'undefined')? '-': call_status.name;
    history_detail.find('.js-history-status').text(call_status_name);
    
    //禁止か
    history_detail.find('.js-history-is-ban').text($(e.currentTarget).find('.-ban').text());

    //音声
    const audio = call_history_detail.record_file? '<div id="player" class="pull-right" data-src="' + call_history_detail.record_file + '">\
      <audio preload></audio>\
    </div>': '-';
    history_detail.find('.js-history-sound').html(audio);
    if(audio !== '-') playerInit(); //プレイヤー初期化

    //メモ
    history_detail.find('.js-history-memo').html(call_history_detail.memo_1);
  }


  /****************** 
   * 架電履歴登録
  *******************/

  //! customerデータも構築する

  //カレンダー日付初期設定
  calenderInit(working_days) {
    working_days = working_days || TF.working_days;

    // 営業日設定
    //今日
    this.add = 0;
    let setToday = () => {
      let date_obj = new Date();
      date_obj.setDate( date_obj.getDate() + this.add );
      
      if(date_obj.getDay() == 0 || date_obj.getDay() == 6 ) {
        this.add++;
        setToday(this.add);
      } else {
        let d = moment().add(this.add,'days').format('YYYY/M/D');
        TF.calender.workingdays[d] = d;
      }
    };
    setToday();

    //次の日
    //const sit_c = setInterval(() => {

      if(TF.calender.shukujitsu) {
        //TF.calender.shukujitsu['2020/12/9'] = ['2020/12/9','test'];
        //TF.calender.shukujitsu['2020/12/11'] = ['2020/12/11','test'];

        let i = 0;
        let lock = false;
        //clearInterval(sit_c);
        let date_obj = new Date();
        date_obj.setDate( date_obj.getDate() + this.add);
       

        let check = () => {
          
          // 営業日チェック
          if(i < working_days) {

            // 一日進める
            date_obj.setDate( date_obj.getDate() + 1);
          
            // 休日だったら1日進める
            if(date_obj.getDay() == 0 || date_obj.getDay() == 6 ) {
              check();
            }

            // 祝日だったら1日進める
            const ymd = date_obj.getFullYear() + '/' + (date_obj.getMonth() + 1) + '/' + date_obj.getDate();
            const shukujitsu = TF.calender.shukujitsu[ymd];

            if(shukujitsu) {
              check();
            } else {
              
              // 営業日だったらセットする
              if(Object.keys(TF.calender.workingdays).length < working_days) {
                TF.calender.workingdays[ymd] = ymd;
                i++;
                check();
              } else {
                this.lastday = ymd;
                // 3営業日で終り カレンダー初期化
                if(!lock){
                  lock =  true;
                  this.datepickerInit();
                } 
                return true;
              }
            }
          }
        }

        check();
      } 

    //}, TF.intervaltime); 

  }

  hiduketitle() {
    const sit_c = setInterval(() => {
      if($('.datepicker-days .datepicker-switch')[0]) {
        clearInterval(sit_c);
        let elm = $('.datepicker-days .datepicker-switch');
        let text = elm.text();
        let year = `<span class="year">${text.match(/[0-9]{4}/)[0]}</span>`;
        let month = text.match(/([0-9]{0,2})(月)/);
        month[1] = month[1].replace(/^0/,'');
        let smonth = `<span class="smonth">${TF.short_month[month[1] + month[2]]}</span>`;
        month = `${month[1]}<span class="u-text">月</span>`;
        elm.html($('<span class="datepicker-title">').html(month + smonth + year));
      }
    }, TF.intervaltime);

  }

  //カレンダー初期化
  datepickerInit() {
    if(TF.calender.obj) {
      TF.calender.obj.datepicker( "destroy" )
    }

    TF.history.apoint_date = "";

    this.activeCancel = (e) => {
      if(!$(e.currentTarget).hasClass('is-workingday')) return false;
    }

    TF.calender.obj = $('.js-select-calender').datepicker({
      dateformat: 'yyyy/mm/dd',
      language:'ja',
      startDate: Date(),
      endDate: moment(this.lastday, 'YYYY/M/D').toDate(),
      todayHighlight: true,
    
      beforeShowDay: (date) => {
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        let d = date.getDate();
        const ymd = y + '/' + m + '/' + d;

        m = m >= 10 ? m: '0' + m;
        d = d >= 10 ? d: '0' + d;

        const y0m0d = y + '/' + m + '/' + d;

        let ary = [];

        if(TF.calender.shukujitsu[ymd]) {
          //ary = [false, 'is-holiday', ""];
          ary = {enable: false, classes: 'is-holiday'};
        } else if(TF.calender.workingdays[ymd] && TF.history.apoint_date !== y0m0d) {
          ary = {enable: true, classes: 'is-workingday'};
        } else if(TF.calender.workingdays[ymd] && TF.history.apoint_date == y0m0d) {
          ary = {enable: true, classes: 'is-selected is-workingday'};
        } else if(!TF.calender.workingdays[ymd]) {
          ary = {enable: false};
        }
        return ary;
      }
    })
    .off('changeMonth changeYear').on('changeMonth changeYear', (e) => {
      $('.js-select-calender td').off('click').on('click', this.activeCancel);
      this.hiduketitle();
    })
    .off('changeDate').on('changeDate', (e) => { 

      $('.js-select-calender td').off('click').on('click', this.activeCancel);
      const date = moment(e.date).format('YYYY/M/D');
      if(!TF.calender.workingdays[date]) {
        const unix = moment(e.date).unix();
        //$(`.js-select-calender td.active`).not('.is-workingday').removeClass('active');
      } else {
        TF.history.apoint_date = moment(e.date).format('YYYY/MM/DD');
      }
      this.hiduketitle();
    });

    this.hiduketitle();

    $('.js-select-calender td').off('click').on('click', this.activeCancel);
  }

  // データ登録
  historyDataEntry() {
    TF.history.apoint_memo = "";
    TF.history.apoint_date_hour = "";
    TF.history.apoint_date_minute = "";

    // エリア
    /*$('.p-call-entry select[name="area_select"]').on('change', (e) => {
      TF.history.area =  $(e.currentTarget).find('option:selected').val();
    });*/

    /*$('#search_pref').on('input', (e) => {
      TF.history.area =  $('#area_select').val();
      console.log(TF.history.area);
    });*/


    // 見込みアポ時間：時間

    $('.p-data-input-calender select[name="select-hour"]').on('click', (e)=>{
      let current = moment().format('YYYY/MM/DD');
      let current_hour = moment().format('H')|0;
      let current_minutes = moment().format('m')|0;
      let hours = $(e.currentTarget).find('option');
      if(current_minutes > 45) current_hour++;

      hours.filter((i) => {
        let v = $(hours[i]).val();
        if(v && v.match(/[0-9]*/) && (v|0) < current_hour && TF.history.apoint_date == current) {
          $(hours[i]).attr('disabled', true);
        } else {
          $(hours[i]).removeAttr('disabled');
        }
      });

    }).on('change', (e) => {
      TF.history.apoint_date_hour = $(e.currentTarget).find('option:selected').val();
    });

    // 見込みアポ時間：分
    $('.p-data-input-calender select[name="select-minute"]').on('change', (e) => {
      TF.history.apoint_date_minute =  $(e.currentTarget).find('option:selected').val();
    });

    // 見込みメモ
    $('.-mikomi textarea[name="memo"]').on('input', (e) => {
      TF.history.apoint_memo = $(e.currentTarget).val().replace(/<[^>]+>/g, '')
      .replace(/&/g, '&amp;');

      if($('.-mikomi textarea[name="memo"]').val()) {
        TF.history.apoint_memo = $('.-mikomi textarea[name="memo"]').val().replace(/<[^>]+>/g, '')
        .replace(/&/g, '&amp;');
        $('.-history-check textarea[name="memo"]').val(TF.history.apoint_memo);
      }
    });

    // 商材
    $('input[name="call_counterpart"]').on('click', (e) => {
      $('#call_counterpart_category_list_box').remove();
      $('.p-input-box.-jobs .p-input-radio-list').remove();
      TF.history.call_counterpart = $(e.currentTarget).val();
      TF.history.job = '';

      let jobs_categories = TF.call_counterpart.filter((v) => {
        return (v.id|0) === (TF.history.call_counterpart|0); 
      })[0];

      //業種カテゴリリストを表示
      let ttl = $('<h3>', {
        class: 'u-ttl',
        text: '業種カテゴリを選択'
      });

      let cats_box = $('<div>',{
        id: 'call_counterpart_category_list_box',
      });
      
      let cats = $('<div>',{
        id: 'call_counterpart_category_list',
        class: 'u-shadow'
      });

      if(!jobs_categories || jobs_categories.categories.length == 0) {
        alert("業種カテゴリが登録されていません\n管理者に問い合わせて下さい");
        return false;
      }

      jobs_categories.categories.filter((v) => {
        let btn = $('<a>', {
          href: 'javascript:void(0)',
          class: 'js-job -job',
          'data-jobs': JSON.stringify(v.jobs),
          text: v.name
        });
        cats.append(btn);
      });

      $('.p-input-box.-counterpart').append(cats_box);

      cats_box.append(cats.prepend(ttl));

      //業種リストを表示
      $('#call_counterpart_category_list .js-job').on('click', (e) => {
        let unique = [];
        let list = [];
        let data = $(e.currentTarget).data('jobs');

        if(data.length == 0) {
          alert("業種が登録されていません\n管理者に問い合わせて下さい");
          return false;
        }

        for(let i = 0, n = data.length; i < n; i++ ) {
          let _data = data[i];

          if(unique.indexOf(_data.id) == -1) {
            unique.push(_data.id); 
            
            if(i == 0) list[0] = '<ul class="p-input-radio-list">';
          
            list[0] += '<li class="p-input-radio-list-item"><label><input type="radio" name="job" value="' + _data.id + '" data-name="' + _data.name + '"><span class="u-text">' + _data.name + '</span></label></li>';
            
            if(i + 1 == n) list[0] += '</ul>';
          }
        }
        
        list = list.join('\n');
        
        $('.p-input-box.-jobs').append($(list));
            // 業種
        $('input[name="job"]').on('click', (e) => {
          TF.history.job =  $(e.currentTarget).val();
        });

        cats_box.remove();
      });

      //this.getJobData(null, listPrint);
    });

    // 履歴ステータス
    $('input[name="status"]').on('click', (e) => {
      let elm = $(e.currentTarget);
      if(!elm.parent().hasClass('-mikomi') || TF.history.call_status != elm.val()) {
        $('.-mikomi.js-modal-area input[type="text"],.-mikomi.js-modal-area textarea,.-mikomi.js-modal-area select').val('');
        $('.-mikomi.js-modal-area input[type="radio"]').prop("checked", false);
        $('.-mikomi.js-modal-area .is-selected').removeClass('is-selected');
        $('.js-select-calender').datepicker("clearDates");
        TF.history.apoint_date_hour = '';
        TF.history.apoint_date_minute = '';
        TF.history.apoint_date = '';
        TF.history.apoint_memo = '';
        TF.mikomi_flag = '';
      }
      TF.history.call_status = elm.val();
    
      if(elm.data('statustype') == 'mikomi') {
        TF.mikomi_flag = {id: TF.history.call_status, name: elm.parent().find('.u-text').text()}; 
      }
    });
  
    // 見込みモーダル
    $('.-mikomi .u-btn.-save').on('click', (e) => {
      e.preventDefault();
      TF.history.apoint_memo = $('.-mikomi textarea[name="memo"]').val();

      if(TF.history.apoint_memo) {
        TF.history.apoint_memo = TF.history.apoint_memo
        .replace(/<[^>]+>/g, '')
        .replace(/&/g, '&amp;');
      }

      if(TF.mikomi_flag) {

        if(!TF.history.apoint_date) {
          message('見込み時刻の日付が指定されていません', 1);
          return false;
        }

        if(!TF.history.apoint_date_hour) {
          message('見込み時刻の時間が設定されていません', 1);
          return false;
        }

        if(!TF.history.apoint_date_minute) {
          message('見込み時刻の分が設定されていません', 1);
          return false;
        }

        if(TF.history.apoint_date_hour == 9) TF.history.apoint_date_hour = '0' + TF.history.apoint_date_hour;

        TF.mikomi_flag.status = TF.history.call_status;
        TF.mikomi_flag.tel_no = TF.history.tel_no;
        TF.mikomi_flag.apoint_date = `${TF.history.apoint_date} ${TF.history.apoint_date_hour}:${TF.history.apoint_date_minute}`;
        TF.mikomi_flag.memo = TF.history.apoint_memo;
        TF.mikomi_flag.staff = CURRENT_USER.name;
        TF.mikomi_flag.staff_id = CURRENT_USER.telforce_id;
        TF.mikomi_flag.dept_unit_id = CURRENT_USER.dept_unit_id;
        if(TF.mikomi_flag.dept_unit_id > 0) {
          TF.mikomi_flag.unit_name = ALL_USERS[CURRENT_USER.telforce_id].dept_unit.unit_name;
        } else {
          TF.mikomi_flag.unit_name = '所属なし';
        }
      }
      $('.js-modal.-history-check').click();
      $('.js-modal-area.-mikomi').fadeOut(200);
      return false;
    });

    // 登録ボタン
    $('.js-entry-btn').on('click', (e) => {
      e.preventDefault();
      //TF.history.area =  $('.p-call-entry select[name="area_select"] option:selected').val();
      TF.history.area = $('#area_select').val();
      this.sendHistory();
      return false;
    });

    // リセット
    $('.js-btn-reset').on('click', (e) => {
      $('#area_group_select').css('top', '-10000px');
      $('input[type="tel"], input[type="text"], input[type="hidden"], textarea, select').val('');
      $('input[type="radio"]').prop("checked", false);
      $('.p-input-box.-jobs .p-input-radio-list').remove();
      $('.js-select-calender').datepicker("clearDates");
      $('.p-call-history-list tr').not('.js-history-list-header').remove();
      $('.p-call-history-detail-list-item-dd').text('-');

      $('#search_pref').trigger('keyup').trigger('focusout')
      $('#area_group_select').removeAttr('style');


      TF.call_customer_entry_response = '';
      TF.call_history_entry_response = '';
      TF.mikomi_flag = null;
      TF.history = {};
    });
  }

  getJobData(id, func) {
    let jobs_categories = TF.call_counterpart.filter((v) => {
      return (v.id|0) === (id|0); 
    })[0];
    console.log(jobs_categories);
    func(jobs_categories);
    /*let ccid = id || TF.history.call_counterpart;

    //選択した商材に紐づく業種をsgteforceから取得
    let get_api = '/api/job/list/' + ccid;
    let opt = {
      url:  get_api,
      type: 'GET',
    };

    let doFunc = (data) => {
      data = JSON.parse(data);
      if(!data.success) {
        message('業種取得に失敗しました', 1);
        return;
      }

      func(data);
    };

    let failFunc = (err) => {
      console.log(err);
    };

    getApiDataCommon(opt, doFunc, failFunc);*/
  }

  getAllJobData() {
    let jobs = {};
    for(let i = 0; i < TF.jobs.length; i++) {
      let id = TF.jobs[i].id;
      let name = TF.jobs[i].name;
      jobs[id] = name;
    }
    $.cookie('jobs', JSON.stringify(jobs), {path:'/'});
    //$.cookie('jobs_category', data, {path:'/'});
  }

  /*
  getAllJobData() {
    let elm = $('input[name="call_counterpart"]');
    let jobs = {};
    let tmp = [];
    let n = 0;
    let num = elm.length;

    for(let i = 0; i < TF.jobs.length; i++) {
      let id = TF.jobs[i].id;
      let get_api = '/api/job/list/' + id;
      let opt = {
        url:  get_api,
        type: 'GET',
      };
  
      let doFunc = (data) => {
        data = JSON.parse(data);
        if(!data.success) {
          message('業種取得に失敗しました', 1);
          return;
        }

        for(let ii in data.data) {
          if(data.data[ii].job && tmp.indexOf(data.data[ii].job.id) == -1) {
            tmp.push(data.data[ii].job.id);
            jobs[data.data[ii].job.id] = data.data[ii].job.name;
          }
        }

        n++;

        if(n == num) {
          $.cookie('jobs', JSON.stringify(jobs), {path:'/'});
        }
      };
  
      let failFunc = (err) => {
        console.log(err);
        num--;
      };
  
      getApiDataCommon(opt, doFunc, failFunc);
    }   
  }*/

  // 履歴データAPIに送信
  sendHistory() {
    let check = $('.js-data-check-table');
    // 電話番号
    if(TF.history.tel_no) {
      check.find('.-telno td').text(TF.history.tel_no);

      if(TF.history.tel_no.length < 10 || TF.history.tel_no.length > 13) {
        check.find('.-telno td').html('<span class="-alert">電話番号は10~13桁の入力が必須です</span>');
        TF.history.tel_no = null;
      }
    } else {
      check.find('.-telno td').html('<span class="-alert">電話番号は必須です</span>');
      TF.history.tel_no = null;
    }
    // エリア
    if(TF.history.area) {
      //let name = $('select#area_select option[data-id="' + TF.history.area + '"]').text();
      //let name = $('#search_pref').val();
      let name = TF.area_codes.filter((v) => {
        if((v.prefecture_code|0) == (TF.history.area|0)) {
          return v;
        }
      })[0].prefecture_name;
      check.find('.-area td').text(name);
    } else {
      check.find('.-area td').html('<span class="-alert">都道府県は必須です</span>');
    }
    // 業種
    if(TF.history.job) {
      let name = $('input[name="job"][value="' + TF.history.job + '"]').data('name');
      check.find('.-job td').text(name);
    } else {
      check.find('.-job td').html('<span class="-alert">業種は必須です</span>');
    }
    // 商材
    $('.-counterpart th').text('商材');
    if(TF.history.call_counterpart) {
      const counterpart = TF.call_counterpart.filter((v) => v.id == TF.history.call_counterpart);
      check.find('.-counterpart td').text(counterpart[0].name);
    } else {
      check.find('.-counterpart td').html('<span class="-alert">商材は必須です</span>');
    }

    // ステータス
    if(TF.history.call_status) {
      const status = TF.call_status.filter((v) => v.id === TF.history.call_status);
      check.find('.-status td').text(status[0].name);
      this.status_mikomi_flag = status[0].fixed_phrase == '見込み';
    } else {
      this.status_mikomi_flag = false;
      check.find('.-status td').html('<span class="-alert">ステータスは必須です</span>');
    }

    // 架電予定時刻
    if(TF.history.apoint_date && TF.history.apoint_date_hour && TF.history.apoint_date_minute) {
      let apoint_date = TF.history.apoint_date.replace(/\//g, '-') + ' ' + TF.history.apoint_date_hour + ':' + TF.history.apoint_date_minute;
      TF.history.apoint_time = apoint_date;

      check.find('.-apoint-time td').text(apoint_date);
    } else {
      let mes = '';
      
      if(this.status_mikomi_flag) {
        if(!TF.history.apoint_date || !TF.history.apoint_date_hour || !TF.history.apoint_date_minute) {
          mes = '<span class="-alert">見込み時刻が正しく設定されていません</span>';
          TF.history.apoint_time = '';
        }
      }

      TF.history.apoint_time = "";
      check.find('.-apoint-time td').html(mes);
    }

    // メモ
    if(TF.history.apoint_memo) {
      check.find('.-apoint-memo td .p-data-textarea').html(TF.history.apoint_memo);

    } else {
      let mes = '';
      
      check.find('.-apoint-memo td .p-data-textarea').html(mes);
    }

    // 送信ボタン
    const btn = $('.u-btn.-entry').addClass('-disable');
    if(
      TF.history.area &&
      TF.history.tel_no &&
      TF.history.job &&
      TF.history.call_counterpart &&
      TF.history.call_status
    ) {

      if(
        this.status_mikomi_flag &&
        !TF.history.apoint_time.match(/[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{1,2}:[0-9]{2}/)
      ) return false;

      btn.removeClass('-disable');
      $('.u-btn.-cancel').removeClass('-disable');
    } else {
      btn.addClass('-disable');
      $('.u-btn.-cancel').addClass('-disable');
    }

    btn.off('click').one('click', (e) => {
      if(TF.call_customer_entry_response == TF.history.tel_no || TF.call_history_entry_respons == TF.history.tel_no) {
        message('この番号はすでに登録されています', false, {class: '-already'});
        return false;
      }
      if($(this).hasClass('-disable')) return false;
      btn.addClass('-disable');

      TF.history.apoint_memo = $('.-history-check textarea[name="memo"]').val().replace(/<[^>]+>/g, '')
      .replace(/&/g, '&amp;');

      $('.js-modal-area').fadeOut(100);

      //架電日時を取得
      TF.history.call_time = moment().format('YYYY-MM-DD HH:mm:ss');

      this.saveCustomer();
      //console.log('send');
    });
  }

  //履歴登録
  saveHistory(customer_id) {
    //08080808089
    //送信データ構築

    let recall_flag = '';
    let recall_query = '';
    if(TF.history.apoint_time) {
      recall_flag = 1;
      recall_query = ',"recall_time":"' + TF.history.apoint_time + '","recall_flag":"' + recall_flag + '"';
    }

    let json = '{"customer_id":"' + customer_id + '","integration_id_1":"' + TF.history.area + '","integration_id_2":"' + TF.history.job + '","staff_id":"' + CURRENT_USER.telforce_id + '","tel_no":"' + TF.history.tel_no + '","call_status":"' + TF.history.call_status + '","call_counterpart":"' + TF.history.call_counterpart + '","memo_1":"' + TF.history.apoint_memo + '","call_time":"' + TF.history.call_time + '"' + recall_query + '}';

    let get_api = TF.api.url + 'call_history' + TF.api.key;

    let opt = {
      url: get_api,
      type: 'POST',
      data: json
    };



    let doFunc = (data) => {
      let reset_flag = 0;
      if(!data) {
        message('履歴登録失敗', 1);
        console.log('[error] 履歴登録失敗');
        return;
      }

      let history_id = JSON.parse(data)[0];

      TF.call_history_entry_response = JSON.parse(data);
      message('架電履歴登録完了');
      try {

        if(TF.history.apoint_time) {
          //talknoteへsgteforceを介して通知
          let mikomi_api = '/api/push';

          const status = TF.call_status.filter((v) => v.id === TF.history.call_status);

          let unit_name = '所属なし';
          if(CURRENT_USER.dept_unit_id > 0) {
            unit_name = ALL_USERS[CURRENT_USER.telforce_id].dept_unit.unit_name;
          }

          let title = `【見込みステータスが登録されました】登録者：${CURRENT_USER.name}[${CURRENT_USER.telforce_id}]`;
          let mes = `\n▼架電予約登録内容\n\n[架電対象電話番号]　　${TF.history.tel_no}\n[ステータス]　　　　　${status[0].name}\n[ステータス登録日時]　${TF.history.call_time}\n[登録者]　　　　　　　${CURRENT_USER.name} [${CURRENT_USER.telforce_id}]\n[登録者所属グループ]　${unit_name}\n[予約日時]　　　　　　${TF.history.apoint_time}\n[メモ]\n${TF.history.apoint_memo}\n`;

          TF.history.call_status_name = status[0].name;
          //TF.mikomi_flag.call_time = TF.history.call_time;

          let mikomi_opt = {
            url:  mikomi_api,
            type: 'POST',
            data: {
              to: TF.talknote_group,
              message: mes,
              subject: title
            }
          };

          let mikomi_opt_admin = {
            url:  mikomi_api,
            type: 'POST',
            data: {
              to: TF.talknote_group_admin,
              message: mes,
              subject: '⚜管理者宛 - ' + title
            }
          };

          let mikomi_doFunc = (data) => {
            /*data = JSON.parse(data);
            if(!data.success) {
              message('talknoteへの通知失敗');
              return;
            }*/
            message('見込みステータスをtalknoteに通知しました');
            reset_flag++;
          }

          let mikomi_doFunc_admin = (data) => {
            /*data = JSON.parse(data);
            if(!data.success) {
              message('管理者用talknoteへの通知失敗');
              return;
            }*/
            if(data) {
              console.log(data);
            }
            message('見込みステータスをtalknoteに通知しました');
            reset_flag++;

          }

          let mikomi_failFunc = (data) => {
            console.log(`[error] talknoteへ通知失敗\n${JSON.stringify(data)}`);
            message('[error] sgtelforceへの接続失敗');
            reset_flag++;
          }
          //talknoteへ送信
          getApiDataCommon(mikomi_opt, mikomi_doFunc, mikomi_failFunc);
          getApiDataCommon(mikomi_opt_admin, mikomi_doFunc_admin, mikomi_failFunc);


          /****************************************************************
          予約時刻告知用登録開始
          ****************************************************************/

          //本日から先の見込み予約履歴を取得
          let today =  moment().format('YYYY-MM-DD');
          let apoint_opt = {
            url: `${TF.api.url}call_history/staff_id=/${CURRENT_USER.telforce_id}/recall_time>=/${today}${TF.api.key}`,
            type: 'GET'
          };

          let apoint_doFunc = (data) => {
            data = JSON.parse(data);
            //web storageを使用

            let apoint_dates = {};

            for(let i in data) {
              if(data[i].cancel_flag || data[i].closing_flag) continue;

              let obj = {};
              obj.id = data[i].id|0;
              obj.tel_no = data[i].tel_no;
              obj.status = data[i].call_status;
              
              let status = obj.status + '';
              if(status.match(/[0-9]+/) && TF.call_status) {
                status = TF.call_status.filter((v) => v.id === status);
                obj.status = status? status[0].name: obj.status;
              }

              obj.call_time = data[i].call_time;
              obj.apoint_date = moment(data[i].recall_time, 'YYYY-MM-DD HH:mm:ss').format('YYYY/MM/DD HH:mm');

              let unit_name = '所属なし';
              if(CURRENT_USER.dept_unit_id > 0) {
                unit_name = ALL_USERS[CURRENT_USER.telforce_id].dept_unit.unit_name;
              }

              obj.staff = CURRENT_USER.name;
              obj.staff_id = CURRENT_USER.telforce_id;
              obj.unit_name = unit_name;
              obj.memo = data[i].memo_1;

              let key = moment(obj.apoint_date, 'YYYY/MM/DD HH:mm').unix();
              
              if(!apoint_dates[key]) {
                apoint_dates[key] = [];
              }

              apoint_dates[key].push(obj);
            }

            apoint_dates = JSON.stringify(apoint_dates);  

            let db = localStorage;
            db.setItem('sgtelforce_apoint_dates', apoint_dates);

            message('架電予約日時をブラウザに保存しました');
            reset_flag++;

            //
            let apoint_popup_flag = db.getItem('sgtelforce_apoint_popup_flag');
            if(apoint_popup_flag) {
              apoint_popup_flag = JSON.parse(apoint_popup_flag);
            } else {
              apoint_popup_flag = {};
            }

            let key2 = moment(TF.history.apoint_time, 'YYYY/MM/DD HH:mm').format('YYYY-MM-DD');
            apoint_popup_flag[key2] = false;
            apoint_popup_flag = JSON.stringify(apoint_popup_flag);
            //web storageに格納
            db.setItem('sgtelforce_apoint_popup_flag', apoint_popup_flag);

            //予約チェック
            apointCheck(TF.history.apoint_time.split('-').join('/'));
            TF.mikomi_flag = null;
          }
          
          let apoint_failFunc = (err) => {
            console.log(`[error] 見込み履歴取得失敗\n${JSON.stringify(err)}` );
            message('[error] 見込み履歴取得失敗');
            reset_flag++;
          }

          getApiDataCommon(apoint_opt, apoint_doFunc, apoint_failFunc);

          /*
          //web storageを使用
          let db = localStorage;
          let apoint_dates = db.getItem('sgtelforce_apoint_dates');
          if(apoint_dates) {
            //ex: 2021/2/17 14:00
            apoint_dates = JSON.parse(apoint_dates);
          } else {
            apoint_dates = {};
          }

          let obj = {};
          obj.id = history_id;
          obj.tel_no = TF.history.tel_no;
          obj.status = TF.mikomi_flag.status;
          obj.call_time = TF.mikomi_flag.call_time;
          obj.apoint_date = TF.mikomi_flag.apoint_date;
          obj.staff = TF.mikomi_flag.staff;
          obj.staff_id = TF.mikomi_flag.staff_id;
          obj.unit_name = TF.mikomi_flag.unit_name;
          obj.memo = TF.history.apoint_memo;

          let key = moment(TF.mikomi_flag.apoint_date, 'YYYY/MM/DD HH:mm').unix();
          
          if(Array.isArray(apoint_dates[key])) {
            apoint_dates[key].push(obj);
          } else {
            if(apoint_dates[key]) {
              apoint_dates[key] = [apoint_dates[key], obj];
            } else {
              apoint_dates[key] = [obj];
            }
          }

          apoint_dates = JSON.stringify(apoint_dates);
          //web storageに格納
          db.setItem('sgtelforce_apoint_dates', apoint_dates);
          message('架電予約日時をブラウザに保存しました');
          reset_flag++;

          let apoint_popup_flag = db.getItem('sgtelforce_apoint_popup_flag');
          if(apoint_popup_flag) {
            apoint_popup_flag = JSON.parse(apoint_popup_flag);
          } else {
            apoint_popup_flag = {};
          }

          let key2 = moment(TF.mikomi_flag.apoint_date, 'YYYY/MM/DD HH:mm').format('YYYY-MM-DD');
          apoint_popup_flag[key2] = false;
          apoint_popup_flag = JSON.stringify(apoint_popup_flag);
          //web storageに格納
          db.setItem('sgtelforce_apoint_popup_flag', apoint_popup_flag);

          //予約チェック
          apointCheck(TF.mikomi_flag.apoint_date);

          TF.mikomi_flag = null;*/

        } else {
          reset_flag = 3;
        }

      } catch(err) {
        console.log(err.toString());
      }

      let sit = setInterval(() => {
        if(reset_flag >= 3) {
          clearInterval(sit);
          setTimeout(() => {
            $('.js-btn-reset').trigger('click');
            message('リセット完了');
          }, TF.intervaltime);
        }
      }, TF.intervaltime);
    }

    let failFunc = (err) => {
      console.log(err.responseText);
    }
  
    getApiDataCommon(opt, doFunc, failFunc);
  }

  //カスタマーデータ登録
  saveCustomer() {
    //08080808089
    message('顧客データに登録中');
    let prohibit_flag = TF.call_status.filter((v) => v.id === TF.history.call_status);
    prohibit_flag = prohibit_flag[0].fixed_phrase == '禁止'? 1: 0;
    
    //送信データ構築
    let json = '{"prefecture":"' + TF.history.area + '","add_info100":"' + TF.history.job + '","staff_id":"' + CURRENT_USER.telforce_id + '","tel_1":"' + TF.history.tel_no + '","call_status":"' + TF.history.call_status + '","remarks":"' + TF.history.apoint_memo + '","add_info99":"' + TF.history.apoint_time + '","prohibit_flag":"' + prohibit_flag + '","add_info98":"' + TF.history.call_counterpart + '","last_call":"' + TF.history.call_time + '"}';

    let get_api = TF.api.url + 'customer' + TF.api.key;

    let opt = {
      url:  get_api,
      type: 'POST',
      data: json
    };

    let doFunc = (data) => {
      TF.call_customer_entry_response = JSON.parse(data)[0];
      this.saveHistory(TF.call_customer_entry_response);

      message('顧客データに登録完了');
      message('架電履歴に登録中');
    }

    let failFunc = (err) => {
      console.log(err);
    }

    getApiDataCommon(opt, doFunc, failFunc);
  }


  // 今日の日付返す
  getToday(sep) {
    let dt = new Date(); // 「今日」のインスタンスを取得
    return this.createDate(dt, sep);
  }

  //日付
  createDate(dt, sep) {
    let month = dt.getMonth() + 1; 
    let day = dt.getDate(); 
    let date = dt.getFullYear() + sep + month + sep + day;
    return date;
  }

  //0埋めする
  zeroPadding(date, sep, rep) {
    date = date.split(sep);
    let month = date[1] + 1 > 10 ? date[1] + 1: '0' + (date[1] + 1); 
    let day = date[2] > 10 ? date[2]: '0' + date[2];
    
    sep = rep? rep: sep;
    date = date[0] + sep + month + sep + day;
    return date;
  }

  printPrefectureList() {
    if (typeof TF.rurals == 'undefined') {
      console.log('ステータスリストが取得されていません');
      return;
    }
    let ul = ``;

    for(let i in TF.rurals) {
      if (TF.rurals[i].prefectures.length === 0){
        continue
      }
      ul += `<li class="-area_group"><div class="-area_label">` + TF.rurals[i].name + `</div>
                <ul class="-pref_list">`;
      for (let j in TF.rurals[i].prefectures) {
        ul += `<li class="-pref_item"><a href="javascript:void(0)" class="-pref js-pref" data-id="` + TF.rurals[i].prefectures[j].identification + `">
            ` + TF.rurals[i].prefectures[j].name + `</a>
          </li>`
      }
      ul += `</ul></li>`;
    }

    $('#area_group_select').html(ul);
  }
}
