const CSV = class {
  constructor(type, change_data) {
    this.type = type;
    this.change_data = change_data;
    this.loading = new TF.loading();
  }

  // 出力
  output(json, delimiter, filename) {
    this.delimiter = delimiter || ',';
    this.filename = filename || 'data';
    json = this.selectData(json);

    if(json.length == 0) {
      message('データがありません', 1);
      return false;
    }

    this.loading.start();

    //音源テキストCSV出力
    if(location.href.indexOf('record_to_text') > -1) {
      if(json) {
        this.json = json;
        return this.exportCSV(this.json, 0);
      } else {
        alert('JSONデータが設定されていません');
        return false;
      }
    }

    let lastyear = moment().subtract(1, 'years').format('YYYY-MM-DD');
    let set_date = location.href.match(/call_log|recall_list/)?'call_time>=/' + lastyear: '';

    //初回取得
    if((!json[0].call_time && !json[0].last_call) && location.href.indexOf('report') == -1 || TF.split_flag) {
      
      let query = 'customer/sel.id/sel.customer_id/sel.category/sel.client_id/sel.staff_id/sel.project_id/sel.name/sel.kana/sel.staff/sel.zip/sel.prefecture/sel.address_1/sel.address_2/sel.address_kana/sel.tel_1/sel.tel_2/sel.tel_3/sel.tel_4/sel.call_count/sel.rate/sel.call_status/sel.last_call/sel.add_info12/sel.add_info98/sel.add_info99/sel.add_info100/sel.prohibit_flag/sel.access_flag/sel.active_flag/sel.account_flag/sel.closing_date/sel.contract_date/sel.cancellation_date/sel.enable/sel.reg_date/sel.upd_date/';

      if(location.href.match(/call_log|recall_list/)) {
        query = 'call_history/sel.id/sel.customer_id/sel.integration_id_1/sel.integration_id_2/sel.staff_id/sel.tel_no/sel.call_time/sel.call_type/sel.duration/sel.call_status/sel.call_counterpart/sel.record_file/sel.recall_time/sel.recall_flag/sel.closing_flag/sel.cancel_flag/sel.active_flag/sel.reg_date/sel.upd_date/';
      } else {
        TF.history_id = json;

        if(location.href.match(/ban_list/)) {
          query += 'prohibit_flag=/1/';
        }

        if(location.href.match(/admin-list|admin\/list/)) {
          query += 'prohibit_flag!=/1/';
        }
      }


      if($('.js-data-check:checked').length == 0) {
        //1年分のみ取得
        lastyear = moment().subtract(1, 'years').format('YYYY-MM-DD');
        //let lastyear = moment().subtract(1, 'days').format('YYYY-MM-DD');
        //let set_date = location.href.match(/call_log|recall_list/)?'call_time>=/' + lastyear: 'last_call>=/' + lastyear;
        set_date = location.href.match(/call_log|recall_list/)?'call_time>=/' + lastyear: '';
        //query += set_date;
      } else if($('.js-data-check:checked').length > 0) {
        let ids = [];
        for(let i in json) {
          ids.push(`id/${json[i].id}`);
        }
        ids = '(/' + ids.join('/') + '/)/';
        query += ids;
      }

      if(location.href.match(/recall_list/)) {
        query += 'recall_flag/1';
      }


      (async () => {

        let get_ids = await TF.history_id;
        let count = 50000; //分割件数
        let num = Math.ceil(get_ids.length / count);
        let get_num = 0;
        message(`${num}のファイルがダウンロードされます`);

        get_ids = get_ids.sort(function(a,b){
          if(a.id - 0 > b.id - 0) return -1;
          if(a.id - 0 < b.id - 0) return 1;
          return 0;
        });

        let result = {};

        for(let i = 0; i < num; i++) {
          let start, end;
          start = i * count; 
          end = (i + 1) * count;
      
          result[i] = [];

          //先に取得したIDを元にデータ範囲取得
          let select_results = get_ids.slice(start, end);
          let limit_query = [];
          if(TF.search_query) {
            query += TF.search_query.replace(/^\//, '');
            limit_query = '';
          } else {
            //IDの取得範囲のクエリ
            limit_query = [
              'id<=',
              select_results[0].id,
              'id>=',
              select_results[select_results.length - 1].id
            ].join('/');
          }


          $.ajax({
            url: TF.api.url + query + limit_query.replace(/\/$/, '') + TF.api.key,
            type:'GET',
          }).done((data) => {
            result[i] = JSON.parse(data).reverse();
            get_num++;
            
          }).fail((jqXHR, textStatus, errorThrown) => {
            if(data.statusText == 'abort') {
              console.log('cancel');
            } else {
              message('データを取得できませんでした', 1);
              this.loading.end();
            }
          });
        }

        let sit = setInterval(() => {
          if(get_num == num) {
            clearInterval(sit);
            
            /*let tmp = [];
            for(let ii in result) {
              tmp = tmp.concat(result[ii]);
            }
            result = tmp;
            tmp = null;*/

            this.loading.end();

            if(result) {
              
              //this.json = result;
              //result = null;
              for(let i in result) {
                this.exportCSV(result[i], i);
              }
            } else {
              alert('JSONデータが設定されていません');
              return false;
            }

          } 
        }, TF.intervaltime);

      })();

      return ;

    } else {
      if(json) {
        this.json = json;
        this.removeMemo();
        return this.exportCSV(this.json, 0);
      } else {
        alert('JSONデータが設定されていません');
        return false;
      }
    }
  }

  removeMemo() {
    let tmp = [];
    let obj = {};

    for(let row in this.json) {
      for(let i in this.json[row]) {
        if(i != 'memo_1' && i != 'remarks') {
          obj[i] = this.json[row][i];
        }
      }

      tmp.push(obj);
      obj = {};
    }
    this.json = tmp;
  }
  
  // jsonをcsv文字列に編集する
  jsonToCsv(json) {
    this.job_data_flag = false;
    let header = this.keyToText(Object.keys(json[0])).join(this.delimiter) + "\n";
    let body = json.map((d) => {
      this.job_data_flag = TF.job_data_switch_date <= moment(d.reg_date, 'YYYY-MM-DD HH:mm').unix();
      return Object.keys(d).map((key) => {
          let val = this.valueToText(key, d[key]);
          val = (val && typeof val == "string")? val.replace('"','""').replace(',','","') : '';
          return `"${val}"`;
      }).join(this.delimiter);
    }).join("\n");

    let csv = header + body;

    let unicodeList = [];
    for (let i = 0; i < csv.length; i += 1) {
      unicodeList.push(csv.charCodeAt(i));
    }

    csv = null;
    header = null;
    body = null;
    
    // 変換処理の実施
    let shiftJisCodeList = Encoding.convert(unicodeList, 'sjis', 'unicode');
    unicodeList = null;

    let uInt8List = new Uint8Array(shiftJisCodeList);
    shiftJisCodeList =  null;

    return uInt8List;
  }

  keyToText(keys) {
    let ary = [];
    for(let i in keys) {
      switch(keys[i]) {
        //都道府県の場合
        case 'prefecture':
        case 'integration_id_1': {
          ary.push('都道府県');
          break;
        }
  
        //時間の場合
        case 'call_time':
        case 'last_call': {
          ary.push('コール日時');
          break;
        }
        case 'recall_time':
        case 'add_info99'/*架電予約時間*/: {
          ary.push('架電予定');
          break;
        }
        //スタッフの場合
        case 'staff_id': {
          ary.push('アポインター');
          break;
        }
        //商材の場合
        case 'call_counterpart':
        case 'add_info98': {
          ary.push('商材');
          break;
        }
        //業種
        case 'add_info100'/*業種*/:
        case 'integration_id_2': {
          ary.push('業種カテゴリ');
          ary.push('業種');
          break;
        }
        //ステータスの場合
        case 'call_status': {
          ary.push('ステータス');
          break;
        }
        //IDの場合
        case 'id': {
          ary.push('ID');
          break;
        }
        //電話番号の場合
        case 'tel_no':
        case 'tel_1': {
          ary.push('電話番号');
          break;
        }
        case 'add_info12'/*携帯*/: {
          ary.push('携帯番号');
          break;
        }
        //音源の場合
        case 'record_file': {
          ary.push('音源ファイル名');
          break;
        }
        //メモと通話時間、ラベル、項目名の場合
        case 'memo_1':
        case 'remarks': {
          ary.push('メモ');
          break;
        }
        case 'duration': {
          ary.push('通話時間');
          break;
        }
        case 'label': {
          ary.push('ラベル');
          break;
        }
        case 'name': {
          ary.push('名前');
          break;
        }
        //その他の場合
        default: {
          ary.push(keys[i]);
          break;
        }
      }
    }
    return ary;
  }

  valueToText(key, val) {
    let cell = '';
    switch(key) {
      //都道府県の場合
      case 'prefecture':
      case 'integration_id_1': {
        if(val && val.match(/[0-9]+/)) {
          cell = TF.area.filter((v) => v.id === (val|0));
          cell = cell.length > 0? cell[0].name: '';
        }
        break;
      }

      //時間の場合
      case 'call_time':
      case 'last_call':
      case 'recall_time':
      case 'add_info99'/*架電予約時間*/: {
        if(typeof val == 'undefined') break;
        if(val != "0000-00-00 00:00:00") {
          cell = (val)? val: '';
        } else {
          cell = ""; 
        }
        break;
      }
      //スタッフの場合
      case 'staff_id': {
        if(val) {
          let staff = TF.GetData.getStaffInfo(val);
          cell = (typeof staff == 'undefined')? '名前不詳': staff.name;
        }
        break;
      }
      //商材の場合
      case 'call_counterpart':
      case 'add_info98': {
        if(val) {
          TF.call_counterpart = typeof TF.call_counterpart == 'string'? JSON.parse(TF.call_counterpart): TF.call_counterpart;
          cell = TF.call_counterpart.filter((v) => v.id === val);
          cell = cell.length > 0? cell[0].name: '';
        }
        break;
      }
      //業種
      case 'add_info100'/*業種*/:
      case 'integration_id_2': {
        
        let tmp = !this.job_data_flag? TF.jobs: TF.jobs_category;        
        let old = !this.job_data_flag? '(旧)':'';
        cell = tmp.filter((v) => (v.id|0) === (val|0));

        let job, cat;
        if(cell.length > 0) {
          cat = cell[0].category || '';
          job = old + cell[0].name;
        } else {
          cat = '';
          job = val;
        }

        cell = cat + ',' + job; 
        break;
      }
      //ステータスの場合
      case 'call_status': {
        cell = '';
        if(val) {
          cell = TF.call_status.filter((v) => v.id === val);
          cell = cell.length > 0? cell[0].name: '';
        }
        break;
      }

      //電話番号の場合
      //IDの場合
      case 'id':
      case 'tel_no':
      case 'tel_1':
      case 'add_info12'/*携帯*/:
      //音源の場合
      case 'record_file':
      //メモと通話時間、ラベル、項目名の場合
      case 'memo_1':
      case 'remarks':
      case 'duration':
      case 'label':
      case 'name':
      //その他の場合
      default: {
        cell = val;
        break;
      }
    }

    return cell + '';
  }

  // csv変換
  exportCSV(json, i) {
  
    //文字列に変換する
    var csv = this.jsonToCsv(json);

    //拡張子
    var extention = this.delimiter == ","? "csv": "tsv";

    //出力ファイル名
    var number = (i|0) + 1;
    number = number < 10? '0' + number: number;
    var exportedFilenmae = number + '_' + (this.filename || 'export') +'.' + extention;

    //BLOBに変換
    var blob = new Blob([csv], { type: 'text/csv' });
    csv = null;

    if (navigator.msSaveBlob) { // for IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
    } else {
        //anchorを生成してclickイベントを呼び出す。
        var link = document.createElement("a");
        if (link.download !== undefined) {
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            blob = null;
        }
    }

    this.loading.end();
  }

  //ファイルアップロード処理
  upload(evt) {
      const _this = this;
    
    // 2：FileAPIがブラウザに対応してるかcheckFileReader関数でチェック
    if (!this.checkFileReader()) {
      alert("エラー：FileAPI非対応のブラウザです。");
    } else {
      // 3: 選択されたファイル情報を取得
      let file = evt.target.files[0];
      if(file) {
        let type = file.type; // MIMEタイプ
        let size = file.size; // ファイル容量（byte）
        let limit = 10000; // byte, 10KB

        // MIMEタイプの判定
        if ( type != 'text/csv' && file.name.indexOf('.csv') == -1) {
            alert('CSVファイルのみアップできます');
            return;
        }

        //readerオブジェクトを作成
        let reader = new FileReader();
            
        // ファイル読み取りを実行
        reader.readAsText(file);

        // 4：読み込んだCSVファイルを取得
        reader.onload = function(event) {
          let result = event.target.result;
          _this.makeJSON(result);
        };
            
        //読み込めなかった場合のエラー処理
        reader.onerror = function() {
          alert("エラー：ファイルをロードできません。");
        };
      }
    }
  }
    
  //アップできるかチェック
  checkFileReader() {
    let isUse = false;
    if (window.File && window.FileReader && window.FileList && window.Blob) {
      isUse = true;
    }
    return isUse;
  }

  //CSVを出力する関数
  makeJSON(csvdata) {
    //5:csvデータを1行ごとに配列にする
    let tmp = csvdata.split("\n");
    
    //6：1行のデータから各項目（各列）のデータを取りだして、2次元配列にする
    //各列を格納する変数
    this.data = [];
    let keys = tmp[0].split(",");

    for (let i = 1; i < tmp.length - 1; i++) {
      //csvの1行のデータを取り出す
      let row_data = tmp[i];
      let row = row_data.split(",");
      let obj = {};
      for(let ii in row) {
        obj[keys[ii]] = row[ii];
      }

      this.data.push(obj);
    }

    this.importAPI();
  }

  //APIに投げる
  importAPI() {
    let count = 0;
    this.loading.start();

    for(let i in this.data) {
      let obj = this.data[i];
      let url = [this.type];
      let action = 'POST'

      if(obj.id) {
        url.push('id');
        url.push(obj.id);
        action = 'PUT';
      } else {
        action = 'POST';
      }

      delete obj.id;
      delete obj.reg_date;
      delete obj.upd_date;

      for(let ii in this.change_data) {
        obj[ii] = this.change_data[ii];
      }

      $.ajax({
        url: TF.api.url + url.join('/') + TF.api.key,
        type: action,
        data: JSON.stringify(obj),
      })
      .done((d) => {
        count++;
        console.log(d);
      
        if(this.data.length == count) {
          message('インポート完了');
          this.loading.end();
          location.reload();
        }
      })
      .fail((data) => {
        message('インポートできませんでした', 1);
        this.loading.end();
      });
    }
    this.loading.end();
  }

  //ファイル名を取得
  filename() {
    return moment().format('[data]YYYY-MM-DD_hhmmss');
  }

  //取得するデータが選択されていれば
  selectData(json) {
    this.json_copy = json;
    let checkbox = $('.js-data-check:checked');
    let tmp = [];

    if(checkbox.length > 0) {
      for(let i in json) {
        let id = json[i].id;
        for(let ii = 0, n = checkbox.length; ii < n; ii++) {
          let cid = checkbox.eq(ii).attr('id').match(/[0-9]+/);
          if(cid) {
            if(cid && (id|0) == (cid[0]|0)) tmp.push(json[i]);
          }
        }
      }
      return tmp; 
    } else {
      return this.json_copy;
    }
  }
}