
/****************** 
モーダル class
*******************/

function modalInit(cls) {
  cls = cls? cls: '';

  $('.js-modal' + cls).on('click', (e) => {
console.log(cls)
    $('.js-btn-reset').mousedown();
    // カレンダー
    //await (() => {
      if($(e.currentTarget).hasClass('-mikomi') && TF.CallEntry) {
        const name = $(e.currentTarget).data('name');
        const worgking_day = name == '見込み（パス）'? 3: null;
        TF.calender.workingdays = [];
        TF.calender.select_workingdays = [];     
        TF.CallEntry.calenderInit(worgking_day);
      }
    //})()

    TF.modal_click_obj = e.currentTarget;
    TF.modal = $('.js-modal-area' + cls); 
    TF.modal.find('.-disable').removeClass('-disable');
    TF.modal.find('.-require').removeClass('-require');
    TF.modal.fadeIn(100, function() {  
      $(this).addClass('-active');

      if(typeof TF.modalAction == 'function') {
        TF.modalAction();
      }
    });
  });

  // カレンダー
  /*
  if(this.cls == '.-mikomi' && TF.CallEntry) {
    TF.calender.workingdays = [];
    TF.calender.select_workingdays = [];
    TF.CallEntry.calenderInit();
  }
*/
  if(cls == '.-history-check') {
    $('.-history-check .-apoint-memo td').append('<textarea class="p-data-textarea" name="memo"></textarea>');
  }

}