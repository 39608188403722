const Calender = class {
  constructor(type) {
    if(!type) return false;
    TF.calender.workingdays = {};

    //選択可能営業日設定
    this.daySetting();

    if(TF.calender.workingdays) {

      type.filter((v) => {
        const setting = {};
        setting[v] = {};

        //datepicker format
        const month_format = 'yyyy/mm';
        const date_format = 'yyyy/mm/dd';

        let options = {
          format: date_format,
          language: "ja",
          startDate: '-3y',
          autoclose: true,
          endDate: moment().add(1, 'months').format('YYYY/MM/DD')
        };
        
        if(v == 'date') {
          setting[v].target = '.p-data-input-duration-select-date';
          setting[v].data = {format: date_format};
        }

        if(v == 'hour') {
          setting[v].target = '.p-data-input-duration-select-time';
        }

        if(v == 'date-duration') {
          setting[v].target = {};
          setting[v].target.start = '.p-data-input-duration-select-date.-start';
          setting[v].target.end = '.p-data-input-duration-select-date.-end';
          setting[v].data = {};
          setting[v].data.start = options;
          setting[v].data.end = options;
        }

        if(v == 'hour-duration') {
          setting[v].target = {};
          setting[v].target.start = '.p-data-input-duration-select-time.-start';
          setting[v].target.end = '.p-data-input-duration-select-time.-end';
          setting[v].data = {};
          setting[v].data.start = options;
          setting[v].data.end = options;
        }

        if(v == 'month') {
          setting[v].target = '.p-data-input-duration-select-month';
          setting[v].data = options;
          setting[v].data.format = month_format;
          setting[v].data.minViewMode = 'months';
        }

        if(v == 'month-duration') {
          setting[v].target = {};
          setting[v].target.start = '.p-data-input-duration-select-month.-start';
          setting[v].target.end = '.p-data-input-duration-select-month.-end';
          setting[v].data = {};
          setting[v].data.start = options;
          setting[v].data.start.format = month_format;
          setting[v].data.start.minViewMode = 'months';
          setting[v].data.end = options;
          setting[v].data.end.format = month_format;
          setting[v].data.end.minViewMode = 'months';
        }

        this.init(setting[v], v);
      });
    }
  }

  init(setting, type) {
    TF.calender.obj = {};
    //moment format
    const month_format = 'YYYY/MM';
    const date_format = 'YYYY/MM/DD';
    const time_format = 'HH:mm';
    const minTime = '6:00am';
    const maxTime = '11:30pm';
    const timeFormat = 'H:i';
    const step = 5;

    this.first = true;

    if((typeof setting.target == 'object' && typeof setting.data == 'object')) {
      TF.calender.obj[type] = {};
      
      if(type.indexOf('month') > -1) {
        TF.calender.obj[type].start = 
          $(setting.target.start)
          .datepicker(setting.data.start)
          .datepicker('setDate','today')
          .on('changeDate', (e) => { 

            const start = moment($(setting.target.start).val(), month_format);
            const end = moment($(setting.target.end).val(), month_format);    
            const date = new Date(e.date);

            TF.calender.select_start_date = date;
            if($(setting.target.end).val() == '' || end.isBefore(start)) {
              $(setting.target.end).datepicker('setDate', moment(date).format(month_format));
            }
          });

        TF.calender.obj[type].end = 
          $(setting.target.end)
          .datepicker(setting.data.end)
          .datepicker('setDate','today')
          .on('changeDate', (e) => {

            const start = moment($(setting.target.start).val(), month_format);         
            const end = moment($(setting.target.end).val(), month_format);    
            
            const date = new Date(e.date);
            TF.calender.select_end_date = date;
            if($(setting.target.start).val() == '' || end.isBefore(start)) {
              $(setting.target.start).datepicker('setDate', moment(date).format(month_format));
            }
          });

      } else if(type.indexOf('hour') > -1) {
      
          TF.calender.obj[type].start = 
            $(setting.target.start).timepicker({
              'minTime': minTime,
              'maxTime': maxTime,
              'timeFormat': timeFormat,
              'step': step,
              'scrollDefault': 'now',
            })
            .on('changeTime', function() {
              const start = moment($(setting.target.start).val(), time_format);         
              const end = moment($(setting.target.end).val(), time_format);           
              if (end.isBefore(start)) {
                $(setting.target.end).val(start.subtract(-10, 'minutes').format(time_format));
              }
            });

          TF.calender.obj[type].end = 
            $(setting.target.end).timepicker({
              'minTime': minTime,
              'maxTime': maxTime,
              'timeFormat': timeFormat,
              'step': step,
              'scrollDefault': 'now',
            })
            .on('changeTime', function() {
              const start = moment($(setting.target.start).val(), time_format);
              const end = moment($(setting.target.end).val(), time_format);           
              if (end.isBefore(start)) {
                $(setting.target.start).val(end.subtract(10, 'minutes').format(time_format));
              }
            });
          
      } else {
        TF.calender.obj[type].start = [];
        TF.calender.obj[type].end = [];
      
        let start_elm = $(setting.target.start);
        let end_elm = $(setting.target.end);

        for(let i = 0, n = start_elm.length; i < n; i++) {
          TF.calender.obj[type].start[i] = 
          start_elm.eq(i)
          .datepicker(setting.data.start)
          .datepicker('setDate','today')
          .on('changeDate', (e) => { 
            const start = moment(start_elm.eq(i).val(), date_format);         
            const end = moment(end_elm.eq(i).val(), date_format);    
            
            const date = new Date(e.date);
            TF.calender.select_start_date = date;
            if(end_elm.eq(i).val() == '' || end.isBefore(start)) {
              end_elm.eq(i).datepicker("setDate", moment(date).format(date_format));
            }
          });
        }

        for(let i = 0, n = end_elm.length; i < n; i++) {
          TF.calender.obj[type].end[i] = 
            end_elm.eq(i)
            .datepicker(setting.data.end)
            .datepicker('setDate','today')
            .on('changeDate', (e) => {

              const start = moment(start_elm.eq(i).val(), date_format);         
              const end = moment(end_elm.eq(i).val(), date_format);    

              const date = new Date(e.date);
              TF.calender.select_end_date = date;
              if(start_elm.eq(i).val() == '' || end.isBefore(start)) {
                start_elm.eq(i).datepicker("setDate", moment(date).format(date_format));
              }
            });
        }


        TF.modalAction = () => {
          //let href = location.href;
          $('#date_start, #date_end, #recall_date_start, #recall_date_end').datepicker('update','');
          /*if(href.match(/\/?[^_]list/)) {
            //$('#date_end').datepicker('setDate','today');
            $('#date_start').datepicker('update','');
          }

          if(href.match(/\/?call_log/)) {
            $('#date_start').datepicker('update','');
          }

          if(href.match(/\/?recall_list/)) {
            $('#date_start, #date_end, #recall_date_start, #recall_date_end').datepicker('update','');
          }*/
          
        }

      }

    } else {
      if(type == 'hour') {
        TF.calender.obj[type] = $(setting.target).timepicker({
          'minTime': minTime,
          'maxTime': maxTime,
          'timeFormat': timeFormat,
          'step': step,
          'scrollDefault': 'now',
        });
      } else {


        //選択可能営業日を追加
        setting.data = this.setWorkingDays(setting.data);

        if(setting.data.beforeShowDay) {

          TF.history.apoint_date = "";

          this.activeCancel = (e) => {
            if(!$(e.currentTarget).hasClass('is-workingday')) return false;
          }

          TF.calender.obj[type] = $(setting.target)
          .datepicker(setting.data)
          .on('changeMonth changeYear', (e) => {
            $('.js-select-calender td').on('click', this.activeCancel);
          })
          .on('changeDate', (e) => { 
      
            $('.js-select-calender td').on('click', this.activeCancel);
            const date = moment(e.date).format('YYYY/M/D');
            if(!TF.calender.workingdays[date]) {
              const unix = moment(e.date).unix();
            } else {
              TF.history.apoint_date = moment(e.date).format('YYYY/MM/DD');
            }

          });
      
          $('.datepicker td').on('click', this.activeCancel);
        } else {
          TF.calender.obj[type] = $(setting.target).datepicker(setting.data).datepicker('setDate','today');
        }


      }
      
    }
  }

  //カレンダー日付初期設定
  daySetting() {
    // 営業日設定
    //今日
    this.add = 0;
    let setToday = () => {
      let date_obj = new Date();
      date_obj.setDate( date_obj.getDate() + this.add );
      
      if(date_obj.getDay() == 0 || date_obj.getDay() == 6 ) {
        this.add++;
        setToday(this.add);
      } else {
        let d = moment().add(this.add,'days').format('YYYY/M/D');
        TF.calender.workingdays[d] = d;
      }
    };
    setToday();


    //次の日
    if(TF.calender.shukujitsu) {

      let i = 0;
      let lock = false;
      let date_obj = new Date();
      if(this.add > 0) {
        date_obj.setDate( date_obj.getDate() + this.add);
      }

      let check = () => {
        
        // 営業日チェック
        if(i < TF.working_days) {

          // 一日進める
          date_obj.setDate( date_obj.getDate() + 1);
          
          // 休日だったら1日進める
          if(date_obj.getDay() == 0 || date_obj.getDay() == 6 ) {
            check();
          }

          // 祝日だったら1日進める
          const ymd = date_obj.getFullYear() + '/' + (date_obj.getMonth() + 1) + '/' + date_obj.getDate();
          const shukujitsu = TF.calender.shukujitsu[ymd];

          if(shukujitsu) {
            check();
          } else {
            
            // 営業日だったらセットする
            if(Object.keys(TF.calender.workingdays).length < TF.working_days) {
              TF.calender.workingdays[ymd] = ymd;
              i++;
              check();
            } else {

              this.lastday = moment(ymd, 'YYYY/M/D').add(-1, 'days').format('YYYY/M/D');

              // 3営業日で終り カレンダー初期化
              if(!lock){
                lock =  true;
                return true;
              } 
              return false;
            }
          }
        }
      }

      check();
    } 

  }

  //選択可能営業日設定
  setWorkingDays(setting) {
    //とりあえずページが見込みリストであれば
    if($('.js-modal-area').hasClass('-edit-call_history')) {
      let obj = {
        dateformat: 'yyyy/mm/dd',
        language:'ja',
        startDate: Date(),
        endDate: moment(this.lastday, 'YYYY/M/D').toDate(),
        todayHighlight: true,
      
        beforeShowDay: (date) => {
          let y = date.getFullYear();
          let m = date.getMonth() + 1;
          let d = date.getDate();
          const ymd = y + '/' + m + '/' + d;
  
          m = m >= 10 ? m: '0' + m;
          d = d >= 10 ? d: '0' + d;
  
          const y0m0d = y + '/' + m + '/' + d;
  
          let ary = [];
  
          if(TF.calender.shukujitsu[ymd]) {
            //ary = [false, 'is-holiday', ""];
            ary = {enable: false, classes: 'is-holiday'};
          } else if(TF.calender.workingdays[ymd] && TF.history.apoint_date !== y0m0d) {
            ary = {enable: true, classes: 'is-workingday'};
          } else if(TF.calender.workingdays[ymd] && TF.history.apoint_date == y0m0d) {
            ary = {enable: true, classes: 'is-selected is-workingday'};
          } else if(!TF.calender.workingdays[ymd]) {
            ary = {enable: false};
          }
          return ary;
        }
      }

      setting = obj;
    }

    return setting;
  }
}