const List = class {
  constructor() {
    TF.list_contents = [];
  }

  //リスト出力
  printList(flag) {

    let layout = {};

    //各セルをデータに合わせて出力する関数
    //テーブルヘッダ
    layout.thead = (id) => {
      return '<thead id="tr' + id + '" class="p-data-table-tr">';
    };
    
    //tr
    layout.tr = (id) => { 
      return '<tr id="tr' + id + '" data-id="' + id + '" class="p-data-table-tr">';
    };
    
    //th
    layout.th = (data, num) => {
      let cls = ' -' + data[0];
      let content = data[1];

      if(data[0] == 'check') {
        content = '\
        <input type="checkbox" id="all_select" class="p-data-table-td-checkbox js-data-check" data-cellnum="' + num + '" />\
        <label for="all_select"></label>';
      }

      return '<th class="p-data-table-th' + cls + '"><span class="u-text">' + content + '</span></th>';
    };

    //checkboxの場合
    layout.check = (data, num) => {
      //const checked = data.active_flag == 1? ' checked': '';
      const checked = '';
      this.toggle_column = 1;

      return '\
      <td class="p-data-table-td -func -check">\
        <input type="checkbox" id="row' + data.id + '" class="p-data-table-td-checkbox js-data-check" data-cellnum="' + num + '" ' + checked + '/>\
        <label for="row' + data.id + '"></label>\
      </td>';
    };

    //削除ボタンの場合
    layout.del = (data) => {
      return '\
      <td class="p-data-table-td -func -del">\
        <a href="javascript:void(0)" id="del' + data.id + '" data-id="' + data.id + '" class="p-data-table-td-delbtn js-data-del"><span>del</span></a>\
      </td>';
    };

    //編集ボタンの場合
    layout.edit = (data) => {
      return '\
      <td class="p-data-table-td -func -edit">\
        <a href="javascript:void(0)" id="edit' + data.id + '" data-id="' + data.id + '" class="p-data-table-td-editbtn js-modal -edit"><span>edit</span></a>\
      </td>';
    };

    //音声ボタンの場合
    layout.sound = (data, type) => {
      layout.sound_btn = data.record_file ? '<a href="javascript:void(0)" class="js-audio-btn" data-src="' + data.record_file + '"><span class="-sound-icon"></span></a><br><a href="' + TF.mp3dir + data.record_file + '.mp3" class="js-dl-btn" target="_blank" download>DL↓</a>': '-';
      
      return '<td class="p-data-table-td -'+ type +'">' + layout.sound_btn + '</td>';
    };

    //テキスト変換ボタンの場合
    layout.convert = (data, type) => {
      layout.convert_btn = '-'

      if(data.record_file) {
        if(data.record_to_text) {
          layout.convert_btn = `<a href="javascript:window.open('${TF.js_url_path}record_to_text.html?id=${data.id}')" id="convert${data.id}" data-id="${data.id}" data-src="${data.record_file}" data-text="${data.record_to_text}" class="p-data-table-td-convertbtn "><span class="p-data-table-td-convertbtn-view">view</span></a>`;
        } else {
          layout.convert_btn = `<a href="javascript:void(0)" id="convert${data.id}" data-id="${data.id}" data-src="${data.record_file}" class="p-data-table-td-convertbtn js-btn-convert"><span>変換</span></a>`;
        }
      }

      return '\
      <td class="p-data-table-td -func -convert">' + layout.convert_btn + '</td>';
    };

    //基本
    layout.column = (data, type) => {
      data = data? data: "";
      return '<td class="p-data-table-td -'+ type +'">' + data + '</td>';
    };


    //データのタイプに合わせてtdを返す
    let cell;
    layout.td = (type, data, num) => {
      switch(type) {
        //checkboxの場合
        case 'check': {
          cell = layout.check(data, num);
          break;
        }
        //削除ボタンの場合
        case 'del': {
          cell = layout.del(data);
          break;
        }
        //編集ボタンの場合
        case 'edit': {
          cell = layout.edit(data);
          break;
        }
        //都道府県の場合
        case 'prefecture':
        case 'integration_id_1': {
          cell = data[type];
          if(cell && cell.match(/[0-9]+/)) {
            cell = TF.area.filter((v) => v.id === (cell|0));
            cell = cell.length > 0? cell[0].name: '';
          }
          cell = layout.column(cell, type);
          break;
        }
        //電話番号の場合
        case 'tel_no':
        case 'tel_1':
        case 'add_info12'/*携帯*/:　 {
          cell = data[type];
          cell = layout.column(cell, type);
          break;
        }
        //IDの場合
        case 'id': {
          cell = data.id;
          cell = layout.column(cell, type);
          break;
        }
        //時間の場合
        case 'call_time':
        case 'last_call':
        case 'upd_date':
        case 'recall_time':
        case 'add_info99'/*架電予約時間*/: {
          if(typeof data[type] == 'undefined') break;
          if(data[type] != "0000-00-00 00:00:00") {
            cell = (data[type])? dateConverter(Date.parse(data[type].replace(/\-/g, '/')).toString(), 'list'): '-';
          } else {
            cell = "-"; 
          }

          cell = layout.column(cell, type);
          break;
        }
        //スタッフの場合
        case 'staff_id': {
          cell = '';
          if(data.staff_id) {
            let staff = TF.GetData.getStaffInfo(data.staff_id);
            staff = (typeof staff == 'undefined')? '名前不詳': staff.name;
            cell = layout.column(staff, type);
          }
          break;
        }
        //商材の場合
        case 'call_counterpart':
        case 'add_info98': {
          cell = '';
          if(data[type]) {
            TF.call_counterpart = typeof TF.call_counterpart == 'string'? JSON.parse(TF.call_counterpart): TF.call_counterpart;
            cell = TF.call_counterpart.filter((v) => v.id === data[type]);
            cell = cell.length > 0? cell[0].name: '';
          }
          cell = layout.column(cell, type);
          break;
        }
        //業種カテゴリ
        case 'jobs_category': {
          //cell = data[type];
          let id = data.add_info100 || data.integration_id_2;
          let tmp = !this.job_data_flag? TF.jobs: TF.jobs_category;
          cell = tmp.filter((v) => (v.id|0) === (id|0));
          cell = cell.length > 0? cell[0].category: '';
          cell = layout.column(cell, type);
          break;
        }
        //業種
        case 'add_info100'/*業種*/:
        case 'integration_id_2': {
          //cell = data[type];
          let tmp = !this.job_data_flag? TF.jobs: TF.jobs_category;
          let old = !this.job_data_flag? '(旧)':'';
          cell = tmp.filter((v) => (v.id|0) === (data[type]|0));
          cell = cell.length > 0? old + cell[0].name: data[type];
          cell = layout.column(cell, type);
          break;
        }
        //ステータスの場合
        case 'call_status': {
          cell = '';
          if(data.call_status) {
            cell = TF.call_status.filter((v) => v.id === data.call_status);
            cell = cell.length > 0? cell[0].name: '';
          }
          cell = layout.column(cell, type);
          break;
        }
        //音源の場合
        case 'record_file': {
          cell = layout.sound(data, type);
          break;
        }
        //メモと通話時間、ラベル、項目名の場合
        case 'memo_1':
        case 'remarks':
        case 'duration':
        case 'label':
        case 'name': {
          cell = layout.column(data[type], type);
          break;
        }
        //対応状況の場合
        case 'condition': {
          let status = '予約中';

          let current = moment(new Date()).unix();
          let recall_time = moment(data.recall_time, 'YYYY/MM/DD HH:mm').unix();

          this.condition_class = '';

          if(current >= recall_time) {
            status = '状況不明';
            this.condition_class = '-unknown';
          }

          if(data.closing_flag == 1 && !data.cancel_flag) {
            status = '再コール済';
            this.condition_class = '-closing';
          }

          if(!data.closing_flag && data.cancel_flag == 1) {
            status = 'キャンセル';
            this.condition_class = '-cancel';
          }

          cell = layout.column(status, type);
          break;
        }
        //テキスト変換ボタンの場合
        case 'convert': {
          cell = data;
          cell = layout.convert(cell, type);
          break;
        }
        //その他の場合
        default: {
          cell = layout.column(data, type);
          break;
        }
      }

      //tdを返す
      return cell;
    }

    //データをループ
    let table = [];
    this.job_data_flag = false;

    for(let i = 0, n = Object.keys(TF.list_contents).length; i < n; i++ ) {
      let data = TF.list_contents[i];

      this.job_data_flag = TF.job_data_switch_date <= moment(data.reg_date, 'YYYY-MM-DD HH:mm').unix();

      // ヘッダ出力
      if(i == 0) {
        table.push(layout.thead('_head'));
        for(let ii = 0, nn = Object.keys(TF.list_column).length; ii < nn; ii++ ) {
          table.push(layout.th(TF.list_column[ii], ii));
        }
        table.push('</thead>');
      }

      // リスト出力
      let tr = '';
      for(let ii = 0, nn = Object.keys(TF.list_column).length; ii < nn; ii++ ) {
        if(ii == 0) {
          //table.push(layout.tr(data.id));
          tr = layout.tr(data.id);
        }

        //table.push(layout.td(TF.list_column[ii][0], data, ii));
        tr += layout.td(TF.list_column[ii][0], data, ii);

        if(ii + 1 == nn) {
          //table.push('</tr>');
          tr += '</tr>';

          //追加のクラスがあれば
          if(this.condition_class) {
            let cls = this.condition_class? ' ' + this.condition_class: '';
            tr = tr.replace(/(<tr.+?class="[^"]+)"/, function(){
              return arguments[1] + cls + '"';
            });
          }

          table.push(tr);
        }
      }

    }

    //出力
    $('.p-data-table').not('.-view').html('');
    if(table.length > 0) {
      table = table.join('\n')
    } else {
      table = '<tr class="p-data-table-tr"><td class="p-data-table-td">検索結果は0件でした。</td></tr>';
    }

    $(table).appendTo($('.p-data-table').not('.-view'));
    
    //テーブルソート
    /*$('.p-data-table').not('.-view').tablesorter({headers: {
      0: { sorter: false }
    }}).trigger('sortReset').trigger('updateAll');
*/

    let opt = this.toggle_column? {headers: {
      0: { sorter: false }
    }}:null;

    $('.p-data-table').not('.-view').tablesorter(opt).trigger('sortReset').trigger('updateAll');
    
    //一括切り替え
    $('label[for="all_select"]').on('click', (e) => {
      let checked = !$(e.currentTarget).prev().prop('checked');
      $('.p-data-table-td.-check input[data-cellnum="' + $(e.currentTarget).prev().data('cellnum') + '"]').prop('checked', checked);
    });

    //プレイヤー初期化
    //if(layout.sound_btn !== '-') { 
      $('.js-audio-btn').on('click', (e) => {
        let src = $(e.currentTarget).data('src');
        //src = '20201014-090239-5f8641e51c51e.wav';
        $("#player").attr('data-src', src).empty().html('<audio preload></audio>');
        playerInit(src);
      });
    //} 

  }


  //項目出力
  printInputList(type, dist, mode) {
    let layout = {};
       
    //編集ボタン
    layout.edit_btn =  () => {
      return '\
          <div class="p-data-btnbox">\
            <a href="javascript:void(0)" class="p-data-btn -edit js-btn-edit"><span>変更</span></a>\
          </div>';
    };

    //削除ボタン
    layout.del_btn =  () => {
      return '\
          <div class="p-data-btnbox">\
            <a href="javascript:void(0)" class="p-data-btn -del js-btn-del"><span>削除</span></a>\
          </div>';
    };

    //input
    layout.input =  (data, id) => {
      return '<input type="text" name="' + data.name + '" value="' + data.value + '" data-orig="' + data.value + '" placeholder="' + data.placeholder + '" class="-input-text">';
    };

    //checkbox
    layout.checkbox =  (data, id) => {
      const checked = data.value == 1 ? 'checked': ''; 
      return ' <input type="checkbox" id="' + data.name + '_' + id + '" name="' + data.name + '_' + id + '"' + checked + ' value="1" data-orig="' + checked.replace(' ', '') + '">\
      <label for="' + data.name + '_' + id + '"></label>';
    };

    //textarea
    layout.textarea =  (data, id) => {
      return '<textarea class="p-data-textarea" id="' + data.name  + '_' +  id +'" name="' + data.name + '_' +  id + '" data-orig="' + data.value + '" placeholder="' + data.placeholder + '">' + data.value + '</textarea>';
    };

    //select2box
    layout.select2box =  (data, id) => {
      TF.select2_list.push({id: `${data.name}_${id}`, parent:{id: id, table: type}});

      return `<div class="p-data-selectbox-wrapper">
      <select name="${data.name}_${id}_select" id="${data.name}_${id}_select" class="p-data-selectbox -hide" multiple="multiple">
        <option></option>
        <optgroup label="${data.placeholder}"></optgroup>
      </select>
    </div>`;
    };

    //input一つ
    layout.single = (data) => {
      data = data? data: "";
      return '\
      <div class="p-data-input-area" data-id="' + data.id + '" data-telforce_id="' + data.telforce_id + '">\
        <div class="p-data-input">' + layout.input(data) + '</div>\
        <div class="p-data-input-btn">' + 
          layout.edit_btn() + layout.del_btn() +
        '</div>\
      </div>';
    };

    //input二つ
    layout.two = (data) => {
      data = data? data: "";
      return '\
      <div class="p-data-input-area" data-id="' + data.id + '" data-telforce_id="' + data.telforce_id + '">\
        <div class="p-data-input -two-column">' +
          layout.input(data.input_data[0]) + layout.input(data.input_data[1]) +
        '</div>\
        <div class="p-data-input-btn">' + 
          layout.edit_btn() + layout.del_btn() +
        '</div>\
      </div>';
    };

    //input1つとテキストエリアとチェックボックス
    layout.set1 = (data) => {
      data = data? data: "";
      
      return '\
      <ul class="p-data-input-group" data-id="' + data.id + '" data-telforce_id="' + data.telforce_id + '">\
        <li class="p-data-input-group-item -checkbox" >\
          <div class="p-data-checkbox">' +
          layout.checkbox(data.input_data[0], data.id) +
          '</div>\
        </li>\
        <li class="p-data-input-group-item">\
          <div class="p-data-input">' +
            layout.input(data.input_data[1], data.id) +
          '</div>\
          <div class="p-data-input">' +
          layout.textarea(data.input_data[2], data.id) +
          '</div>\
        </li>\
        <li class="p-data-input-group-item -btn -vertical">' + 
        layout.edit_btn() + layout.del_btn() +
        '</li>\
      </ul>';
    };

    //input1つとチェックボックス
    layout.set2 = (data) => {
      data = data? data: "";
      return '\
      <ul class="p-data-input-group" data-id="' + data.id + '" data-telforce_id="' + data.telforce_id + '">\
        <li class="p-data-input-group-item -checkbox">\
          <div class="p-data-checkbox">' +
          layout.checkbox(data.input_data[0], data.id) +
          '</div>\
        </li>\
        <li class="p-data-input-group-item">\
          <div class="p-data-input">' +
            layout.input(data.input_data[1]) +
          '</div>\
        </li>\
        <li class="p-data-input-group-item -btn">' + 
        layout.edit_btn() + layout.del_btn() +
        '</li>\
      </ul>';
    };

    //input1つと複数選択セレクトボックス
    layout.set3 = (data) => {
      data = data? data: "";

      return '\
      <ul class="p-data-input-group" data-id="' + data.id + '" data-telforce_id="' + data.telforce_id + '">\
        <li class="p-data-input-group-item -checkbox">\
          <div class="p-data-checkbox">' +
          layout.checkbox(data.input_data[0], data.id) +
          '</div>\
        </li>\
        <li class="p-data-input-group-item">\
          <div class="p-data-input">' +
            layout.input(data.input_data[1]) +
          '</div>\
        </li>\
        <li class="p-data-input-group-item">\
          <div class="p-data-input">' +
            layout.select2box(data.input_data[2], data.id) +
          '</div>\
        </li>\
        <li class="p-data-input-group-item -btn -vertical">' + 
        layout.edit_btn() + layout.del_btn() +
        '</li>\
      </ul>';
    };

    layout.check_data = (data) => {
      //データごとに切り替え
      switch(dist) {
        case 'office': {
          return {id: data.id, telforce_id: data.telforce_id, name: 'name', value: data.office_name, placeholder: 'オフィス名'};
        }
        case 'dept_unit': {
          let email = data.email == null? "" : data.email;

          return {id: data.id, telforce_id: data.telforce_id, input_data: 
            [
              {name: 'name', value: data.unit_name, placeholder: 'グループ名'},
              {name: 'email', value: email, placeholder: 'メールアドレス'}
            ]
          };
        }
        case 'call_status': {
          return {id: data.id, telforce_id: data.id, input_data: 
            [
              {name: 'call_status_active_flag', value: data.active_flag},
              {name: 'name', value: data.name, placeholder: 'ステータス名'},
              {name: 'fixed_phrase', value: data.fixed_phrase, placeholder: '通常・見込み・禁止のいずれかを入力（禁止を入力した場合、選択時に該当の電話番号が禁止番号として設定されます）'}
            ]
          };
        }
        case 'call_counterpart': {
          console.log(data.jobs)
          return {id: data.id, telforce_id: data.id, input_data:
            [
              {name: 'call_counterpart_active_flag', value: data.active_flag},
              {name: 'name', value: data.name, placeholder: TF.call_counterpart_label + '名'},
              {name: 'jobs', value: data.jobs, placeholder: '業種を指定'}
            ]
          };
        }
      }
    }

    //データをループ
    let table = [];
    for(let i in TF.list_contents[dist]) {
      let data = TF.list_contents[dist][i];
      table.push(layout[type](layout.check_data(data)));
    }

    //出力
    let distination = $('.p-data.-' + dist + ' .p-data-area').html('');
    $(table.join('\n')).appendTo(distination);
  
    if(typeof TF.select2_list == 'object' && TF.select2_list_id) setSelectList(TF.select2_list, TF.select2_list_id);

    table = [];
  } 

  //ボタンアクション
  btnAction() {
  
    if($('.js-data-btn.-add')[0]) {
      $('.js-data-btn.-add').on('click', (e) => {

      });
    }
  }

} 