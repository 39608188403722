/***********
  loading
  arg: jquery object
***********/

TF.loading = class {
  
  constructor($loader) {
    this.$loader = $loader;
    this.loding_class = '-loading';
  }

  start() {
    if(!this.loading_flag) {
      this.loader();
    }

    if(this.$loader) {
      this.$loader.addClass(this.loding_class);
      if(typeof this.$loader[0] != 'undefined' && this.$loader[0].nodeName == 'BUTTON') {
        this.$loader.prop('disabled', true);
      }
    }
  }

  end() {
    if(this.$loader) {
      this.$loader.removeClass(this.loding_class);
      if(typeof this.$loader[0] != 'undefined' && this.$loader[0].nodeName == 'BUTTON') {
        this.$loader.prop('disabled', false);
      }
    }

    if(this.loading_flag) {
      this.loading_flag = false;
      $('.loader').remove();
    }
  }

  loader() {
    const loader = `<div class="loader">
    <div class="loader-inner logo">
      <svg version="1.1" 
      xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 74.668 76.43"
      xml:space="preserve" class="logo_svg">
        <path d="M66.404,61.938c-0.167,0.16-0.329,0.312-0.506,0.465c-0.243,0.232-0.508,0.455-0.791,0.654
          c-9.187,7.094-26.685,3.856-41.006-8.125C9.636,42.825,3.364,26.11,8.833,15.813c0.133-0.271,0.281-0.539,0.444-0.787
          c0.108-0.192,0.238-0.387,0.372-0.57c-0.424,0.4-0.801,0.802-1.149,1.219c-0.571,0.685-1.075,1.428-1.523,2.211
          c-0.013,0.014-0.02,0.039-0.033,0.055c-0.542,0.749-1.047,1.508-1.521,2.269c-9.42,15.372-6.418,35.718,7.827,47.665
          C27.194,79.489,47.235,79.1,60.675,67.79c0.96-0.795,1.888-1.66,2.769-2.582c0.01-0.01,0.038-0.025,0.056-0.035
          c0.692-0.58,1.317-1.197,1.896-1.896C65.741,62.867,66.08,62.407,66.404,61.938z" id="path1" />
        <path d="M70.42,55.145c0.192-0.232,0.371-0.474,0.547-0.725c2.879-3.988,4.164-10.246,3.55-17.078
          c-0.114,1.052-0.333,2.055-0.651,3.014c-0.227,0.674-0.504,1.333-0.84,1.939c-0.335,0.614-0.725,1.183-1.174,1.719
          c-0.045,0.055-0.105,0.102-0.149,0.162c-0.08,0.09-0.153,0.169-0.226,0.251c-6.408,6.866-21.252,4.24-33.588-6.089
          C25.467,27.971,20.276,13.693,26.099,6.203c0.028-0.027,0.052-0.073,0.079-0.101c0.051-0.068,0.096-0.127,0.14-0.182
          c0.442-0.541,0.951-1.012,1.488-1.459c0.54-0.443,1.141-0.84,1.761-1.164c0.879-0.477,1.833-0.87,2.857-1.168
          c-6.83,0.598-12.766,2.972-16.181,6.494c-0.219,0.227-0.435,0.452-0.626,0.678c-0.003,0.004-0.011,0.024-0.017,0.03
          c-0.387,0.458-0.711,0.937-1.016,1.432c-0.125,0.192-0.255,0.406-0.368,0.618c-5.112,9.334,1.25,25.224,15.34,36.992
          c13.973,11.705,30.682,15.18,38.995,8.576c0.209-0.176,0.422-0.344,0.626-0.521c0.427-0.392,0.845-0.801,1.219-1.254
          C70.41,55.166,70.415,55.153,70.42,55.145z" id="path2"/>
        <path d="M72.618,35.553c0.353-0.736,0.595-1.555,0.716-2.443c0.093-0.702,0.115-1.463,0.048-2.256
          c-0.199-2.982-1.447-6.448-3.577-9.969c0.018,0.279,0.004,0.527-0.019,0.777c-0.019,0.24-0.05,0.476-0.099,0.701
          c-0.107,0.435-0.266,0.838-0.494,1.207c-0.087,0.142-0.184,0.287-0.293,0.425c-0.003,0-0.005,0.005-0.011,0.007
          c-0.339,0.399-0.747,0.699-1.221,0.949c-3.167,1.556-9.185-0.322-14.585-4.829c-5.531-4.63-8.464-10.509-7.115-13.827
          c0.149-0.363,0.345-0.689,0.592-0.988c0.001,0,0.003-0.002,0.006-0.006c0.104-0.127,0.23-0.251,0.368-0.371
          c0.317-0.284,0.682-0.516,1.102-0.697c0.207-0.088,0.433-0.164,0.666-0.225c0.241-0.061,0.49-0.103,0.75-0.135
          c-3.838-1.501-7.473-2.109-10.437-1.778c-0.792,0.086-1.542,0.237-2.221,0.455c-0.854,0.276-1.62,0.645-2.278,1.129
          c-0.425,0.314-0.819,0.659-1.152,1.063c-0.021,0.018-0.031,0.033-0.055,0.059c-0.076,0.094-0.14,0.186-0.209,0.283
          c-3.893,5.389,1.161,16.509,11.512,25.172c10.249,8.574,21.893,11.628,26.653,7.165c0.149-0.153,0.306-0.314,0.455-0.478
          c0.014-0.03,0.03-0.042,0.053-0.062C72.112,36.479,72.388,36.034,72.618,35.553z" id="path3"/>
        <path d="M69.043,17.503c-3.933,4.209-10.522,4.443-14.736,0.532c-4.199-3.907-4.43-10.539-0.499-14.737
          c3.922-4.18,10.512-4.43,14.709-0.5C72.741,6.717,72.969,13.303,69.043,17.503z" id="path4"/>
   </svg>
   
    </div>
  </div>`;
    $('body').append($(loader));
    this.loading_flag = true;
  }
};
