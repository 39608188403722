
TF.REPORT_TEMPLATES = {
  table: '\
    <div class="p-report-box">\
      <div class="p-report-box-header">\
        <h3 class="u-ttl">%TITLE%</h3>\
        <div class="p-report-useranchor-list"></div>\
      </div>\
      <div class="p-report-box-table-area">\
        <table class="p-data-table">%ROWS%</table>\
      </div>\
    </div>',

  bar: '\
  <div class="p-report-box">\
    <div class="p-report-box-header">\
      <h3 class="u-ttl">%TITLE%</h3>\
      <div class="p-report-useranchor-list"></div>\
    </div>\
    <div class="p-report-box-graph-area">\
      <canvas id="%CANVASID%" width="400" height="250"></canvas>\
    </div>\
  </div>',

  circle: '\
  <div class="p-report-box">\
    <div class="p-report-box-header">\
      <h3 class="u-ttl">%TITLE%</h3>\
      <div class="p-report-useranchor-list"></div>\
    </div>\
    <div class="p-report-box-graph-area">\
      <canvas id="chart-pie" width="400" height="250"></canvas>\
    </div>\
  </div>'
}