/****************** 
 * 管理画面・コール画面用
*******************/

function adminInit(setting) {
  
  TF.GetData = new GetData();
  
  TF.GetData.getJobsList();
  TF.GetData.getAndPutData(JSON.stringify(TF.jobs_category), 'jobs_category');
  new Calender(setting.calender_type);
  new Admin(setting);
}

const Admin = class {
  constructor(setting) {
    $('form').on('submit',false);
    $('#limitselect').val(TF.limit);
    this.page_type = setting.type;
    this.calender_type = setting.calender_type;
    this.select_list = ['staff', 'call_counterpart', 'call_status',  'jobs_category_list', 'jobs', 'area'];
    this.select_list_edit = ['staff_edit', 'call_counterpart_edit', 'call_status_edit',  'jobs_category_edit', 'jobs_edit', 'area_edit'];
    
    this.select_column_query = {'call_history':'sel.id/sel.customer_id/sel.integration_id_1/sel.integration_id_2/sel.staff_id/sel.tel_no/sel.call_time/sel.call_type/sel.duration/sel.call_status/sel.call_counterpart/sel.record_file/sel.memo_1/sel.recall_time/sel.recall_flag/sel.closing_flag/sel.cancel_flag/sel.active_flag/sel.reg_date/sel.upd_date/sel.record_to_text', 'customer':'sel.id/sel.customer_id/sel.category/sel.client_id/sel.staff_id/sel.project_id/sel.name/sel.kana/sel.staff/sel.zip/sel.prefecture/sel.address_1/sel.address_2/sel.address_kana/sel.tel_1/sel.tel_2/sel.tel_3/sel.tel_4/sel.call_count/sel.remarks/sel.rate/sel.call_status/sel.last_call/sel.add_info12/sel.add_info98/sel.add_info99/sel.add_info100/sel.prohibit_flag/sel.access_flag/sel.active_flag/sel.account_flag/sel.closing_date/sel.contract_date/sel.cancellation_date/sel.enable/sel.reg_date/sel.upd_date'}

    this.reset('.p-modal');

    TF.search_query = false;

    TF.select2_list = [];
    TF.set_select2_list = {};

    TF.List = new List();
    //ローディングインスタンス
    this.loading = new TF.loading($('.js-btn-search'));
    this.loading.start();

    if(this.page_type == 'staff') {
      TF.call_counterpart = [];
      TF.call_status = [];
    }

    const sit = setInterval(() => {
        
      if(TF.call_counterpart && TF.call_status && typeof TF.GetData == 'object') {
        clearInterval(sit);

        if(
          this.page_type != 'staff' && 
          this.page_type != 'officegroup'
        ) {
          TF.call_counterpart = JSON.parse(TF.call_counterpart);
          TF.call_status = JSON.parse(TF.call_status);
          

          if(this.page_type != 'adminvalue') {
            TF.GetData.getAreaList();
          }
        }

        if(this.page_type != 'adminvalue') {
          setSelectList(this.select_list);
        }

        new Calender(this.calender_type);

        if(this.page_type == 'ban_list' || this.page_type == 'list') {

          this.search('customer');
          this.customerInit(this.page_type);
        }

        if(this.page_type == 'officegroup') {
          TF.GetData.getOfficeList();
          TF.GetData.getGroupList();
          this.officegroupInit();
        }

        if(this.page_type == 'staff') {
          TF.GetData.getOfficeList();
          TF.GetData.getGroupList();
          this.staffInit();
        }

        if(this.page_type == 'adminvalue') {
          this.adminValueInit();
        }

        if(this.page_type == 'call_log' || this.page_type == 'recall_list') {
          //TF.GetData.getCallLogList();

          new Calender(this.calender_type.concat(['date','hour']));
          if(this.page_type == 'recall_list') TF.GetData.getGroupList();
          this.search('call_history');
          this.callLogInit();
        }
      }
    }, TF.intervaltime);
  }

  //データリスト取得
  getList(type, func) {
    this.loading.start();

    //クエリ構築
    let query = [
      type,
      this.select_column_query[type]
    ];
    console.log(query);  
    //IDの件数指定クエリがあれば
    if(this.limit_query) {
      query = query.concat(this.limit_query);
    }

    //追加クエリがあれば
    if(this.add_query) {
      query = query.concat(this.add_query);
    }

    query = query.join('/');

    if(!TF.split_flag && TF.search_query || this.limit_query.length == 0) {
      TF.List.printList();
      this.edit(type);
      this.loading.end();
    } else {
      //データ取得
      this.ajax_obj = $.ajax({
        url: TF.api.url + query + TF.api.key,
        type: 'GET'
      })
      .done((data) => {
        TF.list_contents = JSON.parse(data).reverse();
        TF[type] = TF.list_contents;
        TF.List.printList();

        /****************************************
         * テーブル出力後にアクションなどを設定
        ****************************************/
        setSelectList(this.select_list_edit);

        this.edit(type, this.sync_api_edit);
        this.del(type, this.sync_api_remove);
        this.bulk(type, 'DELETE');

        //CSVインポートエクスポート
        $('.p-data-btn.-export').off('mousedown').on('mousedown', (e) => {
          if(confirm('50,000件ごとに分割されてダウンロードされるので\nファイルが複数に渡る場合があります')) {
            const _CSV = new CSV(type, this.csv_add_query);
            _CSV.output(TF.csv_contents, ',', _CSV.filename());
          }
        });

        $('.csv_upload').off('change').on('change', function(e) {
          const _CSV = new CSV(type, this.csv_add_query);
          _CSV.upload(e);
        });

        $('.p-data-btn.-import').off('mousedown').on('mousedown', (e) => {
          $('.csv_upload').click();
        });

        //音声テキスト変換ボタン
        $('.js-btn-convert').off('mousedown').on('mousedown', (e) => {
          let _this = $(e.currentTarget);

          if(_this.hasClass('js-btn-convert-complete') || _this.hasClass('js-btn-convert-proccess')) return false;

          _this.addClass('js-btn-convert-proccess -loader-icon').find('.p-data-table-td-convertbtn-view').text('変換中...');

          let id = _this.data('id');
          let try_n = 720;
          let tel = $('#tr' + id + ' .-tel_no').text(); 

          let url = TF.api.url.replace('/rest/', '/api/convert_speech_to_text.php');
          let api_key;
          if(url.indexOf('dev-') > -1) {
            api_key = 'qIH279iaX6O4t6qrcgGAPik5Yjdno0r2AdrFLiE-0v6m';
          } else {
            api_key = 'BwpOCDYILPIl5EXvIiNHAglmt9hLpx_EHo2jritJK-U3';
          }

          //url = '/api/convert/';
          /*
          let data = {
            'call_id' : id + '',
            'api_key': api_key,
          };*/

          let data = new FormData();
          data.set('call_id', id + '');
          data.set('api_key', api_key);

          let get_text = function() {
            
              $.ajax({
                url: url,
                type:'POST',
                data: data,
                processData: false,
                contentType: false,
                //enctype: 'multipart/form-data',
                dataType: 'json'
              }).done(function(res) {
                //data = JSON.parse(data);
                switch(res.message) {
                  case 'success': {
                    message(tel + 'の架電音源のテキスト変換が開始されました。\n該当ログにテキストがセットされるまでしばらくお待ちください。');

                    let check_text = function() {
                      $.ajax({
                        url: TF.api.url + 'call_history/sel.record_to_text/id=/' + id + TF.api.key,
                        type:'GET',
                      }).done(function(res2) {
                        if(!res2 || res2 == '[]') {
                          message(`[${id}]の架電ログが存在しません`, 1);
                          _this.removeClass('js-btn-convert-proccess -loader-icon');
                          _this.text('変換');
                          return;
                        }

                        console.log(((720 - try_n) * 10000) + 'sec');
                        res2 = JSON.parse(res2);

                        if(res2[0].record_to_text) {
                          if(confirm(tel + 'の架電音源のテキストが該当ログにセットされました\n内容を確認しますか？')) {
                            window.open(`${TF.js_url_path}record_to_text.html?id=${id}`);
                          }

                          _this.removeClass('js-btn-convert').removeClass('js-btn-convert-proccess -loader-icon').addClass('js-btn-convert-complete').attr({
                            href: `javascript:window.open('${TF.js_url_path}record_to_text.html?id=${id}')`,
                            id:`convert${id}`,
                            'data-id': id,
                            'data-text': res.record_to_text,
                          }).html('<span class="p-data-table-td-convertbtn-view">view</span>');

                          //message(`[${id}]の通話テキスト取得が完了しました`);
                        } else {
                          try_n--;

                          if(try_n > 0) {
                            setTimeout(check_text, 10000);
                          } else {
                            message(`タイムアウトで[${id}]の通話テキストが取得できませんでした。後ほど再取得を試みてください`, 1);
                            _this.text('変換');
                          }
                        }
                        
                      }).fail(function(jqXHR, textStatus, errorThrown){
                        message(`サーバに接続できませんでした`, 1);
                        _this.text('変換');
                      });
                    };
                    check_text();
                    break;
                  }
                  case 'fail':
                  default: {
                    message('テキスト変換要求に失敗しました', 1);
                    _this.text('変換');
                    break;
                  }
                }
                
              }).fail(function(jqXHR, textStatus, errorThrown){
                console.log(jqXHR.responseJSON, 236);
                //message(`サーバが応答しなかったため[${id}]の通話テキストが取得できませんでした`, 1);

                _this.removeClass('js-btn-convert-proccess -loader-icon');

                switch(jqXHR.responseJSON.message) {
                   case 'Unauthorized.': {
                    message('api_keyがない　または　api_keyが正しくないようです', 1);
                    _this.text('変換');
                    break;
                  }
                  case 'call_id is required.': {
                    message(tel + 'への架電履歴IDが指定されていません', 1);
                    _this.text('変換');
                    break;
                  }
                  case 'Call log not found.': {
                    message(tel + 'への指定された架電履歴がありません', 1);
                    _this.text('変換');
                    break;
                  }
                  case 'This call log does not have record.': {
                    message(tel + 'の架電音源データがまだ準備できていないようです', 1);
                    _this.text('変換');
                    break;
                  }
                  case 'File not found.': {
                    message(tel + 'の架電音源データが存在しません', 1);
                    _this.text('変換');
                    break;
                  }
                  default: {
                    message(`サーバが応答しなかったため[${id}]の通話テキストに変換できませんでした`, 1);
                    break;
                  }
                }
                _this.text('変換');
              });            
          }

          get_text();
        });


        if(this.bulk_edit_query) {
          this.bulk(type, 'PUT', this.bulk_edit_query);
        }
        this.loading.end();
      })
      .fail((data) => {
        if(data.statusText == 'abort') {
          console.log('cancel');
        } else {
          message('データを取得できませんでした', 1);
          this.loading.end();
        }
      });
    }
  }

  //ページャー、件数設定セレクタの初期化およびデータの絞り込み
  listControlerInit(type, change) {
    //ページ取得
    this.getPage();

    //取得件数を設定してデータ絞り込む
    this.getLimit(type, change);

    //ページ数
    this.page_count = Math.ceil(this.get_result.length / this.limit); 

    //ページャー生成
    this.pager(type);
  }

  getPage() {
    //ページ番号を設定
    this.hash = location.hash? location.hash.split('/'): null;
    //表示ページ番号
    this.page = !this.hash? 1: this.hash[2];
  }

  getLimit(type, change) {
    //セレクタから取得数を取得するかURLから取得するか
    let limitselect = $('#limitselect');
    if(location.hash.indexOf('/limit/') > -1 && !change) {
      this.limit = this.hash[4];
      limitselect.val(this.hash[4]);
    } else {
      this.limit = limitselect.val();
    }

    //取得範囲設定
    this.limit_query = [];
    if(this.limit <= this.get_result.length) {
      let start, end;
      start = (this.page - 1) * this.limit; 
      end = this.page * this.limit;
  
      //先に取得したIDを元にデータ範囲取得
      let select_results = this.get_result.slice(start, end);

      if(TF.split_flag) {

        //先に取得したIDを元にデータ範囲取得
        select_results = select_results.map((v) => {
          return `id/${v.id}`;
        });
        select_results = '(/' + select_results.join('/') + '/)';

        //IDの取得範囲のクエリ
        this.limit_query = [
          select_results
        ];

      } else {
        //IDの取得範囲のクエリ
        this.limit_query = [
          'id<=',
          select_results[0].id,
          'id>=',
          select_results[select_results.length - 1].id
        ];
      }

      //検索時の検索結果を格納
      if(TF.search_query) {
        TF.list_contents = select_results;
      }
    } else {
      //先に取得したIDを元にデータ範囲取得
      if(this.get_result.length > 0) {
        let select_results = this.get_result.map((v) => {
          return `id/${v.id}`;
        });
        select_results = '(/' + select_results.join('/') + '/)';
  
        //IDの取得範囲のクエリ
        this.limit_query = [
          select_results
        ];
      } else {
        this.limit_query = [];
      }

      //取得範囲内に収まる場合はそのまま格納
      TF.list_contents = this.get_result;
    }

    //CSV出力用
    TF.csv_contents = this.get_result;

    //取得数セレクタ
    limitselect.off('change').on('change', (e) => {
      this.listControlerInit(type, true);
      this.getList(type);
    });
  }
  
  //ページャー初期化
  pager(type) {
    let dataSource = []; 
    
    //ページ番号を配列に設定
    for(let i = 1, n = this.page_count; i <= n; i++ ) {
      dataSource.push(i);
    }

    //ページャー初期化
    this.pager_obj = $('.p-data-pagenation-list').pagination({
      dataSource: dataSource, //ページ番号
      pageSize: 1,
      showPageNumbers: true,
      showNavigator: false,
      className: 'p-data-pagenation-list-page',
      activeClassName: 'is-current',
      ulClassName: 'p-data-pagenation-list-ul',
      prevText: '',
      nextText: '',
      pageNumber: this.page, //表示ページ
      callback: (data, pagination) => {
        //先にデータ取得の通信が発生していたらキャンセル
        if(this.ajax_obj) this.ajax_obj.abort();

        let first = '.p-data-pagenation-tofirst';
        let end = '.p-data-pagenation-toend';

        //ページネーションの最初と最後のリンクの無効化を解除
        $(first + ',' + end).removeClass('-disabled');

        //ページネーションの最初のリンクを無効化
        if(pagination.pageNumber == 1) {
          $(first).addClass('-disabled');
        }

        //ページネーションの最後のリンクを無効化
        if(pagination.pageNumber == pagination.totalNumber || pagination.totalNumber == 0) {
          $(end).addClass('-disabled');
        }
        
        //ページ番号と取得件数をハッシュ化してURLに反映
        location.href = '#/page/' + pagination.pageNumber + '/limit/' + this.limit;

        //表示件数の表示
        let page_num = pagination.totalNumber > 0? pagination.totalNumber: 1;
        $('.p-data-pagenation-desc-txt').text(`表示: ${pagination.pageNumber}/${page_num}ページ（${this.get_result.length}件）`);

        //取得したページ番号が現在のページと違えばデータ取得して表示
        if(this.page != pagination.pageNumber) {
          this.getPage();
          this.getLimit(type, false);
          this.getList(type);
        }

        //ページネーションの最初のリンククリック
        $(first).off('click').on('click', (e) => {
          e.preventDefault();
          this.page = 1;
          this.pager_obj.pagination(this.page);
          this.getLimit(type, true);
          this.getList(type);

          return false;
        });

        //ページネーションの最後のリンククリック
        $(end).off('click').on('click', (e) => {
          e.preventDefault();
          this.page = pagination.totalNumber;
          this.pager_obj.pagination(this.page);
          this.getLimit(type, true);
          this.getList(type);
          return false;
        });
      }
    });
  }


  /*****************************
    ここから各画面
  ******************************/
  //コールログ
  callLogInit() {
    $('.p-data').addClass('-call_history');

    $('.-edit-call_history .p-data-input-duration').after('<div class="p-data-input-textarea"><div class="p-data-input-textarea-select"><label class="-modal">メモ修正</label><textarea class="p-data-textarea" id="recall_memo" name="memo"></textarea></div></div>');

    let wide_n = $('.-search .-call_history .p-data-input-area.-wide').length;
    $('.-search .-call_history .p-data-input-area.-wide').eq(wide_n - 1).after('<div class="p-data-input-area -wide"><div class="p-data-input"><label class="-modal">音声テキスト</label><textarea class="p-data-textarea" name="record_to_text" id="record_to_text"></textarea></div></div>');

    if(location.href.indexOf('call_log') > -1) {
      $('#tel_no').after($('<div>', {class: 'u-text', text: '複数検索は半角スペースで区切って入力してください'}));
      TF.list_column.push(['convert', '変換']);
    } 

    TF.list_column.push(['upd_date', '最終更新日時']);

    const sit = setInterval(() => {
      if(TF.history_id) {
        clearInterval(sit);
        //IDのdataだけ取得して降順に変換
        TF.history_id = JSON.parse(TF.history_id);
        this.get_result = TF.history_id.sort(function(a,b){
          if(a.id - 0 > b.id - 0) return -1;
          if(a.id - 0 < b.id - 0) return 1;
          return 0;
        });
        //this.get_result = TF.history_id;
        this.add_query = [];
        if(this.page_type == 'recall_list') {
          this.add_query = ['recall_flag=','1'];
        }

        if((CURRENT_USER.auth_level|0) == 3) {
          this.add_query.push('staff_id=');
          this.add_query.push(CURRENT_USER.telforce_id);

          $("#staff_select, #staff_edit_select").parents('.p-data-input-area').remove();
        }

        $('#condition_select').on('change', () => {
          $('#recall_date_start, #recall_date_end').val(null);
        });

        TF.getSelectData = () => {

          if(TF.select_data && TF.modal && TF.modal.hasClass('-edit-call_history')) {

            let modal_table = $('.-edit-call_history .p-data-table');
            try {
              modal_table.find('.-value').eq(0).text(TF.select_data.customer_id);
            } catch (err) {
              console.log('customer_idのエラー', err);
              modal_table.find('.-value').eq(0).text('未定義');
            }

            try {
              modal_table.find('.-value').eq(1).text(TF.select_data.id);
            } catch (err) {
              console.log('idのエラー', err);
              modal_table.find('.-value').eq(1).text('未定義');
            }

            try {
              modal_table.find('.-value').eq(2).text(TF.select_data.call_time);
            } catch (err) {
              console.log('call_timeのエラー', err);
              modal_table.find('.-value').eq(2).text('未定義');
            }

            try {
              modal_table.find('.-value').eq(3).text(TF.select_data.recall_time);
              let date = TF.select_data.recall_time.split(' ');
              $('#date').val(date[0]);
              $('#time').val(date[1]);
            } catch (err) {
              console.log('recall_timeのエラー', err);
              modal_table.find('.-value').eq(3).text('未定義');
            }


            try {
              modal_table.find('.-value').eq(4).text(TF.select_data.tel_no);
            } catch (err) {
              console.log('tel_noのエラー', err);
              modal_table.find('.-value').eq(4).text('未定義');
            }

            try {
              let name = TF.call_status.filter(v => v.id == TF.select_data.call_status|0)[0].name;
              modal_table.find('.-value').eq(5).text(name);
            } catch (err) {
              console.log('call_statusのエラー', err);
              modal_table.find('.-value').eq(5).text('未定義');
            }

            try {
              let name = TF.area.filter(v => v.id == TF.select_data.integration_id_1|0)[0].name;
              modal_table.find('.-value').eq(6).text(name);
            } catch (err) {
              console.log('areaのエラー', err);
              modal_table.find('.-value').eq(6).text('未定義');
              message('都道府県が未設定です', 1);
            }
            
            try {
              let name = TF.jobs_category.filter(v => v.id == TF.select_data.integration_id_2|0)[0].name;
              modal_table.find('.-value').eq(7).text(name);
            } catch (err) {
              console.log('jobsのエラー', err);
              modal_table.find('.-value').eq(7).text('未定義');
              //message('業種が未設定です', 1);
            }

            try {
              let name = TF.staff[TF.select_data.staff_id].name;
              modal_table.find('.-value').eq(8).text(name);
            } catch (err) {
              console.log('staff_idのエラー', err);
              modal_table.find('.-value').eq(8).text('未定義');
            }

            try {
              let condition = $('#tr' + TF.select_data.id + ' .-condition').text();
              modal_table.find('.-value').eq(9).text(condition);
            } catch (err) {
              console.log('ログの状態のエラー', err);
              modal_table.find('.-value').eq(9).text('未定義');
            }

            try {
              modal_table.find('.-value').eq(10).text(TF.select_data.memo_1);
              $('.-edit-call_history textarea[name="memo"]').val(TF.select_data.memo_1);
            } catch (err) {
              console.log('メモのエラー', err);
            }


            //再コール解除ボタン
            $('.js-btn-cancel').off('mousedown').on('mousedown', (e) => {

              if(CURRENT_USER.auth_level > 3 && (TF.select_data.staff_id|0) != CURRENT_USER.telforce_id) {
                alert('不正操作');
                return false;
              }

              //履歴データを修正
              let current = moment(new Date()).format('YYYY-MM-DD HH:mm:ss');
              let query = `call_history/id/${TF.select_data.id}`;
              //let data = `{"cancel_flag":"1","closing_flag":null,"recall_time":"${current}"}`;
              let data = `{"cancel_flag":"1","closing_flag":null}`;

              let c_query = `customer/id/${TF.select_data.customer_id}`;
              let c_data = `{"cancellation_date":"${current}"}`;

              //予約時刻キャンセルフラグ用
              let key = moment(TF.select_data.recall_time, 'YYYY-MM-DD HH:mm:ss').unix();

              let db = localStorage;
              let apoint_dates = db.getItem('sgtelforce_apoint_dates');
              if(!apoint_dates) apoint_dates = '{}';

              if(apoint_dates) {
                apoint_dates = JSON.parse(apoint_dates);
                  
                if(apoint_dates[key] && !Array.isArray(apoint_dates[key])) {
                  apoint_dates[key] = [apoint_dates[key]];
                }

                //いったん展開
                let spread = [];
                let tmp = {};
                for(let i in apoint_dates) {
                  for(let ii in apoint_dates[i]) {
                    if(apoint_dates[i][ii].call_time == TF.select_data.call_time) {
                      apoint_dates[i][ii].apoint_date = moment(date, 'YYYY-MM-DD HH:mm').format('YYYY/MM/DD HH:mm');
                      
                      //予約時刻キャンセルフラグ
                      apoint_dates[i][ii].cancel_flag = 1;
                      apoint_dates[i][ii].closing_flag = null;
                    }

                    spread.push(apoint_dates[i][ii]);
                    let key = moment(apoint_dates[i][ii].apoint_date, 'YYYY/MM/DD HH:mm').unix();
                    tmp[key] = [];
                  }
                }

                for(let i in spread) {
                  let key = moment(spread[i].apoint_date, 'YYYY/MM/DD HH:mm').unix();
                  tmp[key].push(spread[i]);
                }

                apoint_dates = JSON.stringify(apoint_dates);
                //web storageに格納
                db.setItem('sgtelforce_apoint_dates', apoint_dates);

                //フラグ削除
                let apoint_popup_flag = db.getItem('sgtelforce_apoint_popup_flag');
                if(apoint_popup_flag) {
                  apoint_popup_flag = JSON.parse(apoint_popup_flag);
                } else {
                  apoint_popup_flag = {};
                }

                let key2 = moment(TF.select_data.recall_time, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD');
                delete apoint_popup_flag[key2];

                apoint_popup_flag = JSON.stringify(apoint_popup_flag);
                //web storageに格納
                db.setItem('sgtelforce_apoint_popup_flag', apoint_popup_flag);
              }

              let reload_flag = 0;

              const opt = {
                url: TF.api.url + query + TF.api.key,
                type: 'PUT',
                data: data,
                dataType: 'text'
              };
        
              const doFunc = function(data) {
                message('キャンセル完了');
                $('#tr' + TF.select_data.id + ' .-condition').html('キャンセル');
                reload_flag++;
              };
        
              const failFunc = function(err) {
                console.log(err);
                message('キャンセルに失敗しました');
                reload_flag++;
              };
              //APIのcall_historyの該当データを修正
              getApiDataCommon(opt, doFunc, failFunc);


              const opt2 = {
                url: TF.api.url + c_query + TF.api.key,
                type: 'PUT',
                data: c_data,
                dataType: 'text'
              };
        
              const doFunc2 = function(data) {
                message('顧客データ修正完了');
                reload_flag++;
              };
        
              const failFunc2 = function(err) {
                console.log(err);
                message('顧客データ修正失敗しました');
                reload_flag++;
              };
              //APIのcall_historyの該当データを修正
              getApiDataCommon(opt2, doFunc2, failFunc2);

              let sit = setInterval(() => {
                if(reload_flag >= 2) {
                  clearInterval(sit);
                  message('リロード開始');
                  setInterval(() => { location.reload(); }, TF.intervaltime * 20);
                }
              }, TF.intervaltime);

            });

            //再コール完了ボタン
            $('.js-btn-recall').off('mousedown').on('mousedown', (e) => {
              if(CURRENT_USER.auth_level > 3 && (TF.select_data.staff_id|0) != CURRENT_USER.telforce_id) {
                alert('不正操作');
                return false;
              }

              let current = moment(new Date()).format('YYYY/MM/DD HH:mm:ss');
              let query = `call_history/id/${TF.select_data.id}`;
              //let data = `{"cancel_flag":null,"closing_flag":"1","recall_time":"${current}"}`;
              let data = `{"cancel_flag":null,"closing_flag":"1"}`;

              //予約時刻完了フラグ用
              let key = moment(TF.select_data.recall_time, 'YYYY-MM-DD HH:mm:ss').unix();

              let db = localStorage;
              let apoint_dates = db.getItem('sgtelforce_apoint_dates');
              if(!apoint_dates) apoint_dates = '{}';

              if(apoint_dates) {
                apoint_dates = JSON.parse(apoint_dates);
                  
                if(apoint_dates[key] && !Array.isArray(apoint_dates[key])) {
                  apoint_dates[key] = [apoint_dates[key]];
                }

                //いったん展開
                let spread = [];
                let tmp = {};
                for(let i in apoint_dates) {
                  for(let ii in apoint_dates[i]) {
                    if(apoint_dates[i][ii].call_time == TF.select_data.call_time) {
                      apoint_dates[i][ii].apoint_date = moment(date, 'YYYY-MM-DD HH:mm').format('YYYY/MM/DD HH:mm');
                      
                      //予約時刻キャンセルフラグ
                      apoint_dates[i][ii].cancel_flag = null;
                      apoint_dates[i][ii].closing_flag = 1;
                    }

                    spread.push(apoint_dates[i][ii]);
                    let key = moment(apoint_dates[i][ii].apoint_date, 'YYYY/MM/DD HH:mm').unix();
                    tmp[key] = [];
                  }
                }

                for(let i in spread) {
                  let key = moment(spread[i].apoint_date, 'YYYY/MM/DD HH:mm').unix();
                  tmp[key].push(spread[i]);
                }

                apoint_dates = JSON.stringify(apoint_dates);
                //web storageに格納
                db.setItem('sgtelforce_apoint_dates', apoint_dates);

                //フラグ削除
                let apoint_popup_flag = db.getItem('sgtelforce_apoint_popup_flag');
                if(apoint_popup_flag) {
                  apoint_popup_flag = JSON.parse(apoint_popup_flag);
                } else {
                  apoint_popup_flag = {};
                }

                let key2 = moment(TF.select_data.recall_time, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD');
                delete apoint_popup_flag[key2];

                apoint_popup_flag = JSON.stringify(apoint_popup_flag);
                //web storageに格納
                db.setItem('sgtelforce_apoint_popup_flag', apoint_popup_flag);
              }

              const opt = {
                url: TF.api.url + query + TF.api.key,
                type: 'PUT',
                data: data,
                dataType: 'text'
              };

              const doFunc = function(data) {
                message('再コール完了');
                $('#tr' + TF.select_data.id + ' .-condition').html('再コール済');

                message('リロード開始');
                setInterval(() => { location.reload(); }, TF.intervaltime * 20);
              };
        
              const failFunc = function(err) {
                console.log(err);
                message('再コール完了設定に失敗しました');
              };
              
              getApiDataCommon(opt, doFunc, failFunc);

            });

            //時刻再設定ボタン
            $('.js-btn-reload').text('時刻・メモ再設定').off('mousedown').on('mousedown', (e) => {
              if(CURRENT_USER.auth_level > 3 && (TF.select_data.staff_id|0) != CURRENT_USER.telforce_id) {
                alert('不正操作');
                return false;
              }
              
              let recall_date = TF.select_data.recall_time.split(' ');
              if(!$('#date').val())$('#date').val(recall_date[0]);
              if(!$('#time').val())$('#time').val(recall_date[1]);

             //時間指定
              let date = $('#date').val()? moment($('#date').val(), 'YYYY/MM/DD').format('YYYY-MM-DD'): '';
              date = date + ' ' + $('#time').val() + ':00';

              TF.select_data.memo_1 = $('#recall_memo').val().replace(/<[^>]+>/g, '')
              .replace(/&/g, '&amp;');

              let flag = 0;

              //履歴データ修正
              let query = `call_history/id/${TF.select_data.id}`;
              let data = `{"memo_1":"${TF.select_data.memo_1}","recall_time":"${date}","cancel_flag":null,"closing_flag":null}`;

              const opt = {
                url: TF.api.url + query + TF.api.key,
                type: 'PUT',
                data: data,
                dataType: 'text'
              };
        
              const doFunc = function(data) {
                message('履歴データ予約日時修正完了');
                $('#tr' + TF.select_data.id + ' .-condition').html('予約中');
                flag++;
              };
        
              const failFunc = function(err) {
                console.log(err);
                message('履歴データ予約日時修正に失敗しました');
                flag++;
              };
              
              getApiDataCommon(opt, doFunc, failFunc);

              //顧客データ修正
              let query2 = `customer/id/${TF.select_data.customer_id}`;
              let data2 = `{"remarks":"${TF.select_data.memo_1}","add_info99":"${date}","cancellation_date":null}`;

              const opt2 = {
                url: TF.api.url + query2 + TF.api.key,
                type: 'PUT',
                data: data2,
                dataType: 'text'
              };
        
              const doFunc2 = function(data) {
                message('顧客データ予約日時修正完了');
                flag++;
              };
        
              const failFunc2 = function(err) {
                console.log(err);
                message('顧客データ予約日時修正に失敗しました');
                flag++;
              };
              
              getApiDataCommon(opt2, doFunc2, failFunc2);

              let sit = setInterval(() => {
                if(flag >= 2) {
                  clearInterval(sit);

                  //予約時刻告知用修正開始
                  if(TF.select_data.recall_time.match(/[0-9]{4}}\/[0-9]{2}\/[0-9]{2}/)) {
                    TF.select_data.recall_time = TF.select_data.recall_time.split('/').join('-').replace(/:[0-9]{2}$/, '');
                  }

                  let key = moment(TF.select_data.recall_time, 'YYYY-MM-DD HH:mm').unix();

                  let db = localStorage;
                  let apoint_dates = db.getItem('sgtelforce_apoint_dates');
                  if(!apoint_dates) apoint_dates = '{}';
                  
                  if(apoint_dates) { 
                    apoint_dates = JSON.parse(apoint_dates);
           
                    if(apoint_dates[key] && !Array.isArray(apoint_dates[key])) {
                      apoint_dates[key] = [apoint_dates[key]];
                    }

                    //いったん展開
                    let spread = [];
                    let tmp = {};
                    for(let i in apoint_dates) {
                      for(let ii in apoint_dates[i]) {
                        if(apoint_dates[i][ii].call_time != TF.select_data.call_time) {
                          spread.push(apoint_dates[i][ii]);
                          let key = moment(apoint_dates[i][ii].apoint_date, 'YYYY/MM/DD HH:mm').unix();
                          tmp[key] = [];
                        }
                      }
                    }

                    //予約告知データ構築
                    let obj = {};
                    obj.id = (TF.select_data.id|0);
                    obj.tel_no = TF.select_data.tel_no;

                    let status = TF.call_status.filter((v) => v.id == TF.select_data.call_status);
                    obj.status = status[0].name;

                    obj.call_time = TF.select_data.call_time;

                    let staff = TF.staff[TF.select_data.staff_id];
                    obj.staff = staff.name;
                    obj.staff_id = TF.select_data.staff_id;

                    let dept_unit = TF.dept_unit.filter((v) => v.id == staff.dept_unit_id);

                    if(dept_unit.length > 0) obj.unit_name = dept_unit[0].unit_name;

                    obj.cancel_flag = null;
                    obj.closing_flag = null;
                    obj.memo = TF.select_data.memo_1;
                    obj.apoint_date = moment(date, 'YYYY-MM-DD HH:mm').format('YYYY/MM/DD HH:mm');

                    //既存のデータに新しいデータを追加する
                    spread.push(obj);


                    //変更後の日時からキーを生成
                    let new_key = moment(date, 'YYYY-MM-DD HH:mm').unix();
                    tmp[new_key] = [];

                    for(let i in spread) {
                      let key = moment(spread[i].apoint_date, 'YYYY/MM/DD HH:mm').unix();
                      tmp[key].push(spread[i]);
                    }

                    apoint_dates = JSON.stringify(tmp);
                    //web storageに格納
                    db.setItem('sgtelforce_apoint_dates', apoint_dates);
                    message('架電予約日時をブラウザに保存しました');

                    let apoint_popup_flag = db.getItem('sgtelforce_apoint_popup_flag');
                    if(apoint_popup_flag) {
                      apoint_popup_flag = JSON.parse(apoint_popup_flag);
                    } else {
                      apoint_popup_flag = {};
                    }

                    let key2 = moment(date, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD');
                    apoint_popup_flag[key2] = false;
                    apoint_popup_flag = JSON.stringify(apoint_popup_flag);
                    //web storageに格納
                    db.setItem('sgtelforce_apoint_popup_flag', apoint_popup_flag);


                    //talknoteへsgteforceを介して通知
                    let mikomi_api = '/api/push';
                    //let status = TF.call_status.filter((v) => v.id === TF.history.call_status);

                    
                    let title = `🔄【見込みステータスが変更されました】登録者：${obj.staff}[${obj.staff_id}]`;

                    let mes = `\n▼架電予約登録内容\n\n[架電対象電話番号]　　${obj.tel_no}\n[ステータス]　　　　　${obj.status}\n[ステータス登録日時]　${obj.call_time}\n[登録者]　　　　　　　${obj.staff} [${obj.staff_id}]\n[登録者所属グループ]　${obj.unit_name}\n[予約日時]　　　　　　${obj.apoint_date}\n[メモ]\n${obj.memo}\n`;

                    let reload_flag = 0;

                    let mikomi_opt = {
                      url:  mikomi_api,
                      type: 'POST',
                      data: {
                        to: TF.talknote_group,
                        message: mes,
                        subject: title
                      }
                    };

                    let mikomi_opt_admin = {
                      url:  mikomi_api,
                      type: 'POST',
                      data: {
                        to: TF.talknote_group_admin,
                        message: mes,
                        subject: '管理者宛 - ' + title
                      }
                    };


                    let mikomi_doFunc = (data) => {

                      if(data) {
                        console.log(data);
                      }
                      message('見込みステータスをtalknoteに通知しました');
                      reload_flag++;
                    }

                    let mikomi_doFunc_admin = (data) => {
  
                      if(data) {
                        console.log(data);
                      }
                      message('[管理者用] 見込みステータスをtalknoteに通知しました');
                      reload_flag++;
                    }

                    let mikomi_failFunc = (data) => {
                      console.log(data);
                      message('sgtelforceへの接続失敗');
                      reload_flag++;
                    }
                    //talknoteへ送信
                    getApiDataCommon(mikomi_opt, mikomi_doFunc, mikomi_failFunc);
                    getApiDataCommon(mikomi_opt_admin, mikomi_doFunc_admin, mikomi_failFunc);


                    //予約チェック
                    //apointCheck(false);

                    //location.reload();
                    let sit = setInterval(() => {
                      if(reload_flag >= 2) {
                        clearInterval(sit);
                        message('リロード開始');
                        setInterval(() => { location.reload(); }, TF.intervaltime * 20);
                      }
                    }, TF.intervaltime);
                    //alert('complete');
                  }
                }
              }, TF.intervaltime);

            });
          }
        };

        this.listControlerInit('call_history', false);
        this.getList('call_history');
      }
    }, TF.intervaltime);
  }

  //リスト・禁止リスト管理
  customerInit(ban) {
    $('.p-data').addClass('-customer -' + ban);
    const sit = setInterval(() => {
      if(TF.customer) {
        TF.customer = JSON.parse(TF.customer);

        clearInterval(sit);
        //IDのdataだけ取得して降順に変換
        this.get_result = TF.customer.sort(function(a,b){
          if(a.id - 0 > b.id - 0) return -1;
          if(a.id - 0 < b.id - 0) return 1;
          return 0;
        });
        
        this.add_query = [];

        if(this.page_type == 'list') {

          if(!$('.js-bulk-alldel')[0]) {
            $('.p-data-limitselectbox .p-data-btnbox:eq(0)').after('<div class="p-data-btnbox"><a href="javascript:void(0)" class="p-data-btn -bulk js-bulk-alldel"><span>検索結果削除</span></a></div>');
          } 

          this.add_query = ['prohibit_flag<','1'];
          this.bulk_edit_query = {'prohibit_flag':'1'};
        }
        
        if(this.page_type == 'ban_list') {
          this.add_query = ['prohibit_flag=','1'];
          this.bulk_edit_query = {'prohibit_flag':''};
          this.csv_add_query = {'prohibit_flag':'1'};
        }


        TF.getSelectData = () => {
          if(TF.select_data && TF.modal) {
             
            for(let i in TF.select_data) {
              let key;
              switch(i) {
                case 'staff_id': {
                  key = 'staff_edit';
                  break;
                }
                case 'prefecture': {
                  key = 'area_edit';
                  break;
                }
                case 'add_info98': {
                  key = 'call_counterpart_edit';
                  break;
                }
                case 'add_info100': {
                  key = 'jobs_edit';
                  break;
                }
                case 'tel_1': {
                  key = 'tel_no_edit';
                  break;
                }
                case 'add_info12': { //携帯番号
                  key = 'add_info12_edit';
                  break;
                }
                case 'call_status': {
                  key = 'call_status_edit';
                  break;
                }
                case 'add_info99': { //架電予約時間
                  key = 'add_info99_edit';
                  break;
                }
                case 'last_call': { //最終架電時間
                  key = 'last_call_edit';
                  break;
                }
                case 'prohibit_flag': {
                  key = 'prohibit_flag_edit';
                  break;
                }
                case 'remarks': {
                  key = 'remarks_edit';
                  break;
                }
              }
              
              if(TF.set_select2_list[key + '_select']) {
                TF.set_select2_list[key + '_select'].val(TF.select_data[i]).trigger('change');
              } else {
                if(TF.modal.find('#' + key).attr('type') == 'checkbox') {

                  if(TF.select_data[i] == 1) {
                    TF.modal.find('#' + key).prop('checked', 1);
                  } else {
                    TF.modal.find('#' + key).prop('checked', false);
                  }
                } else {
                  TF.modal.find('#' + key).val(TF.select_data[i]);
                }
              }
             
              
            }
          }
        };

        this.listControlerInit('customer', false);
        this.getList('customer');
      }
    }, TF.intervaltime);
  }

  //グループ設定画面
  officegroupInit() {
    // 同期用
    this.sync_data = {};
    
    const sit = setInterval(() => {
      if((TF.office && TF.office.length > 0) && (TF.dept_unit && TF.dept_unit.length > 0)) {
        clearInterval(sit);
        TF.list_contents.office = TF.office;
        TF.List.printInputList('single', 'office');
    
        TF.list_contents.dept_unit = TF.dept_unit;
        TF.List.printInputList('two', 'dept_unit');

        modalInit('.-office');
        modalInit('.-dept_unit');
        
        this.add('office', '/api/office/add');
        this.add('dept_unit', '/api/group/add');

        this.edit('office', '/api/office/edit/');
        this.edit('dept_unit', '/api/group/edit/');

        this.del('office', '/api/office/remove/');
        this.del('dept_unit', '/api/group/remove/');

        this.loading.end();
      }
    }, TF.intervaltime);

    TF.List.btnAction();
  }

  //ユーザー設定画面
  staffInit() {
    const sit = setInterval(() => {
      if(TF.office.length > 0 && TF.dept_unit.length > 0) {
        clearInterval(sit);

        [['office', 'office_name'], ['dept_unit', 'unit_name']].filter((v) => {
          let ary = ['<option value="0">未選択</option>'];
          let obj = TF[v[0]];

          for(let i in obj) {
            ary.push(`<option value="${obj[i].telforce_id}">${obj[i][v[1]]}</option>`);
          }

          $(`#${v[0]}_id`).append(ary.join(''));
        });

        TF.getSelectData = () => {
          if(TF.select_data && TF.modal) {

            for(let i in TF.select_data) {
              //let key = i == 'telforce_id'? 'login_id': i;
              let key = i == 'name'? 'staff_name': i;
              TF.modal.find('#' + key).val(TF.select_data[i]); 
            }
          }
        };

        const getNewestId = (e) => {

          //IDの最大値をゲット
          let ids = [];
          for(let i in ALL_USERS) {
            if((ALL_USERS[i].login_id|0) < 9998) {
              ids.push((ALL_USERS[i].login_id|0));
            }
          }

          let id = Math.max(...ids);
          $('.-add-staff.js-modal-area').find('#login_id').val(id + 1);

          /*this.loading = new TF.loading($('body'));
          this.loading.start();
          
          //個別データ取得
          getApiDataCommon({
            url: TF.api.url + 'staff/sel.login_id' + TF.api.key,
            method: 'GET'
          },
          (d) => {
            if(d == 0) {
              this.loading.end();
              message('ユーザー情報が取得出来ませんでした。', 1);
              return false;
            }

            d = JSON.parse(d);

            this.loading.end();

            //IDの最大値をゲット
            let ids = [];
            for(let i in d) {
              if((d[i].login_id|0) < 9998) {
                ids.push((d[i].login_id|0));
              }
            }

            let id = Math.max(...ids);
            $('.-add-staff.js-modal-area').find('#login_id').val(id);
          },
          (d) => {
            this.loading.end();
            message('データ取得失敗');
          });*/
        }
        
        TF.addModalFunc = getNewestId;
        TF.resetFunc = getNewestId;

        this.sync_api_add = '/api/user/add';
        this.sync_api_edit = '/api/user/edit/';
        this.sync_api_remove = '/api/user/remove/';
        this.add('staff', this.sync_api_add);
        this.edit('staff', this.sync_api_edit);
        this.del('staff', this.sync_api_remove);
        this.loading.end();
      }
    }, TF.intervaltime);

    TF.List.btnAction();
  }

  //設定値管理画面
  adminValueInit() {
    TF.newest_id = {};
    TF.list_contents = {};
    //TF.jobs = TF.GetData.getJobsList();
    
    
    if(TF.call_status && TF.call_counterpart) {

      TF.list_contents.call_status = TF.call_status;
      TF.List.printInputList('set1', 'call_status');
      
      /*TF.list_contents.call_counterpart = TF.call_counterpart;
      TF.list_contents.jobs = TF.jobs;
      TF.jobRelations = TF.jobRelations || {};
      //重複を除外
      let tmp = [];
      let checkdup = [];
      for(let i in TF.jobRelations) {
        for(let ii in TF.jobRelations[i]) {
          let job = TF.jobRelations[i][ii];

          if(checkdup.indexOf(job.job_id) == -1) {
            checkdup.push(job.job_id);
            tmp.push(job);
          } else {
            $.ajax(
              {
                url: '/api/job/remove/' + job.id,
                type: 'POST'
              }
            )
            .then(
              function(res) {
                console.log(res);
              }
            )
            .fail(
              function(err) {
                console.log(err);
              }
            );
          }
        }
        TF.jobRelations[i] = tmp;
        tmp = [];
        checkdup = [];
      }

      TF.list_contents.select2values = {};


      if(TF.jobRelations) {
        for(let i in TF.jobRelations) {
          let jobRelationsobj = TF.jobRelations[i];
          TF.list_contents.select2values[i] = [];
          
          let check = [];
          TF.jobs.filter((v) => {
            jobRelationsobj.filter((vv, ii) => {
              if((vv.job_id|0) == (v.id|0)) {
                
                if(check.indexOf(v.id) == -1) {
                  check.push(v.id);
                  TF.list_contents.select2values[i].push({id: v.id, name: v.name, relation_id: vv.id});
                } 

              }
            });
          })

        };

      }

      TF.select2_list_id = 'jobs';
      TF.List.printInputList('set3', 'call_counterpart');*/

      const getNewstId = (type) => {
        let nums = [];
        let elm = $('.p-data.-' + type).find('[data-id]');
        elm.filter((v) => {
          nums.push($(elm[v]).data('id') - 0);
        });

        if(nums.length > 0) {
          TF.newest_id[type] = Math.max(...nums) + 1;
        }
      }

      getNewstId('call_status');
      //getNewstId('call_counterpart');

      //setSelectList(['jobs']);

      this.add('call_status');
      //this.add('call_counterpart');

      this.edit('call_status');
      //this.edit('call_counterpart');

      this.del('call_status');
      //this.del('call_counterpart');

      console.log('load');
      this.loading.end();
    }

    TF.List.btnAction();
  }

  // 検索
  search(table) {

    onlyNumeric();

    //検索ボタンクリック
    $('.js-btn-search').on('click', (e) => {
      e.preventDefault();
      //ローディングスタート
      this.loading.start();
      //検索用クエリの初期化
      TF.search_query = {};

      //分割フラグ
      TF.split_flag = false;

      //入力項目を取得
      TF.search_query.tel = $('input[name="tel_no"]').val();
      
      //各プルダウンリスト選択項目取得
      TF.search_query.selects = [];
      this.select_list_enable = 0;
      this.select_list_num = 0;

      let url_groups = [];
      let nn = 0;

      let jobs_ary = [];
      if($(`#jobs_category_list_select`).val()) {
        let tmp = [];
        jobs_ary = $(`#jobs_category_list_select`).val();
        for(let i in jobs_ary) {
          let jobs_data = $(`#jobs_category_list_select option[value="${jobs_ary[i]}"]`).data('jobs');
          
          for(let ii in jobs_data) {
            tmp.push(jobs_data[ii].id);
          }
        }
        jobs_ary = tmp;
      }

      if($(`#jobs_select`).val()) {
        jobs_ary = $(`#jobs_select`).val();
      }


      for(let i in this.select_list) {
        let ary = $(`#${this.select_list[i]}_select`).val();
        let key = this.select_list[i];

        if(this.select_list[i] == 'jobs_category_list') {
          continue;
        }
        
        if(this.select_list[i] == 'jobs') {
          ary = jobs_ary
        }

        let n = ary? ary.length: 0;

        if(n > 0) {
          this.select_list_enable++;
          this.select_list_num += n;

          TF.search_query.selects[nn] = [];

          let group = [];

          ary.filter((vv) => { 
            if(vv) {
              group.push(`${key}/${vv}`);
              TF.search_query.selects[nn].push(`${key}/${vv}`);
            }
          });
          nn++;

          if(group.length > 0) url_groups.push('(/' + group.join('/') + '/)');
        } 
      }

      if(url_groups.length > 0) url_groups = url_groups.join('/') + '/'; 

      this.select_list_num *= this.select_list_enable;

      //1年分のみの暫定的設定
      TF.calender.startDate = moment().subtract(1, 'years').format('YYYY-MM-DD');
      
      //時間指定
      let date_start = $('#date_start').val()? moment($('#date_start').val(), 'YYYY/MM/DD').format('YYYY-MM-DD'): '';
      let date_end = $('#date_end').val()? moment($('#date_end').val(), 'YYYY/MM/DD').format('YYYY-MM-DD'): '';

      if(date_start && $('#time_start').val()) {
        date_start = date_start + ' ' + $('#time_start').val() + ':00';
      }

      if(date_end && $('#time_end').val()) {
        date_end = date_end + ' ' + $('#time_end').val() + ':00';
      }

      if(!date_start && $('#time_start').val()) {
        date_start = moment(TF.calender.startDate, 'YYYY/MM/DD').format('YYYY-MM-DD') + ' ' + $('#time_start').val() + ':00';
      }

      if(!date_end && $('#time_end').val()) {
        date_end = moment(new Date()).format('YYYY-MM-DD') + ' ' + $('#time_end').val() + ':00';
      }

      TF.search_query.date = {
        start: date_start,
        end: date_end
      };

      //予約時間指定
      let recall_date_start = $('#recall_date_start').val()? moment($('#recall_date_start').val(), 'YYYY/MM/DD').format('YYYY-MM-DD'): '';
      let recall_date_end = $('#recall_date_end').val()? moment($('#recall_date_end').val(), 'YYYY/MM/DD').format('YYYY-MM-DD'): '';

      if(recall_date_start && $('#recall_time_start').val()) {
        recall_date_start = recall_date_start + ' ' + $('#recall_time_start').val() + ':00';
      }

      if(recall_date_end && $('#recall_time_end').val()) {
        recall_date_end = recall_date_end + ' ' + $('#recall_time_end').val() + ':00';
      }

      if(!recall_date_start && $('#recall_time_start').val()) {
        recall_date_start = moment(TF.calender.startDate, 'YYYY/MM/DD').format('YYYY-MM-DD') + ' ' + $('#recall_time_start').val() + ':00';
      }

      if(!recall_date_end && $('#recall_time_end').val()) {
        recall_date_end = moment(new Date()).format('YYYY-MM-DD') + ' ' + $('#recall_time_end').val() + ':00';
      }

      TF.search_query.recall_date = {
        start: recall_date_start,
        end: recall_date_end
      };

      //メモ取得
      TF.search_memo = $('#memo').val();

      //音声テキスト取得
      TF.search_record_to_text = $('#record_to_text').val();

      if(TF.search_record_to_text || TF.search_memo) {
        let isnotset = (!TF.search_query.date.start || !TF.search_query.date.end);
        let month = moment(TF.search_query.date.end).diff(moment(TF.search_query.date.start), 'months', true); 

        if(isnotset || month > 1) {
          message('検索項目のメモまたは音声テキストにテキストが入力されています。<br>この場合1か月以下の期間を指定してください。', 1, 8000);
          this.loading.end();
          return false;
        }

        if(TF.search_record_to_text) TF.search_record_to_text = unicodeEscape(TF.search_record_to_text);
      }

 
 
      //検索クエリを構築
      let url = '/';
      //let urls = [];

      //顧客データの場合
      if(table == 'customer') {
        //電話番号
        if(TF.search_query.tel) {
          url += 'tel_1=/' + TF.search_query.tel + '/';
        }

        //携帯番号
        if(TF.search_query.add_info12) {
          url += 'add_info12=/' + TF.search_query.add_info12 + '/';
        }
        
        //メモ
        if(TF.search_memo) {
          //url += 'remarks=/' + TF.search_query.memo + '/';
          url += 'remarks>/0/';
        }
        
        let set_date = '';
        //開始日付
        if(TF.search_query.date.start) {
          set_date += 'last_call>=/' + TF.search_query.date.start + '/';
        }
      
        //終了日付
        if(TF.search_query.date.end) {
          set_date += 'last_call<=/' + TF.search_query.date.end + '/';
        }

        //一日指定
        if(TF.search_query.date.start && TF.search_query.date.end && TF.search_query.date.start == TF.search_query.date.end) {
          let end_date = moment(TF.search_query.date.end, 'YYYY-MM-DD').add(1, 'd').format('YYYY-MM-DD');

          set_date = '';
          set_date = 'last_call>=/' + TF.search_query.date.start + '/last_call</' +  end_date + '/';
        }

        if(set_date) url += set_date;
      }

      //コールログの場合
      if(table == 'call_history') {

        //再コール
        let recall_flag = $('input[name="recall_flag"]:checked').val();
        if(typeof recall_flag != 'undefined' && recall_flag != 2) {
          TF.search_query.recall_flag = recall_flag;
          url += 'recall_flag=/' + (recall_flag == 0? null: recall_flag) + '/';
        }

        //通話時間
        let duration = ($('#duration').val()|0);
        if(duration) {
          let second = duration * 60;
          second = second % 60;
          second = second >= 10? second: '0' + second;
          
          let minute = Math.floor(duration);
          minute = minute % 60;
          minute = minute >= 10? minute: '0' + minute;
  
          let hour = Math.floor(duration / 60);
          hour = hour > 0? hour: '00';

          duration += 1;

          let second_after = duration * 60;
          second_after = second_after % 60;
          second_after = second_after >= 10? second_after: '0' + second_after;
          
          let minute_after = Math.floor(duration);
          minute_after = minute_after % 60;
          minute_after = minute_after >= 10? minute_after: '0' + minute_after;
  
          let hour_after = Math.floor(duration / 60);
          hour_after = hour_after > 0? hour_after: '00';

  
          url += `duration>=/${hour}:${minute}:${second}/`;
          url += `duration</${hour_after}:${minute_after}:${second_after}/`;
        }

        this.condition = $('#condition_select').val();
        if(this.condition) {
          this.condition_unknown = null;
          let recall_time = moment().format('YYYY-MM-DD HH:mm:ss');
          //「すべて」はとくになにもしない
          //予約中
          if(this.condition === '1') {
            url += `recall_time>=/${recall_time}/`;
          }

          //キャンセル
          if(this.condition === '2') {
            url += 'cancel_flag=/1/';
          }

          //再コール済
          if(this.condition === '3') {
            url += 'closing_flag=/1/';
          }

          //状況不明
          if(this.condition === '4') {
            this.condition_unknown = 1;
          }
        }

        //電話番号
        if(TF.search_query.tel) {
          let ary = TF.search_query.tel.match(/[0-9\-０-９\/]+/g);

          let tmp = [];
          for(let i = 0, n = ary.length; i < n; i++) {
            tmp.push(`tel_no=/${ary[i]}`);
          }
          url += '(/' + tmp.join('/') + '/)/';
        }
        
        //メモ
        if(TF.search_memo) {
          url += 'memo_1>/0/';
        }

        let set_date = '';
        //架電日時開始日付
        if(TF.search_query.date.start) {
          set_date += 'call_time>=/' + TF.search_query.date.start + '/';
          TF.split_flag = true;
        }
      
        //架電日時終了日付
        if(TF.search_query.date.end) {
          set_date += 'call_time<=/' + TF.search_query.date.end + '/';
          TF.split_flag = true;
        }

        //架電日時一日指定
        if(TF.search_query.date.start && TF.search_query.date.end && TF.search_query.date.start == TF.search_query.date.end) {
          let end_date = moment(TF.search_query.date.end, 'YYYY-MM-DD').add(1, 'd').format('YYYY-MM-DD');

          set_date = '';
          set_date = 'call_time>=/' + TF.search_query.date.start + '/call_time</' +  end_date + '/';
          TF.split_flag = true;
        }


        if(!TF.split_flag) {
          //1年分のみ取得
          TF.split_flag = moment().subtract(1, 'years').format('YYYY-MM-DD');
          //let lastyear = moment().subtract(1, 'days').format('YYYY-MM-DD');
          TF.split_flag = 'call_time>=/' + TF.split_flag + '/';
        }

        url += set_date;

        let set_recall_date = '';
        //予約日時開始日付
        if(TF.search_query.recall_date.start && !this.condition) {
          set_recall_date += 'recall_time>=/' + TF.search_query.recall_date.start + '/';
        }

        //予約日時終了日付
        if(TF.search_query.recall_date.end && !this.condition) {
          set_recall_date += 'recall_time<=/' + TF.search_query.recall_date.end + '/';
        }

        //予約日時一日指定
        if(TF.search_query.recall_date.start && TF.search_query.recall_date.end && TF.search_query.recall_date.start == TF.search_query.recall_date.end && !this.condition) {
          let end_date = moment(TF.search_query.recall_date.end, 'YYYY-MM-DD').add(1, 'd').format('YYYY-MM-DD');

          set_recall_date = '';
          set_recall_date = 'recall_time>=/' + TF.search_query.recall_date.start + '/recall_time</' +  end_date + '/';
        }

        if(set_recall_date) {
          url += set_recall_date;
        }
      }

      if(url_groups) url += url_groups;

      //音声テキスト
      if(TF.search_record_to_text) {
        url += 'record_to_text/';
      }
/*
      //各リストを総当たりで構築
      if(TF.search_query.selects.length > 0) {
        const makeComb = function(array) {
          const make = (arr1, arr2) => {
              if (arr1.length === 0) {
                return arr2;
              }
              return arr1.reduce((arr, v1) => {
                arr2.forEach(v2 => {
                  const group = [].concat(v1, v2);
                  arr.push(group);
                });
                return arr;
              }, []);
            };
          return array.reduce(make, []);
        };

        const ary = makeComb(TF.search_query.selects);

        ary.forEach(s => {
          let tmp;
          if(typeof s == 'object') {
            tmp = url + s.join('/');
          } else {
            tmp = url + s;
          }
          
          //顧客データの場合
          this.select_list.filter((v) => {
            if(table == 'customer') {
              if(tmp.indexOf('/staff/') > -1) tmp = tmp.replace('/staff/', '/staff_id=/');
              if(tmp.indexOf('/jobs/') > -1) tmp = tmp.replace('/jobs/', '/add_info100=/');
              if(tmp.indexOf('/area/') > -1) tmp = tmp.replace('/area/', '/prefecture=/');
              if(tmp.indexOf('/call_status/') > -1) tmp = tmp.replace('/call_status/', '/call_status=/');
              if(tmp.indexOf('/call_counterpart/') > -1) tmp = tmp.replace('/call_counterpart/', '/add_info98=/');
            }
          });
          urls.push(tmp);
        });
      } else {
        urls.push(url);
      }*/
      

      //カラム置換
      this.select_list.filter((v) => {
        if(url.indexOf('/staff/') > -1) url = url.split('/staff/').join('/staff_id=/');
        if(url.indexOf('/call_status/') > -1) url = url.split('/call_status/').join('/call_status=/');

        if(table == 'customer') {
          if(url.indexOf('/jobs/') > -1) url = url.split('/jobs/').join('/add_info100=/');
          if(url.indexOf('/area/') > -1) url = url.split('/area/').join('/prefecture=/');
          if(url.indexOf('/call_counterpart/') > -1) url = url.split('/call_counterpart/').join('/add_info98=/');
        }

        if(table == 'call_history') {
          if(url.indexOf('/jobs/') > -1) url = url.split('/jobs/').join('/integration_id_2=/');
          if(url.indexOf('/area/') > -1) url = url.split('/area/').join('/integration_id_1=/');
          if(url.indexOf('/call_counterpart/') > -1) url = url.split('/call_counterpart/').join('/call_counterpart=/');
        }
      });

      
      //検索項目の指定がない場合リロードする
      if(url == '/' && !this.condition_unknown) {
        location.reload();
        return false;
      }

      //分割フラグ用の日付追加
      if(typeof TF.split_flag == 'string' && TF.split_flag.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/)) url += TF.split_flag;

      TF.search_query = url;
      let urls_tmp = [url];
      
/*

      TF.search_urls = urls;

      //API改修まで使う処理
      let urls_tmp = [];
      urls.filter((v) => {
        let tmp = v.replace(TF.api.url, '');
        tmp = tmp.replace(/\/$/, '').split('/');
        urls_tmp.push(tmp);
      });

      //検索結果格納用
      this.search_results = [];
      
      this.get_count = 0;
*/
      //検索結果取得開始
      urls_tmp.filter((v) => {
        //追加クエリがあれば
        
        if(this.add_query.length > 0) {
          v += this.add_query.join('/');
        }

        let url = '';
        let add = (TF.search_record_to_text)? '/sel.record_to_text': '';

        if(TF.split_flag && table == 'call_history') {
          add += (TF.search_memo)? '/sel.memo_1': '';
          url = TF.api.url + table + '/sel.id/sel.recall_time/sel.closing_flag/sel.cancel_flag' + add + v.replace(/\/$/,'') + TF.api.key;
          TF.search_memo_1 = TF.search_memo;
        } else {
          //add = (TF.search_memo)? '/sel.remarks': '';
          url = TF.api.url + table + '/' + this.select_column_query[table] + add + v.replace(/\/$/,'') + TF.api.key;
          TF.search_remarks = TF.search_memo;
        }

        let opt = {
          //url: TF.api.url + v.join('/') + TF.api.key,
          url: url,
          type: 'GET'
        }

        if(TF.search_record_to_text || TF.search_memo) {
          opt.type = 'POST'; 
          opt.url =  TF.js_url_path + 'data/search.php';
          opt.data = {query: encodeURIComponent(url), search_record_to_text: TF.search_record_to_text, search_memo_1: TF.search_memo_1, search_remarks: TF.search_remarks};
        }
  
        //実行結果
        const doFunc = (d) => {
          //this.get_count++;
//          console.log(d);
//          this.loading.end();
//          return;
          d = JSON.parse(d);

          if(TF.search_record_to_text || TF.search_memo) {
            message('絞り込み完了<br>データ取得開始');
          }

          this.get_result = d.sort(function(a,b){
            if(a.id - 0 > b.id - 0) return -1;
            if(a.id - 0 < b.id - 0) return 1;
            return 0;
          });

          if(this.condition_unknown) {
            let tmp = [];
            let recall_time = moment().unix();
            for(let i in this.get_result) {
              let recall_timestamp = moment(this.get_result[i].recall_time, 'YYYY-MM-DD HH:mm:ss').unix();
              if(
                !this.get_result[i].closing_flag &&
                !this.get_result[i].cancel_flag &&
                recall_time > recall_timestamp
                ) {
                 
                tmp.push(this.get_result[i]);
              }
            }
            this.get_result = tmp;
          }

          //ページャー設定（取得件数からデータ絞り込み）
          this.listControlerInit(table, false);

          //ページ分割のフラグが立っている場合
          if(TF.split_flag && table == 'call_history') {
            TF.history_id = this.get_result;
            this.getList('call_history');
          } else {
            //表示
            TF.List.printList();
            this.edit(table);
          }

          $('.js-modal-area').fadeOut(100);
          this.loading.end();

          /*
          //検索結果にデータ格納
          if(this.search_results.length == 0) {
            this.search_results = d;
          } else {
            this.search_results = this.search_results.concat(d);
          }

          //検索用クエリと検索結果の数が一緒になれば
          if(this.get_count ==  urls.length) {

            this.get_result = this.search_results.sort(function(a,b){
              if(a.id - 0 > b.id - 0) return -1;
              if(a.id - 0 < b.id - 0) return 1;
              return 0;
            });
            
            //ページャー設定（取得件数からデータ絞り込み）
            this.listControlerInit(table, false);

            //表示
            TF.List.printList();
            this.edit(table);
            $('.js-modal-area').fadeOut(100);
            this.loading.end();
          }*/
        }
  
        const failFunc = (d) => {
          console.log(d);
          message('データを取得できませんでした。',1);
          $('.js-modal-area').fadeOut(100);
          this.loading.end();
        }

        getApiDataCommon(opt, doFunc, failFunc);
      });
    });

  }

  //追加
  add(type, sync_api) {
    const _this = this;
    this.api = TF.api.url;

    let modalRun = (e) => {
      TF.modal_click_obj = e.currentTarget;
      TF.modal = $('.js-modal-area.-add-' + type);
      TF.modal.find('.-disable').removeClass('-disable');
      TF.modal.find('.-require').removeClass('-require');
      TF.modal.fadeIn(100, function() {
        $(this).addClass('-active');
      });

      $('.js-btn-reset').mousedown();

      if(typeof TF.addModalFunc == 'function') {
        TF.addModalFunc(e);
      }
    }
    $('.js-modal.-add-' + type).on('mousedown', modalRun);
    
    $('.js-modal-area.-add-' + type + ' .js-btn-add').on('mousedown', (e) => {
      e.preventDefault();
      $(e.currentTarget).addClass('-disable');

      if(this.entry_data.action == 'edit') {
        console.log(this.entry_data, sync_api );
      } else {
        this.entry_data = {};
        this.query_data = [];
        this.entry_data.action = 'add';
        this.entry_data.type = type;
        this.entry_data.request_type = 'POST';
        this.sync_api = sync_api;
      }

      this.dataEntry();
      return false;
    });
  }
  
  // 編集
  edit(type, sync_api) {
    this.entry_data = {};
    this.query_data = [];

    //保存ボタンクリック
    $('.p-data.-' + type + ' .js-btn-edit, .p-data.-' + type + ' .js-btn-reload').on('mousedown', (e) => {
      e.preventDefault();
      let id = this.entry_data.id;
      this.query_data = [];
      this.entry_data = {};
      this.entry_data.action = 'edit';
      this.entry_data.type = type;
      this.entry_data.request_type = 'PUT';
      this.entry_data.request_datatype = 'text';
      TF.edit_obj = e.currentTarget;
      this.entry_data.parent = $(TF.edit_obj).closest('.p-data-table-tr, .p-data-input-group, .p-data-input-area');

      if(!this.entry_data.id) {
        this.entry_data.id = this.entry_data.parent.data('id') || id;
      }
      if(!this.entry_data.telforce_id) {
        this.entry_data.telforce_id = this.entry_data.parent.data('telforce_id');
      }
      this.api = TF.api.url;
 
      this.sync_api = sync_api + this.entry_data.id;
      
      this.dataEntry();
      return false; 
    });

    //ユーザー管理用
    if(type == 'staff') {
      //プルダウンで選択したときにも実行する
      TF.selectAction = (e) => {
        if(e) e.preventDefault();
        this.query_data = [];
        this.entry_data = {};
        this.entry_data.action = 'edit';
        this.entry_data.type = type;
        this.entry_data.request_type = 'PUT';
        this.entry_data.request_datatype = 'text';
        TF.modal = $('.js-modal-area.-add-' + type);
        TF.modal.find('.-disable').removeClass('-disable');
        TF.modal.find('.-require').removeClass('-require');

        if(TF.select_data) {
          this.entry_data.telforce_id = TF.select_data.telforce_id;
        } else {
          this.entry_data.telforce_id = $(e.currentTarget).data('id');
        }
        TF.select_data = TF[type][this.entry_data.telforce_id];

        this.sync_api = sync_api + TF.select_data.id;

        //個別データ取得
        getApiDataCommon({
          url: TF.api.url + 'staff/id/' + this.entry_data.telforce_id + TF.api.key,
          method: 'GET'
        },
        (d) => {
          d = JSON.parse(d)[0];
          
          if(!d || d == 0) {
            message('このユーザー情報の取得に失敗しました', 1);
            return false;
          }

          TF.select_data.telforce_id = d.id;
          TF.select_data.login_id = d.login_id;
          TF.select_data.login_passwd = d.login_passwd;
          TF.select_data.active_flag = d.active_flag;
          TF.select_data.career_rec_number = d.career_rec_number;
          TF.select_data.email = d.email;
          TF.select_data.dept_unit_id = d.dept_unit_id;
          TF.select_data.office_id = d.office_id;


          $('.js-modaltitle').html(`${TF.select_data.name}[${TF.select_data.login_id}]の情報を編集`);
          //フォームに値をセット
          TF.getSelectData();
        },
        (d) => {
          message('データ取得失敗<br>サーバからレスポンスが返ってきませんでした。', 1);
        });
       
        //モーダルが表示されてなかったら
        if(!TF.modal_click_obj) {
          TF.modal_click_obj = e.currentTarget;
          TF.modal.fadeIn(100, function() {
            $(this).addClass('-active');
          });
        }
        
        return false; 
      }

      $('.p-data-table-td-editbtn.js-data-edit').on('mousedown', TF.selectAction);
      return false; 

    }

    //モーダル起動用
    $('.p-data.-' + type + ' .js-modal.-edit').on('mousedown', (e) => {
      e.preventDefault();
      this.entry_data = {};
      this.query_data = [];
      TF.modal_click_obj = e.currentTarget;
      let id = $(e.currentTarget).data('id');
 
      this.query_data.push('id');
      this.query_data.push(id);
      this.entry_data.id = id;
     
      TF.modal = $('.js-modal-area.-edit-' + type);
      TF.modal.find('.-disable').removeClass('-disable');
      TF.modal.find('.-require').removeClass('-require');
      TF.select_data = TF.list_contents.filter((v) => v.id - 0 === id );
      TF.select_data = TF.select_data[0];
      TF.getSelectData();

      this.sync_api = sync_api;

      if(typeof TF.editModalFunc == 'function') {
        TF.editModalFunc(e);
      }
      TF.modal.fadeIn(100, function() {
        $(this).addClass('-active');
      });
      return false; 
    });
  }

  dataEntry() {

    let doFunc = {}, failFunc = {}, json;

    //DBをqueryに追加
    if(this.query_data && this.query_data.length > 0) {
      if(this.query_data.indexOf(this.entry_data.type) == -1) this.query_data.unshift(this.entry_data.type);
      delete this.entry_data.id;
    } else {
      let id = this.entry_data.telforce_id || this.entry_data.id;

      if(this.entry_data.action == 'edit') {
        this.query_data = [this.entry_data.type, 'id', id];
      } else {
        this.query_data = [this.entry_data.type];
      }
    }

    const _this = this;

    const opt = {
      url: this.api + this.query_data.join('/') + TF.api.key,
      type: this.entry_data.request_type,
    }

    /********************
     * リスト管理・禁止リスト
     * *******************/
    if(this.entry_data.type.indexOf('customer') > -1) {
      let modal = $('.p-modal-area');

      this.entry_data.tel_1 = $('#tel_no_edit').val();
      if(!this.entry_data.tel_1) {
        $('#tel_no_edit').addClass('-require');
        alert('架電先の電話番号を入力してください');
        modal.find('.-disable').removeClass('-disable');
        return false;
      }

      this.entry_data.add_info12 = $('#add_info12_edit').val();
      
      this.entry_data.staff_id = $('#staff_edit_select').val();

      this.entry_data.add_info100 = $('#jobs_edit_select').val();

      this.entry_data.prefecture = $('#area_edit_select').val();

      this.entry_data.call_status = $('#call_status_edit_select').val();

      this.entry_data.call_counterpart = $('#add_info98_edit_select').val();

      this.entry_data.prohibit_flag = $('#prohibit_flag_edit:checked').val()?1:null;

      this.entry_data.prohibit_flag = !this.entry_data.prohibit_flag? 0:this.entry_data.prohibit_flag;

      this.entry_data.remarks = $('#remarks_edit').val();

      if(this.entry_data.request_datatype) {
        opt.dataType = this.entry_data.request_datatype;
        delete this.entry_data.request_datatype;
      }
      json = _this.dataScheme('customer');

      if(!json) {
        message('データに変更がありません');
        this.loading.end();
        return false;
      }

      const json_text = JSON.stringify(json);
      opt.data = '';
      opt.data = json_text;

      doFunc = (d) => {
        if(d == 0) {
          message('ステータス登録失敗');
          this.loading.end();
          return false;
        } 
        
        if(d == 1) {
          this.entry_data.id = this.entry_data.id;
        } else {
          this.entry_data.id = JSON.parse(d)[0] || this.entry_data.id;
        }
        console.log('変更時の返却値', d);
        message('変更完了');
        console.log('変更時に次のデータを送信しました', json);
        console.log('リロードします');
        
        location.reload();
        return;
      }

      failFunc = (err) => {
        console.log(err);
        message('データ登録失敗', 1);
        this.loading.end();
      }
    } // リスト管理・禁止リスト end

    /********************
     * ステータス登録
     * *******************/
    if(this.entry_data.type.indexOf('call_status') > -1) {
      switch(this.entry_data.action) {
        case 'add': {
          this.entry_data.name = $('#call_status_name').val();
          this.entry_data.fixed_phrase = $('#call_status_memo').val();
          this.entry_data.active_flag = 1;
          this.entry_data.client_id = 2;
          break;
        }
        case 'edit': {
          this.entry_data.name = this.entry_data.parent.find('input[name="name"]').val();
          this.entry_data.fixed_phrase = this.entry_data.parent.find('textarea').val();
          this.entry_data.active_flag = $(`#${this.entry_data.type}_active_flag_${this.entry_data.id}:checked`).val();
          this.entry_data.active_flag = typeof this.entry_data.active_flag == 'undefined'? null: this.entry_data.active_flag;
          break;
        }
      }

      if(this.entry_data.request_datatype) {
        opt.dataType = this.entry_data.request_datatype;
        delete this.entry_data.request_datatype;
      }

      delete this.entry_data.telforce_id;

      json = _this.dataScheme('call_status');
      
      if(!json) {
        message('データに変更がありません');
        return false;
      }

      json = JSON.stringify(json);
      opt.data = '';
      opt.data = json;

      doFunc = (d) => {
        if(d == 0) {
          message('ステータス登録失敗');
          this.loading.end();
          return false;
        } 
        
        if(d == 1) {
          this.entry_data.id = this.entry_data.id;
        } else {
          this.entry_data.id = JSON.parse(d)[0] || this.entry_data.id;
        }

        console.log('ステータス登録時の返却値', d);
        message('ステータス登録完了');
        console.log('ステータス登録時に次のデータを送信しました', json);
        TF.call_status = null;
        TF.GetData.getStatusList();
        const sit = setInterval(() => {
          if(TF.call_status.length > 0) {
            clearInterval(sit);
            TF.list_contents.call_status = TF.call_status;
            TF.List.printInputList('set1', 'call_status');
            this.edit('call_status');
            this.del('call_status');
            $('.js-modal-area').fadeOut(100, function(e) {
              $(this).find('.-disable').removeClass('-disable');
            });

            this.loading.end();
          }
        },TF.intervaltime);
      }

      failFunc = (err) => {
        console.log(err);
        this.loading.end();
        message('データ登録失敗', 1);
      }
    } // ステータス登録 end

    /********************
     * 商材（商材）登録
     * ******************
    if(this.entry_data.type.indexOf('call_counterpart') > -1) {
      delete this.entry_data.telforce_id;
      this.entry_data.relationJobs = {};

      switch(this.entry_data.action) {
        case 'add': {
          this.entry_data.name = $('#call_counterpart_name').val();
          this.entry_data.active_flag = 1;
          this.entry_data.client_id = 2;
          this.entry_data.relationJobs.jobs = $('#jobs_select').val();
          break;
        }
        case 'edit': {
          this.entry_data.name = this.entry_data.parent.find('input[name="name"]').val();
          this.entry_data.active_flag = $(`#${this.entry_data.type}_active_flag_${this.entry_data.id}:checked`).val();
          this.entry_data.active_flag = typeof this.entry_data.active_flag == 'undefined'? null: this.entry_data.active_flag; 
          this.entry_data.relationJobs.id = this.entry_data.id;
          this.entry_data.relationJobs.jobs = $(`#jobs_${this.entry_data.id}_select`).val();
          break;
        }
      }
      
      if(this.entry_data.request_datatype) {
        opt.dataType = this.entry_data.request_datatype;
        delete this.entry_data.request_datatype;
      }

      json = _this.dataScheme('call_counterpart');
      delete json.jobs;

      this.entry_data = this.entry_data.relationJobs;
      const json2 = this.dataScheme('jobRelations');

      if(!json && !json2) {
        message('データに変更がありません');
        return false;
      }

      if(json) {
        json = JSON.stringify(json);
        opt.data = json;
      } 
      

      //リスト更新
      let listReload = () => {
        TF.call_counterpart = null;
        TF.GetData.getCounterpartList();
        const sit = setInterval(() => {;
          if(TF.call_counterpart) {
            clearInterval(sit);
            TF.list_contents.call_counterpart = TF.call_counterpart;
            TF.List.printInputList('set3', 'call_counterpart');
            this.edit('call_counterpart');
            this.del('call_counterpart');
            $('.js-modal-area').fadeOut(100, function(e) {
              $(this).find('.-disable').removeClass('-disable');
            });
            this.loading.end();
          }
        },TF.intervaltime);
      }

      const doFunc1 = (d) => {
        if(d == 0) {
          this.loading.end();
        
          if(json) {
            message('商材登録失敗');
            return false;
          }          
        } 
        
        if(d == 1) {
          this.entry_data.id = this.entry_data.id;
        } else {
          this.entry_data.id = JSON.parse(d)[0] || this.entry_data.id;
          json2.id = this.entry_data.id;
        }

        console.log('商材登録時の返却値', d);
        message(TF.call_counterpart_label + '登録完了');
        console.log('商材の登録時に次のデータを送信しました', json);

        //リスト更新
        listReload();

        console.log('次の業種を登録します', json2);

        if(Object.keys(json2).length > 0) {
          this.loading.start();
          getApiDataCommon(opt2, doFunc2, failFunc2);
        }
      }

      const failFunc1 = function(err) {
        console.log(err);
        this.loading.end();
        message('データ登録失敗', 1);
      }

      const opt2 = {
        url: '/api/job/add',
        type: 'POST',
        data: json2,
      }

      const doFunc2 = (d) => {
        console.log('紐づけ後の返却値', d);
        message(TF.call_counterpart_label + 'と業種の紐づけが完了しました。');
        console.log('商材と業種の紐づけに次のデータを送信しました', json2);
        console.log(d);
        d = JSON.parse(d);

        location.reload();
        /*
        //商材・業種紐づけリスト更新
        if((d.success == true && d.data.length > 0)) {
          d.data.filter((v) => {
            if(typeof TF.jobRelations[json2.id] == 'undefined') TF.jobRelations[json2.id] = [];
            TF.jobRelations[json2.id].push({id: v.id, job_id: v.job_id})
          });

          //select2用にリスト再作成
          for(let i in TF.jobRelations) {
            let obj = TF.jobRelations[i];
            TF.list_contents.select2values[i] = [];
            
            TF.jobs.filter((v) => {
              obj.filter((vv, ii) => {
                if(vv.job_id - 0 == v.id - 0) { 
                  TF.list_contents.select2values[i].push({id: v.id, name: v.name, relation_id: vv.id});
                }
              });
            })
  
          };
         
          //リスト更新
          listReload();
        } else {
          this.loading.end();
        }
      }

      const failFunc2 = (err) => {
        console.log(err);
        this.loading.end();
        message('データ登録失敗', 1);
      }

      if(Object.keys(json).length > 0) {
        //商材登録
        this.loading.start();
        getApiDataCommon(opt, doFunc1, failFunc1);
      }

      if(!json && Object.keys(json2).length > 0) {
        //次に業種と登録した商材を紐づける
        this.loading.start();
        getApiDataCommon(opt2, doFunc2, failFunc2);
      }

      return false;
    } // 商材（商材）登録 end*/

    /********************
    * オフィス登録
    * *******************/
    if(this.entry_data.type.indexOf('office') > -1) {
      this.sync_data = {};

      switch(this.entry_data.action) {
        case 'add': {
          this.entry_data.office_name = $('#office_name').val();
          this.sync_data.office_name = this.entry_data.office_name;
          break;
        }
        case 'edit': {
          this.entry_data.office_name = this.entry_data.parent.find('input[name="name"]').val();

          this.sync_data.office_name = this.entry_data.office_name;
          this.sync_api = this.sync_api;
          break;
        }
      }
      
      if(this.entry_data.request_datatype) {
        opt.dataType = this.entry_data.request_datatype;
        delete this.entry_data.request_datatype;
      }

      json = _this.dataScheme('office');

      if(!json) {
        message('データに変更がありません');
        return false;
      }

      if(this.entry_data.telforce_id) json.id = this.entry_data.telforce_id;

      opt.data = '';
      opt.data = JSON.stringify(json);
      
      doFunc = (d) => {
        let telforce_id;
        if(d == 0) {
          message('データ登録失敗');
          this.loading.end();
          return false;
        } 
        
        /*if(d == 1) {
          this.entry_data.id = this.entry_data.id;
        } else {
          telforce_id = JSON.parse(d)[0];
          this.entry_data.id = telforce_id || this.entry_data.id;
        }

        this.entry_data.telforce_id = d.data[0] || this.entry_data.telforce_id;
        if(!json.id) json.id = this.entry_data.telforce_id;*/

        if(d == 1) {
          this.entry_data.telforce_id = this.entry_data.telforce_id;
        } else {
          telforce_id = JSON.parse(d)[0];
          this.entry_data.telforce_id = telforce_id || this.entry_data.telforce_id;
        }

        if(!json.id) json.id = this.entry_data.telforce_id;

        //戻ってきたらOFFICEの同じIDのデータを上書き
        if(d == 1) {
          for(let i in OFFICE) {
            if(OFFICE[i].telforce_id == json.id) {
              for(let ii in OFFICE[i]) {
                if(ii != 'id') {
                  if(ii == 'telforce_id') {
                    OFFICE[i].telforce_id = json.id - 0;
                  } else if(json[ii]) {
                    OFFICE[i][ii] = json[ii];
                  }
                }
              }
            }
          }

        //新規追加
        } else {
          let tmp = {};
          for(let i in OFFICE[0]) {
            tmp[i] = '';
          }

          for(let i in tmp) {
            if(i == 'telforce_id') {
              tmp.telforce_id = this.entry_data.telforce_id;
            } else if(i != 'id') {
              tmp[i] = json[i]? json[i]: null;
            }
          }

          OFFICE.push(tmp);
        }

        console.log('オフィス登録時の返却値', d);
        message('オフィス登録完了');
        console.log('オフィス登録時に次のデータを送信しました', json);

        /*TF.GetData.getOfficeList();
        const sit = setInterval(() => {
          if(TF.office && TF.office.length > 0) {
            clearInterval(sit);
            TF.list_contents.office = TF.office;
            TF.List.printInputList('single', 'office');
            this.edit('office');
            this.del('office');
            $('.js-modal-area').fadeOut(100, function(e) {
              $(this).find('.-disable').removeClass('-disable');
            });
            this.loading.end();
          }
        },TF.intervaltime);*/

        //サングローブサーバと同期
        if(this.entry_data.telforce_id) {
          this.sync_data.telforce_id = this.entry_data.telforce_id;
        } else {
          this.sync_data.telforce_id = json.id;
        }
       
        console.log('同期開始');
        getApiDataCommon({
          url: this.sync_api,
          type: 'POST',
          data: this.sync_data,
        }, (d) => {
          d = JSON.parse(d);

          message('同期完了');

          //新規追加されたデータをサングローブ側のIDで新規登録したデータOFFICEに追加
          for(let i in OFFICE) {
            if(OFFICE[i].telforce_id == json.id) {
              OFFICE[i].id = d.data.id;
            }
          }

          TF.office = OFFICE;
          TF.list_contents.office = TF.office;
          TF.List.printInputList('single', 'office');
  
          this.edit('office', '/api/office/edit/');
          this.del('office', '/api/office/remove/');
  
          $('.js-modal-area').fadeOut(100, function(e) {
            $(this).find('.-disable').removeClass('-disable');
          });
          this.loading.end();

        }, (d) => {
          message('同期失敗');
          this.loading.end();
        });
      }

      failFunc = function(err) {
        console.log(err);
        this.loading.end();
        message('データ登録失敗', 1);
      }
    } // オフィス登録 end

     /********************
     * グループ登録
     * *******************/
    if(this.entry_data.type.indexOf('dept_unit') > -1) {
      this.sync_data = {};

      switch(this.entry_data.action) {
        case 'add': {
          this.entry_data.unit_name = $('#unit_name').val();
          this.entry_data.email = $('#email').val();

          this.sync_data.unit_name = this.entry_data.unit_name;
          this.sync_data.email = this.entry_data.email;
          break;
        }
        case 'edit': {
          this.entry_data.unit_name = this.entry_data.parent.find('input[name="name"]').val() + '';
          this.entry_data.email = this.entry_data.parent.find('input[name="email"]').val() + '';

          this.sync_data.unit_name = this.entry_data.unit_name;
          this.sync_data.email = this.entry_data.email;
          this.sync_api = this.sync_api;
          break;
        }
      }

      if(this.entry_data.request_datatype) {
        opt.dataType = this.entry_data.request_datatype;
        delete this.entry_data.request_datatype;
      }

      json = _this.dataScheme('dept_unit');

      if(!json) {
        message('データに変更がありません');
        this.loading.end();
        return false;
      }

      if(this.entry_data.telforce_id) {
        delete json.telforce_id;
        delete json.id;
      } 

      opt.data = '';
      opt.data = JSON.stringify(json);

      doFunc = (d) => {
        if(d == 0) {
          message('データ登録失敗', 1);
          this.loading.end();
          return false;
        } 
        
        if(d == 1) {
          this.entry_data.telforce_id = this.entry_data.telforce_id;
        } else {
          this.entry_data.telforce_id = JSON.parse(d)[0] || this.entry_data.telforce_id;
        }

        
        if(!json.id) json.id = this.entry_data.telforce_id;

        //戻ってきたらDEPT_UNITの同じIDのデータを上書き
        if(d == 1) {
          for(let i in DEPT_UNIT) {
            if(DEPT_UNIT[i].telforce_id == json.id) {
              for(let ii in DEPT_UNIT[i]) {
                if(ii != 'id') {
                  if(ii == 'telforce_id') {
                    DEPT_UNIT[i].telforce_id = json.id - 0;
                  } else if(json[ii]){
                    DEPT_UNIT[i][ii] = json[ii];
                  }
                }
              }
            }
          }

        //新規追加
        } else {
          let tmp = {};
          for(let i in DEPT_UNIT[0]) {
            tmp[i] = '';
          }

          for(let i in tmp) {
            if(i == 'telforce_id') {
              tmp.telforce_id = JSON.parse(d)[0];
            } else if(i != 'id') {
              tmp[i] = json[i]? json[i]: null;
            }
          }

          DEPT_UNIT.push(tmp);
        }

        console.log('グループ登録時の返却値', d);
        message('グループ登録完了');
        console.log('グループ登録時に次のデータを送信しました', json);

        /*TF.GetData.getGroupList();
        const sit = setInterval(() => {
          if(TF.dept_unit && TF.dept_unit.length > 0) {
            clearInterval(sit);
            TF.list_contents.dept_unit = TF.dept_unit;
            TF.List.printInputList('two', 'dept_unit');
            this.edit('dept_unit');
            this.del('dept_unit');
            $('.js-modal-area').fadeOut(100, function(e) {
              $(this).find('.-disable').removeClass('-disable');
            });
            this.loading.end();
          }
        },TF.intervaltime);*/

        //サングローブサーバと同期
        if(JSON.parse(d)[0]) {
          this.sync_data.telforce_id = JSON.parse(d)[0];
        } else {
          this.sync_data.telforce_id = json.id;
        }

        console.log('同期開始');
        getApiDataCommon({
          url: this.sync_api,
          type: 'POST',
          data: this.sync_data,
        }, (d) => {
          message('同期完了');

          //新規追加されたデータをサングローブ側のIDで新規登録したデータDEPT_UNITに追加
          for(let i in DEPT_UNIT) {
            if(d.id && DEPT_UNIT[i].telforce_id == json.id) {
              DEPT_UNIT[i].id = d.id;
            }
          }

          TF.dept_unit = DEPT_UNIT;
          TF.list_contents.dept_unit = TF.dept_unit;
          TF.List.printInputList('two', 'dept_unit');
  
          this.edit('dept_unit', '/api/group/edit/');
          this.del('dept_unit', '/api/group/remove/');
  
          $('.js-modal-area').fadeOut(100, function(e) {
            $(this).find('.-disable').removeClass('-disable');
            location.reload();
          });
          this.loading.end();

        }, (d) => {
          message('同期失敗');
          this.loading.end();
        });
      }

      failFunc = (err) => {
        console.log(err);
        this.loading.end();
        message('データ登録失敗', 1);
      }
    } // グループ登録 end

     /********************
     * ユーザー登録
     * *******************/
    if(this.entry_data.type.indexOf('staff') > -1) {

      if(this.entry_data.request_datatype) {
        opt.dataType = this.entry_data.request_datatype;
        delete this.entry_data.request_datatype;
      }

      let modal = $('.p-modal-area');

      this.entry_data.login_id = $('#login_id').val();
      if(this.entry_data.action == 'add') {
        this.entry_data.id = $('#login_id').val();
      }
      
      if(!this.entry_data.login_id) {
        $('#login_id').addClass('-require');
        alert('ログインIDを入力してください');
        modal.find('.-disable').removeClass('-disable');
        return false;
      }

      this.entry_data.name = $('#staff_name').val();
      if(!this.entry_data.name) {
        $('#staff_name').addClass('-require');
        alert('氏名を入力してください');
        modal.find('.-disable').removeClass('-disable');
        return false;
      }
      
      this.entry_data.office_id = $('#office_id').val();
      if(!this.entry_data.office_id) {
        $('#office_id').addClass('-require');
        alert('所属オフィスを選択してください');
        modal.find('.-disable').removeClass('-disable');
        return false;
      }

      this.entry_data.dept_unit_id = $('#dept_unit_id').val();

      this.entry_data.auth_level = $('#auth_level').val();
      if(!this.entry_data.auth_level) {
        $('#auth_level').addClass('-require');
        alert('ユーザー種別を選択してください');
        modal.find('.-disable').removeClass('-disable');
        return false;
      }

      this.entry_data.login_passwd = $('#login_passwd').val();
      //新規登録時のみ
      if(!this.entry_data.login_passwd && this.entry_data.action == 'add') {
        $('#login_passwd').addClass('-require');
        alert('パスワードを設定してください');
        modal.find('.-disable').removeClass('-disable');
        return false;
      }
      //編集時はセットされてなかったら
      if(!this.entry_data.login_passwd && this.entry_data.action == 'edit') {
       // delete this.entry_data.login_passwd;
      }

      this.entry_data.career_rec_number = $('#career_rec_number').val();



      json = _this.dataScheme('staff');

      if(!json) {
        message('データに変更がありません');
        return false;
      }

      let telforce_id = json.telforce_id;
      delete json.telforce_id;

      const json_text = JSON.stringify(json);
      opt.data = json_text;

      json.telforce_id = telforce_id;

      doFunc = (d) => {
        let telforce_id = json.telforce_id;
        this.loading.end();

        if(d == 0) {
          message('データ登録失敗', 1);  
          return false;
        } 

        if(d == 1) {
          this.entry_data.telforce_id = json.id;
          telforce_id = json.telforce_id;
        } else {
          telforce_id = JSON.parse(d)[0];
          this.entry_data.telforce_id = telforce_id || this.entry_data.telforce_id;
        }

        //戻ってきたらALL_USERSの同じIDのデータを上書き
        if(d == 1) {
          if(ALL_USERS[telforce_id]) {
            for(let i in ALL_USERS[telforce_id]) {
              if(i != 'id') {
                if(i == 'telforce_id') {
                  ALL_USERS[telforce_id].telforce_id = json.id - 0;
                } else if(json[i]){
                  ALL_USERS[telforce_id][i] = json[i];
                }
              }
            }
          }

        //新規追加
        } else {
          let tmp = {};
          for(let i in ALL_USERS[CURRENT_USER.telforce_id]) {
            tmp[i] = '';
          }

          for(let i in tmp) {
            if(i == 'telforce_id') {
              tmp.telforce_id = telforce_id;
            } else if(i != 'id') {
              tmp[i] = json[i]? json[i]: null;
            }
          }

          ALL_USERS[telforce_id] = tmp;
        }

        console.log('ユーザー登録時の返却値', d);
        message('ユーザー登録完了');
        console.log('ユーザー登録時に次のデータを送信しました', json);

        //サングローブサーバと同期
        this.sync_data = {};
        this.sync_data.office_id = json.office_id || '';
        this.sync_data.telforce_id = telforce_id || '';
        this.sync_data.name = json.name || '';
        this.sync_data.dept_unit_id = json.dept_unit_id || '';
        this.sync_data.login_id = json.login_id || '';
        this.sync_data.password = json.login_passwd || '';
        this.sync_data.career_rec_number = json.career_rec_number || '';
        this.sync_data.email = json.email || '';
        this.sync_data.auth_level = json.auth_level || '';

        console.log('同期開始');

        getApiDataCommon({
          url: this.sync_api,
          type: 'POST',
          data: this.sync_data,
        }, (d) => {
          message('同期完了');
          this.loading.end();         
          console.log('リロードします');
          location.reload();
        }, (d) => {
          message('同期失敗');
          this.loading.end();
        });

        return;
      }

      failFunc = function(err) {
        console.log(err);
        message('データ登録に失敗しました', 1);
        this.loading.end();
      }
    } // ユーザー登録 end


    //登録
    this.loading.start();
    getApiDataCommon(opt, doFunc, failFunc);
  } // add end

  // 削除
  del(type, sync_api) {
    $('.p-data.-' + type + ' .js-btn-del,  .p-data-table-td-delbtn.js-data-del').on('mousedown', (e) => {
      TF.del_obj = e.currentTarget;
      this.sync_api = sync_api;

      let parent = $(TF.del_obj).closest('.p-data-input-group, .p-data-input-area, .p-data-table-tr');
      let sungrove_id, telforce_id, name, del_cancel = false;
      
      if(type == 'staff') {

        telforce_id = $(TF.del_obj).data('id');
        name = TF[type][telforce_id].name;
        sungrove_id = TF[type][telforce_id].id;
        this.sync_api = this.sync_api + sungrove_id;

        getApiDataCommon({
          url: TF.api.url + 'staff/' + telforce_id + TF.api.key,
          type: 'GET',
        }, (d) => {

          if(JSON.parse(d) == 0) {
            message('このユーザーは存在しません',1);
            del_cancel = true;

            if(this.sync_api) {
              //サングローブサーバと同期
              getApiDataCommon({
                url: this.sync_api,
                type: 'POST',
              }, (d) => {
                message('同期完了');
                //リロード
                location.reload();
              }, (d) => {
                message('同期失敗');
              });
            }
            return false;
          }
        }, (d) => {
          del_cancel = true;
          message('ユーザー情報取得失敗');
          return false;
        });

      } else {
        //サングローブ側ID取得
        sungrove_id = parent.data('id');
        telforce_id = parent.data('telforce_id');

        name = parent.find('input[name="name"]').val();
        
        if(type == 'customer') {
          name = TF.list_contents.filter((v) => v.id - 0 === sungrove_id );
          name = name[0].tel_1;
        }

        if(type == 'office' || type == 'dept_unit') {
          telforce_id = parent.data('telforce_id');
          this.sync_api = this.sync_api + sungrove_id;
        }
      }
 
      if(!del_cancel && confirm('id:' + (telforce_id || sungrove_id) + "\n「" + name + "」\nこちらを削除してよろしいですか？")) {

        const opt = {
          url: TF.api.url + `${type}/id/${(telforce_id || sungrove_id)}` + TF.api.key,
          type: 'DELETE',
          dataType: 'text'
        }

        const doFunc = (d) => {
          parent.fadeOut(200, () => {
            $(this).remove();
          });
          message('id:' + (telforce_id || sungrove_id) + "\n「" + name + "」\nこちらの削除を完了しました。");

          if(typeof delAction == 'function' ) {
            delAction({id: id, table: type});
          }

          this.loading.end();

          if(this.sync_api) {
            //サングローブサーバと同期
            getApiDataCommon({
              url: this.sync_api,
              type: 'POST',
              //data: this.sync_data,
            }, (d) => {
              message('同期完了');
              //リロード
              location.reload();
            }, (d) => {
              message('同期失敗');
            });

          }

        }

        const failFunc = (err) => {
          console.log(err);
          this.loading.end();
          alert("削除に失敗しました。");
        }

        this.loading.start();
        getApiDataCommon(opt, doFunc, failFunc);
      }
    });
  } // del end

  // 一括処理
  bulk(type, action, query) {
    const _this = this;
    _this.bulk_ids = [];
    _this.query = query;

    let cls = action == 'DELETE'? 'del': '';
    cls = action == 'PUT'? 'edit': cls;

    let text = action == 'DELETE'? '削除':'変更';

    $('.js-bulk-' + cls).off('click').on('click', (e) => {
      _this.bulk_ids = [];
      let checkboxies = $('.js-data-check');
      for(let i in checkboxies)  {
        let elm = checkboxies.eq(i);
        
        if(elm.prop('checked')) {
          if(elm.attr('id').match(/row[0-9]+/)) {
            _this.bulk_ids.push(elm.attr('id').replace('row', ''));
          }
        }
      };


      if( _this.bulk_ids.length > 0 && confirm("「" +  _this.bulk_ids.join(',') + "」\nこちらを" + text + "してよろしいですか？")) {
        _this.loading.start();

        let count =  _this.bulk_ids.length;
        _this.bulk_ids.filter((v) => {
          let opt = {
            url: TF.api.url,
            type: action,
            dataType: 'text' 
          }

          if(_this.query) {
            opt.url = opt.url + [type, 'id', v].join('/') + TF.api.key;
            opt.data = JSON.stringify(_this.query);
            
          }

          const doFunc = function(d) {
            $(this).remove();
            count--;
            if(count == 0) {
              message("「" +  _this.bulk_ids.join(',') + "」\nこちらの" + text + "を完了しました。");
              if(typeof delAction == 'function' ) {
                delAction({id: id, table: type});
              }
  
              _this.loading.end();
              //リロード
              location.reload();
            }  
          }

          const failFunc = function(err) {
            console.log(err);
            _this.loading.end();
            alert("削除に失敗しました。");
          }


          getApiDataCommon(opt, doFunc, failFunc);
        });
      }
    });

    $('.js-bulk-alldel').off('click').on('click', (e) => {
      let mes = `検索項目が指定されていないので\n顧客データの禁止番号として登録されているデータ以外全て削除されます`;
      
      let query = 'customer/prohibit_flag!=/1';
      if(TF.search_query) {
        query += TF.search_query.replace(/\/$/, '');
        mes = '検索結果のデータを削除します';
      }

      if(confirm(mes)) {
        let opt = {
          url: TF.api.url + query + TF.api.key,
          type: 'DELETE',
          dataType: 'text' 
        }
  
        const doFunc = function(d) {
          _this.loading.end();
          //リロード
          location.reload(); 
        }
  
        const failFunc = function(err) {
          console.log(err);
          _this.loading.end();
          alert("削除に失敗しました。");
        }
        //console.log(opt);
        getApiDataCommon(opt, doFunc, failFunc);
      }
    });

  }// bulk end

  // リセット
  reset(parent) {
    
    let cls = parent + ' ' || '';
   
    $('.js-btn-reset').on('mousedown', (e) => {

      e.preventDefault();
  
      $(`${cls}input[type="text"], ${cls}input[type="tel"], ${cls}input[type="number"], ${cls}input[type="password"], ${cls}textarea, ${cls}select`).val('');
      $(`${cls}input[type="radio"], ${cls}input[type="checkbox"]`).prop("checked", false);

      let cal = $(`#date_start, #date_end, #recall_date_start, #recall_date_end`);
      cal.datepicker("clearDates");

      if(TF.modal) {
        cal.datepicker("update", '');
      }

      this.query_data = [];
      this.entry_data = {};
      TF.select_data = null;

      $('.js-modaltitle').html(`新規登録`);

      if($(`${cls}.p-data-selectbox`).not('.-select2-disable').length > 0) {
        //console.log($(`${cls}.p-data-selectbox`).length);
        //let ids = TF.select2_ids.join(',');
        
        if(!$('.-add-staff')[0]) {
          $(`${cls}.p-data-selectbox`).not('.-select2-disable').off('select2:select').off('select2:unselect').select2('destroy');
        }

        let select_list = this.select_list;
        select_list = select_list.concat(this.select_list_edit); 
        setSelectList(select_list);
      }

      if(typeof TF.resetFunc == 'function') {
        TF.resetFunc(e);
      }
      
      if(TF.modal && !TF.modal.hasClass('-active')) TF.modal = null;
      return false;
    })
  } // reset end

  // データ構築
  dataScheme(type) {
    let json = {};

    for(let i in this.entry_data ) {
      if(
        i == 'request_type' ||
        i == 'type' ||
        i == 'action' ||
        i == 'parent' ||
        i == 'relationJobs'
      ) continue;

      let key = i;

      if((type == 'office' || type == 'dept_unit') && i == 'name') {
        key = type == 'office'? 'office_name': key;
        key = type == 'dept_unit'? 'unit_name': key;
      }

      if(i == 'staff_name') {
        key = 'name';
      }

      
      //if(this.entry_data[i]) {
        json[key] = this.entry_data[i];

        /*if(i == 'login_id') {
          json.telforce_id = this.entry_data[i];
        }*/
      //}
      
    }
/*
    if(this.entry_data.id) {
      json.id = this.entry_data.id;
    }

    if(this.entry_data.name) {
      let key = type == 'office'? 'office_name': 'name';
      key = type == 'dept_unit'? 'unit_name': key;

      json[key] = this.entry_data.name;
    }

    if(this.entry_data.fixed_phrase) {
      json.fixed_phrase = this.entry_data.fixed_phrase;
    }

    if(this.entry_data.login_id) {
      json.login_id = this.entry_data.login_id;
    }
    
    if(this.entry_data.jobs) {
      json.jobs = this.entry_data.jobs;
    }

    if(this.entry_data.active_flag) {
      json.active_flag = this.entry_data.active_flag;
    }
    
    if(this.entry_data.client_id) {
      json.client_id = this.entry_data.client_id;
    }
    */
   
    return this.validate(type, json);
  }

  //データ変更チェック
  validate(type, json) {
    let tmp = {};

    if(json && Object.keys(json).length > 0) {
      if (json.id && !(type == 'staff' && this.entry_data.action == 'add')) {
        /*
        if(type == 'jobRelations' && json.jobs.length == 0) {
          return false;
        } 

        if(type == 'jobRelations' && TF[type].length == 0) {
          return json;
        } */

        for(let i in TF[type]) {
          let obj = TF[type][i];
          let id = type == 'jobRelations'? i: (type == 'staff')? obj.telforce_id: obj.id;

          if((json.id|0) == (id|0)) {
          
            for(let ii in json) {
              /*
              if(type == 'jobRelations' && ii == 'jobs') {
                let orig = [];
                obj.filter((iii) => {orig.push(iii.job_id + '')});

                let jobs = [];
                if(json[ii]) {
                  json[ii].filter((v) => {
                    if(orig.indexOf(v) == -1) {
                      jobs.push(v);
                    } 
                  });
                  if(jobs.length > 0) tmp[ii] = jobs;
                } else {
                  tmp[ii] = null;
                }
              } else*/
              if(ii != 'id' && json[ii] != obj[ii]) {
                tmp[ii] = json[ii];
              }
            }
          } else if(type == 'jobRelations') {
            tmp = json;
          }
        } 

        if(Object.keys(tmp).length > 0) {
          tmp.id = json.id + '';
          json = tmp;
        } else {
          json = false;
        }
      }
      
      return json;
    } else {
      return false;
    }
  }
}